import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})

export class ResetBotService implements OnDestroy {    
    $resetBotSync: Observable<boolean>;
    // Need replaysubject so data loads on first-time page load
    private resetBotStatus = new ReplaySubject<boolean>(1);
    private ngUnsubscribe = new Subject<boolean>();

    constructor() {
        this.$resetBotSync = this.resetBotStatus.asObservable();
    }

    resetBotIcon(isNav: boolean) {        
        this.resetBotStatus.next(isNav);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}