import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'app-unconnected-memory',
  templateUrl: './unconnected-memory.component.html',
  styleUrls: ['./unconnected-memory.component.scss']
})
export class UnconnectedMemoryComponent implements OnInit {
  memoryTitle: string;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.memoryTitle = GlobalConstants.MEMORY_TITLE;
  }

}
