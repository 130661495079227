import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import {​​​​​​​​ QrConstants }​​​​​​​​ from'../../../../core/services/qr-input-service/qr.constants';



@Injectable({
  providedIn: 'root'
})
export class GraphicsService {
panel = [];
isNonIntel: boolean;
graphicsMemory: number;
isAppGaming: boolean;

isTakeAndStore = false;
graphicBadge: string;
deviceName : string;


graphicDescription: string;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }



setDefaultData(pageTitles): void {​​​​​​​​
const qrData = this.qrCodeService.getQrData();
this.isNonIntel = qrData.ContentType === GlobalConstants.NON_INTEL_TEXT;
this.graphicsMemory = Number(qrData.GraphicsMemory);
this.deviceName = qrData.DeviceName;
this.isAppGaming = this.qrCodeService.isAppGaming();
    // IJR-98 Multiple Graphics Start
  if(pageTitles === GlobalConstants.GRAPHICS_CARD) {
    let checkValue = false;
    this.qrCodeService.currentGraphicsCard.subscribe(msg => {
      checkValue = msg;
      if(checkValue) {
        this.graphicBadge = qrData?.GraphicsBadge;
        this.graphicDescription = qrData?.GraphicsDescription;
      } else {
        this.graphicBadge = qrData?.SecondaryGraphicsBadge;
        this.graphicDescription = qrData?.SecondaryGraphicsCard;
      }
    });
  } else {
    if(qrData?.GraphicsBadge) {
      this.graphicBadge = qrData?.GraphicsBadge;
      this.graphicDescription = qrData?.GraphicsDescription;
    } else {
      this.graphicBadge = qrData?.SecondaryGraphicsBadge;
      this.graphicDescription = qrData?.SecondaryGraphicsCard;
    }
    
  }
  // IJR-98 Multiple Graphics End
  }​​​​​​​​



  displayGraphicsDetailPage(pageTitles) {
    this.panel = [];
    this.qrCodeService.getQrData(); 
    this.setDefaultData(pageTitles);

    // IIT2-2350 ACR Graphics start

    if (this.isNonIntel) {
      const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.NON_INTEL_NAME, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        subTitle: panelData.tr_subtitle
      };
      this.panel.push(panel1);
    }
    else if(QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)!=-1) {
      let graphicsModel=this.qrCodeService?.qrInput?.GraphicsBadge;
      if(GlobalConstants.ARC_A5XXM_SERIES.includes(this.qrCodeService?.qrInput?.GraphicsBadge)){
        graphicsModel = GlobalConstants.ARC_A5XXM; 
      }

      let panelData=this.libraryService.getArcPages(QrConstants.ARC_MODEL[graphicsModel]);
      this.panel=panelData;
    } else if ((QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge) === -1) && (this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A')) {
        let panelData=this.libraryService.getArcFalbackGraphicsDetails(this.qrCodeService?.qrInput?.GraphicsBadge);
        this.panel = panelData;   
    } else {

    // IIT2-2350 ACR Graphics  end
     if (this.isAppGaming){ 
      if(this.graphicBadge === QrConstants.BADGE_GIN012) {
        let graphicsModel = this.graphicBadge;
        let panelData=this.libraryService.getArcPages(QrConstants.ARC_MODEL[graphicsModel]);
        this.panel=panelData;
       
      } else if (QrConstants[this.graphicBadge] === GlobalConstants.NVIDIA_TEXT){
        this.setGamingPanel1Data(GlobalConstants.OTHERS);
      }else  {
        if (this.graphicBadge === QrConstants.BADGE_GIN007 || this.graphicBadge === QrConstants.BADGE_GIN008) {
          this.setGamingPanel1Data(QrConstants[this.graphicBadge]);
        } else {
          if(this.graphicBadge === QrConstants.BADGE_GIN006) { // IJR-1021 Bug Fixess
            // IJR-1029 fix start
            if(this.graphicsMemory <= 0){
              this.setGamingPanel1Data(QrConstants.GIN0010);
            } else { // IJR-1029 fix ends
              this.setGamingUHDContent(QrConstants.UHD_CONTENT);
            }
          } else if (this.graphicBadge === QrConstants.BADGE_GIN011) {
            this.setGamingPanel1DataIntel25(); // IIT2-1970
          } else {
            
            
            this.setGamingPanel1Data(QrConstants.GIN0010);
          }
        }
      }
    }​​​​​​​​ else {
      if(QrConstants.ARC_GRAPHICS.indexOf(this.graphicBadge)!=-1 || this.graphicBadge === QrConstants.BADGE_GIN012) {
        let graphicsModel = this.graphicBadge;
        if(GlobalConstants.ARC_A5XXM_SERIES.includes(this.graphicBadge)){
          graphicsModel = GlobalConstants.ARC_A5XXM; 
        }
        let panelData=this.libraryService.getArcPages(QrConstants.ARC_MODEL[graphicsModel]);
        this.panel=panelData;
       
      } else if ((QrConstants.ARC_GRAPHICS.indexOf(this.graphicBadge) === -1) && (this.graphicBadge[0] === 'A')) {
        let panelData=this.libraryService.getArcFalbackGraphicsDetails(this.qrCodeService?.qrInput?.GraphicsBadge);
        this.panel = panelData;           
      } else if (this.graphicBadge === QrConstants.BADGE_GIN007 || this.graphicBadge === QrConstants.BADGE_GIN008 ||
        this.graphicBadge === QrConstants.BADGE_GIN003){
        this.setGraphicPanels(QrConstants[this.graphicBadge]);
      } else if(this.graphicBadge === QrConstants.BADGE_GIN013){
        this.setIntelGraphicPanels(QrConstants[this.graphicBadge]);
      } else if (this.graphicBadge === QrConstants.BADGE_GIN001 || this.graphicBadge === QrConstants.BADGE_GIN000) {
        this.setNonIntelGraphicPanels(QrConstants.GIN000);
      } else if (this.graphicBadge === QrConstants.BADGE_GIN002 || this.graphicBadge === QrConstants.BADGE_GIN004 ||
        this.graphicBadge === QrConstants.BADGE_GIN006) {
        this.setNonIntelGraphicPanels(QrConstants.GIN002);
      } else if (this.graphicBadge === QrConstants.BADGE_GIN010) {
        this.setUHDFeaturedIris();
      } else if (this.graphicBadge === QrConstants.BADGE_GIN009 ) {
        this.setNonIntelGraphicPanels(QrConstants.GIN009);  // IJR - 1020
      } else {
        this.setGraphicPanels(QrConstants.OTHERS);
      }
    }
  }
    return this.panel;
  }

  setGraphicPanels(graphicType): void{
    const badgeIconsArr = [];
    const data = this.libraryService.getFactTagsDetails(graphicType, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
      GlobalConstants.DEFAULT_TYPE);
    const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
    GlobalConstants.DEFAULT_TYPE);
    badgeIconsArr.push(data2.badgeIcons[0], data2.badgeIcons[1], data2.badgeIcons[6]);
    const panel = {
      panelType: data.panelType1,
      title: data.tr_title,
      badgeUrl: (graphicType !== QrConstants.OTHERS) ? data.badgeUrl : '',
      preTitle: this.graphicDescription,
      subTitle: data.tr_subtitle,
      badgeIcons: data.badgeIcons
    };
    const panel2 = {
      title: data2.tr_title,
      subTitle: data2.tr_subtitle,
      panelType: data2.panelType2,
      badgeIcons: badgeIconsArr,
      noteSection: data2.tr_noteSection
    };
    this.panel.push(panel);
    this.panel.push(panel2);
  }

  setIntelGraphicPanels(graphicType): void{
    const badgeIconsArr = [];
    const data = this.libraryService.getFactTagsDetails(graphicType, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
      GlobalConstants.DEFAULT_TYPE);
    const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
    GlobalConstants.DEFAULT_TYPE);
    badgeIconsArr.push(data2.badgeIcons[7], data2.badgeIcons[8]);
    const panel = {
      panelType: data.panelType1,
      title: data.tr_title,
      badgeUrl: (graphicType !== QrConstants.OTHERS) ? data.badgeUrl : '',
      preTitle: this.graphicDescription,
      subTitle: data.tr_subtitle,
      badgeIcons: data.badgeIcons
    };
    const panel2 = {
      title: data2.tr_title,
      subTitle: data2.tr_subtitle,
      panelType: data2.panelType2,
      badgeIcons: badgeIconsArr,
      noteSection: data2.tr_noteSection
    };
    this.panel.push(panel);
    this.panel.push(panel2);
  }

  setNonIntelGraphicPanels(graphicType): void{
    const badgeIconsArr = [];
    const data = this.libraryService.getFactTagsDetails(graphicType, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
      GlobalConstants.DEFAULT_TYPE);
    const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
    GlobalConstants.DEFAULT_TYPE);
    badgeIconsArr.push(data2.badgeIcons[0], data2.badgeIcons[1], data2.badgeIcons[6]);
    this.isTakeAndStore = true;
    const panel = {
      panelType: data.panelType1,
      title: data.tr_title,
      badgeUrl: data.badgeUrl,
      preTitle: this.graphicDescription,
      subTitle: data.tr_subtitle,
      badgeIcons: data.badgeIcons
    };
    const panel2 = {
      title: data2.tr_title,
      subTitle: data2.tr_subtitle,
      panelType: data2.panelType2,
      badgeIcons: badgeIconsArr,
      noteSection: data2.tr_noteSection
    };
    this.panel.push(panel);
    this.panel.push(panel2);
  }
  setUHDFeaturedIris(): void{
    const badgeIconsArr = [];
    const data = this.libraryService.getFactTagsDetails(QrConstants.UHD_FEATURING_IRIS_XE, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD,
    GlobalConstants.DEFAULT_TYPE);
    badgeIconsArr.push(data2.badgeIcons[3], data2.badgeIcons[4], data2.badgeIcons[5]);
    const panel = {
      panelType: data.panelType1,
      title: data.tr_pretitle,
      badgeUrl: data.badgeUrl,
      preTitle: data.tr_title,
      subTitle: data.tr_text1,
    };
    const panel2 = {
      panelType: data2.panelType2,
      badgeIcons: badgeIconsArr
    };
    this.panel.push(panel);
    this.panel.push(panel2);
  }

  setGamingPanel2Data(): void  {
    const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    const panel = {
      title: data2.tr_title,
      subTitle: data2.tr_subtitle,
      panelType: data2.panelType2,
      badgeIcons: data2.badgeIcons,
      noteSection: data2.tr_noteSection
    };
    this.panel.push(panel);
  }

  setSubTitleText(): void {
    let subTitle;
    const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.OTHERS, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    if (this.graphicsMemory < 1) {
      subTitle = panelData.titleArray[0].tr_subtitle;
    } else if (this.graphicsMemory >= 1 && this.graphicsMemory <= 4) {
      subTitle = panelData.titleArray[1].tr_subtitle;
    } else if (this.graphicsMemory > 4 && this.graphicsMemory <= 8) {
      subTitle = panelData.titleArray[2].tr_subtitle;
    } else {
      subTitle = panelData.titleArray[3].tr_subtitle;
    }
    return subTitle;
  }

  setGamingPanel1Data(contentType): any{
    const data = this.libraryService.getFactTagsDetails(contentType, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    const panel = {
      panelType: data.panelType1,
      title: this.graphicDescription,
      badgeUrl: (contentType === GlobalConstants.OTHERS) ? '' : data.badgeUrl,
      preTitle: data.tr_pretitle,
      subTitle: this.setSubTitleText(),
      badgeIcons: (this.graphicsMemory > 0) ? data.badgeIcons : ''
    };
    if (panel.badgeIcons.length > 0) {
      panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.XX, this.graphicsMemory.toString());
      panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.X, this.graphicsMemory.toString());
    }
    this.panel.push(panel);
    if (this.panel.length < 2 && this.graphicsMemory > 0) {
      this.setGamingPanel2Data();
    }
    return this.panel;
  }

  setGamingUHDContent(contentType) {
    const data = this.libraryService.getFactTagsDetails(contentType, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      const panel = {
        panelType: data.panelType1,
        title: this.graphicDescription,
        badgeUrl: (contentType === GlobalConstants.OTHERS) ? '' : data.badgeUrl,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle,
        badgeIcons: (this.graphicsMemory > 0) ? data.badgeIcons : ''
      };
      if (panel.badgeIcons.length > 0) {
        panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.XX, this.graphicsMemory.toString());
        panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.X, this.graphicsMemory.toString());
      }
      this.panel.push(panel);
      if (this.panel.length < 2 && this.graphicsMemory > 0) {
        this.setGamingPanel2Data();
      }
      return this.panel;
  }
  // IIT2-1970-start
  setGamingPanel1DataIntel25() {
    const data = this.libraryService.getFactTagsDetails(QrConstants.GIN0010, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    const panel = {
      panelType: data.panelType1,
      title: this.graphicDescription,
      preTitle: data.tr_pretitle,
      subTitle: data.tr_subtitle,
    };
    this.panel.push(panel);
    return this.panel;
  }
  // IIT2-1970-end
}
