import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { MessageService } from '../../../../core/services/message-service/message.service';
import { FactTag } from '../../../../core/interfaces/fact-tag.i';
import { GoogleAnalyticsService } from '../../../../core/services/google-analytics-service/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PcSpecsService {

  private factTagMenu = new BehaviorSubject<FactTag[]>([]);
  pcSpecsData = this.factTagMenu.asObservable();
  homePageCount = 0;
  previousPage = [];
  highlightFacttag: string;
  private currentHighlightFactTag = new BehaviorSubject('');
  getCurrentHighlightFactTag = this.currentHighlightFactTag.asObservable();
  constructor(
    private router: Router,
    private messageService: MessageService,
    private googleAnalyticsService:GoogleAnalyticsService
  ) { }

  pcSpecsDetailsDetails(factTags: FactTag[]) {    
    this.factTagMenu.next(factTags)
    
  }
// creating common service for previous page update
  previousPageDetail(): void {
    this.messageService.getHomePageCount.subscribe(data => {
      this.homePageCount = data;
    });
    this.messageService.getPreviousPage.subscribe(data => {
      this.previousPage = data;
    });
  }

  gotoDetailPage(factTagValue, typeOfFacttag): void {
    this.googleAnalyticsService.factTagAnalytics(factTagValue, typeOfFacttag); // II-3852
    this.messageService.setHomePageCount(this.homePageCount + 1);
    const index = this.previousPage.indexOf(factTagValue);
    if (index > -1) { // If same facttag is available
      this.previousPage.splice(index, 1);
      this.messageService.setHomePageCount(this.homePageCount - 1);
    }
    this.previousPage.push(factTagValue);
    this.messageService.setPreviousPage(this.previousPage);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/specs/' + factTagValue]);
    });
  }

  setCurrentHighlightFactTag(page: any): void {
    this.currentHighlightFactTag.next(page);
  }
}
