import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { QrConstants } from '../../../../core/services/qr-input-service/qr.constants';

@Injectable({
  providedIn: 'root'
})
export class OperatingSystemService {
  panel = [];
  isAppGaming = false;
  osType: string;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void{
    const qrData = this.qrCodeService.getQrData();
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.osType = (qrData.Os) ? qrData.Os : 'ANA';
  }

  operatingSystemData(): any{
    this.panel = [];
    this.setDefaultData();
    if (this.osType === QrConstants.WINDOWS || this.osType === GlobalConstants.W10 ){ // IIT2-2986
      this.windowsDetails();
    } else if (this.osType === GlobalConstants.W10S){ // IIT2-2986
      this.windows10SDetails();
    } else if (this.osType === QrConstants.W11 || this.osType === QrConstants.WINDOWS_11_UWP || this.osType === GlobalConstants.W11S) { // IJR-2118
      this.windows11Details();
    } else {
      this.windowsGenericDetails();
    }

    return this.panel;
  }

  windowsDetails(): void{
    let panelData;
    let panel1;
    let panel2;
    if (this.isAppGaming) {
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        preTitle: panelData.tr_pretitle,
        subTitle: panelData.tr_subtitle,
        noteSection: panelData.tr_noteSection
      };
      panel2 = {
        panelType: panelData.panelType2,
        preTitle: panelData.titleArray[0].tr_title,
        subTitle: panelData.titleArray[0].tr_subtitle,
        badgeIcons: panelData.badgeIcons,
        noteSection: panelData.titleArray[0].tr_noteSection
      };
    } else {
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        preTitle: panelData.tr_pretitle,
        subTitle: panelData.tr_subtitle,
        noteSection: panelData.tr_noteSection
      };
      panel2 = {
        panelType: panelData.panelType2,
        title: panelData.titleArray[0].tr_title,
        subTitle: panelData.titleArray[0].tr_subtitle,
        badgeIcons: panelData.badgeIcons,
        noteSection: panelData.titleArray[0].tr_noteSection
      };
    }
    this.panel.push(panel1);
    this.panel.push(panel2);
  }

  windows10SDetails(): void{
    let panelData;
    if (this.isAppGaming) {
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWSUWP, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    }else {
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWSUWP, GlobalConstants.OPERATING_SYSTEM_NAME,
      GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    }
    const panel1 = {
      panelType: panelData.panelType1,
      title: panelData.tr_title,
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle
    };
    this.panel.push(panel1);
  }

  windowsGenericDetails(): void{
    let panelData;
    if (this.isAppGaming){
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS_GENERIC, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    } else {
      panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS_GENERIC, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    }
    const panel1 = {
      panelType: panelData.panelType1,
      title: panelData.tr_title,
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle
    };
    this.panel.push(panel1);
  }
//  IJR-626 start
  windows11Details(): void {
    let panelData;
    panelData =  this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS11, GlobalConstants.OPERATING_SYSTEM_NAME,
      GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    // IIT2-4403-start: showing English content images for Icelandic language
    // const languageCode = this.qrCodeService.qrInput.Language.toLowerCase(); commented
    const languageCode = this.qrCodeService?.qrInput?.Language?.toLowerCase() === GlobalConstants.IS_SMALL_CASE ?
      GlobalConstants.EN_US_SMALL : this.qrCodeService?.qrInput?.Language?.toLowerCase();
    // IIT2-4403-end 
    const badgeArray = panelData.badgeIcons;
    if (languageCode !== GlobalConstants.EN_US_SMALL) {
      badgeArray.pop(); // Removing last badge from badgeArray as we have one less image for other language except en-us
    }
    const newBadgeArray = badgeArray.map(ele => ({
      imgUrl: ele.imgUrl.replace(GlobalConstants.VALUE_5, languageCode)}));

    const panel1 = {
      panelType: panelData.panelType1,
      badgeIcons: newBadgeArray
    };

    this.panel.push(panel1);
  }
//  IJR-626 end
}
