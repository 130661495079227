import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { QrConstants } from '../../../../core/services/qr-input-service/qr.constants';
import { LibraryChrombookV2DataService } from '../../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {
  panelData = [];
  badgeIcons = [];  // IJR-239 bug fix
  isStandardContent = false;
  isMTLVpro = false;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService,
    private libraryChrombookService: LibraryChrombookV2DataService
  ) {}

  processorData(): any {
    this.isStandardContent = this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 ||
    this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN13 || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN14;
    let processorBadgeArray = ['INT080', 'INT081', 'INT082', 'INT083', 'INT086', 'INT087', 'INT088']; //Story IJR-19, 66 changes
    this.panelData = [];
    const isBelowGen = this.qrCodeService?.qrInput?.ProcessorBadge.split('_');
    if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN11 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN9) {
        // Story IJR-19 H45 processor changes --start
        // Moved code and added below function to reuse
        this.addPanelData(GlobalConstants.GAMING);
        // Story IJR-19 H45 processor changes --end
    } else if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN12 ||
      ((this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12) &&
        (this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT105 || this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT114))) {  // 12th gen standard i9
      this.enhancementPanelData();
    } else if ((this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN13 && this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT125) || 
     (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN13 && 
      GlobalConstants.GAMING_13TH_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge))) {
        this.enhancementPanelData13thgen();
    }
    else if((this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN14 && (this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT148||(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT162  && this.qrCodeService?.qrInput?.VproEvo !== GlobalConstants.CHECK_Y))) ||
    (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN14 && 
      (GlobalConstants.GAMING_14TH_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge) ||
      GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)))) {
        this.enhancementPanelDataAbove13thgen();
    }
    else if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12
      || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN13|| this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN13 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN14) {
      this.enhancementStandardPanelData();

    } else if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN11 ||  this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN10) {
      this.creatorProcessorData();
    } else if (isBelowGen.length === 2) {
      let cpuBelow = 'INTEL_CORE_' + this.qrCodeService.qrInput.ProcessorBadge;
      const defaultData = this.libraryService.getFactTagsDetails(QrConstants.BELOW_7TH_GEN, GlobalConstants.PROCESSOR_NAME,
         GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      if (defaultData.badgeUrl) {

        const changeGen = Number(isBelowGen[1]) - 2;
        const newbadge = isBelowGen[0] + '_' + changeGen;
        cpuBelow = 'INTEL_CORE_' + newbadge;

        defaultData.badgeUrl = defaultData.badgeUrl.replace('[cup_badge]', cpuBelow);
        const checkGen = isBelowGen[1];
        const coreCheck = isBelowGen[0];
        let genValue;
        if (coreCheck) {
          if (coreCheck === 'I7') {
            genValue = 'i7';
          } else if (coreCheck === 'I5') {
            genValue = 'i5';
          } else if (coreCheck === 'I3') {
            genValue = 'i3';
          }
        }
        let procTitle;

        if (checkGen === '7') {
          procTitle = defaultData.titleArray[0].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '6') {
          procTitle = defaultData.titleArray[1].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '5') {
          procTitle = defaultData.titleArray[2].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '4') {
          procTitle = defaultData.titleArray[3].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '3') {
          procTitle = defaultData.titleArray[4].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '2') {
          procTitle = defaultData.titleArray[5].tr_title.replace('[cpu_fam]', genValue);
        } else if (checkGen === '1') {
          procTitle = defaultData.titleArray[6].tr_title.replace('[cpu_fam]', genValue);
        }
        const panel = {
          panelType: defaultData.panelType1,
          preTitle: this.qrCodeService.qrInput.ProcessorDescription,
          title: procTitle,
          badgeUrl: defaultData.badgeUrl,
          badgeIcons: '',
          subTitle: '',
        };
        this.panelData.push(panel);

      } // Story IJR-19, 66  processor changes --start 
    } else if((this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN10 || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN11) && 
      (processorBadgeArray.includes(this.qrCodeService.qrInput.ProcessorBadge))){
        this.addPanelData(GlobalConstants.STANDARD);
        // Story IJR-19, 66  processor changes --end
    }
    else {
      let cpuFamily = '';
      let checkDeviceType;
      const processorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
      if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT063 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT064 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT065 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT006 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT005 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT062) {
        cpuFamily = GlobalConstants.OTHER_OLD_PROCESSOR;
        checkDeviceType = GlobalConstants.DEFAULT_TYPE;
        const pentiumDetail = this.libraryService.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME,
           GlobalConstants.STANDARD, checkDeviceType);
        let preTitlePanel;
        let subTitlePanel;
        let badgeURL;
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT064) {
          preTitlePanel = pentiumDetail.titleArray[1].tr_pretitle;
          subTitlePanel = pentiumDetail.titleArray[1].tr_subtitle;
          badgeURL = pentiumDetail.badgeIcons[1].badgeUrl;
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT065) {
          preTitlePanel = pentiumDetail.titleArray[0].tr_pretitle;
          subTitlePanel = pentiumDetail.titleArray[0].tr_subtitle;
          badgeURL = pentiumDetail.badgeIcons[0].badgeUrl;
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT006 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT005) {
          preTitlePanel = pentiumDetail.titleArray[2].tr_pretitle;
          subTitlePanel = pentiumDetail.titleArray[2].tr_subtitle;
          badgeURL = QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT006 ?
          pentiumDetail.badgeIcons[2].badgeUrl : GlobalConstants.INTEL_CELERON_1_BADGE;
          // badgeURL = pentiumDetail.badgeIcons[2].badgeUrl;
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT063 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT062) { // IJR-2802
          preTitlePanel = pentiumDetail.titleArray[3].tr_pretitle;
          subTitlePanel = pentiumDetail.titleArray[3].tr_subtitle;
          badgeURL = QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT063 ?
          pentiumDetail.badgeIcons[3].badgeUrl : GlobalConstants.INTEL_PENTIUM_1_BADGE;
          // badgeURL = pentiumDetail.badgeIcons[3].badgeUrl;
        }

        const panel = {
          panelType: pentiumDetail.panelType1,
          preTitle: preTitlePanel,
          title: pentiumDetail.tr_title,
          badgeUrl: badgeURL,
          badgeIcons: '',
          subTitle: subTitlePanel,
        };
        this.panelData.push(panel);
      } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT066 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT067 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT068 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT111) {
        let checkCPUBadge = '';
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT066) {
          checkCPUBadge = GlobalConstants.PENTIUM_SILVER;
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT067) {
          checkCPUBadge = GlobalConstants.PENTIUM_GOLD;
        }
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT068 || QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT111) {
          checkCPUBadge = GlobalConstants.CPU_FAMILY_CELERON;
        }
        const pentiumNewDetail = this.libraryService.getFactTagsDetails(checkCPUBadge, GlobalConstants.PROCESSOR_NAME,
           GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelTwoData = this.libraryService.getFactTagsDetails(GlobalConstants.OTHER_OLD_PROCESSOR, GlobalConstants.PROCESSOR_NAME,
           GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT066 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT067) {
          // pentiumNewDetail.processorCompare.table_head[0].tr_imgCaption = panelTwoData.titleArray[0].tr_pretitle; // IIT2-2254 - commenting for future change
          // pentiumNewDetail.processorCompare.tabletr[2].td1 = true; // IIT2-2254 - commenting for future change
        }
        let badgeDesc = this.qrCodeService.qrInput.ProcessorDescription;
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT066) {
          badgeDesc = panelTwoData.titleArray[0].tr_pretitle;
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT067) {
          // badgeDesc = pentiumNewDetail.processorCompare.table_head[1].tr_imgCaption; // IIT2-2254 - commenting for future change
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT068) {
          // badgeDesc = pentiumNewDetail.processorCompare.table_head[0].tr_imgCaption; // IIT2-2254 - commenting for future change
        } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT111) { // IIT2-4515
          pentiumNewDetail.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
          pentiumNewDetail.processorCompare.table_head[0].imgUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
          // pentiumNewDetail.processorCompare.table_head[0].imgUrl
        }
        const panel = {
          panelType: pentiumNewDetail.panelType1,
          preTitle: badgeDesc,
          title: pentiumNewDetail.tr_title,
          badgeUrl: pentiumNewDetail.badgeUrl,
          badgeIcons: pentiumNewDetail.badgeIcons,
          subTitle: pentiumNewDetail.tr_subtitle,
        };
        this.panelData.push(panel);

        // IIT2-2254 - 2ND PANEL-legacy
        // if(pentiumNewDetail.gamingPanleType2 === GlobalConstants.GAMING_PANEL_TYPE1) {
        //   const panel2 = {
        //     panelType2: pentiumNewDetail?.gamingPanleType2,
        //     badgeIcons2: pentiumNewDetail?.enhancementCompare
        //   };
        //   this.panelData.push(panel2);
        // }

        // IIT2-2254 - commenting for future change-legacy

        if (pentiumNewDetail.panelType2) {
          const panel2 = {
            panelType: pentiumNewDetail.panelType2,
            title: pentiumNewDetail.processorCompare.tr_title,
            table_head: pentiumNewDetail.processorCompare.table_head,
            tabletr: pentiumNewDetail.processorCompare.tabletr,
            table_footer: pentiumNewDetail.processorCompare.table_footer
          };
          this.panelData.push(panel2);
        }
      } else if (QrConstants[processorBadge] === QrConstants.INT070 ||
        QrConstants[processorBadge] === QrConstants.INT069 || QrConstants[processorBadge] === QrConstants.INT109 || 
        QrConstants[processorBadge] === QrConstants.INT110 || QrConstants[processorBadge] === QrConstants.INT118 ||
        QrConstants[processorBadge] === QrConstants.INT122 || QrConstants[processorBadge] === QrConstants.INT123 ||
        QrConstants[processorBadge] === QrConstants.INT124 || QrConstants[processorBadge] === QrConstants.INT157 ||
        QrConstants[processorBadge] === QrConstants.INT158 || QrConstants[processorBadge] === QrConstants.INT159) { // last 3 badge for 13th Gen
        const gamingTabledata = this.libraryService.getFactTagsDetails(
          QrConstants[processorBadge],
          GlobalConstants.PROCESSOR_NAME,
          GlobalConstants.STANDARD,
          GlobalConstants.LAPTOP_NAME
        );
        
        const processorDescription = gamingTabledata.tr_gen_core_title ?
          gamingTabledata.tr_gen_core_title : this.qrCodeService.qrInput.ProcessorDescription;
          const evoVprobadge = GlobalConstants.EVO_VPRO_BADGES[processorBadge];
          if(evoVprobadge && this.qrCodeService?.qrInput?.VproEvo === GlobalConstants.CHECK_Y) {
            gamingTabledata.badgeUrl = evoVprobadge;
          }
          let videoURL='';
        if(GlobalConstants.EVO_MTL_BADGES?.includes(processorBadge)){
          if(QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)!=-1
          || QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.SecondaryGraphicsBadge)!=-1 ||this.qrCodeService?.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012 ||this.qrCodeService?.qrInput?.SecondaryGraphicsBadge === QrConstants.BADGE_GIN012 || (QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)===-1 && this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A')|| (QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.SecondaryGraphicsBadge)===-1 && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A')){
            // videoURL = GlobalConstants.EVO_STD_MTL_PROC.replace(GlobalConstants.MTL_VIDEO, GlobalConstants.MTL_ARC_VIDEO);
            videoURL = gamingTabledata?.videoUrl?.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language).replace(GlobalConstants.MTL_VIDEO, GlobalConstants.MTL_ARC_VIDEO);
          }else{
            // videoURL = GlobalConstants.EVO_STD_MTL_PROC;
            videoURL = gamingTabledata?.videoUrl?.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language);
          }
        }
        const panel = {
          panelType: gamingTabledata.panelType1,
          title: gamingTabledata.tr_title,
          badgeUrl: gamingTabledata.badgeUrl,
          badgeIcons: gamingTabledata.badgeIcons,
          subTitle: gamingTabledata.tr_subtitle,
          tileTitle: gamingTabledata.tr_gen_core_title_uppercase,
          targetURL: gamingTabledata.detailBGUrl,
          preTitle: processorDescription, // IIT2-4639
          processorTileBadgeUrl: gamingTabledata.processorTileBadgeUrl,
          videoUrl:videoURL

        };
        this.panelData.push(panel);

      }
      else if(processorBadge == GlobalConstants.INT166 || processorBadge == GlobalConstants.INT167){
        const evoLunarPanelData = this.libraryService.getFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,GlobalConstants.STANDARD,GlobalConstants.DEFAULT_TYPE);        
        
        const panel1 = {
          panelType: evoLunarPanelData.panelType1,
          preTitle: evoLunarPanelData.tr_gen_core_title,
          badgeUrl: evoLunarPanelData.badgeUrl,
          title: evoLunarPanelData?.tr_pretitle,
          bannerImgUrl: evoLunarPanelData.bannerImgUrl   
        };                
        this.panelData.push(panel1);
      }

      else {
        if (this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP ||
          this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_TWOINONE) {
          checkDeviceType = GlobalConstants.LAPTOP_NAME;
        } else {
          checkDeviceType = GlobalConstants.DESKTOP_NAME;
        }
        let processorDesc = this.qrCodeService.qrInput.ProcessorDescription;
        const newCPUFamily = QrConstants[this.qrCodeService.qrInput.ProcessorBadge];
        let newDeviceType = this.qrCodeService.qrInput.ContentType === QrConstants.ATHENA ?
         GlobalConstants.ATHENA_CONTENT_TYPE : checkDeviceType;
        if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT017 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT028 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT041 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT016 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT027 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT040 ||
          QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT079) {  // IJR-1332
          newDeviceType = GlobalConstants.DEFAULT_TYPE;
          processorDesc = '';
        }
        const gamingTabledata = this.libraryService.getFactTagsDetails(
          newCPUFamily,
          GlobalConstants.PROCESSOR_NAME,
          GlobalConstants.STANDARD,
          newDeviceType
        );
        processorDesc = gamingTabledata.tr_gen_core_title ?
          gamingTabledata.tr_gen_core_title : processorDesc;
        // IJR-5 Hybrid cpu - start
        const hybridBadgeData = this.libraryService.getHybridDetails();
        if (newCPUFamily === QrConstants.INT084) {
          gamingTabledata.badgeUrl = hybridBadgeData[0].badgeURL;
          processorDesc = hybridBadgeData[0].tr_gen_core_title;
        } else if (newCPUFamily === QrConstants.INT085) {
          gamingTabledata.badgeUrl = hybridBadgeData[1].badgeURL;
          processorDesc = hybridBadgeData[1].tr_gen_core_title;
        }
        // end
        const panel = {
          panelType: gamingTabledata.panelType1,
          preTitle: processorDesc,
          title: gamingTabledata.tr_title,
          badgeUrl: gamingTabledata.badgeUrl,
          badgeIcons: gamingTabledata.badgeIcons,
          subTitle: gamingTabledata.tr_subtitle,
          processorTileBadgeUrl: gamingTabledata.processorTileBadgeUrl,
          tileTitle: gamingTabledata?.tr_gen_core_title_uppercase
        };
        this.panelData.push(panel);
        if (newCPUFamily !== QrConstants.INT084 && newCPUFamily !== QrConstants.INT085) { // IJR-169 Hybrid content Issue
          if (gamingTabledata.panelType2) {
            const panel2 = {
              panelType: gamingTabledata.panelType2,
              title: gamingTabledata.processorCompare.tr_title,
              table_head: gamingTabledata.processorCompare.table_head,
              tabletr: gamingTabledata.processorCompare.tabletr,
              table_footer: gamingTabledata.processorCompare.table_footer
            };
            this.panelData.push(panel2);
          }  else if(gamingTabledata.gamingPanleType2 === GlobalConstants.GAMING_PANEL_TYPE1) {
            const panel2 = {
              panelType2: gamingTabledata?.gamingPanleType2,
              badgeIcons2: gamingTabledata?.enhancementCompare
            };
            this.panelData.push(panel2);
          }
        }
      }


    }
    return this.panelData;
  }

  addZeroes(num1): any {
    const num = Number(num1);
    if (isNaN(num)) {
        return 0;
    }
    if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2 ){
        num1 = num.toFixed(2);
    }
    return num1;
  }

  // Story IJR-19 H45 processor changes --start
  // Created common function to reuse
  addPanelData(iposType: string): void{
    let processorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
    const is11Gen = (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN11 &&
      !GlobalConstants.legacyProcessorArray.includes(processorBadge)) ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN11;
    if (processorBadge === GlobalConstants.INT095) {
      processorBadge = GlobalConstants.INT075;
    } else if (processorBadge === GlobalConstants.INT096) {
      processorBadge = GlobalConstants.INT076;
    } else if (processorBadge === GlobalConstants.INT097) {
      this.qrCodeService?.qrInput?.IsUnlocked === GlobalConstants.CHECK_Y && 
      this.qrCodeService?.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP ?
        processorBadge = GlobalConstants.INT083 : processorBadge = GlobalConstants.INT077; // IJR - 1258
    }
    let deviceType;
     if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 || processorBadge === GlobalConstants.INT087) { // UAT-337
       deviceType = (this.qrCodeService?.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) ?
       GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
     } else  {
       deviceType = GlobalConstants.DEFAULT_TYPE;
         }
    const gamingTabledata = this.libraryService.getFactTagsDetails(
      QrConstants[processorBadge],
      GlobalConstants.PROCESSOR_NAME,
      iposType,
      deviceType
    );
    if(this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP ) {
      if (processorBadge === GlobalConstants.INT075) {
        gamingTabledata.processorTileBadgeUrl = GlobalConstants.I5_G11_GAMING_TILE;
      } else if (processorBadge === GlobalConstants.INT076) {
        gamingTabledata.processorTileBadgeUrl = GlobalConstants.I7_G11_GAMING_TILE;
      } else if (processorBadge === GlobalConstants.INT077) {
        gamingTabledata.processorTileBadgeUrl = GlobalConstants.I9_G11_GAMING_TILE
      }
    }
    let ClockSpeedValue = this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ClockSpeed);
    if (this.qrCodeService.qrInput.Language === 'ja') {
      ClockSpeedValue = this.addZeroes(ClockSpeedValue);
    }
    if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 || this.qrCodeService.qrInput.ProcessorBadge === 'INT087' || 
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN9) { // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic 
      gamingTabledata.badgeIcons[0].tr_badgeTitle = gamingTabledata?.badgeIcons[0]?.tr_badgeTitle
      .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ThreadValue));
      gamingTabledata.badgeIcons[2].tr_badgeTitle = gamingTabledata?.badgeIcons[2]?.tr_badgeTitle
      .replace('[XX]', ClockSpeedValue);
    }
    else {
      gamingTabledata.badgeIcons[0].tr_badgeTitle = gamingTabledata.badgeIcons[0]?.tr_badgeTitle
      .replace('[XX]', ClockSpeedValue);
      gamingTabledata.badgeIcons[2].tr_badgeTitle = gamingTabledata.badgeIcons[2]?.tr_badgeTitle
      .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ThreadValue));
    }
    gamingTabledata.badgeIcons[1].tr_badgeTitle = gamingTabledata.badgeIcons[1]?.tr_badgeTitle
    .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.CoreValue));
    gamingTabledata.badgeIcons[3].tr_badgeTitle = gamingTabledata.badgeIcons[3]?.tr_badgeTitle
    .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.CacheValue));
    let procDesc = this.qrCodeService.qrInput.ProcessorDescription;
    if (this.qrCodeService.qrInput.ProcessorBadge === 'INT074') {
      procDesc = gamingTabledata?.processorCompare?.table_head[2].tr_imgCaption.replace('-11375H', '')
      .replace(GlobalConstants.EDITION_SMALL, GlobalConstants.EDITION);
    }
    // IJR-239, IJR-344 Bug fix --starts
    this.badgeIcons = [];
    if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 || this.qrCodeService.qrInput.ProcessorBadge === 'INT087' ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN9) { // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
      if (Number(this.qrCodeService.qrInput.ThreadValue) > 0) {
        this.badgeIcons.push(gamingTabledata.badgeIcons[0]);
      }
    } else {
      if (Number(this.qrCodeService.qrInput.ClockSpeed) > 0) {
        this.badgeIcons.push(gamingTabledata.badgeIcons[0]);
      }
    }
    if (Number(this.qrCodeService.qrInput.CoreValue) > 0) {
      this.badgeIcons.push(gamingTabledata.badgeIcons[1]);
    }
    if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 || this.qrCodeService.qrInput.ProcessorBadge === 'INT087' ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.GAMINGGEN9) { // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
      if (Number(this.qrCodeService.qrInput.ClockSpeed) > 0) {
        this.badgeIcons.push(gamingTabledata.badgeIcons[2]);
      }
    } else {
      if (Number(this.qrCodeService.qrInput.ThreadValue) > 0) {
        this.badgeIcons.push(gamingTabledata.badgeIcons[2]);
      }
    }
    if (Number(this.qrCodeService.qrInput.CacheValue) > 0) {
      this.badgeIcons.push(gamingTabledata.badgeIcons[3]);
    }
    if (this.qrCodeService?.qrInput?.IsUnlocked === GlobalConstants.CHECK_Y &&
        !GlobalConstants.UNLOCKED_UNSUPPORTED_PROCESSOR_BADGES.includes(processorBadge)){
      this.badgeIcons.push(gamingTabledata.badgeIcons[4]);
    }
    // IJR-239 Bug fix --ends
    procDesc = gamingTabledata.tr_gen_core_title ?
      gamingTabledata.tr_gen_core_title : procDesc;
    const panel = {
      panelType: gamingTabledata.panelType1,
      preTitle: procDesc,
      title: gamingTabledata.tr_title,
      badgeUrl: gamingTabledata.badgeUrl,
      badgeIcons: this.badgeIcons,
      subTitle: gamingTabledata.tr_subtitle,
      processorTileBadgeUrl: gamingTabledata.processorTileBadgeUrl,
      tileTitle: gamingTabledata?.tr_gen_core_title_uppercase
    };
    this.panelData.push(panel);
    if (gamingTabledata.panelType2 && gamingTabledata.gamingPanleType2 === GlobalConstants.PANEL_TYPE_TABLE_FORMAT) { // IIT2-2254
      let footerData = gamingTabledata.processorCompare.table_footer;   // UAT- 337
      if ((processorBadge === GlobalConstants.INT089 ||
        processorBadge === GlobalConstants.INT090 || processorBadge === GlobalConstants.INT091) &&
        this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) {
        footerData = gamingTabledata.processorCompare.table_footer_desktop;
      }
      const panel2 = {
        panelType: gamingTabledata.panelType2,
        title: gamingTabledata.processorCompare.tr_title,
        table_head: gamingTabledata.processorCompare.table_head,
        tabletr: gamingTabledata.processorCompare.tabletr,
        table_footer: footerData
      };
      this.panelData.push(panel2);
    } else if(is11Gen && gamingTabledata.gamingPanleType2 === GlobalConstants.GAMING_PANEL_TYPE1) {
      const panel2 = {
        panelType2: gamingTabledata?.gamingPanleType2,
        badgeIcons2: gamingTabledata?.enhancementCompare
      };
      this.panelData.push(panel2);
    }
    // const panel3 = {
    //   panelType: gamingTabledata.panelType2,
    //   title: gamingTabledata.processorCompare.tr_title,
    //   table_head: gamingTabledata.processorCompare.table_head,
    //   tabletr: gamingTabledata.processorCompare.tabletr,
    //   table_footer: gamingTabledata.processorCompare.table_footer
    // };
    // this.panelData.push(panel3);
  }
  // Story IJR-19 H45 processor changes --ends

  chromebookProcessorData(): any {
    const evoBadge={
      'INT142':"assets/images/processor/EVO_generic.svg",
      'INT143':"assets/images/processor/EVO_I5_12GEN.svg",
      'INT144':"assets/images/processor/EVO_I7_GEN12.svg",
      'INT145': "assets/images/processor/EVO_generic.svg",
      'INT146': "assets/images/processor/EVO_I5_12GEN.svg",
      'INT147': "assets/images/processor/EVO_I7_GEN12.svg",
      'INT148': "assets/images/processor/EVO_I9_GEN12.svg",
    }
    this.panelData = [];
    let processorBadge = this.qrCodeService.qrInput.ProcessorBadge;
    let defaultData;
    if (this.qrCodeService.qrInput.ContentType === GlobalConstants.CHROMEBOOKATHENA) {
        defaultData = this.libraryChrombookService.getProcessorFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.STANDARD, GlobalConstants.ATHENA_CONTENT_TYPE);
    } else if (this.qrCodeService.qrInput.ContentType === GlobalConstants.CHROMEBOOK_CELERON_AND_PENTIUM) {
      processorBadge = this.qrCodeService.chromePentiumCeleronOldBadge(); // IIT2-4515
      defaultData = this.libraryChrombookService.getProcessorFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE); // IIT2-2504
      // IIT2-4515
      if (processorBadge === 'INT005') {
        defaultData.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
      } else if (processorBadge === 'INT062') {
        defaultData.badgeUrl = GlobalConstants.INTEL_PENTIUM_1_BADGE;
      } else if (processorBadge === 'INT111') {
        defaultData.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
      }
      // IIT2-4515
    } else if (this.qrCodeService.qrInput.ContentType === GlobalConstants.CHROMEBOOKEVO) { // IIT2-2222
      defaultData = this.libraryChrombookService.getProcessorFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,
      GlobalConstants.EVO_CAPITAL, GlobalConstants.DEFAULT_TYPE);
      if(GlobalConstants.CHROME_RPL_BADGES.includes(processorBadge)){
        defaultData.badgeUrl= evoBadge[processorBadge];
      }
    } else {
        const cpuFamily = GlobalConstants.INTEL_PROCESSOR_BADGES.includes(processorBadge) ? GlobalConstants.INTEL_INSIDE_13 : QrConstants[processorBadge];
        defaultData = this.libraryChrombookService.getProcessorFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    }
    const processorDescription = defaultData.tr_gen_core_title ?
      defaultData.tr_gen_core_title : this.qrCodeService.qrInput.ProcessorDescription; // chromebook processor description
    const panel = {
      panelType: defaultData.panelType1,
      preTitle: processorDescription,
      title: defaultData.tr_title,
      badgeUrl: defaultData.badgeUrl,
      badgeIcons: defaultData.badgeIcons,
      subTitle: defaultData.tr_subtitle,
    };
    this.panelData.push(panel);
    return this.panelData;
  }

  creatorProcessorData(): any {
    this.panelData = [];
    const processorBadge = this.qrCodeService.qrInput.ProcessorBadge;
    const cpuFamily  = QrConstants[processorBadge];
    let deviceType = (this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) ?
      GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
    let defaultData;
    let graphicsBadge = '';
    const corei9Badges = ['INT086', 'INT088', 'INT071'];
    if (deviceType === GlobalConstants.LAPTOP_NAME && this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN11 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12) {
      if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE){
        graphicsBadge = GlobalConstants.INTEL_IRIS_XE_BADGE;
      } else if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX) {
        graphicsBadge = GlobalConstants.INTEL_IRIS_XE_MAX_BADGE;
      } else {
        graphicsBadge = '';
      }
    }

    if (cpuFamily === QrConstants.INT042 && deviceType === GlobalConstants.DESKTOP_NAME) {
      defaultData = this.libraryService.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, deviceType);
    } else {
      if (cpuFamily === QrConstants.INT071){
        deviceType = GlobalConstants.LAPTOP_NAME;
      }
      defaultData = this.libraryService.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.CREATOR, deviceType);
    }

    let ClockSpeedValue = this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ClockSpeed);
    if (this.qrCodeService.qrInput.Language === 'ja') {
      ClockSpeedValue = this.addZeroes(ClockSpeedValue);
    }

    if (corei9Badges.includes(this.qrCodeService.qrInput.ProcessorBadge)) {
      if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN10) {
        defaultData.badgeIcons[0].tr_badgeTitle = defaultData?.badgeIcons[0]?.tr_badgeTitle
        .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ThreadValue));
        defaultData.badgeIcons[2].tr_badgeTitle = defaultData?.badgeIcons[2]?.tr_badgeTitle
        .replace('[XX]', ClockSpeedValue);
      } else {
        defaultData.badgeIcons[0].tr_badgeTitle = defaultData.badgeIcons[0]?.tr_badgeTitle
        .replace('[XX]', ClockSpeedValue);
        defaultData.badgeIcons[2].tr_badgeTitle = defaultData.badgeIcons[2]?.tr_badgeTitle
        .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.ThreadValue));
      }
      defaultData.badgeIcons[1].tr_badgeTitle = defaultData.badgeIcons[1]?.tr_badgeTitle
      .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.CoreValue));
      defaultData.badgeIcons[3].tr_badgeTitle = defaultData.badgeIcons[3]?.tr_badgeTitle
      .replace('[XX]', this.qrCodeService.transformDecimal(this.qrCodeService.qrInput.CacheValue));
    }

    // Supressing badge if value is less than 1
    this.badgeIcons = [];
    if (corei9Badges.includes(this.qrCodeService.qrInput.ProcessorBadge)) {
      if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN10) {
        if (Number(this.qrCodeService.qrInput.ThreadValue) > 0) {
          this.badgeIcons.push(defaultData.badgeIcons[0]);
        }
      } else {
        if (Number(this.qrCodeService.qrInput.ClockSpeed) > 0) {
          this.badgeIcons.push(defaultData.badgeIcons[0]);
        }
      }
      if (Number(this.qrCodeService.qrInput.CoreValue) > 0) {
        this.badgeIcons.push(defaultData.badgeIcons[1]);
      }
      if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN10) {
        if (Number(this.qrCodeService.qrInput.ClockSpeed) > 0) {
          this.badgeIcons.push(defaultData.badgeIcons[2]);
        }
      } else {
        if (Number(this.qrCodeService.qrInput.ThreadValue) > 0) {
          this.badgeIcons.push(defaultData.badgeIcons[2]);
        }
      }
      if (Number(this.qrCodeService.qrInput.CacheValue) > 0) {
        this.badgeIcons.push(defaultData.badgeIcons[3]);
      }
      if (this.qrCodeService?.qrInput?.IsUnlocked === GlobalConstants.CHECK_Y){
        this.badgeIcons.push(defaultData.badgeIcons[4]);
      }
    }

    const processorDescription = defaultData.tr_gen_core_title ?
        defaultData.tr_gen_core_title : this.qrCodeService.qrInput.ProcessorDescription; // creator processor description
    const panel = {
      panelType: defaultData.panelType1,
      preTitle: processorDescription,
      title: defaultData.tr_title,
      badgeUrl: defaultData.badgeUrl,
      graphicsBadgeUrl: graphicsBadge,
      badgeIcons: this.badgeIcons,
      subTitle: defaultData.tr_subtitle,
      tileTitle: defaultData?.tr_gen_core_title_uppercase
    };
    this.panelData.push(panel);
    return this.panelData;
  }
  // 12th gen gaming
  enhancementPanelData(): any {
    let gamingTabledata;
    if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT099 || QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT100 ||
      QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT101 || QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT105 ||
      QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT108) { // 12th gen standard i9-INT105
      gamingTabledata = this.libraryService.getFactTagsDetails(
        GlobalConstants.INTEL_CORE_K_GAMING,
        GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.GAMING,
        GlobalConstants.DESKTOP_NAME
      );
    } // HX-Gaming IIT2-4645
    else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT112 ||
      QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT113 ||
      QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT114 ) { // 12th gen HX std i9 - INT114
        gamingTabledata = this.libraryService.getFactTagsDetails(
          GlobalConstants.INTEL_CORE_HX_GAMING,
          GlobalConstants.PROCESSOR_NAME,
          GlobalConstants.GAMING,
          GlobalConstants.LAPTOP_NAME
        );
      }
      let badgeURL;
      let preTitlePanel;
      let tileTitle;
      if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT099 ||
         QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT108) {
        badgeURL = gamingTabledata?.titleArray[0].badgeUrl;
        preTitlePanel = gamingTabledata.titleArray[0].tr_gen_core_title;
      } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT100) {
        badgeURL = gamingTabledata?.titleArray[1].badgeUrl;
        preTitlePanel = gamingTabledata.titleArray[1].tr_gen_core_title;
      } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT101 ||
         QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT105) { // 12th gen standard i9
        badgeURL = gamingTabledata?.titleArray[2].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[2].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[2].tr_gen_core_title_uppercase; // adding processor uppercase title
      } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT112) {
        badgeURL = gamingTabledata?.titleArray[0]?.badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[0]?.tr_gen_core_title;
      } else if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT113 ||
        QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT114 ) { // 12th gen HX std i9
        badgeURL = gamingTabledata?.titleArray[1]?.badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[1]?.tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[1].tr_gen_core_title_uppercase; // IIT2-5025
      }

      gamingTabledata.badgeIcons[0].tr_badgeTitle = gamingTabledata.badgeIcons[0]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.CoreValue));

      gamingTabledata.badgeIcons[0].tr_badgePreTitle = gamingTabledata.badgeIcons[0]?.tr_badgePreTitle
        .replace(GlobalConstants.P_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreCores));

      gamingTabledata.badgeIcons[0].tr_badgeSubTitle = gamingTabledata.badgeIcons[0]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreCores));

      gamingTabledata.badgeIcons[1].tr_badgeTitle = gamingTabledata.badgeIcons[1]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.ThreadValue));

      gamingTabledata.badgeIcons[1].tr_badgePreTitle = gamingTabledata.badgeIcons[1]?.tr_badgePreTitle
        .replace(GlobalConstants.P_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreThreads));

      gamingTabledata.badgeIcons[1].tr_badgeSubTitle = gamingTabledata.badgeIcons[1]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreThreads));

      if (Number(this.qrCodeService.qrInput.CoreValue) < 0 || this.qrCodeService.qrInput.CoreValue === '') {
        gamingTabledata.badgeIcons[0].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreCores) < 0 || this.qrCodeService.qrInput.PcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreCores) < 0 || this.qrCodeService.qrInput.EcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgeSubTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.ThreadValue) < 0 || this.qrCodeService.qrInput.ThreadValue === '') {
        gamingTabledata.badgeIcons[1].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreThreads) < 0 || this.qrCodeService.qrInput.PcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreThreads) < 0 || this.qrCodeService.qrInput.EcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgeSubTitle = '';
      }

      if ((this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT105 ||
        this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT114) &&
        this.qrCodeService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT) {
        gamingTabledata.enhancementCompare.imgUrl = GlobalConstants.BLACK_TICK;
      }

      const panel = {
        panelType: gamingTabledata?.panelType1,
        preTitle: preTitlePanel,
        title: gamingTabledata?.tr_title,
        badgeUrl: badgeURL,
        badgeIcons: gamingTabledata?.badgeIcons,
        tileTitle: tileTitle,
        processorTileBadgeUrl: this.isStandardContent? gamingTabledata?.processorTileBadgeUrl : ''
      };
      this.panelData.push(panel);

      const panel2 = {
        panelType: gamingTabledata?.processorCompare?.panelType2,
        title: gamingTabledata?.processorCompare?.tr_title,
        badgeIcons: gamingTabledata?.processorCompare?.badgeIcons,
        imgUrl: gamingTabledata?.processorCompare?.imgUrl,
        currentTitle: gamingTabledata?.processorCompare?.tr_table_heading,
        panelType2: gamingTabledata?.gamingPanleType2,
        badgeIcons2: gamingTabledata?.enhancementCompare
      };
      this.panelData.push(panel2);
  }
  // 12th gen standard start
  enhancementStandardPanelData() {
    const processorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
    let standardTableData;
    const maxTurboFrequency = this.qrCodeService?.qrInput?.ClockSpeed;
    const is12thGenVPro = GlobalConstants.VPRO_12THGEN_BADGES?.includes(processorBadge);
    const is13thGenVPro = GlobalConstants.VPRO_13TH_GEN_BADGE?.includes(processorBadge);
    this.isMTLVpro = (GlobalConstants.STD_MTL_BADGES?.includes(processorBadge)) && (this.qrCodeService?.qrInput?.VproEvo === GlobalConstants.CHECK_Y);
    if(GlobalConstants.INTEL_PROCESSOR_BADGES.includes(processorBadge)){
      standardTableData = this.libraryService.getFactTagsDetails(GlobalConstants.INTEL_INSIDE, GlobalConstants.PROCESSOR_NAME,GlobalConstants.STANDARD,GlobalConstants.DEFAULT_TYPE);
    }
    else if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN13 ||
      this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN14) {
        if ((processorBadge === GlobalConstants.INT145 || processorBadge === GlobalConstants.INT146 ||
          processorBadge === GlobalConstants.INT147 || processorBadge === GlobalConstants.INT160 || processorBadge === GlobalConstants.INT161 ||
          processorBadge === GlobalConstants.INT162 ||
           processorBadge === GlobalConstants.INT142 || processorBadge === GlobalConstants.INT143 || processorBadge === GlobalConstants.INT144) && (this.qrCodeService.qrInput.VproEvo == GlobalConstants.CHECK_Y)){
          standardTableData = this.libraryService.getFactTagsDetails(GlobalConstants.INTEL_RPL_MTL_vPro, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          standardTableData.bannerImgUrl = GlobalConstants.VPRO_BANNER.replace(GlobalConstants.XX,this.qrCodeService.qrInput.Language);
          let display={
            'INT160':'5',
            'INT161':'7',
            'INT162':'9'
          }
          if(processorBadge === GlobalConstants.INT160 || processorBadge === GlobalConstants.INT161 ||
            processorBadge === GlobalConstants.INT162){
              standardTableData.tr_title = standardTableData.tr_title.replace(GlobalConstants.XX, display[processorBadge]);
              standardTableData.tr_gen_core_title = standardTableData.tr_gen_core_title.replace(GlobalConstants.XX, display[processorBadge]);
            }           

         }
      else if( processorBadge === GlobalConstants.INT160 || processorBadge === GlobalConstants.INT161 ){
        standardTableData = this.libraryService.getFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,GlobalConstants.STANDARD,GlobalConstants.LAPTOP_NAME);        
      }else{
        standardTableData = this.libraryService.getFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,GlobalConstants.STANDARD,GlobalConstants.DEFAULT_TYPE);
      }
    } else if (processorBadge === GlobalConstants.INT135 ||
      processorBadge === GlobalConstants.INT134 || processorBadge === GlobalConstants.INT136) {
        standardTableData = this.libraryService.getFactTagsDetails(GlobalConstants.INTEL_CORE_I9_CREATOR, GlobalConstants.PROCESSOR_NAME, GlobalConstants.CREATOR, GlobalConstants.LAPTOP_NAME);
   } else {
      standardTableData = this.libraryService.getFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME,GlobalConstants.CREATOR,GlobalConstants.LAPTOP_NAME);      
    }

    if(processorBadge === GlobalConstants.INT134 ||
      processorBadge === GlobalConstants.INT135 ||
      processorBadge === GlobalConstants.INT136){
      let badgeURL;
      let preTitlePanel;
      let tileTitle;
      badgeURL = standardTableData?.badgeUrl;
      preTitlePanel = standardTableData?.tr_gen_core_title;
      tileTitle = standardTableData?.tr_gen_core_title_uppercase;

      standardTableData.badgeIcons[0].tr_badgeTitle = standardTableData.badgeIcons[0]?.tr_badgeTitle
      .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.CoreValue));

      standardTableData.badgeIcons[0].tr_badgePreTitle = standardTableData.badgeIcons[0]?.tr_badgePreTitle
      .replace(GlobalConstants.P_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreCores));

      standardTableData.badgeIcons[0].tr_badgeSubTitle = standardTableData.badgeIcons[0]?.tr_badgeSubTitle
      .replace(GlobalConstants.E_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreCores));

      standardTableData.badgeIcons[1].tr_badgeTitle = standardTableData.badgeIcons[1]?.tr_badgeTitle
      .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.ThreadValue));

      standardTableData.badgeIcons[1].tr_badgePreTitle = standardTableData.badgeIcons[1]?.tr_badgePreTitle
      .replace(GlobalConstants.P_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreThreads));

      standardTableData.badgeIcons[1].tr_badgeSubTitle = standardTableData.badgeIcons[1]?.tr_badgeSubTitle
      .replace(GlobalConstants.E_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreThreads));
       
      if (Number(this.qrCodeService?.qrInput?.CoreValue) < 0 || this.qrCodeService?.qrInput?.CoreValue === '') {
        standardTableData.badgeIcons[0].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService?.qrInput?.PcoreCores) < 0 || this.qrCodeService?.qrInput?.PcoreCores === '') {
        standardTableData.badgeIcons[0].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService?.qrInput?.EcoreCores) < 0 || this.qrCodeService?.qrInput?.EcoreCores === '') {
        standardTableData.badgeIcons[0].tr_badgeSubTitle = '';
      }
      if (Number(this.qrCodeService?.qrInput?.ThreadValue) < 0 || this.qrCodeService?.qrInput?.ThreadValue === '') {
        standardTableData.badgeIcons[1].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService?.qrInput?.PcoreThreads) < 0 || this.qrCodeService?.qrInput?.PcoreThreads === '') {
        standardTableData.badgeIcons[1].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService?.qrInput?.EcoreThreads) < 0 || this.qrCodeService?.qrInput?.EcoreThreads === '') {
        standardTableData.badgeIcons[1].tr_badgeSubTitle = '';
      }
      const panel = {
        panelType: standardTableData?.panelType1,
        preTitle: preTitlePanel,
        title: standardTableData?.tr_title,
        badgeUrl: badgeURL,
        badgeIcons: standardTableData?.badgeIcons,
        tileTitle: tileTitle,
        processorTileBadgeUrl: ''       
      };
      this.panelData.push(panel);
      
      const panel2 = {
        panelType2: standardTableData?.gamingPanleType2,
        badgeIcons2: standardTableData?.enhancementCompare
      };
      this.panelData.push(panel2);      
    }
    else {
      if ((is12thGenVPro || is13thGenVPro) && Number(maxTurboFrequency) > 0) {
        standardTableData.tr_subtitle = standardTableData?.tr_subtitle?.replace(GlobalConstants.MAX_TURBO_FREQUENCY, this.qrCodeService.transformDecimal(maxTurboFrequency));
      }

      if (GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) {
        const isINT137 = this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT137;
        const panel = {
          panelType: standardTableData?.panelType1,
          preTitle: isINT137 ? standardTableData?.tr_title : this.qrCodeService?.qrInput?.ProcessorDescription,
          badgeUrl: isINT137 ? standardTableData?.badgeUrl : GlobalConstants.INTEL_INSIDE_I3_BADGE,
          bannerImgUrl: standardTableData?.detailBGUrl,
          tileTitle: standardTableData?.tr_gen_core_title_uppercase,
          subTitle: standardTableData?.tr_subtitle,
          processorTileBadgeUrl: isINT137 ? standardTableData?.tileBG : GlobalConstants.INTEL_INSIDE_I3_HOME_TILE_BADGE
        };
        this.panelData.push(panel);
      }
      else {
        let videoURL='';
        if(processorBadge === GlobalConstants.INT160 || processorBadge=== GlobalConstants.INT161){
          if(QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)!=-1
          || QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.SecondaryGraphicsBadge)!=-1 ||this.qrCodeService?.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012 || this.qrCodeService?.qrInput?.SecondaryGraphicsBadge === QrConstants.BADGE_GIN012 ||(QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)===-1 && this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A')|| (QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.SecondaryGraphicsBadge)===-1 && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A')){
            // videoURL = GlobalConstants.EVO_STD_MTL_PROC.replace(GlobalConstants.MTL_VIDEO, GlobalConstants.MTL_ARC_VIDEO);
            videoURL = standardTableData?.videoUrl?.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language).replace(GlobalConstants.MTL_VIDEO, GlobalConstants.MTL_ARC_VIDEO);
          }else{
            // videoURL = GlobalConstants.EVO_STD_MTL_PROC ;
            videoURL = standardTableData?.videoUrl?.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language);
          }
        }
        if(this.isMTLVpro){
          const panel = {
            panelType: standardTableData?.panelType1,
            preTitle: standardTableData?.tr_gen_core_title,
            title: standardTableData?.tr_subtitle,
            badgeUrl: standardTableData?.badgeUrl,
            bannerImgUrl: standardTableData?.bannerImgUrl,
            tileTitle: standardTableData?.tr_gen_core_title_uppercase, // adding processor uppercase title
            subTitle: Number(maxTurboFrequency) > 0 ? standardTableData?.tr_title : '', // Added for 12thgenvpro
            processorTileBadgeUrl: this.isStandardContent ? standardTableData?.processorTileBadgeUrl : '', 
          } 
          this.panelData.push(panel);
        }
        if(processorBadge === GlobalConstants.INT168 || processorBadge === GlobalConstants.INT169){
          const panel = {
            panelType: standardTableData.panelType1,
            preTitle: standardTableData.tr_gen_core_title,
            badgeUrl: standardTableData.badgeUrl,
            title: standardTableData?.tr_pretitle,
            bannerImgUrl: standardTableData.bannerImgUrl,
            titleArray: standardTableData.titleArray   
          };        
          this.panelData.push(panel);
        }
        
        else {
        const panel = {
          panelType: standardTableData?.panelType1,
          preTitle: standardTableData?.tr_gen_core_title,
          title: standardTableData?.tr_title,
          badgeUrl: standardTableData?.badgeUrl,
          bannerImgUrl: standardTableData?.bannerImgUrl,
          tileTitle: standardTableData?.tr_gen_core_title_uppercase, // adding processor uppercase title
          subTitle: Number(maxTurboFrequency) > 0 ? standardTableData?.tr_subtitle : '', // Added for 12thgenvpro
          processorTileBadgeUrl: this.isStandardContent ? standardTableData?.processorTileBadgeUrl : '',
          videoUrl: videoURL
        };
        this.panelData.push(panel);
      } 
      }

      if (is12thGenVPro || is13thGenVPro) {
        const panel2 = {
          panelType: standardTableData?.panelType2,
          title: standardTableData?.processorCompare?.tr_title,
          table_head: standardTableData?.processorCompare?.table_head,
          tabletr: standardTableData?.processorCompare?.tabletr
        };
        this.panelData.push(panel2);
      }
      else if(this.isMTLVpro){
        const panel2 = {
        panelType: standardTableData?.panelType1,
        badgeIcons: standardTableData?.badgeIcons
      };
      this.panelData.push(panel2);
    }
      else if (GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) {
        // standardTableData?.titleArray.splice(3,1)
        for (let i = 0; i < standardTableData?.titleArray.length; i++) {
          const panel2 = {
            panelType: standardTableData?.panelType2,
            tileData: standardTableData?.titleArray[i]
          }
          this.panelData.push(panel2);
        }
        this.panelData.splice(4, 1)
      } else if(processorBadge !== GlobalConstants.INT160 && processorBadge !== GlobalConstants.INT161 && !GlobalConstants.LUNAR_BADGES.includes(processorBadge)) {
        const panel2 = {
          panelType: standardTableData?.processorCompare?.panelType2,
          title: standardTableData?.processorCompare?.tr_title,
          badgeIcons: standardTableData?.processorCompare?.badgeIcons,
          imgUrl: standardTableData?.processorCompare?.imgUrl,
          currentTitle: standardTableData?.processorCompare?.tr_table_heading,
          panelType2: standardTableData?.gamingPanleType2,
          badgeIcons2: standardTableData?.enhancementCompare
        };
        this.panelData.push(panel2);
      }
    }    
  }
  // 12th gen standard end

   // 13th gen std i9 & gaming
   enhancementPanelData13thgen(): any {
    let gamingTabledata;
    if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT125 ||
      GlobalConstants.GAMING_13TH_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) { // 13th gen standard i9-INT125
      gamingTabledata = this.libraryService.getFactTagsDetails(
        GlobalConstants.INTEL_CORE_13TH_GEN_GAMING,
        GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.GAMING,
        GlobalConstants.DESKTOP_NAME
      );
    }    
      let badgeURL;
      let preTitlePanel;
      let tileTitle;
      let procesorHomeTileBadgeURL;
      const setProcessorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
      if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT125) { // 12th gen standard i9
        badgeURL = gamingTabledata?.titleArray[2].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[2].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[2].tr_gen_core_title_uppercase; // adding processor uppercase title
        procesorHomeTileBadgeURL = gamingTabledata?.titleArray[2]?.processorTileBadgeUrl2;
      } else if (setProcessorBadge === GlobalConstants.INT126 || setProcessorBadge === GlobalConstants.INT127) {
        badgeURL = gamingTabledata?.titleArray[0].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[0].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[0].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT126 ? gamingTabledata?.titleArray[0]?.processorTileBadgeUrl : gamingTabledata?.titleArray[0]?.processorTileBadgeUrl1;
      } else if (setProcessorBadge === GlobalConstants.INT128 || setProcessorBadge === GlobalConstants.INT129) {
        badgeURL = gamingTabledata?.titleArray[1].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[1].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[1].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT128 ? gamingTabledata?.titleArray[1]?.processorTileBadgeUrl : gamingTabledata?.titleArray[1]?.processorTileBadgeUrl1;
      } else if (setProcessorBadge === GlobalConstants.INT130 || setProcessorBadge === GlobalConstants.INT131) {
        badgeURL = gamingTabledata?.titleArray[2].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[2].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[2].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT130 ? gamingTabledata?.titleArray[2]?.processorTileBadgeUrl : gamingTabledata?.titleArray[2]?.processorTileBadgeUrl1;
        
        
      }

      gamingTabledata.badgeIcons[0].tr_badgeTitle = gamingTabledata.badgeIcons[0]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.CoreValue));

      gamingTabledata.badgeIcons[0].tr_badgePreTitle = gamingTabledata.badgeIcons[0]?.tr_badgePreTitle
        .replace(GlobalConstants.P_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreCores));

      gamingTabledata.badgeIcons[0].tr_badgeSubTitle = gamingTabledata.badgeIcons[0]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreCores));

      gamingTabledata.badgeIcons[1].tr_badgeTitle = gamingTabledata.badgeIcons[1]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.ThreadValue));

      gamingTabledata.badgeIcons[1].tr_badgePreTitle = gamingTabledata.badgeIcons[1]?.tr_badgePreTitle
        .replace(GlobalConstants.P_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreThreads));

      gamingTabledata.badgeIcons[1].tr_badgeSubTitle = gamingTabledata.badgeIcons[1]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreThreads));

      if (Number(this.qrCodeService.qrInput.CoreValue) < 0 || this.qrCodeService.qrInput.CoreValue === '') {
        gamingTabledata.badgeIcons[0].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreCores) < 0 || this.qrCodeService.qrInput.PcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreCores) < 0 || this.qrCodeService.qrInput.EcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgeSubTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.ThreadValue) < 0 || this.qrCodeService.qrInput.ThreadValue === '') {
        gamingTabledata.badgeIcons[1].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreThreads) < 0 || this.qrCodeService.qrInput.PcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreThreads) < 0 || this.qrCodeService.qrInput.EcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgeSubTitle = '';
      }

      if ((this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT125) &&
        this.qrCodeService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT) {
        gamingTabledata.enhancementCompare.imgUrl = GlobalConstants.BLACK_TICK;
      }

      const panel = {
        panelType: gamingTabledata?.panelType1,
        preTitle: preTitlePanel,
        title: gamingTabledata?.tr_title,
        badgeUrl: badgeURL,
        badgeIcons: gamingTabledata?.badgeIcons,
        tileTitle: tileTitle,
        processorTileBadgeUrl: procesorHomeTileBadgeURL
      };
      
      this.panelData.push(panel);

      const panel2 = {
        panelType: gamingTabledata?.processorCompare?.panelType2,
        title: gamingTabledata?.processorCompare?.tr_title,
        badgeIcons: gamingTabledata?.processorCompare?.badgeIcons,
        imgUrl: gamingTabledata?.processorCompare?.imgUrl,
        currentTitle: gamingTabledata?.processorCompare?.tr_table_heading,
        panelType2: gamingTabledata?.gamingPanleType2,
        badgeIcons2: gamingTabledata?.enhancementCompare
      };
      this.panelData.push(panel2);
  }

  enhancementPanelDataAbove13thgen(): any {
    let gamingTabledata;
    if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT148 || GlobalConstants.GAMING_14TH_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) { // 13th gen standard i9-INT125
      gamingTabledata = this.libraryService.getFactTagsDetails(
        GlobalConstants.INTEL_CORE_ABOVE13TH_GEN_GAMING,
        GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.GAMING,
        GlobalConstants.DESKTOP_NAME
      );
    } else if(GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge) || this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT162) {
      gamingTabledata = this.libraryService.getFactTagsDetails(
        GlobalConstants.INTEL_CORE_1ST_GEN_ULTRA_GAMING,
        GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.GAMING,
        GlobalConstants.DESKTOP_NAME
      );
    }
      let badgeURL;
      let preTitlePanel;
      let tileTitle;
      let procesorHomeTileBadgeURL;
      const setProcessorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
      if (QrConstants[this.qrCodeService.qrInput.ProcessorBadge] === QrConstants.INT148) { // 14th gen standard i9
        badgeURL = gamingTabledata?.titleArray[2].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[2].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[2].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = gamingTabledata?.titleArray[2]?.processorTileBadgeUrl2;
      } else if (setProcessorBadge === GlobalConstants.INT139 || setProcessorBadge === GlobalConstants.INT142 ||
        setProcessorBadge === GlobalConstants.INT152) {
        badgeURL = gamingTabledata?.titleArray[0].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[0].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[0].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT142 ? gamingTabledata?.titleArray[0]?.processorTileBadgeUrl : gamingTabledata?.titleArray[0]?.processorTileBadgeUrl1;
      } else if (setProcessorBadge === GlobalConstants.INT140 || setProcessorBadge === GlobalConstants.INT143 ||
        setProcessorBadge === GlobalConstants.INT153) {
        badgeURL = gamingTabledata?.titleArray[1].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[1].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[1].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT143 ? gamingTabledata?.titleArray[1]?.processorTileBadgeUrl : gamingTabledata?.titleArray[1]?.processorTileBadgeUrl1;
      } else if (setProcessorBadge === GlobalConstants.INT141 || setProcessorBadge === GlobalConstants.INT144 ||
        setProcessorBadge === GlobalConstants.INT154 || setProcessorBadge === GlobalConstants.INT162) {
        badgeURL = gamingTabledata?.titleArray[2].badgeUrl;
        preTitlePanel = gamingTabledata?.titleArray[2].tr_gen_core_title;
        tileTitle = gamingTabledata?.titleArray[2].tr_gen_core_title_uppercase;
        procesorHomeTileBadgeURL = setProcessorBadge === GlobalConstants.INT144 ? gamingTabledata?.titleArray[2]?.processorTileBadgeUrl : gamingTabledata?.titleArray[2]?.processorTileBadgeUrl1;
        
        
      }

      gamingTabledata.badgeIcons[0].tr_badgeTitle = gamingTabledata.badgeIcons[0]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.CoreValue));

      gamingTabledata.badgeIcons[0].tr_badgePreTitle = gamingTabledata.badgeIcons[0]?.tr_badgePreTitle
        .replace(GlobalConstants.P_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreCores));

      gamingTabledata.badgeIcons[0].tr_badgeSubTitle = gamingTabledata.badgeIcons[0]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreCores));

      gamingTabledata.badgeIcons[1].tr_badgeTitle = gamingTabledata.badgeIcons[1]?.tr_badgeTitle
        .replace(GlobalConstants.XX, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.ThreadValue));

      gamingTabledata.badgeIcons[1].tr_badgePreTitle = gamingTabledata.badgeIcons[1]?.tr_badgePreTitle
        .replace(GlobalConstants.P_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.PcoreThreads));

      gamingTabledata.badgeIcons[1].tr_badgeSubTitle = gamingTabledata.badgeIcons[1]?.tr_badgeSubTitle
        .replace(GlobalConstants.E_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.EcoreThreads));

      if (Number(this.qrCodeService.qrInput.CoreValue) < 0 || this.qrCodeService.qrInput.CoreValue === '') {
        gamingTabledata.badgeIcons[0].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreCores) < 0 || this.qrCodeService.qrInput.PcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreCores) < 0 || this.qrCodeService.qrInput.EcoreCores === '') {
        gamingTabledata.badgeIcons[0].tr_badgeSubTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.ThreadValue) < 0 || this.qrCodeService.qrInput.ThreadValue === '') {
        gamingTabledata.badgeIcons[1].tr_badgeTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.PcoreThreads) < 0 || this.qrCodeService.qrInput.PcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgePreTitle = '';
      }
      if (Number(this.qrCodeService.qrInput.EcoreThreads) < 0 || this.qrCodeService.qrInput.EcoreThreads === '') {
        gamingTabledata.badgeIcons[1].tr_badgeSubTitle = '';
      }

      if(GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)|| this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT162 ) {

        gamingTabledata.badgeIcons[0].tr_badgeSubTitle1 = gamingTabledata.badgeIcons[0]?.tr_badgeSubTitle1
        .replace(GlobalConstants.L_CORE, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.LpecoreCores));
        gamingTabledata.badgeIcons[1].tr_badgeSubTitle1 = gamingTabledata.badgeIcons[1]?.tr_badgeSubTitle1
        .replace(GlobalConstants.L_THREAD, this.qrCodeService.transformDecimal(this.qrCodeService?.qrInput?.LpecoreThreads));

        if (Number(this.qrCodeService?.qrInput?.LpecoreCores) < 0 || this.qrCodeService?.qrInput?.LpecoreCores === '') {
          gamingTabledata.badgeIcons[0].tr_badgeSubTitle1 = '';
        }
        if (Number(this.qrCodeService?.qrInput?.LpecoreThreads) < 0 || this.qrCodeService?.qrInput?.LpecoreThreads === '') {
          gamingTabledata.badgeIcons[1].tr_badgeSubTitle1 = '';
        }
      }

      if ((this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT148) &&
        this.qrCodeService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT) {
        gamingTabledata.enhancementCompare.imgUrl = GlobalConstants.BLACK_TICK;
      }
      const panel = {
        panelType: gamingTabledata?.panelType1,
        preTitle: preTitlePanel,
        title: gamingTabledata?.tr_title,
        badgeUrl: badgeURL,
        badgeIcons: gamingTabledata?.badgeIcons,
        tileTitle: tileTitle,
        processorTileBadgeUrl: procesorHomeTileBadgeURL
      };
      
      this.panelData.push(panel);

      const panel2 = {
        panelType: gamingTabledata?.processorCompare?.panelType2,
        title: gamingTabledata?.processorCompare?.tr_title,
        badgeIcons: gamingTabledata?.processorCompare?.badgeIcons,
        imgUrl: gamingTabledata?.processorCompare?.imgUrl,
        currentTitle: gamingTabledata?.processorCompare?.tr_table_heading,
        panelType2: gamingTabledata?.gamingPanleType2,
        badgeIcons2: gamingTabledata?.enhancementCompare
      };
      this.panelData.push(panel2);
  }


}
