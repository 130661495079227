import { Injectable } from '@angular/core';
import { Chatbot, ResetWindow, BotMessageRenderer, CloseWindow, DisclaimerWindow } from '../../interfaces/chatbot.i';
import { QrCodeService } from '../qr-input-service/qr-input.service';
// import { AppConfigService } from '../app-config/app-config.service';

declare const Chatbot_en: any;
declare const Chatbot_es: any;
declare const Chatbot_es_mx: any;
declare const Chatbot_it: any;
declare const Chatbot_zh_cn: any;
declare const Chatbot_de: any;
declare const Chatbot_ru: any;
declare const Chatbot_pt_br: any;
declare const Chatbot_vi_vn: any;
declare const Chatbot_fr: any;
declare const Chatbot_th: any;
declare const Chatbot_id: any;
declare const Chatbot_ko_kr: any;
declare const Chatbot_ja_jp: any;

@Injectable({
  providedIn: 'root'
})
export class ChatbotApiService {

  chatbot: Chatbot;
  resetWindow: ResetWindow;
  closeWindow: CloseWindow;
  disclaimerWindow: DisclaimerWindow;
  botMessageRenderer: BotMessageRenderer;
  chatbotName: Chatbot;
  userLang: string;
  language: string;


  constructor(private qrCodeService: QrCodeService ) { }
  
    getChatbotData() {
    this.userLang = this.qrCodeService.getLocale();
    this.userLang ? this.language = this.userLang : this.language = this.qrCodeService.getLocale();
    switch (this.language) {
      case 'en-us': {
        this.chatbotName = Chatbot_en;
        break;
      }
      case 'es-mx': {
        this.chatbotName = Chatbot_es_mx;
        break;
      }
      case 'es': {
        this.chatbotName = Chatbot_es;
        break;
      }
      case 'it': {
        this.chatbotName = Chatbot_it;
        break;
      }     
      case 'zh-cn': {
        this.chatbotName = Chatbot_zh_cn;
        break;
      }
      case 'de': {
        this.chatbotName = Chatbot_de;
        break;
      }
      case 'ru': {
        this.chatbotName = Chatbot_ru;
        break;
      }
      case 'pt-br': {
        this.chatbotName = Chatbot_pt_br;
        break;
      }
      case 'vi': {
        this.chatbotName = Chatbot_vi_vn;
        break;
      }
      case 'fr': {
        this.chatbotName = Chatbot_fr;
        break;
      }
      case 'id': {
        this.chatbotName = Chatbot_id;
        break;
      }
      case 'th': {
        this.chatbotName = Chatbot_th;
        break;
      }
      case 'ko': {
        this.chatbotName = Chatbot_ko_kr;
        break;
      }
      case 'ja': {
        this.chatbotName = Chatbot_ja_jp;
        break;
      }
      default: {
        this.chatbotName = Chatbot_en;
      }
    }
    // TODO: Handle errors from parsing Json below...
    this.chatbot = JSON.parse(JSON.stringify(this.chatbotName)) as Chatbot;
    
    return this.chatbot;
  }

}