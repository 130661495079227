import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalConstants } from '../../constants/global.constant';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { QrConstants } from '../../../core/services/qr-input-service/qr.constants';
import { GraphicsService } from '../../../modules/details-page/services/graphics/graphics.service';
import { ResetBotService } from '../../../chatbot/services/resetBot.service';
import { PcSpecsService } from '../../../modules/details-page/services/pc-specs/pc-specs.service';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Idle } from '@ng-idle/core';
import { IdleService } from '../../../core/services/idle-service/idle.service';
import { Subject } from 'rxjs/internal/Subject';
import { Utils } from '../../utility/utils'
import { win11LangMappingConstant } from '../../constants/win11-lang-mapping-constant';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';
declare let gtag: Function;

@Component({
  selector: 'app-new-penel',
  templateUrl: './new-penel.component.html',
  styleUrls: ['./new-penel.component.scss']
})
export class NewPenelComponent implements OnInit, AfterViewInit {

  @Input() panelData;
  @Input() pageName: string;
  isAppGaming: boolean;
  setFontIntelDisplayOne: boolean;
  col1: boolean;
  col2: boolean;
  col3: boolean;
  gaming12thGenProcessor = GlobalConstants.GAMING_12_GEN_PROCESSOR;
  gaming12thGenProcessorCompare = GlobalConstants.PROCESSOR_COMPARE;
  imageOnlyPanel = GlobalConstants.IMAGE_ONLY_PANEL_TYPE;
  panelTypeBadgeHeading = GlobalConstants.PANEL_TYPE_BADGES_HEADING;
  isOptaneMemory: boolean;
  isStoargePage = false;
  panelTypeGamingGraphics = GlobalConstants.PANEL_TYPE_GAMING_GRAPHICS;
  gamingHighlight: number;
  nonGamingFeatureRow: boolean;
  graphicImageFlip1 = false;
  isGgraphicsGamingPage = false;
  graphicsAlignWidth = false;
  totalGraphicMemory: number;
  gamingGraphOutput: string;
  language: string;
  graphicImageClass: string;
  typeValue: string;
  graphicImageFlip2 = false;
  graphicImageFlip3 = false;
  graphicImageFlip23 = false;
  graphicImageFlip21 = false;
  graphicImageFlip11 = false;
  graphicImageFlip13 = false;
  graphicImageFlip31 = false;
  graphicImageFlip33 = false;
  gbText: string;
  wifiValue: string;
  graphicImageFlip4 = false;
  graphicImageFlip5 = false;
  graphicImageFlip6 = false;
  wifiBadge = GlobalConstants.WIFI_12_GEN_BADGE;
  irisGraphics = false;
  totalInstall: number;
  isTakeAndStore = false;
  gamingTilePanel = GlobalConstants.GAMING_TILE_PANEL;
  playVideo = false;
  connectivityt3Align = false;
  connectivityAlignWidth = false;
  connnectivityType: string;
  thunderboltGraph = GlobalConstants.THUNDERBOLT_GRAPH;
  isWindows11 = false;
  osType: string;
  gamingTilePanel1 = GlobalConstants.GAMING_TILE_PANEL2;
  panelTypeGamingProcessor = GlobalConstants.PANEL_TYPE_GAMING_PROCESSOR;
  learnMoreText: string;
  isStd12Content =  false;
  isStd11Content = false;
  isMTL = false;
  wifiTitle = false;
  wifiBadge1 = false;
  wifiBadge2 = false;
  wifiBadgeTitle = false;
  isCreator = false;
  isStd12i9: boolean; // IJR-1883
  isCreator13i9: boolean;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('videoIcon') videoicon: ElementRef;
  isCreatorProcessor = false;
  isG11Gaming = false;
  generic11thGen = false;
  isEvo = false;
  isEvoContentPanel = false;
  isArcGraphics:boolean = false
  isIntelInside:boolean = false
  evoContentArray = [];
  isGamingNonKi5 = false;
  processorName = GlobalConstants.PROCESSOR_NAME;
  isWindows11NewContent = false;
  windows11NewContentURL: SafeResourceUrl;
  @ViewChild('acc') accordionComponent: NgbAccordion;
  arcPanelHeading = GlobalConstants.ARC_PANEL_HEADING;
  evoVideoPanel =GlobalConstants.EVO_VIDEO_PANEL;
  intelInsidePanel = GlobalConstants.INTEL_INSIDE_PANEL; // IIT2-2350
  arcGraphics =GlobalConstants.ARC_PANEL_GRAPHICS_CONTENT;
  isEvo12Gen = false;
  isInfluencerPage = false; //IIT2-2951
  xboxPage = false; //IIT2-3358
  isOverflowColumn = false;
  private ngUnsubscribe = new Subject<boolean>(); //IIT2-3585
  videoEnd = 0;
  videoName: string;
  isCreator12thGen = false;
  isCreator13thGen= false;
  is12thGenVPro =  false;
  panelTypeTableFormat = GlobalConstants.PANEL_TYPE_TABLE_FORMAT;
  gradientCol: number;
  processorBadge: string;
  isCompareTableFormat = false;
  dci: string;
  isRTL = false;
  isEvo13GenProcessorPage =  false;
  is13thGenGaming = false;
  is13thGenI9Std = false;
  preTitleArc:string;
  otherArcModel:boolean;
  showSubText =[false,false,false,false,false];
  showVproDesc = [false, false, false];
  isHungaryLang = false;
  is14thGenGaming = false;
  is14thGenI9Std = false;
  is13thGenVPro =  false;
  isShowWifiBadge = false;
  isGamingWifi7Killer = false;
  isStdKillerWifi = false;
  isMTLVpro = false;
  posterImage: string;
  isArcNewModel: boolean;
  isArcIntegrated: boolean;
  isArcPro: boolean;
  isLunarLake: boolean;

  constructor(private qrService: QrCodeService,
              private libraryService: LibraryV2DataService,
              private graphicsService: GraphicsService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private resetBot: ResetBotService,
              private pcSpecsService: PcSpecsService,
              public domSanitizer: DomSanitizer,
              private idle: Idle,
              private idleService: IdleService,
             ) {
                // IIT2-2839 fix start
                window.addEventListener('message', receiveMessage, false);
                function receiveMessage(evt){
                    idle.interrupt();
                    resetBot.resetBotIcon(true); // Added for windows11 content chatbot scroll issue
                }
                // IIT2-2839 fix end
              }
  ngAfterViewInit(): void {
    if(this.isMTL){
      this.toggleVideo();
    }
  }

  ngOnInit(): void {  
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    setTimeout(() => {
      document.getElementById('appGaming').scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 200);
    // for testing
    window.addEventListener('scroll', this.scrollEvent, true); // chatbot scroll fix
    this.getQrCodeData();
    this.dci = GlobalConstants.DCI_P3;
    if (this.pageName === GlobalConstants.PROCESSOR_NAME && this.isCreator) {
      this.isCreatorProcessor = true;
    }
    // this.otherArcModel= (QrConstants.ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.GraphicsBadge)===-1) && (this.qrService?.qrInput?.GraphicsBadge[0] === 'A') &&
    // (QrConstants.FALLBACK_ARC_GRAPHICS.indexOf(this.qrService?.qrInput?.GraphicsBadge)===-1);
    this.preTitleArc=(this.qrService.qrInput.GraphicsDescription);
    if(this.pageName === GlobalConstants.GRAPHICS_CARD) {
      this.preTitleArc = this.qrService?.qrInput?.SecondaryGraphicsCard;
    }
    this.isStd12Content = (this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN12) || this.isEvo || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN13 || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN14;
    this.isStd11Content = this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN11;
    this.isCreator = this.qrService?.qrInput?.ContentType === GlobalConstants.CREATORGEN11 || this.qrService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12|| this.qrService?.qrInput?.ContentType === GlobalConstants.CREATORGEN13;
    this.isStd12i9 = this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT105 || this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT114 || (this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT162 &&  this.qrService?.qrInput?.VproEvo !== GlobalConstants.CHECK_Y)||
    this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT125 || this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT148 ? true : false; // IJR-1883
    this.isCreator13i9 = this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT134 || this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT135 ||
    this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT136 ? true : false; // IJR-1883
    this.isCreator12thGen = this.qrService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12;
    this.isCreator13thGen = this.qrService?.qrInput?.ContentType === GlobalConstants.CREATORGEN13;
    this.isGamingNonKi5 = this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT108;
    this.setFontIntelDisplayOne = this.qrService.setenusLang();
    this.isMTL = (GlobalConstants.EVO_MTL_BADGES?.includes(this.processorBadge)|| GlobalConstants.STD_MTL_BADGES?.includes(this.processorBadge)) && this.processorBadge !== GlobalConstants.INT162;
    this.isMTLVpro = (GlobalConstants.STD_MTL_BADGES?.includes(this.processorBadge)) && (this.qrService.qrInput?.VproEvo === GlobalConstants.CHECK_Y);
    window.dispatchEvent(new CustomEvent('RESET_EVENT', { detail: "reset component" }));
    this.learnMoreText = this.libraryService.getStaticText('tr_learnMoreLabel');
    this.is13thGenI9Std = this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT125; // IIT2-8781
    this.is14thGenI9Std = this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT148;
    this.isArcIntegrated =  (this.qrService?.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012 || this.qrService?.qrInput?.SecondaryGraphicsBadge === QrConstants.BADGE_GIN012) && this.pageName === GlobalConstants.GRAPHICS_NAME;
    this.isArcPro =  ((this.qrService?.qrInput?.GraphicsBadge === GlobalConstants.ARC_PRO ||this.qrService?.qrInput?.GraphicsBadge === GlobalConstants.ARC_PRO_MOBILE) && this.pageName === GlobalConstants.GRAPHICS_NAME) || ((this.qrService?.qrInput?.SecondaryGraphicsBadge === GlobalConstants.ARC_PRO || this.qrService?.qrInput?.SecondaryGraphicsBadge === GlobalConstants.ARC_PRO_MOBILE) && this.pageName === GlobalConstants.GRAPHICS_CARD);
    this.isArcNewModel = GlobalConstants.ARC_A5XXM_SERIES.includes(this.qrService?.qrInput?.GraphicsBadge) ||  GlobalConstants.ARC_A5XXM_SERIES.includes(this.qrService?.qrInput?.SecondaryGraphicsBadge) || this.isArcPro || this.isArcIntegrated;
    if (this.pageName === GlobalConstants.STORAGE_NAME) {
      this.isOptaneMemory = this.panelData[0]?.badgeIcons?.length === 1;
      this.isStoargePage = true;
    } else if (this.pageName === GlobalConstants.PROCESSOR_NAME) {
      this.isIntelInside = (this.qrService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 && GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge));
      if(this.qrService.qrInput.ContentType === GlobalConstants.GAMINGGEN12 || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN12 || this.isCreator12thGen ||
        this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN13 || this.qrService.qrInput.ContentType === GlobalConstants.GAMINGGEN13 || this.isCreator13thGen||
        this.qrService.qrInput.ContentType === GlobalConstants.GAMINGGEN14 || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN14 ||
        (this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN11
          && this.qrService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP )) {

          const processor_Badge = this.qrService?.qrInput?.ProcessorBadge;
          if (processor_Badge === GlobalConstants.INT115 || processor_Badge === GlobalConstants.INT116 || processor_Badge === GlobalConstants.INT117 ||
            processor_Badge === GlobalConstants.INT163 || processor_Badge === GlobalConstants.INT164 || processor_Badge === GlobalConstants.INT165) { 
            this.processor12GenStd();
          } else if(!(this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN14 && this.qrService.qrInput.VproEvo === GlobalConstants.CHECK_Y)) {
            this.processor12GenGaming();
          }

        // QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT066
      }
      this.isLunarLake = GlobalConstants.LUNAR_BADGES.includes(this.processorBadge);

      // IIT2-4639
      if (this.isEvo && !this.isLunarLake ) {
        this.panelData[0].preTitle = '';
      }

      this.is12thGenVPro = GlobalConstants.VPRO_12THGEN_BADGES.includes(this.processorBadge);
      this.is13thGenVPro = GlobalConstants.VPRO_13TH_GEN_BADGE.includes(this.processorBadge);
      if (this.panelData[1]?.panelType === this.panelTypeTableFormat) {
        this.isCompareTableFormat = true;
        this.gradientCol = Utils.gradientOnTable(this.panelData[1]?.table_head, QrConstants[this.processorBadge]);
      }

      if(this.is13thGenVPro) {
        this.gradientCol = 1;
      }

      if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(this.processorBadge)) {
        this.isEvo13GenProcessorPage = true;
      }
    } else if (this.pageName === GlobalConstants.CONNECTIVITY_NAME) {
      this.gamingHighlight = 3;
      if (this.connnectivityType === GlobalConstants.THUNDERBOLD_3) {
        this.connectivityt3Align = true;
        this.connectivityAlignWidth = true;
        if (this.language ==="ar-sa" || this.language === "he") {
          this.graphicImageFlip1 = true;
        }
      }
    } else if (this.pageName === GlobalConstants.WIFI_NAME) {
        let isWifiThirdParty = false;   // IJR-1887 start
        const wifiManufacture = this.qrService.qrInput.WifiManufacture;
        const wifiGeneration =  Number(this.qrService.qrInput.WifiGeneration);
        this.isShowWifiBadge = wifiGeneration === 7 && ( this.qrService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT || this.qrService?.qrInput?.ContentType[0] === 'e' ||
          (this.qrService?.qrInput?.ContentType[0] === GlobalConstants.GAMING_CONTENT && this.qrService.qrInput.WifiManufacture === GlobalConstants.KILLER));
        this.isGamingWifi7Killer =  wifiGeneration === 7 && (this.qrService?.qrInput?.ContentType[0] === GlobalConstants.GAMING_CONTENT && this.qrService.qrInput.WifiManufacture === GlobalConstants.KILLER);
        const wifiGenerationVarient = this.qrService.qrInput.WifiGenerationVarient;
        this.isStdKillerWifi =  wifiGeneration === 7 && ( (this.qrService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT || this.qrService?.qrInput?.ContentType[0] === 'e') && this.qrService.qrInput.WifiManufacture === GlobalConstants.KILLER);
        if (this.isStd12Content || this.isCreator || this.isStd11Content){
          if((wifiManufacture === GlobalConstants.KILLER && (wifiGeneration <=5 &&  wifiGeneration>0))||(wifiManufacture !==  GlobalConstants.KILLER && wifiManufacture !== GlobalConstants.INTEL_CAPS && wifiGenerationVarient !== GlobalConstants.VALUE_6E)){
            this.wifiTitle = true;
          }
          if (!((wifiManufacture !== GlobalConstants.INTEL_CAPS && wifiManufacture !== GlobalConstants.KILLER ) && wifiGenerationVarient === GlobalConstants.VALUE_6E)){
            this.wifiBadge1 = true;
          }
          if ((wifiManufacture === GlobalConstants.KILLER || wifiManufacture === GlobalConstants.INTEL_CAPS) && (wifiGeneration === 6 || wifiGeneration === 7)){
            this.wifiBadge2 = true;
          }
          if ((wifiManufacture !== GlobalConstants.INTEL_CAPS && wifiManufacture !== GlobalConstants.KILLER ) && wifiGenerationVarient === GlobalConstants.VALUE_6E){
             this.wifiBadgeTitle = true;
          }
        } // IJR-1887 ends
        this.wifiHighlight();
    } else if (this.pageName === GlobalConstants.GRAPHICS_NAME && this.isAppGaming) {
      // IJR-1029 fix starts
      if (this.panelData.panelType === GlobalConstants.HEADER_BADGE_ICON_TOP_GAMING) {
        this.isGgraphicsGamingPage = true;
      }
      // IJR-1029 fix ends
      this.graphicsAlignWidth = true;
      this.graphicsHighlight();
      if (this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE ||
        this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX) {
          this.irisGraphics = true;
      }
      if(this.qrService?.qrInput?.GraphicsBadge[0] === 'A')
      {
        this.isArcGraphics = true;
      }

      // IIT2-3304 start
      if(this.qrService?.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN003) {
        this.panelData[0].badgeUrl = GlobalConstants.INTEL_IRIS_PLUS;
        this.irisGraphics = true;
      }
      // IIT2-3304 - end

    } else if (this.pageName === GlobalConstants.MEMORY_NAME) {
      if (Number(this.qrService.qrInput.OptaneMemorySize) <= 0) { // IJR-3071
        this.setGamingMemoryHighlightPart();
      }
    } else if (this.pageName === GlobalConstants.OPERATING_SYSTEM_NAME) {
      this.isWindows11 = [QrConstants.W11, QrConstants.WINDOWS_11_UWP, GlobalConstants.W11S].includes(this.osType);
      if (this.isWindows11) {
        this.isWindows11NewContent = true;
        this.windows11NewContentURL = this.getIframelUrl();
      }
    } else if (this.pageName === GlobalConstants.EVOCONTENT_NAME && this.panelData[1].panelType === GlobalConstants.EVO_CONTENT_TILE_PANEL) {
      this.isEvoContentPanel = true;
      this.evoContentArray = this.panelData[1].titleArray;
    } else if (this.pageName === GlobalConstants.INFLUENCER_NAME && this.language === GlobalConstants.EN_US_SMALL) { //IIT2-2951
      this.isInfluencerPage = true;
    } else if (this.pageName === GlobalConstants.XBOX) { //IIT2-3358
      this.xboxPage = true;
    }
    if (this.panelData?.panelType === this.panelTypeBadgeHeading) {
      this.isTakeAndStore = this.graphicsService.isTakeAndStore;
    }
    if (this.qrService.qrInput?.ContentType === GlobalConstants.GAMINGGEN11) {
      this.isG11Gaming = true;
    }
    if (this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT095 ||
      this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT096 ||
      this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT097 ) {
      this.generic11thGen = true;
    }
    // IIT2-2869 start
    if (this.isEvo) {
      const procBadge = this.qrService?.qrInput?.ProcessorBadge;
      if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(procBadge)) {
        this.isEvo12Gen =  true;
    }
  }
    // IIT2-2869 end
    this.isOverflowColumn = this.qrService.qrInput.Language === 'de'
      || this.qrService.qrInput.Language === 'ru'; // german and russian words are exceeding column space
      this.getVideoName();  // for GA

      this.isRTL = this.language === "ar-sa" || this.language === "he";
      this.is13thGenGaming = this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT125 ||
      GlobalConstants.GAMING_13TH_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge);

      this.is14thGenGaming = this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT162 || GlobalConstants.GAMING_14TH_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge) ||
      GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge) ;
    if (this.pageName === GlobalConstants.GRAPHICS_NAME) {
      this.isHungaryLang = this.language === GlobalConstants.HUNGARY_LANG_CODE;
    }
    this.posterImage = this.qrService?.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012 || this.qrService?.qrInput?.SecondaryGraphicsBadge === QrConstants.BADGE_GIN012 ||this.qrService?.qrInput?.GraphicsBadge[0] === 'A' || this.qrService?.qrInput?.SecondaryGraphicsBadge[0] === 'A'   ? GlobalConstants.MTL_ARC_PROCESSOR_POSTER : GlobalConstants.MTL_PROCESSOR_POSTER;
  }

  isOpen(i): boolean {
      return this.accordionComponent && this.accordionComponent.activeIds.includes('panel-' + i.toString());
   }

  getQrCodeData(): void {
    const qrData = this.qrService.getQrData();
    this.isAppGaming = (qrData.ContentType === GlobalConstants.GAMINGGEN11 || qrData.ContentType === GlobalConstants.GAMINGGEN10 ||
      qrData.ContentType === GlobalConstants.GAMINGGEN9 || qrData.ContentType === GlobalConstants.GAMINGGEN12 ||
      qrData.ContentType === GlobalConstants.GAMINGGEN13 || qrData.ContentType === GlobalConstants.GAMINGGEN14) ? true : false;
    this.gbText = this.libraryService.getStaticText(GlobalConstants.GB_STATIC_TEXT);
    this.totalGraphicMemory = Number(qrData.GraphicsMemory);
    this.wifiValue = (qrData.WifiGeneration) ? qrData.WifiGeneration : '';
    this.totalInstall = Number(qrData.ActualMemory);
    this.connnectivityType = qrData.Connectivity;
    this.osType = (qrData.Os) ? qrData.Os : GlobalConstants.ANA;
    this.isEvo = qrData.ContentType === GlobalConstants.EVO;
    this.language = qrData.Language;
    this.processorBadge = this.qrService?.qrInput?.ProcessorBadge;
  }

  // IJR - 1745
  processor12GenGaming(): void {
    let activeIndex = 0;
    if (this.qrService?.qrInput?.ContentType[0] === GlobalConstants.STANDARD_TEXT || this.qrService?.qrInput?.ContentType[0] === GlobalConstants.CREATOR_CONTENT) {
      // IIT2-2254
      if(this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT162){
        activeIndex=2;
      }
      if (QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT066 ||
        QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT067 ||
        QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT068
      ) {
        if (QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT066 || QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT068) {
          activeIndex = 0;
        } else if (QrConstants[this.qrService.qrInput.ProcessorBadge] === QrConstants.INT067) {
          activeIndex = 1;
        }
      } else {
        if (this.panelData[0].badgeUrl.includes(GlobalConstants.I3_TEXT_CAPS)) {
          activeIndex = 0;
        } else if (this.panelData[0].badgeUrl.includes(GlobalConstants.I5_TEXT_CAPS)) {
          activeIndex = 1;
        } else if (this.panelData[0].badgeUrl.includes(GlobalConstants.I7_TEXT_CAPS) || this.panelData[0].badgeUrl.includes(GlobalConstants.I9_TEXT_CAPS)) {
          activeIndex = 2;
        }
      }

    } else {
      if (this.panelData[0].badgeUrl.includes(GlobalConstants.I5_TEXT_CAPS) || this.panelData[0].badgeUrl.includes(GlobalConstants.ULTRA_5_TEXT)) {
        activeIndex = 0;
      } else if ((this.panelData[0].badgeUrl.includes(GlobalConstants.I7_TEXT_CAPS) || this.panelData[0].badgeUrl.includes(GlobalConstants.ULTRA_7_TEXT)) && !this.panelData[0].badgeUrl.includes(GlobalConstants.SPECIAL_EDITION_TEXT)) {
        activeIndex = 1;
      } else if(this.panelData[0].badgeUrl.includes(GlobalConstants.I9_TEXT_CAPS) || this.panelData[0].badgeUrl.includes(GlobalConstants.SPECIAL_EDITION_TEXT) ||
      this.panelData[0].badgeUrl.includes(GlobalConstants.ULTRA_9_TEXT)) {
        activeIndex = 2;
      }
    }
    let panel2Data = this.panelData[1]?.badgeIcons2?.badgeIcons || [];
    if (panel2Data.length > 0) {
      panel2Data.forEach((element, key) => {
        element.active = false;
      });
      panel2Data[activeIndex].active = true;
    }
    window.dispatchEvent(new CustomEvent('RESET_EVENT', { detail: "reset component" }));
  }

  // Graphics start
  graphicsHighlight(): void {
    this.gamingGraphOutput = this.libraryService.getStaticText("tr_memorySubheading").replace(GlobalConstants.XX, this.totalGraphicMemory);    
    
    if (this.totalGraphicMemory == GlobalConstants.SIXTEEN) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_3;
      this.typeValue = GlobalConstants.TYPE_VALUE_3;
      if (this.graphicImageClass === GlobalConstants.GRAPH_3 && this.typeValue === GlobalConstants.TYPE_VALUE_3) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip3 = true;
        }
      }
    }else if (this.totalGraphicMemory < GlobalConstants.SIXTEEN && this.totalGraphicMemory > GlobalConstants.EIGHT) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_31;
      this.typeValue = GlobalConstants.TYPE_VALUE_31;
      if (this.graphicImageClass === GlobalConstants.GRAPH_31 && this.typeValue === GlobalConstants.TYPE_VALUE_31) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip31 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.SIXTEEN ) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_33;
      this.typeValue = GlobalConstants.TYPE_VALUE_33;
      if (this.graphicImageClass === GlobalConstants.GRAPH_33 && this.typeValue === GlobalConstants.TYPE_VALUE_33) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip33 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.FOUR && this.totalGraphicMemory < GlobalConstants.SIX ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_21;
      this.typeValue = GlobalConstants.TYPE_VALUE_21;
      if (this.graphicImageClass === GlobalConstants.GRAPH_21) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip21 = true;
        }
      }
    } else if (this.totalGraphicMemory == GlobalConstants.SIX) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_2;
      this.typeValue = GlobalConstants.TYPE_VALUE_2;
      if (this.graphicImageClass === GlobalConstants.GRAPH_2) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip2 = true;
        }
      }
    } else if (this.totalGraphicMemory <= GlobalConstants.EIGHT && this.totalGraphicMemory > GlobalConstants.SIX ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_23;
      this.typeValue = GlobalConstants.TYPE_VALUE_23;
      if (this.graphicImageClass === GlobalConstants.GRAPH_23 && this.typeValue === GlobalConstants.TYPE_VALUE_23) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip23 = true;
        }
      }
    }else if (this.totalGraphicMemory < GlobalConstants.TWO )  {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_11;
      this.typeValue = GlobalConstants.TYPE_VALUE_11;
      if (this.graphicImageClass === GlobalConstants.GRAPH_11 && this.typeValue === GlobalConstants.TYPE_VALUE_11) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip11 = true;
        }
      }
    } else if (this.totalGraphicMemory == GlobalConstants.TWO) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_1;
      this.typeValue = GlobalConstants.TYPE_VALUE_1;
      if (this.graphicImageClass === GlobalConstants.GRAPH_1 && this.typeValue === GlobalConstants.TYPE_VALUE_1) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip1 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.TWO && this.totalGraphicMemory <= GlobalConstants.FOUR) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_13;
      this.typeValue = GlobalConstants.TYPE_VALUE_13;
      if (this.graphicImageClass === GlobalConstants.GRAPH_13 && this.typeValue === GlobalConstants.TYPE_VALUE_13) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip13 = true;
        }
      }
    }
  }
  graphicImageFlip(){    
      return [this.graphicImageFlip1?'flip-graph1':'',this.graphicImageFlip11?'flip-graph11':'',this.graphicImageFlip13?'flip-graph13':'',
      this.graphicImageFlip2?'flip-graph2':'',this.graphicImageFlip21?'flip-graph21':'',this.graphicImageFlip23?'flip-graph23':'',
      this.graphicImageFlip3?'flip-graph3':'',this.graphicImageFlip31?'flip-graph31':'',this.graphicImageFlip33?'flip-graph33':'']
  }
  graphTypeValue(){
    return [this.graphicImageFlip1? 'flip-graphtypevalue1':'',this.graphicImageFlip11? 'flip-graphtypevalue11':'',this.graphicImageFlip13? 'flip-graphtypevalue13':'',
    this.graphicImageFlip3? 'flip-graphtypevalue3':'',this.graphicImageFlip31? 'flip-graphtypevalue31':'',this.graphicImageFlip33? 'flip-graphtypevalue33':'',
    this.graphicImageFlip21? 'flip-graphtypevalue21':'',this.graphicImageFlip23? 'flip-graphtypevalue23':'', this.graphicImageFlip2? 'flip-graphtypevalue2':''];
  }

  // Graphics end

  wifiHighlight(): void {
    if (this.wifiValue.includes(GlobalConstants.WIRELESS_5)) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_5;
      if (this.graphicImageClass === GlobalConstants.GRAPH_5) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip5 = true;
        }
      }
    } else if (this.wifiValue.includes(GlobalConstants.WIRELESS_4)) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_4;
      if (this.graphicImageClass === GlobalConstants.GRAPH_4) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip4 = true;
        }
      }
    } else if (this.wifiValue.includes(GlobalConstants.WIRELESS_6) || this.wifiValue.includes('7')) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_6;
      if (this.graphicImageClass === GlobalConstants.GRAPH_6) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip6 = true;
        }
      }
    } else {
      this.graphicImageClass = GlobalConstants.GRAPH3_WIFI;
      if (this.graphicImageClass === GlobalConstants.GRAPH3_WIFI) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip3 = true;
        }
      }
    }
  }

  setGamingMemoryHighlightPart() {
    let panel2Data = this.panelData[1].badgeIcons2.badgeIcons;
    panel2Data.forEach((element, key) => {
      element.active = false;
    });

    if (Number(this.totalInstall) <= GlobalConstants.EIGHT) {
      panel2Data[0].active = true;
    } else if (Number(this.totalInstall) <= GlobalConstants.SIXTEEN) {
      panel2Data[1].active = true;
    } else if (Number(this.totalInstall) > GlobalConstants.SIXTEEN) {
      panel2Data[2].active = true;
    }

  }
  showSubTitle(index)
  { this.showSubText[index] = !this.showSubText[index];  }

  showVproTitle(index){
    this.showVproDesc[index] = !this.showVproDesc[index];
  }

  toggleVideo(){
    const video = this.playVideo ? this.videoplayer.nativeElement.pause() : this.videoplayer.nativeElement.play();
    this.playVideo = !this.playVideo;
    this.qrService.changeVideoPlayingStatus(this.playVideo); //IIT2-3585 
    this.videoicon.nativeElement.style.opacity = this.playVideo ? 0 : 1;
    if (this.videoEnd === 0 && this.playVideo) {
      this.googleAnalyticsService.sendGtag( GlobalConstants.VIDEO_PLAY, { event_category: GlobalConstants.VIDEO_PLAY, event_label: this.videoName, 'video_play': 1 });
    }
    this.videoEnd += 1;
    return video;
  }
  playvideo(event: any){
    this.videoplayer.nativeElement.play();
  }

  ngOnDestroy() {
    this.qrService.changeVideoPlayingStatus(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showPlayIcon(): void {
    this.videoicon.nativeElement.style.opacity = 1;
    this.playVideo = false;
    this.videoplayer.nativeElement.load();
    this.qrService.changeVideoPlayingStatus(this.playVideo); //IIT2-3585
    this.idleService.onUserIdle(); //IIT2-3585
    this.videoEnd = 0;
  }
  // onScroll(event): void{
  //   this.resetBot.resetBotIcon(true);
  // }
// redirecting from tile 2nd panel to detail page
  gotoDetailPage(facttagValue): void {
    facttagValue = facttagValue === GlobalConstants.GRAPHICS_NAME && this.qrService.qrInput.SecondaryGraphicsCard ?
      GlobalConstants.GRAPHICS_CARD : facttagValue; // IJR-3031
    this.pcSpecsService.setCurrentHighlightFactTag(facttagValue);
    this.pcSpecsService.gotoDetailPage(facttagValue, GlobalConstants.LEARN_MORE);
  }

  // IIT2-2231-windows11 new content
  getIframelUrl(): SafeResourceUrl {
    const url = `${GlobalConstants.WINDOWS11_FOLDER_PATH}${win11LangMappingConstant[this.language]}`;
    
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // chatbot scroll
  scrollEvent = (event: any): void => {
    this.resetBot.resetBotIcon(true);
  }
  // video name for GA
  getVideoName() {
    if (this.panelData[0]?.videoUrl?.split('/').length > 0) {
      this.videoName = this.panelData[0]?.videoUrl.split('/')[this.panelData[0]?.videoUrl.split('/').length - 1].split('.')[0];
    }
  }

  processor12GenStd(): void {
    let activeIndex = 0;
    if (this.panelData[0]?.badgeUrl.includes(GlobalConstants.I5_TEXT_CAPS)) {
      activeIndex = 1;
    } else if (this.panelData[0]?.badgeUrl.includes(GlobalConstants.I7_TEXT_CAPS) && !this.panelData[0]?.badgeUrl.includes(GlobalConstants.SPECIAL_EDITION_TEXT)) {
      activeIndex = 2;
    } else if(this.panelData[0]?.badgeUrl.includes(GlobalConstants.I9_TEXT_CAPS) || this.panelData[0]?.badgeUrl.includes(GlobalConstants.SPECIAL_EDITION_TEXT)) {
      activeIndex = 3;
    }

    let panel2Data = this.panelData[1]?.badgeIcons2?.badgeIcons || [];
    if (panel2Data.length > 0) {
      panel2Data.forEach((element, key) => {
        element.active = false;
      });
      panel2Data[activeIndex].active = true;
    }
  }

  setRebuildBackgroundColor(colorValue) {
    return colorValue + ' !important';
  }

}
