import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-card-row',
  templateUrl: './card-row.component.html',
  styleUrls: ['../../tutorial/tutorial.component.scss']
})
export class CardRowComponent implements OnInit {
  homeText: string;
  whythispcText: string;
  pcspecsText: string;
  libraryV2Data: LibraryV2Data;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.homeText = this.libraryV2DataService.getHeaders('homepage').tr_specTitle;
    this.whythispcText = this.libraryV2DataService.getHeaders('why_this_pc').tr_specTitle;
    this.pcspecsText = this.libraryV2DataService.getHeaders('pc_specs').tr_specTitle;
  }

}
