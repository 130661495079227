<div id="mobileHeader" class = mobile_header>
    <div id="headerIcon" class="d-flex item_align">
    <span><img class="header_icon" alt = "header logo">
     <span class= ml-2>{{deviceName}}</span></span>
    </div>
     <div id="mobileHeaderInfo" class="d-flex item_align">
     <div id="mobileText" class="align_items ">{{mobileText}}
      <label class="switch">
        <input type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div id="frameIcon" >
    <img class="frame_icon" alt = "frame icon">
  </div>
  </div>
  </div>
<div id="mobileFooter" class="footer">
    <span class="footer_margin"><img class="cart_icon" alt="cart icon"></span>
    <div id="footerPrice" class="footer_price">{{priceFooter}}</div>
</div>