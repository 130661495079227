<div id="chromebookdetailsPanel" class="main_content" [class.chromebook-background]="isChromeBook" *ngIf="!isNewPenel">
  <div id="detailPageName" class="ftag-info-wrapper processor-wrapper detail_{{detailsPageName}}" (scroll)="onScroll($event)">
    <div id="panelData" *ngFor="let panel of panelData">
      <app-panel [pageName]="detailsPageName" [panelData]="panel"></app-panel>
    </div>
  </div>
<div *ngIf="panelData?.length===0" class="nodetailPage" id="detailsPanels">
  <div>
    Work in progress
  </div>
</div>
</div>
<!-- IJR-1736 12th genGaming starts -->
<div id="panelData12Gen" class="new-main-content" *ngIf="isNewPenel">
  <app-new-penel [pageName]="detailsPageName" [panelData]="panelData"></app-new-penel> <!-- Added new panel for new mobile enhancements -->
</div>
<!-- IJR-1736 12th genGaming end -->