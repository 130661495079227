export let mapping={
    "INTEL_ARC_A350M": {
        "panel1": "Graphics_Boost_Workflow",
        "panel2": "Unleash_Your_Creativity",
        "panel3": "Portability_Meets_Performance",
        "panel4": "Take_Control_Of_Your_Games",
        "panel5": "Maximize_Your_Intel_Platform",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        },
    },
    "INTEL_ARC_A370M": {
        "panel1": "Graphics_Boost_Workflow",
        "panel2": "Unleash_Your_Creativity",
        "panel3": "Portability_Meets_Performance",
        "panel4": "Take_Control_Of_Your_Games",
        "panel5": "Maximize_Your_Intel_Platform",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
   
    "INTEL_ARC_A730M": {
        "panel1": "Graphics_Supercharge_Gaming",
        "panel2": "Portability_Meets_Performance",
        "panel3": "Unleash_Your_Creativity_With_Intel_X_Engine",
        "panel4": "Take_Control_Of_Your_Games_1",
        "panel5": "Maximize_Your_Intel_System",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A770M": {
        "panel1": "Graphics_Supercharge_Gaming",
        "panel2": "Portability_Meets_Performance",
        "panel3": "Unleash_Your_Creativity_With_Intel_X_Engine",
        "panel4": "Take_Control_Of_Your_Games_1",
        "panel5": "Maximize_Your_Intel_System",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A310": {
        "panel1": "Graphics_Boost_Workflow",
        "panel2": "Unleash_Your_Creativity_Desktop",
        "panel3": "Give_Your_Games_A_Boost",
        "panel4": "Take_Control_Of_Your_Games_Desktop",
        "panel5": "Maximize_Your_Intel_Platform_Desktop2",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "right",
            "panel3Align": "left",
            "panel4Align": "right",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A380": {
        "panel1": "Graphics_Boost_Workflow",
        "panel2": "Unleash_Your_Creativity_Desktop",
        "panel3": "Give_Your_Games_A_Boost",
        "panel4": "Take_Control_Of_Your_Games_Desktop",
        "panel5": "Maximize_Your_Intel_Platform_Desktop2",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "right",
            "panel3Align": "left",
            "panel4Align": "right",
            "panel5Align": "left",
            "panel6Align": ""
        }
    }, "INTEL_ARC_A580": {
        "panel1": "Graphics_Level_Up_Your_Game",
        "panel2": "Experience_Advanced_Fast_Paced_Gaming",
        "panel3": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
        "panel4": "Take_Center_Stage",
        "panel5": "Maximize_Your_Intel_Platform_A580",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "right",
            "panel4Align": "left",
            "panel5Align": "right",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A750": {
        "panel1": "Graphics_Supercharge_Gaming",
        "panel2": "Experience_Immersive_Hiigh_Performance_Gaming",
        "panel3": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
        "panel4": "Take_Center_Stage",
        "panel5": "Maximize_Your_Intel_Platform_A580",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "right",
            "panel4Align": "left",
            "panel5Align": "right",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A770": {
        "panel1": "Graphics_Supercharge_Gaming_Desktop",
        "panel2": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
        "panel3": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
        "panel4": "Take_Center_Stage_Desktop",
        "panel5": "Maximize_Your_Intel_Platform_A580",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "right",
            "panel4Align": "left",
            "panel5Align": "right",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_A5XXM": {
        "panel1": "Graphics_Universe",
        "panel2": "Accelerate_Your_Frames",
        "panel3": "Ultimate_Visuals_Are_Here",
        "panel4": "Seamless_Photos_And_Videos",
        "panel5": "Experience_Cutting_Edge",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_PRO_AXXX": {
        "panel1": "Professional_Graphics",
        "panel2": "Designers_And_Engineers",
        "panel3": "Making_Ai_Attainable",
        "panel4": "Ray_Tracing",
        "panel5": "Immersive_Display",
        "panel6": "Dynamically_Optimised_Images",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": "left"
        }        
    },
    "INTEL_ARC_PRO_AXXXM": {
        "panel1": "Professional_Graphics",
        "panel2": "Expertly_Verified_Software",
        "panel3": "Ray_Tracing_Mobile",
        "panel4": "Multiple_Large_Display",
        "panel5": "Full_Memory_Bandwidth",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    },
    "INTEL_ARC_INT": {
        "panel1": "Graphics_Universe",
        "panel2": "Breathe_Life",
        "panel3": "Real_Immersive",
        "panel4": "Your_Best_Gameplay",
        "panel5": "Welcome_All_Creators",
        "panel6": "",
        "panelAlignment": {
            "panel1Align": "",
            "panel2Align": "left",
            "panel3Align": "left",
            "panel4Align": "left",
            "panel5Align": "left",
            "panel6Align": ""
        }
    }
}