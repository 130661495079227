import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { LibraryChrombookV2DataService } from '../../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class ExtendedbatterylifeService {
  panel = [];
  constructor(
    private libraryChrombookV2DataService: LibraryChrombookV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  displayExtendedBatterylife(): any {
    this.panel = [];
      let iposType = GlobalConstants.CHROMEBOOKTEXT;
      if(this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CHROMEBOOKEVO) {  // IIT2-6337
        if (this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT103 ||
          this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT104 ||
          this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT120 ||
          this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT121) {
            iposType = GlobalConstants.CHROMEBOOKEVOGEN12;
          } else {
            iposType =  GlobalConstants.CHROMEBOOKEVOTEXT;
          }
      }

    const data = this.libraryChrombookV2DataService.getExtendedBatteryLifeFactTagsDetails(GlobalConstants.EXTENDEDBATTERYLIFE, iposType); // IIT2-2223
    const panel1 = {
      panelType: GlobalConstants.HEADERBADGEICONTOP,
      title: data.tr_title,
      subTitle: data.tr_subtitle,
    };
    this.panel.push(panel1);
    return this.panel;
  }
}
