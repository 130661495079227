import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LibraryV2DataService } from '../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../core/interfaces/library-v2-data.i';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';
import { GlobalConstants } from '../../common/constants/global.constant';
import { MessageService } from '../../core/services/message-service/message.service';
import { GoogleAnalyticsService } from '../../core/services/google-analytics-service/google-analytics.service';
import { PortinoInfo } from 'src/app/core/interfaces/portino-info';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { HttpMethodsService } from 'src/app/common/utility/http-methods.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WebSocketService } from 'src/app/core/services/websocket-service/websocket.service';

declare let gtag: Function;

@Component({
  selector: 'app-body',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  welcomeBtnText: string;
  titleText: string;
  subtitleText: string;
  libraryV2Data: LibraryV2Data;
  welcomeContent: string;
  isIntel: string;
  libraryData: any;
  isStandardEnhancement = false;
  contentTypevalue = GlobalConstants.UNKNOWN;
  isLandingPage = false;
  qrInputData: any;
  buyNowSiteLink: string = '';
  BUY_NOW_API_URL = environment?.portinoAPI;
  private ngUnsubscribe = new Subject<boolean>();
  scannedQRInformation: any={};
  

  constructor(private router: Router,
    private libraryV2DataService: LibraryV2DataService,
    private qrService: QrCodeService,
    private messageService: MessageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private httpsService: HttpMethodsService,
    private webSocketService: WebSocketService) { }

  ngOnInit(): void {
    this.libraryData = this.libraryV2DataService?.getLibraryV2Data(this.qrService.qrInput.Language);
    this.titleText = this.libraryV2DataService.getStaticText('tr_welcomePageTitle');
    this.subtitleText = this.libraryV2DataService.getStaticText('tr_welcomePagetrSubtitle');
    this.welcomeBtnText = this.libraryV2DataService.getStaticText('tr_welcomePageBtnText');
    this.welcomeContent = this.libraryV2DataService.getStaticText('tr_welcomePageText');
    this.qrInputData = this.qrService?.qrInput;
    this.isIntel = this.qrInputData?.CpuManufacture;
    this.qrService.setBackgroundImage();
    if((this.qrInputData?.ContentType === GlobalConstants.STANDARDGEN12 ||
      this.qrInputData?.ContentType === GlobalConstants.STANDARDGEN13 ||
      this.qrInputData?.ContentType === GlobalConstants.CREATORGEN12||
      this.qrInputData?.ContentType === GlobalConstants.CREATORGEN13 ||
      this.qrInputData?.ContentType === GlobalConstants.STANDARDGEN14)) {
      this.isStandardEnhancement = true;
    }
    this.messageService.islandingPage.subscribe(data => {
      this.isLandingPage = data;
    });

    if (!this.isLandingPage) {
      this.onLoadGaValues();
    }
    this.webSocketService.getbuyNowAPIData();
  }
  toHome() {
    this.router.navigateByUrl('/mob-content');   
    this.googleAnalyticsService.sendGtag( GlobalConstants.GET_STARTED, { event_category: GlobalConstants.GET_STARTED, event_label: 'Get Started', 'get_started': 1 });
  }

  // IIT2-5599
  contentType() {
    const iposContentType = this.qrInputData?.ContentType;
    switch(iposContentType) {
      case GlobalConstants.ATHENA_CONTENT:
      case GlobalConstants.EVO:
        this.contentTypevalue = GlobalConstants.STANDARD_TITLE;
        break;
      case GlobalConstants.CHROMEBOOKATHENA:
      case GlobalConstants.CHROMEBOOKEVO:
        this.contentTypevalue = GlobalConstants.CHROMEBOOK_TEXT;
        break;
      case GlobalConstants.NON_INTEL_TEXT:
        this.contentTypevalue = GlobalConstants.NON_INTEL_CONTENT;
        break;
      default:
        this.contentTypes(iposContentType[0]);
    }
    this.scannedQRInformation.ipos_content=this.contentTypevalue;
    this.messageService.setLandingPage(true);
    
  }

  contentTypes(iposContentType) {
    switch (iposContentType) {
      case GlobalConstants.STANDARD_TEXT:
        this.contentTypevalue = GlobalConstants.STANDARD_TITLE;
        break;
      case GlobalConstants.GAMING_CONTENT:
        this.contentTypevalue = GlobalConstants.GAMING_NAME;
        break;
      case GlobalConstants.CREATOR_CONTENT:
        this.contentTypevalue = GlobalConstants.CREATOR;
        break;
      case GlobalConstants.CHROMEBOOK:
        this.contentTypevalue = GlobalConstants.CHROMEBOOK_TEXT;
        break;
    }
  }

  onLoadGaValues() {
    this.contentType();
    this.osValue();
    this.processorGeneration();
    this.processorManufactureValue();
    this.prcessorFamily();
    this.oled();
    this.athenaEvoDevices();
    this.vproDevices();
    this.googleAnalyticsService.sendGtag(GlobalConstants.SCANNED_MOBILE_DEVICE_INFO, { event_category: GlobalConstants.SCANNED_MOBILE_DEVICE_INFO, event_label: "mobile_device_info",...this.scannedQRInformation});

  }


  osValue() {
    const osType = this.googleAnalyticsService.osValue();
    this.scannedQRInformation.os_type=osType;
  }

  processorManufactureValue() {
    const processorManufacture = this.qrInputData?.CpuManufacture;
    this.scannedQRInformation.processor_manufacturer=processorManufacture;
  }

  processorGeneration() {
    const processorGeneration = this.googleAnalyticsService.processorGeneration();
    this.scannedQRInformation.processor_generation=processorGeneration;
  }

  prcessorFamily() {
    const processorFamily = this.googleAnalyticsService.processorFamily();
    this.scannedQRInformation.processor_family=processorFamily;
  }

  oled () {
    if (this.qrInputData.Oled === 'Y') {
      this.scannedQRInformation.oled=GlobalConstants.SAMSUNG_OLED;
    }
  }

  athenaEvoDevices() {
    const iposContentType = this.qrService?.qrInput?.ContentType;
    let contentType;
    if (iposContentType === GlobalConstants.CHROMEBOOKEVO || iposContentType === GlobalConstants.EVO) { 
      contentType=GlobalConstants.EVO; 
      } else if (iposContentType === GlobalConstants.CHROMEBOOKATHENA || iposContentType === GlobalConstants.ATHENA_CONTENT) {
      contentType=GlobalConstants.ATHENA
      }
    this.scannedQRInformation.athena_evo=contentType;
  }

  vproDevices() {
    const qrInputData = this.qrService?.qrInput;
    if (GlobalConstants.vproDevices.includes(qrInputData.ProcessorBadge)) {
      this.scannedQRInformation.vpro=GlobalConstants.VPRO;
    }
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
