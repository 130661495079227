import { GlobalConstants } from './../../common/constants/global.constant';
import { manufacturerMapping } from '../../common/constants/qrCodeMapping';

export class Utils {

  static getDeviceIconDetails(data: string): string {
    let oemBadge = '';
    const manufacturer = manufacturerMapping[data];
    if (manufacturer) {
      oemBadge = manufacturer;
    }

    return oemBadge;
  }

  static isChromebookProcessor(cpuBadge: string, contentType: string): boolean {
    let isChromebookSupportedPorcessor: boolean;
    const chromeSupportedBadges = ['INT018', 'INT029', 'INT042', 'INT016', 'INT027', 'INT057', 'INT058', 'INT059', 'INT026', 'INT039', 'INT025', 'INT038', 'INT019', 'INT030', 'INT043', 'INT005', 'INT006', 'INT062', 'INT063', 'INT064', 'INT065', 'INT066', 'INT067', 'INT068', 'INT092', 'INT111',
      'INT102', 'INT103', 'INT104', 'INT120', 'INT121', 'INT137', 'INT138', 'INT119','INT145','INT146','INT147','INT148','INT142','INT143','INT144','INT139','INT140','INT141'];
    if (contentType !== GlobalConstants.CHROMEBOOKATHENA && chromeSupportedBadges.includes(cpuBadge)) {
      isChromebookSupportedPorcessor = true;
    } else if (contentType === GlobalConstants.CHROMEBOOKATHENA && (cpuBadge === 'INT042' || cpuBadge === 'INT029')) {
      isChromebookSupportedPorcessor = true;
    } else {
      isChromebookSupportedPorcessor = false;
    }
    return isChromebookSupportedPorcessor;
  }  

  static gradientOnTable(badgesArray, cpuBadge: string): number {
    const badges = [];
    for (const badgeData of badgesArray) {
      if (badgeData?.imgUrl) {
        const badgeNumber = badgeData?.imgUrl.split('/');
        const badgeName = badgeNumber[badgeNumber.length - 1].split('.')[0];
        badges.push(badgeName);
      }
    }
    return badges.indexOf(cpuBadge);
  }
}
