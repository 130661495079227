<ngb-carousel #ngcarousel (slide)="slideActivate($event)" [showNavigationArrows]="false" [interval]="0">
  <ng-template ngbSlide>
    <div id="slideOverflow" class="overflow_y">
      <app-nav-bar></app-nav-bar>
      <div id="navBarInfo" class="ipos_mobile mt-4 mb-4 text_padding">
        <div id="navBarCard" class="row card_design">
          <div id="homeSection" class="col-6">
            <div id="homeIcon" class="card active card_index">
              <span class="text_align"><img class="home_icon_url" alt="home icon"></span>
              <div id="HomeText" class="text-center">Home</div>
            </div>
          </div>
          <div id="whyThisPcSection" class=col-6>
            <div id="whyThisPcIcon" class="card card_index">
              <span class="text_align"><img class="why_this_pc_url" alt="whythispc icon"></span>
              <div id="whyThisPcText" class="text-center">Why this PC</div>
            </div>
          </div>
        </div>
        <div id="tootlTiptext1" class=tooltiptextc1>
          {{tooltipText1}}
        </div>
      </div>
      <div id="tutorialContent" class="content_tutorial connected">
        <div id="contentWrapper" class="wrapper wrapper_default_color">
          <app-fact-tags></app-fact-tags>
        </div>
      </div>
      <div id="tutorialOverlay" class="overlay">
        <div id="skipBtn" class="top_content" (click)="back()">{{skipBtn}}</div>
        <div id="overlaysBottomSection" class="bottom_section">
          <div id="prevBtn" class="prev">{{prevBtn}}</div>
          <div id="middleThreeDots" class="middle">
            <span class="dot2 dot3"></span>
            <span class="dot2"></span>
            <span class="dot2"></span>
            <span class="dot2"></span>
          </div>
          <div id="nextBtn" (click)="goToNext()">{{nextBtn}}</div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div id="slideOverflow2" class="overflow_y">
      <div id="overlayContainer2" class=container2>
        <app-nav-bar></app-nav-bar>
        <app-card-row></app-card-row>
        <div id="tootlTipTextC2" class=tooltiptextc2>
          {{tooltipText2}}
        </div>
        <div  id="tutorialContent2" class="content_tutorial connected">
          <div id="bottomOverlays2" class="bottom_overlay overlay">
            <div id="overlaysBottomSection2" class="bottom_section">
              <div id="prevBtn2" (click)="getToPrev()">{{prevBtn}}</div>
              <div id="middleThreeDots2" class="middle">
                <span class="dot2"></span>
                <span class="dot2 dot3"></span>
                <span class="dot2"></span>
                <span class="dot2"></span>
              </div>
              <div id="nextBtn2" (click)="goToNext()">{{nextBtn}}</div>
            </div>
          </div>
          <div id="factTagWrapper" class="wrapper2">
            <app-fact-tags></app-fact-tags>
          </div>
        </div>
        <div id="overlay2Content" class="overlay">
          <div id="skipBtn2" class="top_content" (click)="back()">{{skipBtn}}</div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div>
      <app-nav-bar></app-nav-bar>
      <app-card-row></app-card-row>
      <div id="tutorialContent3" class="content_tutorial">
        <div id="contentWrapper3" class="wrapper wrapper_default_color">
          <div id="processorDetails" class="d-flex">
            <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.031 19.531C10.9613 19.6008 10.8786 19.6563 10.7875 19.6941C10.6963 19.7319 10.5987 19.7513 10.5 19.7513C10.4014 19.7513 10.3037 19.7319 10.2126 19.6941C10.1214 19.6563 10.0387 19.6008 9.969 19.531L0.969003 10.531C0.899157 10.4613 0.843743 10.3786 0.805934 10.2875C0.768124 10.1963 0.748662 10.0987 0.748662 10C0.748662 9.90135 0.768124 9.80367 0.805934 9.71255C0.843743 9.62143 0.899157 9.53867 0.969003 9.469L9.969 0.469002C10.1098 0.328173 10.3008 0.249056 10.5 0.249056C10.6992 0.249056 10.8902 0.328173 11.031 0.469002C11.1718 0.609833 11.251 0.800838 11.251 1C11.251 1.19917 11.1718 1.39017 11.031 1.531L2.5605 10L11.031 18.469C11.1008 18.5387 11.1563 18.6214 11.1941 18.7126C11.2319 18.8037 11.2513 18.9014 11.2513 19C11.2513 19.0987 11.2319 19.1963 11.1941 19.2875C11.1563 19.3786 11.1008 19.4613 11.031 19.531Z"
                fill="white" />
            </svg>
            <span class="processor_detail">{{processorHeading}}</span>
          </div>
          <div id="displayInfo" class="display">
            <div id="middleContent3" class="content_middle">
              <span class="dot_tutorial dot_index"><img class="arrow_up_icon" alt="up arrow icon"></span>
              <span class="dot_tutorial dot_index"><img class="arrow_down_icon" alt="down arrow icon"></span>
            </div>
            <div id="tootltipTextC3" class=tooltiptextc3>
              {{tooltipText3}}
            </div>
          </div>
          <div id="scrollText" class=font_type_tutorial [innerHTML]=scrollText></div>
        </div>
      </div>
      <div class="overlay">
        <div id="skipBtn3" class="top_content" (click)="back()">{{skipBtn}}</div>
        <div id="overlaysBottomSection3" class="bottom_section">
          <div id="prevBtn3" (click)="getToPrev()">{{prevBtn}}</div>
          <div id="middleThreeDots3" class="middle">
            <span class="dot2"></span>
            <span class="dot2"></span>
            <span class="dot2 dot3"></span>
            <span class="dot2"></span>
          </div>
          <div id="nextBtn3" (click)="goToNext()">{{nextBtn}}</div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div id="slideOverflow3" class="overflow_y">
      <div id="mobileHeaderInfo" class=mobile_header>
        <div id="headerDeviceName" class="header_item">
          <span style="flex:1"><img class="header_icon" alt="hp logo">
            <span class=mt-2>{{deviceName}}</span></span>
        </div>
        <div id="headerItem" class="header_item_1">
          <div id="remoteText" class="align_items " style="z-index: 10; position: relative;">
            <div id="tooltipText4" class=tooltiptextc4>
              {{tooltipText4}}
            </div>
            {{remoteText}}
            <span class="align_icon">
              <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14H21C24.866 14 28 10.866 28 7C28 3.13401 24.866 0 21 0H7ZM21 12C23.7614 12 26 9.76142 26 7C26 4.23858 23.7614 2 21 2C18.2386 2 16 4.23858 16 7C16 9.76142 18.2386 12 21 12Z"
                  fill="white" />
              </svg>
            </span>
          </div>
          <div id="frameIcon">
            <img class="frame_icon" alt="info icon"> 
          </div>
        </div>
      </div>
      <app-card-row></app-card-row>
      <div id="tutorialContent4" class="content_tutorial">
        <div id="contentWrapper4" class="wrapper wrapper_default_color">
          <app-fact-tags></app-fact-tags>
        </div>
      </div>
      <div id="tutorialOverlay4" class="overlay">
        <div id="overlaysBottomSection4" class="bottom_section">
          <div id="prevBtn4" (click)="getToPrev()">{{prevBtn}}</div>
          <div id="middleThreeDots4" class="middle">
            <span class="dot2"></span>
            <span class="dot2"></span>
            <span class="dot2"></span>
            <span class="dot2 dot3"></span>
          </div>
          <div id="gotItBtn"><button type="button" class="btn btn-primary" (click)="back()">{{gotItBtn}}</button></div>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>