import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class BatteryService {
  isNonIntel: boolean;
  panel = [];
  isAppGaming = false;
  batteryHours: number;
  evoCondition: string;

  constructor(
    private libraryService: LibraryV2DataService,
    private qrcodeService: QrCodeService
  ) { }

  setDefaultData(): void{
    const qrData = this.qrcodeService.qrInput;
    this.isAppGaming = this.qrcodeService.isAppGaming();
    this.batteryHours = +qrData.Battery;
    this.evoCondition = qrData.ContentType;
  }

  displayBatteryDetailPage(): any{
    this.panel = [];
    this.setDefaultData();
    const contentType = this.qrcodeService.qrInput.ContentType;
    const isBatteryBadge = GlobalConstants.CONTENT_TYPESS.includes(contentType);
    if (this.isAppGaming){
      const panelData =  this.libraryService.getFactTagsDetails( GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        subTitle: panelData.tr_subtitle,
        preTitle: panelData.tr_pretitle,
        badgeUrl: this.qrcodeService.gamaingEnhancement() ? panelData.badgeUrl : ''
      };
      panel1.title = panel1.title.replace(GlobalConstants.DYNAMIC_BATTERY_HOURS, this.batteryHours);
      this.panel.push(panel1);
    } else {
      if (this.evoCondition === GlobalConstants.EVO) {
          const panelData =  this.libraryService.getFactTagsDetails( GlobalConstants.EVO_CAPITAL, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panelData2 =  this.libraryService.getFactTagsDetails( GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panel1 = {
            panelType: panelData.panelType1,
            title: panelData.tr_title,
            subTitle: panelData.tr_subtitle,
            badgeIcons: panelData.badgeIcons,
            noteSection: panelData.tr_noteSection,
            badgeUrl: (contentType === GlobalConstants.EVO) ? panelData.badgeUrl : ''
          };
          const panel2 = {
            panelType: panelData2.panelType2,
            badgeIcons: panelData2.badgeIcons,
          };
          panel1.title = panel1.title.replace(GlobalConstants.DYNAMIC_BATTERY_HOURS, this.batteryHours);
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0]?.tr_badgeTitle?.replace(GlobalConstants.XX, this.batteryHours);
          this.panel.push(panel1);
          this.panel.push(panel2);
      } else if ((this.evoCondition !== GlobalConstants.EVO) && (this.batteryHours <= GlobalConstants.BATTERY_6)) {
          const panelData =  this.libraryService.getFactTagsDetails( GlobalConstants.GOOD, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panelData2 =  this.libraryService.getFactTagsDetails( GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panel1 = {
            panelType: panelData.panelType1,
            title: panelData.tr_title,
            subTitle: panelData.tr_subtitle,
            badgeIcons: panelData.badgeIcons,
            noteSection: panelData.tr_noteSection,
            badgeUrl: isBatteryBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrcodeService.qrInput.ProcessorBadge) ? GlobalConstants.BATTERY_BADGE : ''
          };
          const panel2 = {
            panelType: panelData2.panelType2,
            badgeIcons: panelData2.badgeIcons,
          };
          panel1.title = panel1.title.replace(GlobalConstants.XX, this.batteryHours);
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.XX, this.batteryHours);
          this.panel.push(panel1);
          this.panel.push(panel2);

      } else if ((this.evoCondition !== GlobalConstants.EVO) && (this.batteryHours > GlobalConstants.BATTERY_6 && this.batteryHours <= GlobalConstants.BATTERY_8)) {
          const panelData =  this.libraryService.getFactTagsDetails( GlobalConstants.BETTER, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panelData2 =  this.libraryService.getFactTagsDetails( GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panel1 = {
            panelType: panelData.panelType1,
            title: panelData.tr_title,
            subTitle: panelData.tr_subtitle,
            badgeIcons: panelData.badgeIcons,
            noteSection: panelData.tr_noteSection,
            badgeUrl: isBatteryBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrcodeService.qrInput.ProcessorBadge) ? GlobalConstants.BATTERY_BADGE : ''

          };
          const panel2 = {
            panelType: panelData2.panelType2,
            badgeIcons: panelData2.badgeIcons,
          };
          panel1.title = panel1.title.replace(GlobalConstants.DYNAMIC_BATTERY_HOURS, this.batteryHours);
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.XX, this.batteryHours);
          this.panel.push(panel1);
          this.panel.push(panel2);
      } else if ((this.evoCondition !== GlobalConstants.EVO) && (this.batteryHours  > GlobalConstants.BATTERY_8)) {
          const panelData =  this.libraryService.getFactTagsDetails( GlobalConstants.BEST, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panelData2 =  this.libraryService.getFactTagsDetails( GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
            GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          const panel1 = {
            panelType: panelData.panelType1,
            title: panelData.tr_title,
            subTitle: panelData.tr_subtitle,
            badgeIcons: panelData.badgeIcons,
            noteSection: panelData.tr_noteSection,
            badgeUrl: isBatteryBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrcodeService.qrInput.ProcessorBadge) ? GlobalConstants.BATTERY_BADGE : ''
          };
          const panel2 = {
            panelType: panelData2.panelType2,
            badgeIcons: panelData2.badgeIcons,
          };
          panel1.title = panel1.title .replace(GlobalConstants.DYNAMIC_BATTERY_HOURS, this.batteryHours);
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.XX, this.batteryHours);
          this.panel.push(panel1);
          this.panel.push(panel2);
      }
    }
    return this.panel;
  }
}
