import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { ChatBotData } from './chatbot-status-data.i';

@Injectable({
  providedIn: 'root'
})

export class ChatbotStatusService implements OnDestroy {
  chatbotData: ChatBotData;
  $chatbotSync: Observable<ChatBotData>;
  // Need replaysubject so data loads on first-time page load
  private chatbotStatus = new ReplaySubject<ChatBotData>(1);
  private ngUnsubscribe = new Subject<ChatBotData>();

  constructor() {
    this.$chatbotSync = this.chatbotStatus.asObservable();
    this.initData();
  }

  private initData = () => {
    let chatData: ChatBotData = {
      chatbotActive: false,
      chatbotMinimize: true
    }
    this.setChatbotData(chatData);
  }

  setChatbotActive(chatbotActive: boolean) {
    this.chatbotData.chatbotActive = chatbotActive;
    this.chatbotStatus.next(this.chatbotData);
  }

  setChatbotMinimize(chatbotMinimize: boolean) {
    this.chatbotData.chatbotMinimize = chatbotMinimize;
    this.chatbotStatus.next(this.chatbotData);
  }

  setChatbot(chatbotActive: boolean, chatbotMinimize: boolean) {
    this.chatbotData.chatbotActive = chatbotActive;
    this.chatbotData.chatbotMinimize = chatbotMinimize;
    this.chatbotStatus.next(this.chatbotData);
  }

  setChatbotData(chatData: ChatBotData) {
    this.chatbotData = chatData;
    this.chatbotStatus.next(chatData);
  }

  getChatbotData(): ChatBotData {
    return this.chatbotData;
  }

  isChatbotActive(): boolean {
    return this.chatbotData.chatbotActive;
  }

  isChatbotMinimize(): boolean {
    return this.chatbotData.chatbotMinimize;
  }

  minimizeChat() {
    this.chatbotData.chatbotMinimize = true;
    this.setChatbotData(this.chatbotData);
  }

  resetChat() {
    this.chatbotData.chatbotMinimize = true;
    this.chatbotData.chatbotActive = false;
    this.setChatbotData(this.chatbotData);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}