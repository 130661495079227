import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { FactTag } from '../../../core/interfaces/fact-tag.i';
import { QrCode } from '../../../core/interfaces/qr-input.i';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { DetailsService } from '../../details-page/services/details.service';
import { Utils } from '../../../common/utility/utils';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';

@Component({
  selector: 'app-view-all-specs',
  templateUrl: './view-all-specs.component.html',
  styleUrls: ['./view-all-specs.component.scss']
})
export class ViewAllSpecsComponent implements OnInit {

  private ngUnsubscribe = new Subject<boolean>();
  public qrList: QrCode;
  public factTagMenu: FactTag[] = [];
  reponseMsg: any;
  isNonIntel: boolean;
  wifiName: string;
  batteryValue: string;
  processorValue: string;
  displayValue: string;
  isTouchscreen: boolean;
  graphicsName: string;
  isLinux: boolean;
  OptaneValue: string;
  connectivityValue: string;
  processorGraphicsName: string;
  isprimaryCardDetected: boolean;
  isSecondaryGraphicsCard: boolean;
  graphicsCards: string;
  isAppGaming: boolean;
  wifiValue: number;
  wifiManufacturer: string;
  wifiGenerationVariant: string;
  wifiFamily: string;
  isChromeBook = false;
  isCreator = false;
  disableProcessorsFacttag = false;

  constructor(private qrService: QrCodeService,
    private router: Router,
    private detailServices: DetailsService,
    private libraryV2DataService: LibraryV2DataService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    const qrData = this.qrService.getQrData();
    this.isAppGaming = (qrData.ContentType === GlobalConstants.GAMINGGEN11 || qrData.ContentType === GlobalConstants.GAMINGGEN10 ||
      qrData.ContentType === GlobalConstants.GAMINGGEN9) ? true : false; // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
    this.wifiValue = +qrData.WifiGeneration;
    this.wifiManufacturer = qrData.WifiManufacture ? qrData.WifiManufacture : '';
    this.wifiGenerationVariant = qrData.WifiGenerationVarient ? qrData.WifiGenerationVarient : '';
    this.wifiFamily = qrData.WifiFamily ? qrData.WifiFamily : '';
  

    this.factTagMenu = this.qrService.getRemoteFactTag();
    this.isNonIntel = this.qrService.qrInput.ContentType === 'NI' ? true : false;
    this.wifiName = this.libraryV2DataService.getStaticText('tr_wifiTitle');
    this.batteryValue = this.libraryV2DataService.getStaticText('tr_batteryTitle');
    this.processorValue = this.libraryV2DataService.getStaticText('tr_processorTitle');
    this.displayValue = this.libraryV2DataService.getStaticText('tr_displayTitle');
    this.isTouchscreen = this.qrService.qrInput.TouchScreen === 'Y';
    this.graphicsName = this.libraryV2DataService.getStaticText('tr_graphicTitle');
    this.processorGraphicsName = this.libraryV2DataService.getStaticText('tr_processorGraphicsCaps'); // IJR-98 Multiple Graphics
    this.isLinux = this.qrService.qrInput.Os === 'ANA';
    this.OptaneValue = this.libraryV2DataService.getStaticText('tr_IntelOptaneMemory');
    this.connectivityValue = this.libraryV2DataService.getStaticText('tr_connectivityTitle');
    this.graphicsCards = this.libraryV2DataService.getStaticText('tr_graphicTitle');
    this.isChromeBook = this.qrService.qrInput.ContentType[0] === 'C' ? true : false;
    if (this.isChromeBook) {
      this.qrService.chromebackground();
      this.disableProcessorsFacttag = !Utils.isChromebookProcessor(this.qrService.qrInput.ProcessorBadge, this.qrService.qrInput.ContentType);
    }
    if (this.qrService.qrInput.ContentType[0] === 'R') {
      this.isCreator = true;
    }
    // IJR-98 MULTIPLE GRAPHICS CARD START
    this.factTagMenu.forEach(element => {
        if(element.name === GlobalConstants.GRAPHICS_NAME) {
          this.isprimaryCardDetected = true;
        } else if(element.name === GlobalConstants.GRAPHICS_CARD) {
          this.isSecondaryGraphicsCard = true;
        }
    });
// IJR-98 MULTIPLE GRAPHICS CARD END
    let newfactTagList = [];
    if(this.isNonIntel) {
      this.factTagMenu.forEach(element => {
        if(element.title !== this.OptaneValue && element.title !== this.connectivityValue) {
          newfactTagList.push(element);
        }
      });
      this.factTagMenu = newfactTagList;
    } else {
      this.factTagMenu.forEach(element => {
        if(this.isprimaryCardDetected && this.isSecondaryGraphicsCard && element.name === GlobalConstants.GRAPHICS_NAME) {
          element.title = this.processorGraphicsName;
        }
      });
      this.factTagMenu.forEach(element => {       // for creator intel graphics card removed
        if (this.isprimaryCardDetected && this.isSecondaryGraphicsCard && element.title === this.processorGraphicsName) {
          if (this.qrService.qrInput.ContentType[0] === 'R') {
            this.factTagMenu.splice(1, 1);
          }
        }
      });
      let newFilterFactTagArray1 = this.factTagMenu.map((arrayElement) => Object.assign({}, arrayElement));
      this.factTagMenu = newFilterFactTagArray1;
    }
  }

  navigateFagTag(factTagValue, Item, index): void {
      if(!(this.isNonIntel && (Item.title === this.libraryV2DataService.getStaticText('tr_batteryTitle') ||
      Item.title === this.libraryV2DataService.getStaticText('tr_connectivityTitle') ||
      Item.title === this.libraryV2DataService.getStaticText('tr_IntelOptaneMemory') ||
      (Item.title === this.libraryV2DataService.getStaticText('tr_displayTitle') && this.isLinux) ||
      Item.title === this.libraryV2DataService.getStaticText('tr_processorTitle') || 
      Item.title === this.libraryV2DataService.getStaticText('tr_displayTitle') && !this.isTouchscreen))) {
        this.detailServices.setCurrentPage('all-specs');
        // IJR-98 Graphics Card Change start
        if (this.qrService.qrInput.ContentType[0] === 'R') {   // for creator intel graphics card removed
          this.qrService.changeGraphicsCardDetails(false);
        } else {
        if(factTagValue === GlobalConstants.GRAPHICS_CARD && this.qrService.qrInput.SecondaryGraphicsBadge && index === 1) {
          this.qrService.changeGraphicsCardDetails(true);
        } else {
          if(factTagValue === GlobalConstants.GRAPHICS_CARD && index === 1) {
            this.qrService.changeGraphicsCardDetails(true);
          } else {
            this.qrService.changeGraphicsCardDetails(false);
          }
        }
        }
        // IJR-98 Graphics Card Change End
        this.googleAnalyticsService.factTagAnalytics(factTagValue, GlobalConstants.FACTTAG); // II-3852
        this.router.navigateByUrl('/specs/'+factTagValue);
      }
  }

}
