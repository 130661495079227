import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-mobile-cards',
  templateUrl: './mobile-cards.component.html',
  styleUrls: ['../../tutorial/tutorial.component.scss']
})
export class MobileCardsComponent implements OnInit {
  graphicsHeading: string;
  memoryHeading: string;
  storageHeading: string;
  osHeading: string;
  paceholderText: string;
  libraryV2Data: LibraryV2Data;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.graphicsHeading = this.libraryV2DataService.getStaticText('tr_graphicTitle');
    this.storageHeading = this.libraryV2DataService.getStaticText('tr_storageTitle');
    this.memoryHeading = this.libraryV2DataService.getStaticText('tr_memoryTitle');
    this.osHeading = this.libraryV2DataService.getStaticText('tr_operatingSystemTitle');
    this.paceholderText = this.libraryV2DataService.getStaticText('tr_placeholderText');
  
  }

}
