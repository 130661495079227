import { Component, ElementRef, OnInit, ViewChildren, QueryList } from '@angular/core';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { HeaderDetails } from 'src/app/core/interfaces/library-v2-data.i';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.css']
})

export class TrendingComponent implements OnInit {
  @ViewChildren('videoPlayer') videoPlayers!: QueryList<ElementRef<HTMLVideoElement>>;
  @ViewChildren('videoPlayer2') fullScreenVideoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChildren('videoContainer') videoContainers!: QueryList<ElementRef<HTMLElement>>;
  trendingVideos: HeaderDetails[];
  isFullscreen = false;
  previousVideo: any;
  previousIcon: any;
  previousExpandIcon: any;
  isFirstPlay: boolean[];
  scrollHeight: number[] = [];
  videoHeight: number = 0;
  fullScreenEnd: boolean;
  activeVideoIndex: number = 0;
  scrollTimeout:any;
  lastToggle: boolean;
  isAppGaming: boolean;
  isScrolling = false;
  isIOS: boolean;
  constructor(
    private qrCodeService: QrCodeService,
    private libraryV2DataService: LibraryV2DataService,
    private viewportScroller: ViewportScroller
    
  ) { }

  ngOnInit() {
    this.detectPlatform();    
    this.trendingVideos = this.libraryV2DataService.getTrending();
    this.isAppGaming= this.qrCodeService.isAppGaming();
    
    if(this.qrCodeService.language !== GlobalConstants.EN_US_SMALL){
      this.trendingVideos = this.trendingVideos.filter(item => item.videoName !== 'wifi_6e' && item.videoName !== 'Security_link');
    }
    if(this.qrCodeService.language === 'zh-cn'){
      this.trendingVideos = this.trendingVideos.filter(item => item.videoName !== 'copilot_link' && item.videoName !== 'text_link');
    }
    if(!this.isAppGaming){
      this.trendingVideos = this.trendingVideos.filter(item => !item.videoName.includes('gaming'));  
    } 

    if(this.qrCodeService.qrInput.WifiGeneration == '7'){
      this.trendingVideos = this.trendingVideos.filter(item => item.videoName !== 'wifi_6e' && item.videoName !== 'standard_tile_wifi_intel_panel2_video');        
    }
    else if(this.qrCodeService.qrInput.WifiGeneration == '6'){
      this.trendingVideos = this.trendingVideos.filter(item => !item.videoName.includes('wifi_7'));  
    }
    else{
      this.trendingVideos = this.trendingVideos.filter(item => !item.videoName.includes('wifi')); 
    }    
    
    this.trendingVideos.forEach((item, index) => {
      item.targetURL = item.targetURL.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language);
    });
    this.isFirstPlay = new Array(this.trendingVideos.length).fill(false);
    document.addEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
  }
  detectPlatform() {
    this.isIOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  ngOnDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
    this.qrCodeService.changeVideoPlayingStatus(false);
    clearTimeout(this.scrollTimeout);
    this.videoPlayers.forEach(player => {
      player.nativeElement.pause();
      player.nativeElement.removeAttribute('src');
      player.nativeElement.load();
    });
  }

  ngAfterViewInit() {
    this.trendingVideos.forEach((item, index) => {
      const videoplayer = document.getElementById('tileVideo_' + index) as HTMLVideoElement;
      videoplayer.setAttribute('src', item.targetURL);
    });

    setTimeout(() => {
      this.toggleVideo(this.activeVideoIndex);
    }, 1000);
    this.checkVideoVisibility();

  }

  handleFullscreenChange() {
    this.isFullscreen = !!document.fullscreenElement;
  }

  videoEnds(i: number) {
    const videoplayer = document.getElementById('tileVideo_' + i) as HTMLVideoElement;
    videoplayer.currentTime = 5;
    this.activeVideoIndex = (i + 1) % this.trendingVideos.length;
    this.isFirstPlay[i] = false;
    if (this.isFullscreen) {
      this.fullScreenEnd = true;
      this.fullScreen(this.activeVideoIndex);
    }
    else {
      this.toggleVideo(this.activeVideoIndex);
    }
  }
  toggleVideo(i: number) {
    this.activeVideoIndex = i;
    const videoplayer = document.getElementById('tileVideo_' + i) as HTMLVideoElement;
    const videoIcon = document.getElementById('videoIcon_' + i) as HTMLElement;
    const expandIcon = document.getElementById('expandIcon_' + i) as HTMLElement;

    if (videoplayer.paused) {
      if (this.previousVideo) {
        this.previousVideo.pause();
        this.previousIcon.style.opacity = '1';
        this.previousExpandIcon.style.display = 'none';
      }
      if (!this.isFirstPlay[i] || this.isFullscreen) {
        this.isFirstPlay[i] = true;
        videoplayer.currentTime = 0;
      }
      videoplayer.play();
      if(i === this.trendingVideos.length-1){
        this.lastToggle = true;      
      }
      this.scrollToActiveVideo(i);
      this.qrCodeService.changeVideoPlayingStatus(true);
      videoIcon.style.opacity = '0';
      if(!this.isIOS){
      expandIcon.style.display = 'block';
      }

    } else {
      videoplayer.pause();
      videoIcon.style.opacity = '1';
      expandIcon.style.display = 'none';
      this.qrCodeService.changeVideoPlayingStatus(false);
    }
    this.previousVideo = videoplayer;
    this.previousIcon = videoIcon;
    this.previousExpandIcon = expandIcon;
  }

  fullScreen(id: number) {
    let video = document.getElementById('fullScreenVideo') as HTMLVideoElement;
    let title = document.getElementById('full_screen_desc') as HTMLElement;
    const desc = document.getElementById('fullScreenPanel');    
      video.src = this.trendingVideos[id].targetURL;
      title.innerHTML = this.trendingVideos[id].tr_specTitle;
      video.muted = true;
      video.load();
      video.play();
      this.qrCodeService.changeVideoPlayingStatus(true);
      
    if (desc.requestFullscreen) {
      desc.requestFullscreen();
    } else if (desc['webkitRequestFullscreen']) {
      desc['webkitRequestFullscreen']();
    } else if (desc['mozRequestFullScreen']) {
      video['mozRequestFullScreen']();
    } else if (desc['msRequestFullscreen']) {
      desc['msRequestFullscreen']();
    } else if (desc['webkitEnterFullscreen']) {
      desc['webkitEnterFullscreen']();
    }

    if (!this.fullScreenEnd) {
      this.toggleVideo(id);
    }
  }
  exitFullScreen(id: number) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
    let video = document.getElementById('fullScreenVideo') as HTMLVideoElement;
    video.pause();
    this.toggleVideo(this.activeVideoIndex);
    this.scrollToActiveVideo(this.activeVideoIndex);
    this.fullScreenEnd = false;
  }

  scrollToActiveVideo(index: number) {
    if (this.isScrolling) return;  
    const container = document.getElementById('trendingVideo');
    if (!container) return;
    this.isScrolling = true;
  
    const activeVideo = this.videoContainers.toArray()[index].nativeElement;
    const containerRect = container.getBoundingClientRect();
    const videoRect = activeVideo.getBoundingClientRect();
    
    const scrollPosition = container.scrollTop + (videoRect.top - containerRect.top) - (containerRect.height - videoRect.height) / 3;
    
    container.scrollTo({top: scrollPosition,behavior: 'smooth'});
    setTimeout(() => {
      this.isScrolling = false;
    }, 500); 
  }
  fullScreenPause() {
    this.qrCodeService.changeVideoPlayingStatus(false);
  }
  onscrolling() {
    this.checkVideoVisibility();
    if (this.isScrolling) return;
  
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      const container = document.getElementById('trendingVideo');
      if (!container) return;
  
      const containerRect = container.getBoundingClientRect();
      const offsetPercentage = 0.25;
      const offset = containerRect.height * offsetPercentage;
      const targetCenter = containerRect.top + containerRect.height / 2 - offset;
  
      let closestVideoIndex = -1;
      let minDistance = Infinity;
  
      this.videoContainers.forEach((videoContainer, index) => {
        const element = videoContainer.nativeElement;
        const rect = element.getBoundingClientRect();
        const videoCenter = rect.top + rect.height / 2;
        const distance = Math.abs(videoCenter - targetCenter);
  
        if (distance < minDistance) {
          minDistance = distance;
          closestVideoIndex = index;
        }
      });

      if (closestVideoIndex !== -1 && 
          closestVideoIndex !== this.activeVideoIndex && 
          !this.lastToggle) {
        this.toggleVideo(closestVideoIndex);
      }  
      if (this.lastToggle) {
        this.lastToggle = false;
      }
    }, 250);
  }
  checkVideoVisibility() {
    this.videoContainers.forEach((container, index) => {
      const element = container.nativeElement;
      const rect = element.getBoundingClientRect();
      const isVisible = (rect.top>=0 && rect.top <= window.innerHeight) || (rect.bottom>=0 && rect.bottom <= window.innerHeight); 
      
      
    if (isVisible) {
      this.loadVideo(index);
    } else {
      this.unloadVideo(index);
    }});
  }
  loadVideo(index: number) {
    const videoplayer = document.getElementById('tileVideo_' + index) as HTMLVideoElement;
    if (videoplayer && !videoplayer.src) {
      videoplayer.src = this.trendingVideos[index].targetURL;
    }
  }
  unloadVideo(index: number) {
    const videoplayer = document.getElementById('tileVideo_' + index) as HTMLVideoElement;
    if (videoplayer) {
      videoplayer.pause();
      videoplayer.removeAttribute('src');
      videoplayer.load();
      this.isFirstPlay[index] = false;
      const videoIcon = document.getElementById('videoIcon_' + index);
      const expandIcon = document.getElementById('expandIcon_' + index);
      if (videoIcon) videoIcon.style.opacity = '1';
      if (expandIcon) expandIcon.style.display = 'none';
      if (this.activeVideoIndex === index) {
        this.activeVideoIndex = -1;
      }
    }
  } 
  
}

