<div id="homoLogoImage" class="logo_image" [ngClass]="isIntel === 'INTEL'?'':'nonIntel'">    
    <div id="nonStandardEnhancement" *ngIf="!isStandardEnhancement" [ngClass]="isIntel === 'INTEL'?'intel_logo mt-1219':'mt-non-intel'"></div>
    <div id="std12IntelLogo" *ngIf="isStandardEnhancement" class="std12_intel_logo mt-1219"></div>
    <div id="intelGifContent" *ngIf="isIntel === 'INTEL'" class= gif_content></div>
    <div id="standardEnhancementTitle" class= font_format [innerHTML]="titleText" [class.std_color]="isStandardEnhancement">
    </div>
    <span class = desc_font [innerHTML]="welcomeContent" [class.std_color]="isStandardEnhancement"></span>
    <button type="button" class="btn btn-primary btn_mtop" (click)="toHome()" *ngIf="welcomeBtnText">
        <div id="welocomeBtnText" class= "align-center d-flex">
            <span [innerHTML]="welcomeBtnText"></span>
            <span class= "ml-1 right_arrow_icon left_space"></span>
        </div> 
    </button>
</div>