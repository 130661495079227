<div id="cardRow" class= "ipos_mobile mt-4 mb-4" style="padding: 0px 16px">
  <div id="cardDesign" class="row card_design">
    <div id="homeSection" class = "col-6">
      <div id="homeCard" class="card active">
        <span class="align_text"><img class="home_icon_url" alt="Home icon"></span>
        <div id="homeText" class= "text-center">{{homeText}}</div>
      </div>
    </div>
    <div id="whyThisPcSection" class = col-6>
      <div id="whyThisPcIcon" class="card">
        <span class="align_text"><img class = "why_this_pc_url" alt="Why this PC icon"></span>
        <div id="whyThisPcText" class= "text-center">{{whythispcText}}</div>
      </div>
    </div>
  </div>
</div>
