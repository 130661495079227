import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global.constant';
import { QrCodeService } from '../qr-input-service/qr-input.service';
import { QrConstants } from '../qr-input-service/qr.constants';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  

  constructor(
    private qrService: QrCodeService
  ) { }
    //chatbot google analytics start
  sendGtag(eventName, params){
    const retailerId= localStorage.getItem("retailerId");
    const deviceId= localStorage.getItem("deviceId");
    const retailerName= localStorage.getItem("retailerName");
    const userId=localStorage.getItem("userId");
    const defaultParams={'retailer_id':retailerId, 'retailer_name':retailerName, 'device_id':deviceId, 'ipos_user_id':userId}
    Object.keys(params).forEach(key=>{
      if(params[key] && typeof params[key]!='number')
      {
        params[key]=params[key].split(" ").join("_");        
      }
    })
    eventName=eventName?.split(" ").join("_");
    gtag('event', eventName, {...params,...defaultParams})
  }
  public fallBack() {
    this.sendGtag( GlobalConstants.FALLBACK, { event_category: GlobalConstants.FALLBACK, event_label: GlobalConstants.CHAT_BOT, 'fall_back': 1 })
  }
  public transferToAgent() {
    this.sendGtag( GlobalConstants.TRANSFER_TO_AGENT, { event_category: GlobalConstants.TRANSFER_TO_AGENT, event_label: GlobalConstants.CHAT_BOT, 'transfer_to_agent': 1 })
  }
  public resetChat() {
    this.sendGtag( GlobalConstants.RESET_CHATBOT, { event_category: GlobalConstants.CHAT_BOT, event_label: GlobalConstants.RESET_CHATBOT, 'reset_chat_bot': 1 })
  }
  public closeBot() {
    this.sendGtag( GlobalConstants.CLOSE_CHATBOT, { event_category: GlobalConstants.CHAT_BOT, event_label: GlobalConstants.CLOSE_CHATBOT, 'close_chat_bot': 1 })
  }
  public openBot() {
    this.sendGtag( GlobalConstants.OPEN_CHATBOT, { event_category: GlobalConstants.CHAT_BOT, event_label : GlobalConstants.OPEN_CHATBOT, 'open_chat_bot': 1 })
  }
  public userMessage() {
    this.sendGtag( GlobalConstants.USER_MESSAGE, { event_category: GlobalConstants.CHAT_BOT, event_label: GlobalConstants.USER_MESSAGE, 'user_message': 1 })
  }
  public botMessage() {
    this.sendGtag( GlobalConstants.BOT_MESSAGE, { event_category: GlobalConstants.CHAT_BOT, event_label: GlobalConstants.BOT_MESSAGE, 'bot_message': 1 })
  }
  public commonPhrase(phrase: string) {
    this.sendGtag( 'Common Phrase', { event_category: GlobalConstants.CHAT_BOT, event_label: phrase, 'common_phrases_chat_bot': phrase })
  }
  public chatAcceptedByAgent() {
    this.sendGtag( GlobalConstants.CHAT_ACCEPTED_BY_AGENT, { event_category: GlobalConstants.CHAT_BOT, event_label: GlobalConstants.CHAT_ACCEPTED_BY_AGENT, 'chat_accepted_by_agent': 1 })
  }
    //chatbot google analytics end
    // II-3852-start
  public factTagAnalytics(factTags: string, typeOfFacttag:string) {
    if (factTags === GlobalConstants.OPTANE_MEMORY) {
      factTags = GlobalConstants.OPTANE_MEMORY_TEXT;
    } else if (factTags === GlobalConstants.GRAPHICS_CARD) {
      factTags = GlobalConstants.GRAPHICS_NAME;
    }
    if (typeOfFacttag === GlobalConstants.FACTTAG) {
      this.sendGtag( GlobalConstants.FACT_TAG_CLICKS, { event_category: GlobalConstants.FACT_TAG_CLICKS, event_label: factTags, 'fact_tags': factTags });
    } else if (typeOfFacttag === GlobalConstants.TILE) {
      this.sendGtag( GlobalConstants.TILE_CLICKS, { event_category: GlobalConstants.TILE_CLICKS, event_label: factTags, 'tile_clicks': factTags });
    } else if (typeOfFacttag === GlobalConstants.LEARN_MORE) {
      this.sendGtag( GlobalConstants.LEARN_MORE, { event_category: GlobalConstants.SCREEN_BUTTONS, event_label: factTags, 'screen_buttons': factTags });
    }
  }
  // II-3852-end
  // SRT-start
  public sendGaEvent(eventAction: string, eventCategory: string, eventLabel: string = null,params?:Object) {
    let srt_params= { event_category: eventCategory, event_label: eventLabel, event_value: null , event_name:eventAction};
    if(params){
      srt_params={...srt_params,...params};
    }
    this.sendGtag( eventAction,srt_params);
  }
  // SRT-end
  


  osValue() {
    const qrInputData = this.qrService?.qrInput;
    let osType;
    if (qrInputData?.ContentType[0] === GlobalConstants.CHROMEBOOK) {
      osType = GlobalConstants.CHROMEOS;
    } else if (qrInputData?.Os === QrConstants.WINDOWS || qrInputData?.Os === QrConstants.WINDOWS_10_UWP) {
      osType = GlobalConstants.WINDOWS_10;
    } else if (qrInputData?.Os === QrConstants.WINDOWS_10_UWP_S_MODE) {
      osType = GlobalConstants.WINDOWS_10_SMODE;
    } else if (qrInputData?.Os === QrConstants.WINDOWS_11 || qrInputData?.Os === QrConstants.WINDOWS_11_UWP) {
      osType = GlobalConstants.WINDOWS_11;
    } else if (qrInputData?.Os === QrConstants.WINDOWS_11_UWP_S_MODE) {
      osType = GlobalConstants.WINDOWS_11_SMODE;
    } else if (qrInputData?.Os === QrConstants.WINDOWS_DEFAULT) {
      osType = GlobalConstants.WINDOWS_GENERIC_TEXT;
    } else if (qrInputData?.Os === GlobalConstants.ANA) {
      osType = GlobalConstants.LINUXOS;
    }
    return osType;
  }

  processorGeneration() {
    const qrInputData = this.qrService?.qrInput;
    let procGen;
    if (qrInputData.ContentType === GlobalConstants.EVO || qrInputData.ContentType === GlobalConstants.CHROMEBOOKEVO) {
      GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(qrInputData.ProcessorBadge) ? procGen = 12 : procGen = 11;
    } else {
      if (qrInputData.ContentType) {
        procGen = qrInputData?.ContentType.slice(1);
      }
    }
    const generation = Number(procGen);
    if (generation === 1) {
      procGen = procGen + 'st Gen';
    } else if (generation === 2) {
      procGen = procGen + 'nd Gen';
    } else if (generation === 3) {
      procGen = procGen + 'rd Gen';
    } else { 
      procGen = procGen + 'th Gen';
    }
    return procGen;
  }

  processorFamily() {
    const qrInputData = this.qrService?.qrInput;
    let ProcessorDescription;
    if (GlobalConstants.i3.includes(qrInputData.ProcessorBadge)) {
      ProcessorDescription = GlobalConstants.INTEL_CORE_I3;
    } else if (GlobalConstants.i5.includes(qrInputData.ProcessorBadge)) {
      ProcessorDescription = GlobalConstants.INTEL_CORE_I5;
    } else if (GlobalConstants.i7.includes(qrInputData.ProcessorBadge)) {
      ProcessorDescription = GlobalConstants.INTEL_CORE_I7;
    } else if (GlobalConstants.i9.includes(qrInputData.ProcessorBadge)) {
      ProcessorDescription = GlobalConstants.INTEL_CORE_I9;
    } else if (qrInputData.ProcessorBadge === 'INT065' || qrInputData.ProcessorBadge === 'INT066') {
      ProcessorDescription = GlobalConstants.INTEL_PENTIUM_SILVER;
    } else if (qrInputData.ProcessorBadge === 'INT064' || qrInputData.ProcessorBadge === 'INT067') {
      ProcessorDescription = GlobalConstants.INTEL_PENTIUM_GOLD;
    } else if (qrInputData.ProcessorBadge === 'INT005' || qrInputData.ProcessorBadge === 'INT006'
      || qrInputData.ProcessorBadge === 'INT068') {
      ProcessorDescription = GlobalConstants.INTEL_CELERON;
    } else if (qrInputData.ProcessorBadge === 'INT063' || qrInputData.ProcessorBadge === 'INT092') {
      ProcessorDescription = GlobalConstants.INTEL_PENTIUM;
    }
    return ProcessorDescription;
  }

}
