import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/modules/home/home.component';
import { MobileComponent } from '../app/modules/mobile/mobile.component';
import { RemoteComponent } from '../app/modules/remote/remote.component';
import { MobileContentComponent } from './modules/mobile/mobile-content/mobile-content.component';
import { ViewAllSpecsComponent } from './modules/mobile/view-all-specs/view-all-specs.component';
import { TutorialComponent } from '../app/common/components/tutorial/tutorial.component';
import { OfflineTutorialComponent } from '../app/common/components/offline-tutorial/offline-tutorial.component';
import { UnconnectedProcessorComponent } from './modules/mobile/unconnected-processor/unconnected-processor.component';
import { UnconnectedGraphicsComponent } from './modules/mobile/unconnected-graphics/unconnected-graphics.component';
import { UnconnectedOsComponent } from './modules/mobile/unconnected-os/unconnected-os.component';
import { UnconnectedStorageComponent } from './modules/mobile/unconnected-storage/unconnected-storage.component';
import { UnconnectedMemoryComponent } from './modules/mobile/unconnected-memory/unconnected-memory.component';
import { CompareDevicesComponent } from './modules/mobile/compare-devices/compare-devices.component';
import { DetailsPanelComponent } from './modules/details-page/details-panel/details-panel.component';
import { TrendingComponent } from './modules/mobile/trending/trending.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'mobile',
    component: MobileComponent
  },
  {
    path: 'remote',
    component: RemoteComponent
  },

  // unconnected section
  {
    path: 'all-specs',
    component: ViewAllSpecsComponent
  },
  {
    path: 'mob-content',
    component: MobileContentComponent
  },
  {
    path: 'tutorial',
    component: TutorialComponent
  },
  {
    path: 'offline-tutorial',
    component: OfflineTutorialComponent
  },
  {
    path: 'processor',
    component: UnconnectedProcessorComponent
  },
  {
    path: 'operating-system',
    component: UnconnectedOsComponent
  },
  {
    path: 'graphics',
    component: UnconnectedGraphicsComponent
  },
  {
    path: 'storage',
    component: UnconnectedStorageComponent
  },
  {
    path: 'memory',
    component: UnconnectedMemoryComponent
  },
  {
    path: 'compare',
    component: CompareDevicesComponent
  },
  {
    path: 'specs/:facttagname',
    component: DetailsPanelComponent
  },
  {
    path: 'trending',
    component: TrendingComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
