<div class="content unconnected" [ngClass]="isNonIntel?'nonIntel':''" [class.chromebook]="isChromeBook" >
  <div id="" class="wrapper">
    <div id="ftags" class="spec-fact-tags" *ngFor="let item of factTagMenu; let i = index">
      <div id="specsFtagCard" class="card ftag" [class.creator]="isCreator" *ngIf="((item.title === processorValue && !isNonIntel) || (item.title===graphicsName || item.title === processorGraphicsName || item.title === graphicsCards) && item.image)" >
        <div id="disableProcFtag" class="d-flex w-100" (click)="(disableProcessorsFacttag) ? false : navigateFagTag(item.name, item, i)">
          <div id="procImageContent" class="image_content"><img src={{item.image}} alt="image" /></div>
          <div class="flex-grow-1">
            <div id="ftagBody" class="ftag_body">
              <div id="ftagHeading" class="ftag_heading" [class.chromebook-font]="isChromeBook" [class.creator-heading-font-color]="isCreator" [innerHTML]="item.title"></div>
              <div id="ftagDetail" class="ftag_detail">
                <div id="ftagSubhead " class="ftag_subhead" [class.chromebook-font]="isChromeBook" [class.creator-sub-heading-font-color]="isCreator" [innerHTML]="item.description">
                </div>
                <div id="enableProcFtag" *ngIf="!disableProcessorsFacttag" [ngClass]="isCreator ? 'chevron_right_ftag_white':'chevron_right_ftag'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="rethinkStorageFtagCard" class="card ftag" [class.creator]="isCreator" [class.no-sub-head]="item.name === 'alwaysOnSecurity' || item.name === 'endlessProgramsAndApps' || (!item.description && item.description !== '--' && item.name === 'rethinkStorage')" *ngIf="(!item.image) && 
        ((item.description && item.description !== '--') || (item.name === 'alwaysOnSecurity' || item.name === 'endlessProgramsAndApps') || (!item.description && item.description !== '--' && item.name === 'rethinkStorage')) && !(item.name === 'price' || item.name === 'operatingSystem')"><!--BUg IJR-1779 fix--> <!--IJR-2382 rethink storage-->
        <div id="rethinkStorageFtagBody" class="ftag_body" (click)="navigateFagTag(item.name, item, i)">
          <div id="rethinkStorageHeading" class="ftag_heading" [class.chromebook-font]="isChromeBook" [class.creator-heading-font-color]="isCreator" [ngClass]="item.title === wifiName ? 'text_trans': ''" [innerHTML]="item.title"></div>
          <div id="rethinkStorageDetail" class="ftag_detail">
            <div id="rethinkStorageSubhead" class="ftag_subhead" [class.chromebook-font]="isChromeBook" [class.creator-sub-heading-font-color]="isCreator" [ngStyle]="{'margin-top':item.name === 'das' ? '8px' : ''}" [innerHTML]="item.description"></div><!--IJR-2390 fix--> 
            <div id="dasCreator" [ngClass]="isCreator ? item.name === 'das' ? 'chevron_das_ftag':'chevron_right_ftag_white':'chevron_right_ftag'" *ngIf="(!(this.isNonIntel && (item.title === batteryValue || this.isNonIntel && item.title === processorValue || (item.title === displayValue && !isTouchscreen) || (item.title === displayValue && isLinux) || (this.isNonIntel && item.title === connectivityValue) || (this.isNonIntel && item.title === OptaneValue))))"></div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</div>