<div id="mobileCard" class= "mob_unconnected mob_unconnected_index">
    <div id="mobileCardDetails" class= "row mr-1">
        <div id="cardDetails" class="col-6 pr-0">
            <div id="osCard" class = card >
                <span class="font_processor">{{osHeading}}</span>
                <span class= text_format>{{paceholderText}}</span>
            </div>
            <div id="storageCard" class = card >
                <span class="font_processor">{{storageHeading}}</span>
                <span class= "text_format bottom_margin">{{paceholderText}}</span>
            </div>
        </div>
        <div id="cardDetails2" class="col-6 pr-0">
            <div id="graphicsCard" class = card >
                <span class="font_processor">{{graphicsHeading}}</span>
                <span class= "text_format bottom_margin">{{paceholderText}}</span>
            </div>
            <div id="memoryCard" class = card >
                <span class="font_processor">{{memoryHeading}}</span>
                <span class= text_format >{{paceholderText}}</span>
            </div>
        </div>
    </div>
</div>