export const win11LangMappingConstant = {
    'en-us'  :  'en-us',
    'ar-sa'  :  'ar-ae',
    'cs'  :  'cs-cz',
    'da' : 'da-dk',
    'de' : 'de-de',
    'el' : 'el-gr',
    'es' : 'es-es',
    'es-mx' : 'es-mx',
    'fi' : 'fi-fi',
    'fr' : 'fr-fr',
    'he' : 'he-il',
    'hu' : 'hu-hu',
    'id' : 'id-id',
    'it' : 'it-it',
    'ja' : 'ja-jp',
    'ko' : 'ko-kr',
    'nl' : 'nl-nl',
    'nb' : 'nb-no',
    'pl' : 'pl-pl',
    'pt' : 'pt-pt',
    'pt-br' : 'pt-br',
    'ro' : 'ro-ro',
    'ru' : 'ru-ru',
    'sk' : 'sk-sk',
    'sv' : 'sv-se',
    'th' : 'th-th',
    'tr' : 'tr-tr',
    'uk' : 'uk-ua',
    'vi' : 'vi-vn',
    'zh-cn' : 'zh-cn',
    'zh-tw' : 'zh-tw',
    'is': 'is'
}