import { Component, Input, OnInit } from '@angular/core';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { QrConstants } from '../../../core/services/qr-input-service/qr.constants';
import { Panel } from '../../../core/interfaces/library-v2-data.i';
import { GlobalConstants } from '../../constants/global.constant';
import { DetailsService } from '../../../modules/details-page/services/details.service';
import { Subject } from 'rxjs';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GraphicsService } from '../../../modules/details-page/services/graphics/graphics.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { win11LangMappingConstant } from '../../constants/win11-lang-mapping-constant';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  @Input() panelData: Panel;
  @Input() pageName: string;
  gradientCol: number;
  gamingHighlight: number;
  graphicImageClass: string;
  gamingGraphOutput: string;
  totalMemory: number;
  totalGraphicMemory: number;
  graphicsMemory: number;
  typeValue: string;
  isAppGaming = false;
  isNonIntel = false;
  cpuFamily: string;
  wifiValue: string;
  isTakeAndStore = false;
  connnectivityType: string;
  panelTypeTableFormat = GlobalConstants.PANEL_TYPE_TABLE_FORMAT;
  panelTypeTableFormat2 = GlobalConstants.PANEL_TYPE_TABLE_FORMAT2;
  panelTypeHeaderIconTop = GlobalConstants.PANEL_TYPE_HEADER_ICON_TOP;
  panelTypeHeaderBadgeIconTop = GlobalConstants.PANEL_TYPE_HEADER_BADGE_ICON_TOP;
  panelTypeGamingGraphics = GlobalConstants.PANEL_TYPE_GAMING_GRAPHICS;
  panelTypeGamingProcessor = GlobalConstants.PANEL_TYPE_GAMING_PROCESSOR;
  panelTypeBadgeHeading = GlobalConstants.PANEL_TYPE_BADGES_HEADING;
  gbText: string;
  totalInstall: number;
  connectivityTextAlign = false;
  setFontIntelDisplayOne = false;
  storageukbadgeukAlign = false;
  graphicImageFlip1 = false;
  graphicImageFlip2 = false;
  graphicImageFlip3 = false;
  graphicImageFlip23 = false;
  graphicImageFlip21 = false;
  graphicImageFlip11 = false;
  graphicImageFlip13 = false;
  graphicImageFlip31 = false;
  graphicImageFlip33 = false;
  graphicImageFlip4 = false;
  graphicImageFlip5 = false;
  graphicImageFlip6 = false;
  connectivityt3Align = false;
  connectivityAlignWidth = false;
  connectivityt3Align2 = false;
  wifi5text: string;
  wifi5TextAlign = false;
  language: string;
  contentType: string;
  graphicsAlignWidth = false;
  nonGamingFeatureRow = false; // Story IJR-19, 66 changes
  // IJR-240 bug fix --starts
  isWifiThirdParty6E = false;
  wifiManufacture: string;
  wfiGenerationVarient: string;
  // IJR-240 bug fix --ends
  isSmartCache = false; // IJR-244 bug fix starts
  i9GamingTextAlign = false; // 9GenGaming mobile stories: IJR-154 Epic
  setFontColor = false; // IJR-506 fix
  wifiContent = false; // IJR-274
  generic11thGen = false; // IJR - 661
  isChromeBook: boolean;
  isChrombookProcessorDDP = false;
  chromebookPanelTypeHeaderBadgeIconTop = GlobalConstants.CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP;
  chromebookPanelTypeHeaderBadgeIconTop11Gen = GlobalConstants.CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP_11GEN;
  isCreator = false;
  iscreatorIntelOptane = false;
  isCreatorFeatureRow = false;
  isCreatorProcessor =  false;
  imageOnlyPanel = GlobalConstants.IMAGE_ONLY_PANEL_TYPE;
  isWifiGamingPanel = false;
  isCreatorSmartCache = false; // IJR-1567 fix
  badgeIndex: number; // IJR-1567 fix
  isGgraphicsGamingPage = false; // IJR-1029 fix
  isEvoT4 = false; // IJR-1852
  ischromebookEvo = false;
  isRethinkStoragePage =  false;
  private ngUnsubscribe = new Subject<boolean>();
  isChromebook12gen = false; // IIT2-6440
  isWindows11NewContent = false;
  windows11NewContentURL: SafeResourceUrl;
  osType: string;
  isChromebook13genEvo: boolean;
  isIntelProcessor: boolean;
  intelInsideImage: string;
  isIntelInsidei3: boolean;
  isChromeRplMtl = false;
  isChromebookMTL = false;
  
  constructor(
    private qrService: QrCodeService,
    private detailsService: DetailsService,
    private libraryService: LibraryV2DataService,
    private graphicsService: GraphicsService,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.isChromeBook = this.qrService.isChromeBook;
    if (this.qrService.qrInput.ContentType[0] === 'R') {
      this.isCreator = true;
    }
    this.getQrCodeData();
    if (this.pageName === GlobalConstants.PROCESSOR_NAME && this.isCreator) {
      this.isCreatorProcessor = true;
    }
    if (this.pageName === GlobalConstants.PROCESSOR_NAME && (this.panelData?.panelType === this.panelTypeTableFormat ||
      this.panelData?.panelType === this.panelTypeTableFormat2)) {
      this.gradientOnTable();
    }
    if (this.pageName === GlobalConstants.GRAPHICS_NAME && this.isAppGaming) {
      // IJR-1029 fix starts
      if (this.panelData.panelType === GlobalConstants.HEADER_BADGE_ICON_TOP_GAMING) {
        this.isGgraphicsGamingPage = true;
      }
      // IJR-1029 fix ends
      this.graphicsAlignWidth = true;
      this.graphicsHighlight();
    } else {
      if (this.panelData?.panelType === this.panelTypeBadgeHeading) {
        this.isTakeAndStore = this.graphicsService.isTakeAndStore;
      }
    }
    if (this.pageName === GlobalConstants.MEMORY_NAME) {
      this.memoryHighlight();
    }
    if (this.pageName === GlobalConstants.CONNECTIVITY_NAME) {
      this.gamingHighlight = 3;
      this.connectivityTextAlign = true;
      this.language = this.qrService.qrInput.Language;
      if(this.connnectivityType === 'T3') {
        this.connectivityt3Align = true;
        this.connectivityAlignWidth = true;
        if(this.language === "ja" || this.language === "zh-tw" ) {
          this.connectivityt3Align2 = true;
          this.connectivityt3Align = false;
        }
        if(this.language ==="ar-sa" || this.language === "he") {
          this.graphicImageFlip1 = true;
        }
      }
      // IJR-1852 - starts
      if (this.connnectivityType === GlobalConstants.THUNDERBOLD_4 && 
          this.qrService.qrInput.ContentType === GlobalConstants.EVO) {
            this.isEvoT4 = true;
      }
      // IJR-1852 - ends
    }
    if (this.pageName === GlobalConstants.WIFI_NAME) {
      this.wifiContent = true;
      this.wifiHighlight();
      this.wifi5text = this.libraryService.getStaticText('tr_wifi5');
      if (this.panelData?.badgeIcons) {
        if (this.panelData?.badgeIcons[0].tr_badgeTitle) {
          if (this.panelData?.badgeIcons[0]?.tr_badgeTitle === this.wifi5text) { this.wifi5TextAlign = true; }
          else {
            if (!this.wifi5TextAlign) {
              this.wifi5TextAlign = false;
              }
          }
        }
      }
      // IJR-240 bug fix --starts
      if ((this.wifiManufacture !== GlobalConstants.INTEL_CAPS && this.wifiManufacture !== GlobalConstants.KILLER ) && this.wfiGenerationVarient === '6E'){
        this.isWifiThirdParty6E = true;
      }
      // IJR-240 bug fix --ends

      if (this.isAppGaming && this.panelData.panelType === GlobalConstants.PANEL_TYPE_GAMING_GRAPHICS) {
        this.isWifiGamingPanel = true;
      }
    }
    if (this.qrService?.qrInput?.IsUnlocked !== GlobalConstants.CHECK_Y && this.pageName === GlobalConstants.PROCESSOR_NAME && this.qrService?.qrInput?.ContentType !== GlobalConstants.CHROMEBOOKEVO) {
      if (this.panelData?.badgeIcons?.length > 0) {
        this.panelData.badgeIcons = this.panelData?.badgeIcons?.slice(0, 4);
      }
    }
    if (this.panelData?.badgeIcons) {
      if (this.panelData?.badgeIcons[0]?.tr_badgePreTitle) {  // IJR - 1257
        if (this.panelData?.badgeIcons[0]?.tr_badgePreTitle.includes('4') ||
            this.panelData?.badgeIcons[0]?.tr_badgePreTitle.includes('3')) {
          this.connectivityTextAlign = true;
        } else {
          this.connectivityTextAlign = false;
        }
      }
    }
    this.setFontIntelDisplayOne = this.qrService.setenusLang();
    this.language = this.qrService.qrInput.Language;
    this.contentType = this.qrService.qrInput.ContentType;
    if(this.language ==="uk" && this.pageName === GlobalConstants.STORAGE_NAME) {
      this.storageukbadgeukAlign = true;
    }
  // IJR - 661 start
    if (this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT095 ||
      this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT096 ||
      this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT097 ) {
      this.generic11thGen = true;
    }
  // IJR - 661 end
    // IJR - 949
    if (this.pageName === GlobalConstants.STORAGE_NAME && this.qrService.qrInput.ContentType[0] === 'R'
     && this.qrService.qrInput.IsStorageOptaneAvailable === 'Y') {
      this.iscreatorIntelOptane = true;
    }
    this.ischromebookEvo = this.qrService.qrInput.ContentType === GlobalConstants.CHROMEBOOKEVO;
    this.isChromeRplMtl = GlobalConstants.CHROME_RPL_MTL_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge);
    this.isChromebookMTL = GlobalConstants.CHROME_MTL_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge);
    this.isRethinkStoragePage = this.pageName === GlobalConstants.RETHINKSTORAGE; // GLS-353025
    // IIT2-6440
    if (this.qrService.qrInput.ContentType === GlobalConstants.CHROMEBOOK_GENERATION_12) {
      this.isChromebook12gen = true;
    }

    if (this.pageName === GlobalConstants.OPERATING_SYSTEM_NAME) {
      if ([QrConstants.W11, QrConstants.WINDOWS_11_UWP, GlobalConstants.W11S].includes(this.osType)) {
        this.isWindows11NewContent = true;
        this.windows11NewContentURL = this.getIframelUrl();
      }
    }

    if (this.ischromebookEvo && (this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT120 || this.qrService.qrInput.ProcessorBadge === GlobalConstants.INT121)) {
      this.isChromebook13genEvo = true;
    }

    if (this.qrService.qrInput.ContentType[0] === 'C' &&
     this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT137 || this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT138) {
      this.isIntelProcessor = true;
      this.intelInsideImage = GlobalConstants.INTEL_INSIDE_IMAGE;
      this.isIntelInsidei3 = this.qrService?.qrInput?.ProcessorBadge === GlobalConstants.INT138;
    }
  }

  getQrCodeData(): void {
    const qrData = this.qrService.getQrData();
    this.gbText = this.libraryService.getStaticText(GlobalConstants.GB_STATIC_TEXT);
    this.isAppGaming = (qrData.ContentType === GlobalConstants.GAMINGGEN11 || qrData.ContentType === GlobalConstants.GAMINGGEN10 ||
      qrData.ContentType === GlobalConstants.GAMINGGEN9) // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
      ? true : false;
    this.isNonIntel = (qrData.ContentType === GlobalConstants.NON_INTEL_TEXT) ? true : false;
    this.totalGraphicMemory = Number(qrData.GraphicsMemory);
    this.totalMemory = (qrData.MemoryTotal) ? +qrData.MemoryTotal : 19;
    this.totalInstall = Number(qrData.ActualMemory);
    this.cpuFamily = QrConstants[qrData?.ProcessorBadge];
    this.wifiValue = (qrData.WifiGeneration) ? qrData.WifiGeneration : 'Intel® Wi-Fi5(Gig+)';
    this.connnectivityType = qrData.Connectivity;
    this.wifiManufacture = qrData.WifiManufacture; // IJR-240 bug fix
    this.wfiGenerationVarient = qrData.WifiGenerationVarient; // IJR-240 bug fix
    // Story IJR-19, 66 changes --starts
    let processorBadgeArray = ['INT080', 'INT081', 'INT082', 'INT083', 'INT086', 'INT087', 'INT088'];  // IJR-171, 172 bug fix
    if ((qrData.ContentType === GlobalConstants.STANDARDGEN10 || qrData.ContentType === GlobalConstants.STANDARDGEN11) && 
      (processorBadgeArray.includes(qrData?.ProcessorBadge))) {
        this.nonGamingFeatureRow = true;
    }
    // IJR-244 bug fix starts
    let gen11BadgesArray = processorBadgeArray.filter(badge => badge !=='INT087');
    if ((qrData.ContentType === GlobalConstants.GAMINGGEN11 || qrData.ContentType === GlobalConstants.STANDARDGEN11) && 
    (gen11BadgesArray.includes(qrData?.ProcessorBadge))) {
      this.isSmartCache= true;
  } // IJR-244 bug fix starts
    // Story IJR-19, 66 changes --ends

    this.i9GamingTextAlign = (this.pageName === GlobalConstants.PROCESSOR_NAME && qrData.ContentType === GlobalConstants.GAMINGGEN9) ? true: false; //9GenGaming mobile stories: IJR-154 Epic
    
    this.setFontColor = (qrData.ProcessorBadge === 'INT087') ? true : false; // IJR-506 fix
    // Check if panelType is of chromebook processor type
    if (this.isChromeBook && (this.panelData.panelType === GlobalConstants.CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP ||
      this.panelData.panelType === GlobalConstants.CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP_11GEN)) {
        this.isChrombookProcessorDDP = true;
      }

    if (qrData?.ContentType === GlobalConstants.CREATORGEN10 || qrData?.ContentType === GlobalConstants.CREATORGEN11 ||
      qrData?.ContentType === GlobalConstants.CREATORGEN12) {
      this.isCreatorFeatureRow = true;
      // IJR-1567 fix start
      this.badgeIndex = this.panelData?.badgeIcons?.findIndex(element => element?.tr_badgeTitle.includes(GlobalConstants.INTEL))
      if (Number(qrData.CacheValue) > 0 && this.badgeIndex >= 0) {
        this.isCreatorSmartCache = true;
      }
      // IJR-1567 fix end
    }
    this.osType = (qrData.Os) ? qrData.Os : GlobalConstants.ANA;
  }
  graphicsHighlight(): void {
    this.gamingGraphOutput = this.totalGraphicMemory.toString() + GlobalConstants.SINGLE_SPACE + this.gbText;
    this.language = this.qrService.qrInput.Language;
    if (this.totalGraphicMemory == GlobalConstants.SIXTEEN) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_3;
      this.typeValue = GlobalConstants.TYPE_VALUE_3;
      if (this.graphicImageClass === GlobalConstants.GRAPH_3 && this.typeValue === GlobalConstants.TYPE_VALUE_3) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip3 = true;
        }
      }
    }else if (this.totalGraphicMemory < GlobalConstants.SIXTEEN && this.totalGraphicMemory > GlobalConstants.EIGHT) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_31;
      this.typeValue = GlobalConstants.TYPE_VALUE_31;
      if (this.graphicImageClass === GlobalConstants.GRAPH_31 && this.typeValue === GlobalConstants.TYPE_VALUE_31) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip31 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.SIXTEEN ) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_33;
      this.typeValue = GlobalConstants.TYPE_VALUE_33;
      if (this.graphicImageClass === GlobalConstants.GRAPH_33 && this.typeValue === GlobalConstants.TYPE_VALUE_33) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip33 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.FOUR && this.totalGraphicMemory < GlobalConstants.SIX ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_21;
      this.typeValue = GlobalConstants.TYPE_VALUE_21;
      if (this.graphicImageClass === GlobalConstants.GRAPH_21) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip21 = true;
        }
      }
    } else if (this.totalGraphicMemory == GlobalConstants.SIX) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_2;
      this.typeValue = GlobalConstants.TYPE_VALUE_2;
      if (this.graphicImageClass === GlobalConstants.GRAPH_2) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip2 = true;
        }
      }
    } else if (this.totalGraphicMemory <= GlobalConstants.EIGHT && this.totalGraphicMemory > GlobalConstants.SIX ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_23;
      this.typeValue = GlobalConstants.TYPE_VALUE_23;
      if (this.graphicImageClass === GlobalConstants.GRAPH_23 && this.typeValue === GlobalConstants.TYPE_VALUE_23) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip23 = true;
        }
      }
    }else if (this.totalGraphicMemory < GlobalConstants.TWO )  {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_11;
      this.typeValue = GlobalConstants.TYPE_VALUE_11;
      if (this.graphicImageClass === GlobalConstants.GRAPH_11 && this.typeValue === GlobalConstants.TYPE_VALUE_11) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip11 = true;
        }
      }
    } else if (this.totalGraphicMemory == GlobalConstants.TWO) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_1;
      this.typeValue = GlobalConstants.TYPE_VALUE_1;
      if (this.graphicImageClass === GlobalConstants.GRAPH_1 && this.typeValue === GlobalConstants.TYPE_VALUE_1) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip1 = true;
        }
      }
    } else if (this.totalGraphicMemory > GlobalConstants.TWO && this.totalGraphicMemory <= GlobalConstants.FOUR) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_13;
      this.typeValue = GlobalConstants.TYPE_VALUE_13;
      if (this.graphicImageClass === GlobalConstants.GRAPH_13 && this.typeValue === GlobalConstants.TYPE_VALUE_13) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip13 = true;
        }
      }
    }
  }
  graphicImageFlip(){    
    return [this.graphicImageFlip1?'flip-graph1':'',this.graphicImageFlip11?'flip-graph11':'',this.graphicImageFlip13?'flip-graph13':'',
    this.graphicImageFlip2?'flip-graph2':'',this.graphicImageFlip21?'flip-graph21':'',this.graphicImageFlip23?'flip-graph23':'',
    this.graphicImageFlip3?'flip-graph3':'',this.graphicImageFlip31?'flip-graph31':'',this.graphicImageFlip33?'flip-graph33':'']
}
graphTypeValue(){
  return [this.graphicImageFlip1? 'flip-graphtypevalue1':'',this.graphicImageFlip11? 'flip-graphtypevalue11':'',this.graphicImageFlip13? 'flip-graphtypevalue13':'',
  this.graphicImageFlip3? 'flip-graphtypevalue3':'',this.graphicImageFlip31? 'flip-graphtypevalue31':'',this.graphicImageFlip33? 'flip-graphtypevalue33':'',
  this.graphicImageFlip21? 'flip-graphtypevalue21':'',this.graphicImageFlip23? 'flip-graphtypevalue23':'']
}

  memoryHighlight(): void {
    this.gamingGraphOutput = Number(this.totalInstall).toString() + GlobalConstants.SINGLE_SPACE + this.gbText;
    this.language = this.qrService.qrInput.Language;
    if (Number(this.totalInstall) === GlobalConstants.THIRTY_TWO) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_3;
      this.typeValue = GlobalConstants.TYPE_VALUE_3;
      if (this.graphicImageClass === GlobalConstants.GRAPH_3 && this.typeValue === GlobalConstants.TYPE_VALUE_3) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip3 = true;
        }
      }
    }else if (Number(this.totalInstall) < GlobalConstants.THIRTY_TWO && Number(this.totalInstall) > GlobalConstants.SIXTEEN) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_31;
      this.typeValue = GlobalConstants.TYPE_VALUE_31;
      if (this.graphicImageClass === GlobalConstants.GRAPH_31 && this.typeValue === GlobalConstants.TYPE_VALUE_31) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip31 = true;
        }
      }
    } else if (Number(this.totalInstall) > GlobalConstants.THIRTY_TWO ) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_33;
      this.typeValue = GlobalConstants.TYPE_VALUE_33;
      if (this.graphicImageClass === GlobalConstants.GRAPH_33 && this.typeValue === GlobalConstants.TYPE_VALUE_33) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip33 = true;
        }
      }
    } else if (Number(this.totalInstall) > GlobalConstants.EIGHT && Number(this.totalInstall) < GlobalConstants.TWELVE ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_21;
      this.typeValue = GlobalConstants.TYPE_VALUE_21;
      if (this.graphicImageClass === GlobalConstants.GRAPH_21) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip21 = true;
        }
      }
    } else if (Number(this.totalInstall) === GlobalConstants.TWELVE) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_2;
      this.typeValue = GlobalConstants.TYPE_VALUE_2;
      if (this.graphicImageClass === GlobalConstants.GRAPH_2) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip2 = true;
        }
      }
    } else if (Number(this.totalInstall) <= GlobalConstants.SIXTEEN && Number(this.totalInstall) > GlobalConstants.TWELVE ) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_23;
      this.typeValue = GlobalConstants.TYPE_VALUE_23;
      if (this.graphicImageClass === GlobalConstants.GRAPH_23 && this.typeValue === GlobalConstants.TYPE_VALUE_23) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip23 = true;
        }
      }
    } else if (Number(this.totalInstall) < GlobalConstants.FOUR )  {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_11;
      this.typeValue = GlobalConstants.TYPE_VALUE_11;
      if (this.graphicImageClass === GlobalConstants.GRAPH_11 && this.typeValue === GlobalConstants.TYPE_VALUE_11) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip11 = true;
        }
      }
    } else if (Number(this.totalInstall) === GlobalConstants.FOUR) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_1;
      this.typeValue = GlobalConstants.TYPE_VALUE_1;
      if (this.graphicImageClass === GlobalConstants.GRAPH_1 && this.typeValue === GlobalConstants.TYPE_VALUE_1) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip1 = true;
        }
      }
    } else if (Number(this.totalInstall) > GlobalConstants.FOUR && Number(this.totalInstall) <= GlobalConstants.EIGHT) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_13;
      this.typeValue = GlobalConstants.TYPE_VALUE_13;
      if (this.graphicImageClass === GlobalConstants.GRAPH_13 && this.typeValue === GlobalConstants.TYPE_VALUE_13) {
        if (this.language === "ar-sa" || this.language === "he") {
          this.graphicImageFlip13 = true;
        }
      }
    }
  }


  gradientOnTable(): void {
    const badgesArray = this.panelData?.table_head;
    const badges = [];
    if (badgesArray) {
      for (const badgeData of badgesArray) {
        if (badgeData.imgUrl) {
          const badgeNumber = badgeData.imgUrl.split('/');
          const badgeName = badgeNumber[badgeNumber.length - 1].split('.')[0];
          badges.push(badgeName);
        }
      }
      if (this.cpuFamily === QrConstants.INT111) { // IIT2-4515
        this.gradientCol = 0;
        } else {
      this.gradientCol = badges?.indexOf(this.cpuFamily);
        }
    }
  }

  wifiHighlight(): void {
    this.language = this.qrService.qrInput.Language;
    if (this.wifiValue.includes(GlobalConstants.WIRELESS_5)) {
      this.gamingHighlight = GlobalConstants.TWO;
      this.graphicImageClass = GlobalConstants.GRAPH_5;
      if(this.graphicImageClass === GlobalConstants.GRAPH_5) {
        if(this.language ==="ar-sa" || this.language === "he") {
          this.graphicImageFlip5 = true;
        }
      }
    } else if (this.wifiValue.includes(GlobalConstants.WIRELESS_4)) {
      this.gamingHighlight = GlobalConstants.ONE;
      this.graphicImageClass = GlobalConstants.GRAPH_4;
      if(this.graphicImageClass === GlobalConstants.GRAPH_4) {
        if(this.language ==="ar-sa" || this.language === "he") {
          this.graphicImageFlip4 = true;
        }
      }
    } else if(this.wifiValue.includes(GlobalConstants.WIRELESS_6)) {
      this.gamingHighlight = GlobalConstants.THREE;
      this.graphicImageClass = GlobalConstants.GRAPH_6;
    if(this.graphicImageClass === GlobalConstants.GRAPH_6) {
      if(this.language ==="ar-sa" || this.language === "he") {
        this.graphicImageFlip6 = true;
      }
    }
    } else {
      this.graphicImageClass = 'graph3-wifi';
      if(this.graphicImageClass === 'graph3-wifi') {
        if(this.language ==="ar-sa" || this.language === "he") {
          this.graphicImageFlip3 = true;
        }
      }
      //DISABLE highlight for wifi generation 1-3
    }
  }

  getIframelUrl(): SafeResourceUrl {
    const url = `${GlobalConstants.WINDOWS11_FOLDER_PATH}${win11LangMappingConstant[this.language]}`;
    
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
