import { Component, OnInit } from '@angular/core';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  price: any;
  storeLogo: string;
  constructor(private qrService: QrCodeService) { }

  ngOnInit(): void {
    this.price = this.qrService.qrInput.Price;
    this.storeLogo = this.qrService.qrInput.StoreLogo;
  }

}
