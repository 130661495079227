import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { TilesDetails } from '../../../../core/interfaces/library-v2-data.i';
import { ProcessorService } from '../../services/processor/processor.service';
import { QrConstants } from '../../../../core/services/qr-input-service/qr.constants';

@Injectable({
  providedIn: 'root'
})
export class TilesService {
  isAppGaming: boolean;
  isCreator:boolean;
  isVrReady: boolean;
  tiles: TilesDetails[];
  panel = [];
  language: string;
  deviceType: string;
  isWindowsUWP: boolean;
  isEvo = false;
  isEvoUWP: boolean; // IIT2-4639
  procBadge: string;
  isRebuildTilesApplicable =  false;

  constructor(
    private libraryV2DataService: LibraryV2DataService,
    private qrCodeService: QrCodeService,
    private processorService: ProcessorService) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.qrInput;
    this.isAppGaming = qrData.ContentType[0] === GlobalConstants.GAMINGGEN12[0] ? true : false;
    this.isCreator= qrData.ContentType[0]===GlobalConstants.CREATOR_CONTENT;
    this.isVrReady = qrData.VRContent === 'Y' ? true : false;
    this.language = qrData.Language;
    this.deviceType = (qrData.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) ?
      GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
    this.isWindowsUWP = (qrData.Os === QrConstants.W10 || qrData.Os === GlobalConstants.W10S ||
      qrData.Os === QrConstants.WINDOWS_11_UWP || qrData.Os === QrConstants.UWP || qrData.Os === GlobalConstants.W11S); // IIT2-2986
    this.isEvo = qrData.ContentType === GlobalConstants.EVO;
    this.isEvoUWP = this.isEvo && this.isWindowsUWP; // IIT2-4639
    this.procBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
    this.isRebuildTilesApplicable = qrData.ContentType === GlobalConstants.STANDARDGEN11 || qrData.ContentType === GlobalConstants.STANDARDGEN12 || qrData.ContentType === GlobalConstants.STANDARDGEN13 || qrData.ContentType === GlobalConstants.STANDARDGEN14 ||qrData.ContentType=== GlobalConstants.CREATORGEN11||qrData.ContentType===GlobalConstants.CREATORGEN12||qrData.ContentType===GlobalConstants.CREATORGEN13;
  }

  getTiles(): TilesDetails[] {
    this.tiles = [];
    this.setDefaultData();
    if (this.isAppGaming) {
      this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panelData = this.processorService.processorData();
        const tileName = panelData[0].preTitle;
        let tileBadgeURL;
        if (this.qrCodeService.qrInput?.ContentType === GlobalConstants.GAMINGGEN12) {
          if (this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT099 || this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT108) {
            if(this.qrCodeService.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP || this.qrCodeService?.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_TWOINONE) { // IIT2-2499
              tileBadgeURL = GlobalConstants.G12_Gaming_Laptop_Tile_i5;
            } else {
              tileBadgeURL = GlobalConstants.I5_GAMING_TILE;
            }
          } else if (this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT100 ) {
            if(this.qrCodeService.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP || this.qrCodeService.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_TWOINONE) { // IIT2-2499
              tileBadgeURL = GlobalConstants.G12_Gaming_Laptop_Tile_i7;
            } else {
              tileBadgeURL = GlobalConstants.I7_GAMING_TILE;
            }
          } else if (this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT101 ) {
            if(this.qrCodeService.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP || this.qrCodeService.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_TWOINONE) { // IIT2-2499
              tileBadgeURL = GlobalConstants.G12_Gaming_Laptop_Tile_i9;
            } else {
              tileBadgeURL = GlobalConstants.I9_GAMING_TILE;
            }
          } else if (this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT112) {
            tileBadgeURL = GlobalConstants.G12_Gaming_Hx_i7;
          } else if (this.qrCodeService.qrInput?.ProcessorBadge === GlobalConstants.INT113) {
            tileBadgeURL = GlobalConstants.G12_Gaming_Hx_i9;
          }
        } else if (this.qrCodeService.qrInput?.ContentType === GlobalConstants.GAMINGGEN11 || this.qrCodeService.qrInput?.ContentType === GlobalConstants.GAMINGGEN13 ||
          this.qrCodeService.qrInput?.ContentType === GlobalConstants.GAMINGGEN14) {
          tileBadgeURL = panelData[0].processorTileBadgeUrl;
        }

        const processorTile: TilesDetails = {
          tr_tileTitle: tileName,
          tileUrlName: GlobalConstants.PROCESSOR_NAME,
          tileBadgeUrl: tileBadgeURL
        };
        const index = this.tiles.findIndex(tile => tile.tileUrlName === GlobalConstants.AVENGERS_NAME);
        this.tiles[index] = processorTile;
        
      // check if device is VR ready
      if (!this.isVrReady) {
        this.tiles = this.tiles.filter(tile => tile.tileUrlName !== GlobalConstants.VR_NAME);
      }

      //chcek for new Xbox tile
      if(!(!this.isVrReady && this.qrCodeService?.qrInput?.Os === QrConstants.W11)) {
        this.tiles = this.tiles.filter(tile => tile.tileUrlName !== GlobalConstants.XBOX);
      }

    }
    else if(this.procBadge === GlobalConstants.INT166 || this.procBadge === GlobalConstants.INT168){
      this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.LUNAR_ULTRA_5);
      if(this.procBadge === GlobalConstants.INT166){
        this.setUnisonData();
      }
    }
    else if( this.procBadge === GlobalConstants.INT167 || this.procBadge === GlobalConstants.INT169){
      this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.LUNAR_ULTRA_7);
      if(this.procBadge === GlobalConstants.INT167){
        this.setUnisonData();
      }
    }
   else if (!this.isAppGaming && !this.isWindowsUWP && !this.isEvo) {
      if (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN11 ||
        this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12||
      this.qrCodeService?.qrInput?.ContentType===GlobalConstants.CREATORGEN13) {
          
      const processorBadgeValue: string = QrConstants[this.qrCodeService?.qrInput?.ProcessorBadge];
      let creatorValue:string;
      if (processorBadgeValue.toLocaleLowerCase().includes(GlobalConstants.I5_TEXT_CAPS.toLocaleLowerCase())) {
        creatorValue = GlobalConstants.CREATOR_REBUILD_I5;        
        } else if (processorBadgeValue.toLocaleLowerCase().includes(GlobalConstants.I7_TEXT_CAPS.toLocaleLowerCase())) {
        creatorValue = GlobalConstants.CREATOR_REBUILD_I7;
        } else {
        creatorValue = GlobalConstants.CREATOR_REBUILD_I9;        
        }
        
        this.tiles = this.libraryV2DataService.getTilesData(creatorValue, GlobalConstants.LAPTOP_NAME);
        
         //IIT2-4760 start
         if(this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12) { 
          if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT104) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.GEN12_CREATOR_I7;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT103) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.GEN12_CREATOR_I5;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT114) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G12_Gaming_Hx_i9;
          } else if(this.qrCodeService?.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP && this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT105) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G12_Gaming_Laptop_Tile_i9;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT105) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.I9_GAMING_TILE;
          }
        }

        if(this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN13) { 
          if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT133) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.GEN12_CREATOR_I7;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT132) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.GEN12_CREATOR_I5;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT134) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G13_Gaming_HX_i9;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT136) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G12_Gaming_K_i9;
          } else if(this.qrCodeService?.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP && this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT134) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G12_Gaming_Laptop_Tile_i9;
          } else if(this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT135) {
            this.tiles[0].tileBadgeUrl = GlobalConstants.G12_Gaming_H_i9;
          }
        }
        //IIT2-4760 end
        // check if device is VR ready - IIT2-1997
        if (!this.isVrReady) {
          this.tiles = this.tiles.filter(tile => tile.tileUrlName !== GlobalConstants.VR_NAME);
        }

        //Change as per processor changes
        const panelData = this.processorService.processorData();
        const tileName = panelData[0].preTitle;
        this.tiles[0].tr_tileTitle = this.tiles[0].tr_tileTitle.replace(GlobalConstants.PROCESSOR_VALUE, tileName);
        if (this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT030) {
          if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN007){
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I5_CREATOR_TILE_BADGE_XE_GRAPHICS);
          } else if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN008) {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I5_CREATOR_TILE_BADGE_XEMAX_GRAPHICS);
          } else {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I5_CREATOR_TILE_BADGE);
          }
          // this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I5_CREATOR_TILE_BADGE);
        } else if (this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT043) {
          // this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_CREATOR_TILE_BADGE);
          if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN007){
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_CREATOR_TILE_BADGE_XE_GRAPHICS);
          } else if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN008) {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_CREATOR_TILE_BADGE_XEMAX_GRAPHICS);
          } else {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_CREATOR_TILE_BADGE);
          }

        } else if (this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT074 ) {
          if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN007){
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_SPECIAL_BADGE_XE_GRAPHICS);
          } else if (this.qrCodeService.qrInput.GraphicsBadge === QrConstants.BADGE_GIN008) {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_SPECIAL_BADGE_XEMAX_GRAPHICS);
          } else {
            this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I7_CREATOR_SPECIAL_EDITION_BADGE);
          }

        } else if (this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT083 ||
          this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT086 ||
          this.qrCodeService.qrInput.ProcessorBadge === GlobalConstants.INT088) {
          this.tiles[0].tileBadgeUrl = this.tiles[0].tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, GlobalConstants.I9_CREATOR_TILE_BADGE);
        }

      }else if(this.procBadge === GlobalConstants.INT160){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.STD_MTL_REDESIGN_ULTRA_5);
  
      }
      else if(this.procBadge === GlobalConstants.INT161 || this.procBadge === GlobalConstants.INT162 ){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.STD_MTL_REDESIGN_ULTRA_7_9);
  
      }
       else if (this.isRebuildTilesApplicable) {
        this.getRebuildUsageTiles();
      }
    } else if (this.isWindowsUWP && !this.isEvoUWP && this.isRebuildTilesApplicable) { // IJR-2118
      if(this.procBadge === GlobalConstants.INT160){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.STD_MTL_REDESIGN_ULTRA_5);
  
      }
      else if(this.procBadge === GlobalConstants.INT161 || this.procBadge === GlobalConstants.INT162 ){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.STANDARD, GlobalConstants.STD_MTL_REDESIGN_ULTRA_7_9);
  
      }else{
       this.getRebuildUsageTiles();
      }
    } else if (this.isEvo && !this.isEvoUWP) { // IIT2-2869
      // const procBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
      if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(this.procBadge)) {
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.GEN12_EVO);
      } else if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(this.procBadge)) {
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.GEN13_EVO);
      } 
      else if(this.procBadge === GlobalConstants.INT157){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVO_REDESIGN_ULTRA_5);        
        
      }
      else if(this.procBadge === GlobalConstants.INT158 || this.procBadge === GlobalConstants.INT159){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVO_REDESIGN_ULTRA_7);        
      }      
      else {
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.GEN11_EVO);
      }

      this.setUnisonData();

    } else if (this.isEvoUWP) { // IIT2-4639 start
      if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(this.procBadge)) {
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVOUWP_12GEN);
      } else if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(this.procBadge)){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVOUWP_13GEN);
      }else  if(this.procBadge === GlobalConstants.INT157){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVO_REDESIGN_ULTRA_5);        
      }else  if(this.procBadge === GlobalConstants.INT158 || this.procBadge === GlobalConstants.INT159 ){
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVO_REDESIGN_ULTRA_7);        
      }
       else {
        this.tiles = this.libraryV2DataService.getTilesData(GlobalConstants.EVO_CAPITAL, GlobalConstants.EVOUWP_11GEN);
      }
      const panelData = this.processorService.processorData();
      const tileName = panelData[0]?.preTitle;
      let tileBadge = panelData[0]?.processorTileBadgeUrl;
      if (this.qrCodeService?.qrInput?.VproEvo) {
        if(GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.indexOf(this.qrCodeService?.qrInput?.ProcessorBadge)!=-1 ||
        GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.indexOf(this.qrCodeService?.qrInput?.ProcessorBadge)!=-1){
          if(tileBadge.includes('i5')) {
            tileBadge = GlobalConstants.VPRO_EVO_I5_BADGE_1;
          } else if(tileBadge.includes('i7')) {
            tileBadge = GlobalConstants.VPRO_EVO_I7_BADGE_1;
          } else if(tileBadge.includes('i9')) {
            tileBadge = GlobalConstants.VPRO_EVO_I9_BADGE_1;
          }
        }else{
          if(tileBadge.includes('i5')) {
            tileBadge = GlobalConstants.VPRO_EVO_I5_BADGE;
          } else if(tileBadge.includes('i7')) {
            tileBadge = GlobalConstants.VPRO_EVO_I7_BADGE;
          } else if(tileBadge.includes('i9')) {
            tileBadge = GlobalConstants.VPRO_EVO_I9_BADGE;
          }          
        }
      }
      this.tiles[0].tr_tileTitle = this.tiles[0]?.tr_tileTitle?.replace(GlobalConstants.PROCESSOR_VALUE, tileName);
      this.tiles[0].tileBadgeUrl = this.tiles[0]?.tileBadgeUrl?.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, tileBadge);
      if (!this.qrCodeService?.qrInput?.TotalMemory) {
        this.tiles = this.tiles?.filter(tile => tile.tileUrlName !== GlobalConstants.MEMORY_NAME);
      }
      if (!this.qrCodeService?.qrInput?.ResolutionWidth) {
        this.tiles = this.tiles?.filter(tile => tile.tileUrlName !== GlobalConstants.DISPLAY_NAME);
      }
      this.setUnisonData();
    } // IIT2-4639 end
    return this.tiles;
  }

  getTilesDetails(pageName: string): any {
    this.panel = [];
    const tilesData = this.getTiles().find(tile => tile.tileUrlName === pageName);
    if (this.isAppGaming) {
      let tempVideoUrl = tilesData.tileDetails.videoUrl;
      let tempVideoPosterUrl = tilesData.tileDetails.videoPosterUrl;
      let subtitleUrl = "";

      if (pageName === GlobalConstants.INFLUENCER_NAME) {
        subtitleUrl = tilesData.tileDetails?.subtitleUrl?.replace(GlobalConstants.XX, this.language)
      }
      // For 12th gen gaming no need to show video
      if (pageName === GlobalConstants.VR_NAME) {
        tempVideoUrl = '';
        tempVideoPosterUrl = '';
      }

      const panel1 = {
        panelType: tilesData.tileDetails.panelType1,
        title: tilesData.tileDetails.tr_title,
        subTitle: tilesData.tileDetails.tr_subtitle,
        badgeUrl: tilesData.tileBadgeUrl,
        preTitle: tilesData.tileDetails.tr_pretitle ? tilesData.tileDetails.tr_pretitle : '',
        videoUrl: tempVideoUrl,
        videoPosterUrl: tempVideoPosterUrl,
        subtitleUrl: subtitleUrl
      };
      this.panel.push(panel1);
      if(pageName === GlobalConstants.XBOX) {
        const panel2 = {
          panelType: tilesData.tileDetails?.panelType2,
          titleArray: tilesData.tileDetails?.titleArray
        };
        this.panel.push(panel2);
      }
    } else if (!this.isAppGaming && !this.isEvo && !this.isEvoUWP) { // Standard
      let secondPanelData =
        this.deviceType === GlobalConstants.DESKTOP_NAME && tilesData.tileUrlName === GlobalConstants.ENTERTAINMENT_NAME ?
          tilesData.tileDetails.titleArray1 : tilesData.tileDetails.titleArray;

      let title = tilesData.tr_tileTitle;
      let subTitle = tilesData.tileDetails.tr_subtitle;
      secondPanelData = this.checkTiles(secondPanelData);
      let titleArray = this.setSecondPanelData(secondPanelData);
      // IJR -1761 start
      if (pageName === GlobalConstants.VR_NAME){
        title = tilesData.tileDetails.tr_title;
      }
      if (pageName === GlobalConstants.PRODUCTIVITY_NAME) {
        if (this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP) {
          if (this.qrCodeService.qrInput.Battery) {
            subTitle = tilesData.tileDetails.tr_subtitle;
            titleArray = titleArray.filter(data => data.detailPageName !== GlobalConstants.MEMORY_NAME);
          } else {
            subTitle = tilesData.tileDetails.tr_subtitle2;  // laptop memory content
            titleArray = titleArray.filter(data => data.detailPageName !== GlobalConstants.BATTERY_NAME);
          }

        } else {
          title = tilesData.tr_tileTitle1;
          subTitle = tilesData.tileDetails.tr_subtitle1;
          titleArray = titleArray.filter(data => data.detailPageName !== GlobalConstants.BATTERY_NAME);
          // IIT2-2437 Start
          titleArray.forEach(element => {
            if(element.detailPageName === GlobalConstants.PROCESSOR_NAME){
              element.tr_title = this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12 ? element?.tr_title : element?.tr_title1;
            }
          }); 
          // IIT2-2437 End
        }
        if (Number(this.qrCodeService.qrInput?.WifiGeneration) < 4 ||
          Number(this.qrCodeService.qrInput?.WifiGeneration) > 6) {
          titleArray = titleArray.filter(data => data.detailPageName !== GlobalConstants.WIFI_NAME);
        }
      }
      // IJR -1761 end
      if (pageName === GlobalConstants.LOOKINSIDE_NAME && this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) {
        tilesData.tileBadgeUrl = GlobalConstants.LOOKINSIDEPC_DESKTOP;
      }

      // IIT2-2353 start
      if (pageName === GlobalConstants.CONTENT_NAME) {
        if ((QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)!=-1 || this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A') && (this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 ||
          this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12)) { // Applicable for std and creator
          titleArray[1].tr_title = titleArray[1]?.tr_title1;
        }

        if ((this.qrCodeService?.qrInput?.ContentType === GlobalConstants.STANDARDGEN11 || this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN11 ||
          this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12)
          && this.qrCodeService?.qrInput?.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) {
          titleArray[0].tr_title =this.qrCodeService?.qrInput?.ContentType === GlobalConstants.CREATORGEN12 ? titleArray[0]?.tr_title :  titleArray[0]?.tr_title1;
        }

      }
      // IIT2-2353 end

      const panel1 = {
        panelType: tilesData?.tileDetails?.panelType1,
        title: title,
        subTitle: subTitle,
        badgeUrl: tilesData?.tileBadgeUrl
      };

      const panel2 = {
        panelType: tilesData?.tileDetails?.panelType2,
        titleArray: titleArray
      }

      this.panel.push(panel1);
      this.panel.push(panel2);
    } else if (this.isEvo && !this.isEvoUWP) {  // IIT2-1952 evo changes
      let secondPanelData;
      const panel1 = {
        panelType: tilesData.tileDetails.panelType1,
        title: tilesData.tileDetails?.tr_title,
        subTitle: tilesData.tileDetails?.tr_subtitle,
        badgeUrl: tilesData.tileBadgeUrl,
      };
      this.panel.push(panel1);

      if (pageName === GlobalConstants.EVOCONTENT_NAME) {
        const panel2 = {
          panelType: tilesData.tileDetails?.panelType2,
          title: tilesData.tileDetails?.titleArray[0].tr_title,
          subTitle: tilesData.tileDetails?.titleArray[0].tr_subtitle,
          titleArray: tilesData.tileDetails?.titleArray1,
        };
        this.panel.push(panel2);
      } else {
        secondPanelData = tilesData.tileDetails?.titleArray;
        secondPanelData = this.checkTiles(secondPanelData);
        let titleArray = this.setSecondPanelData(secondPanelData);
        const processorBadge = this.qrCodeService?.qrInput?.ProcessorBadge;
        if (pageName === GlobalConstants.CREATEMORE_NAME) {
          const graphicsBadge = this.qrCodeService?.qrInput?.GraphicsBadge;
          const SecGraphicsBadge = this.qrCodeService?.qrInput?.SecondaryGraphicsBadge;
          if ((GlobalConstants.IRIS_GRAPHICS_BADGES.includes(graphicsBadge) || GlobalConstants.IRIS_GRAPHICS_BADGES.includes(SecGraphicsBadge)) &&
          this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] !== 'A') {
            let graphicsDescription;
            let tempGraphicsBadge = GlobalConstants.IRIS_GRAPHICS_BADGES.includes(SecGraphicsBadge) ? SecGraphicsBadge : graphicsBadge;
            if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(processorBadge)) { // check if 12th gen
                graphicsDescription = (QrConstants[tempGraphicsBadge] === QrConstants.GIN007) ? this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXe12Gen') :
                this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXeMax12Gen');
            } else if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(processorBadge)){
                graphicsDescription = (QrConstants[tempGraphicsBadge] === QrConstants.GIN007) ? this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXe13Gen') :
                this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXeMax13Gen');
            } else {
                graphicsDescription = (QrConstants[tempGraphicsBadge] === QrConstants.GIN007) ? this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXe') :
                this.libraryV2DataService.getStaticText('tr_processorGraphicsIrisXeMax');
            }
            if (QrConstants[processorBadge] === QrConstants.INT069 || QrConstants[processorBadge] === QrConstants.INT109 ||
              QrConstants[processorBadge] === QrConstants.INT122) {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I5_TEXT));
            } else if (QrConstants[processorBadge] === QrConstants.INT070 || QrConstants[processorBadge] === QrConstants.INT110 ||
              QrConstants[processorBadge] === QrConstants.INT123) {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I7_TEXT));
            } else if (QrConstants[processorBadge] === QrConstants.INT118 || QrConstants[processorBadge] === QrConstants.INT124){
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I9_TEXT));
            }
          } else if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(processorBadge) &&
              (QrConstants.ARC_GRAPHICS.indexOf(graphicsBadge) > -1 || this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A')) {
              const newGraphicsValue = "tr_"+ graphicsBadge;
              let graphicsDescription = this.libraryV2DataService.getStaticText('tr_arcGraphicsBadgeToModelNameMapping')?.[newGraphicsValue];
              if (QrConstants.ARC_GRAPHICS.indexOf(graphicsBadge) === -1 && this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A'){
                 graphicsDescription = this.qrCodeService?.qrInput?.GraphicsDescription;
              }
              if (QrConstants[processorBadge] === QrConstants.INT109) {
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I5_TEXT));
              } else if (QrConstants[processorBadge] === QrConstants.INT110){
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I7_TEXT));
              } else {
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I9_TEXT));
              }
              // IIT2-2871 end
          } else if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(processorBadge) &&
            (QrConstants.ARC_GRAPHICS.indexOf(graphicsBadge) > -1  || this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A')) {
            const newGraphicsValue = "tr_"+ graphicsBadge;
            let graphicsDescription = this.libraryV2DataService.getStaticText('tr_arcGraphicsBadgeToModelNameMapping')?.[newGraphicsValue];
            if (QrConstants.ARC_GRAPHICS.indexOf(graphicsBadge) === -1 && this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A'){
              graphicsDescription = this.qrCodeService?.qrInput?.GraphicsDescription;
           }
            if (QrConstants[processorBadge] === QrConstants.INT122) {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I5_TEXT));
            } else if (QrConstants[processorBadge] === QrConstants.INT123){
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I7_TEXT));
            } else {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I9_TEXT));
            }
          } else if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(processorBadge) &&
              (QrConstants.ARC_GRAPHICS.indexOf(SecGraphicsBadge) > -1 || this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A')) {
              const newGraphicsValue = "tr_"+ SecGraphicsBadge; 
              let graphicsDescription = this.libraryV2DataService.getStaticText('tr_arcGraphicsBadgeToModelNameMapping')?.[newGraphicsValue];
              if (QrConstants.ARC_GRAPHICS.indexOf(SecGraphicsBadge) === -1 && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A'){
                 graphicsDescription = this.qrCodeService?.qrInput?.SecondaryGraphicsCard;
              }
              if (QrConstants[processorBadge] === QrConstants.INT109) {
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I5_TEXT));
              } else if (QrConstants[processorBadge] === QrConstants.INT110){
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I7_TEXT));
              } else {
                titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                  GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I9_TEXT));
              }
              // IIT2-2871 end
          } else if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(processorBadge) &&
            (QrConstants.ARC_GRAPHICS.indexOf(SecGraphicsBadge) > -1  || this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A')) {
            const newGraphicsValue = "tr_"+ SecGraphicsBadge;
            let graphicsDescription = this.libraryV2DataService.getStaticText('tr_arcGraphicsBadgeToModelNameMapping')?.[newGraphicsValue];
            if (QrConstants.ARC_GRAPHICS.indexOf(SecGraphicsBadge) === -1 && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge[0] === 'A'){
              graphicsDescription = this.qrCodeService?.qrInput?.SecondaryGraphicsCard;
           }
            if (QrConstants[processorBadge] === QrConstants.INT122) {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I5_TEXT));
            } else if (QrConstants[processorBadge] === QrConstants.INT123){
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I7_TEXT));
            } else {
              titleArray[0].preTitle = titleArray[0]?.preTitle?.replace(GlobalConstants.GRAPHICS_VALUE, graphicsDescription.replace(
                GlobalConstants.CPU_BADGE_VALUE, GlobalConstants.I9_TEXT));
            }
          }
          else {
            titleArray = titleArray.filter(element => element.detailPageName !== GlobalConstants.GRAPHICS_NAME);
          }
          if (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(processorBadge) && this.qrCodeService?.qrInput?.Connectivity === GlobalConstants.THUNDERBOLD_3) {
            titleArray = titleArray.filter(element => element.detailPageName !== GlobalConstants.CONNECTIVITY_NAME);
          }
          if (titleArray.length > 1 && (GlobalConstants.EVO_12GEN_PROCESSOR_BADGES.includes(processorBadge) || GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(processorBadge)) &&
          this.qrCodeService?.qrInput?.ContentType === GlobalConstants.EVO &&
            (GlobalConstants.IRIS_GRAPHICS_BADGES.includes(graphicsBadge) && GlobalConstants.THIRD_PARTY_GRAPHICS_BADGES.includes(SecGraphicsBadge))) {
            titleArray = titleArray.filter(element => element.detailPageName !== GlobalConstants.GRAPHICS_NAME);
          }


          // If all facttags are supressed then only processor will be shown
          if (titleArray.length > 1) {
            titleArray = titleArray.filter(element => element.detailPageName !== GlobalConstants.PROCESSOR_NAME);
          }
        } else if (pageName === GlobalConstants.CONNECTMORE_NAME) {
          if (Number(this.qrCodeService.qrInput?.WifiGeneration) > 5 && this.qrCodeService.qrInput?.WifiDisplay !== '') { // IIT2-2889
            titleArray = titleArray.filter(element => element.detailPageName === GlobalConstants.WIFI_NAME);
          } else {
            titleArray = titleArray.filter(element => element.detailPageName === GlobalConstants.PROCESSOR_NAME);
          }
        }
        // IIT2-2889
        if (titleArray.length) {
          const panel2 = {
            panelType: tilesData?.tileDetails?.panelType2,
            titleArray: titleArray as object,
          };
          this.panel.push(panel2);
        }
      }
    } else if (this.isEvoUWP) { // IIT2-4639  start
      const panel1 = {
        panelType: tilesData.tileDetails?.panelType1,
        title: tilesData.tileDetails?.tr_title,
        subTitle: tilesData.tileDetails?.tr_subtitle,
        badgeUrl: tilesData.tileBadgeUrl,
      };
      this.panel.push(panel1);

      if (pageName === GlobalConstants.EVOCONTENT_NAME) {
        const panel2 = {
          panelType: tilesData.tileDetails?.panelType2,
          title: tilesData.tileDetails?.titleArray[0]?.tr_title,
          subTitle: tilesData.tileDetails?.titleArray[0]?.tr_subtitle,
          titleArray: tilesData.tileDetails?.titleArray1,
        };
        this.panel.push(panel2);
      }
    } // IIT2-4639 end
    return this.panel;
  }

  setSecondPanelData(secondPanelData) {
    const qrData = this.qrCodeService.qrInput;
    secondPanelData.forEach(element => {
      if (element.detailPageName === GlobalConstants.PROCESSOR_NAME) {
        const panelData = this.processorService.processorData();
        const processorDescription = panelData[0]?.tileTitle;  // processor uppercase title
        element.preTitle = element.preTitle.replace(GlobalConstants.PROCESSOR_VALUE, processorDescription);
      } else if (element.detailPageName === GlobalConstants.STORAGE_NAME) {
        const storageDescription = this.qrCodeService.rethinkStorageDescription();
        element.preTitle = element.preTitle.replace(GlobalConstants.STORAGE_VALUE, storageDescription);
      } else if (element.detailPageName === GlobalConstants.DISPLAY_NAME) {
        element.preTitle = this.qrCodeService.getDisplayDescription(GlobalConstants.TILE_SERVICE);
      } else if (element.detailPageName === GlobalConstants.MEMORY_NAME) {
        element.preTitle = element.preTitle.replace(GlobalConstants.MEMORY_VALUE, this.qrCodeService.setMemoryDescription());
      } else if (element.detailPageName === GlobalConstants.WIFI_NAME) {
        let title;
        if (this.qrCodeService.qrInput.WifiFamily.includes(GlobalConstants.VALUE_6E) && (this.qrCodeService.qrInput.WifiManufacture === GlobalConstants.INTEL_CAPS || this.qrCodeService.qrInput.WifiManufacture === GlobalConstants.KILLER)) {
          title = element.tr_title2;
        } else if ((Number(this.qrCodeService.qrInput?.WifiGeneration) === 6) && (this.qrCodeService.qrInput.WifiManufacture === GlobalConstants.INTEL_CAPS || this.qrCodeService.qrInput.WifiManufacture === GlobalConstants.KILLER)) {
          title = element.tr_title;
        } else {
          title = element.tr_title1;
        }
        element.tr_title = title;
        // IJR-2869 start
        if (qrData.WifiManufacture === GlobalConstants.INTEL_CAPS) {
          if (Number(qrData.WifiGeneration) === 4 || Number(qrData.WifiGeneration) === 5) {
            element.preTitle = element.preTitle.replace(GlobalConstants.WIFI_VALUE, this.libraryV2DataService.getStaticText('tr_wifiXXCaps').replace(GlobalConstants.XX, qrData.WifiGeneration));
          } else {
            element.preTitle = element.preTitle.replace(GlobalConstants.WIFI_VALUE, qrData.WifiDisplay);  // IJR-3145
          }
        } else if (qrData.WifiManufacture === GlobalConstants.KILLER) {
          if (Number(qrData.WifiGeneration) === 4) {
            element.preTitle = element.preTitle.replace(GlobalConstants.WIFI_VALUE, this.libraryV2DataService.getStaticText('tr_wifiXXCaps').replace(GlobalConstants.XX, qrData.WifiGeneration));
          } else {
            element.preTitle = element.preTitle.replace(GlobalConstants.WIFI_VALUE, qrData.WifiDisplay);  // IJR-3145
          }
        } else {
          element.preTitle = qrData.WifiGenerationVarient === GlobalConstants.VALUE_6E ?
            element.preTitle.replace(GlobalConstants.WIFI_VALUE, this.libraryV2DataService.getStaticText('tr_wifiXXCaps').replace(GlobalConstants.XX, qrData.WifiGenerationVarient)) :
            element.preTitle.replace(GlobalConstants.WIFI_VALUE, this.libraryV2DataService.getStaticText('tr_wifiXXCaps').replace(GlobalConstants.XX, qrData.WifiGeneration));
        }
        // IJR-2869 end
      } else if (element.detailPageName === GlobalConstants.BATTERY_NAME) {
        const batteryValue = this.libraryV2DataService.getStaticText(GlobalConstants.BATTERY_VALUE_UPPERCASE).replace(GlobalConstants.XX, qrData.Battery);
        element.preTitle = element.preTitle.replace(GlobalConstants.BATTERY_VALUE, batteryValue);
      } else if (element.detailPageName === GlobalConstants.GRAPHICS_NAME && !this.isEvo) {
        element.preTitle = this.qrCodeService.qrInput.SecondaryGraphicsCard ? element.preTitle.replace(GlobalConstants.GRAPHICS_VALUE, qrData.SecondaryGraphicsCard) :
          element.preTitle.replace(GlobalConstants.GRAPHICS_VALUE, qrData.GraphicsDescription); // IJR-3031
        // IIT2- 2354 start
        if (this.isVrReady && (QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge) > -1 || this.qrCodeService?.qrInput?.GraphicsBadge[0] === 'A') && 
            ([GlobalConstants.CREATORGEN11,  GlobalConstants.CREATORGEN12,  GlobalConstants.CREATORGEN13].indexOf(this.qrCodeService?.qrInput?.ContentType) > -1 || GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge) )) {
              element.tr_title = element?.tr_title1;
          }
          // IIT2 - 2354 end
      } else if (element.detailPageName === GlobalConstants.CONNECTIVITY_NAME) {
        const connectivityDescription = (this.qrCodeService.qrInput.Connectivity === GlobalConstants.THUNDERBOLD_4) ?
          this.libraryV2DataService.getStaticText('tr_Thunderbolt4UpperCase') : this.libraryV2DataService.getStaticText('tr_Thunderbolt3UpperCase');
        element.preTitle = element.preTitle.replace(GlobalConstants.CONNECTIVITY_VALUE, connectivityDescription);
      }
    });
    return secondPanelData;
  }
  // checking for fact tag values if suppressed then suppressing from panel
  checkTiles(secondPanelData) {
    if (!this.qrCodeService?.qrInput?.TotalMemory) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.MEMORY_NAME);
    }
    if (!this.qrCodeService.qrInput.ResolutionWidth) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.DISPLAY_NAME);
    }
    if (this.qrCodeService?.qrInput?.IsSSDAvailable !== GlobalConstants.CHECK_Y && this.qrCodeService?.qrInput?.IsHDDAvailable !== GlobalConstants.CHECK_Y) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.STORAGE_NAME);
    }
    if (!this.qrCodeService.qrInput?.WifiDisplay) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.WIFI_NAME);
    }
    if (!this.qrCodeService.qrInput.Battery) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.BATTERY_NAME);
    }
    if (((this.qrCodeService.qrInput.SecondaryGraphicsBadge === '' && !this.qrCodeService.isSingleGraphics) || (this.qrCodeService.isSingleGraphics && this.qrCodeService.qrInput.GraphicsBadge === '')) && (QrConstants.ARC_GRAPHICS.indexOf(this.qrCodeService?.qrInput?.GraphicsBadge)==-1 && this.qrCodeService?.qrInput?.GraphicsBadge[0] !== 'A')) { // IJR-3031, IIT2-2541
        secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.GRAPHICS_NAME);
    }
    if (!this.qrCodeService.qrInput.Connectivity) {
      secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.CONNECTIVITY_NAME);
    }
    // For 13th gen EVO no need to show graphics content in create more page if discrete graphics is detected
    if (GlobalConstants.EVO_13GEN_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge) && this.isEvo &&
        GlobalConstants.THIRD_PARTY_GRAPHICS_BADGES.includes(this.qrCodeService?.qrInput?.SecondaryGraphicsBadge)) {  
          secondPanelData = secondPanelData.filter(tile => tile.detailPageName !== GlobalConstants.GRAPHICS_NAME);
    }

    return secondPanelData;
  }

  checkVproContent() {
    this.tiles.forEach((element, index) => {
      if(element?.tileUrlName === GlobalConstants.ENTERTAINMENT_NAME) { // IIT2-5825
        this.tiles.splice(index, 1);
      }
    });
  }

  getRebuildUsageTiles () {
    let tilesType: string, 
        panelData, 
        isProcessorTileAvailable: boolean = false;
        this.tiles = [];
        const processorBadge: string = QrConstants[this.qrCodeService?.qrInput?.ProcessorBadge];   
      
      if ((GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) || processorBadge.toLocaleLowerCase().includes(GlobalConstants.I3_TEXT_CAPS.toLocaleLowerCase())) {
      tilesType = GlobalConstants.STANDARD_REBUILD_I3;
      isProcessorTileAvailable = true;
      } else if (processorBadge.toLocaleLowerCase().includes(GlobalConstants.I5_TEXT_CAPS.toLocaleLowerCase())) {
      tilesType = GlobalConstants.STANDARD_REBUILD_I5;
      } else if (processorBadge.toLocaleLowerCase().includes(GlobalConstants.I7_TEXT_CAPS.toLocaleLowerCase())) {
      tilesType = GlobalConstants.STANDARD_REBUILD_I7;
      } else {
      tilesType = GlobalConstants.STANDARD_REBUILD_I9;
      isProcessorTileAvailable = true;
      }
      this.tiles = this.libraryV2DataService.getRebuildTilesData(tilesType, GlobalConstants.DEFAULT_TYPE);
      if((GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrCodeService?.qrInput?.ProcessorBadge)) && this.isVrReady && this.tiles.length<5){
        const processorData = this.libraryV2DataService.getFactTagsDetails(GlobalConstants.INTEL_INSIDE, GlobalConstants.PROCESSOR_NAME,GlobalConstants.STANDARD,GlobalConstants.DEFAULT_TYPE);      
        this.tiles.push(this.libraryV2DataService.getVrDetails("CREATOR_REBUILD_I5", GlobalConstants.LAPTOP_NAME));
        this.tiles[4].tileDetails.titleArray[0].preTitle = this.qrCodeService?.qrInput?.ProcessorBadge === GlobalConstants.INT137 ? processorData?.tr_title : this.qrCodeService?.qrInput?.ProcessorDescription;
      }   
    if (isProcessorTileAvailable) {
      panelData = this.processorService.processorData();
      this.tiles[0].tr_tileTitle = this.tiles[0]?.tr_tileTitle.replace(GlobalConstants.PROCESSOR_VALUE, panelData[0]?.preTitle);
      this.tiles[0].tileBadgeUrl = this.tiles[0]?.tileBadgeUrl.replace(GlobalConstants.PROCESSOR_BADGE_LABEL, panelData[0]?.processorTileBadgeUrl);
    }
  }

  getRebuildUsageTilesDetails (pageName: string): any {
    this.panel = [];
    const tilesData = this.getTiles().find(tile => tile.tileUrlName === pageName);

    const panel = {
      panelType: tilesData?.tileDetails?.panelType1,
      title: tilesData?.tileDetails?.tr_title,
      subTitle: tilesData?.tileDetails?.tr_subtitle,
      subTitle1: tilesData?.tileDetails?.tr_subtitle1,
      badgeUrl: tilesData?.tileBadgeUrl,
      backgroundColor: tilesData?.tileDetails?.tileBG,
      titleArray: tilesData.tileDetails?.titleArray,
      badgeURL1: tilesData?.tileBadgeUrl1
    };
    this.panel.push(panel);
    return this.panel;
  }

  getUnisonData() {
    const unisonDetails = this.libraryV2DataService.getUnisonTilesData();
    this.panel = [];
    const panel1 = {
      panelType:unisonDetails.panelType1,
      title: unisonDetails?.tr_title,
      subTitle: unisonDetails?.tr_subtitle,
      badgeUrl: unisonDetails?.detailBGUrl,
    };
    this.panel.push(panel1);

    const panel2 = {
      panelType: unisonDetails?.panelType2,
      titleArray: unisonDetails?.titleArray as object,
    };
    this.panel.push(panel2);
    return this.panel;
  }

  setUnisonData() {
    const checkUnisonIndex = this.tiles.findIndex(tile => tile.tileUrlName === GlobalConstants.UNISON_TEXT);
    if(this.qrCodeService?.qrInput?.UnisonContent === GlobalConstants.CHECK_Y && checkUnisonIndex === -1) {
      const unisonData = this.libraryV2DataService.getUnisonTilesData();
      this.tiles.unshift({
        tileBadgeUrl : unisonData.tileBG,
        tileUrlName : GlobalConstants.UNISON_TEXT,
        tr_tileTitle : unisonData.tr_tileTitle,
        tileDetails: {
          panelType1: unisonData.panelType1,
          panelType2: unisonData.panelType2,
          tr_tileTitle: unisonData.tr_tileTitle,
          detailBGUrl: unisonData.detailBGUrl,
          titleArray: [
            {
              tr_title: unisonData.tr_tileTitle,
              detailPageName: GlobalConstants.UNISON_TEXT,
              tileBadgeUrl: unisonData.tileBG
            }
          ]
        }
      });
    }
  }

}
