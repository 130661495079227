import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'app-unconnected-processor',
  templateUrl: './unconnected-processor.component.html',
  styleUrls: ['./unconnected-processor.component.scss']
})
export class UnconnectedProcessorComponent implements OnInit {
  processor: string;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.processor = GlobalConstants.PROCESSOR_TITLE;
  }

}
