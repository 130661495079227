import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { DetailsService } from '../services/details.service';
import { OperatingSystemService } from '../services/operating-system/operating-system.service';
import { ProcessorService } from '../services/processor/processor.service';
import { ConnectivityService } from '../services/connectivity/connectivity.service';
import { BatteryService } from '../services/battery/battery.service';
import { DisplayService } from '../services/display/display.service';
import { GraphicsService } from '../services/graphics/graphics.service';
import { StorageService} from '../services/storage/storage.service';
import { WifiService } from '../services/wifi/wifi.service';
import { MemoryService } from '../services/memory/memory.service';
import { LibraryChrombookV2DataService } from '../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';
import { AlwaysonsecurityService } from '../services/alwaysonsecurity/alwaysonsecurity.service';
import { EndlessprogramsandappsService } from '../services/endlessprogramsandapps/endlessprogramsandapps.service';
import { ExtendedbatterylifeService } from '../services/extendedbatterylife/extendedbatterylife.service';
import { RethinkstorageService } from '../services/rethink-storage/rethinkstorage.service';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { TilesService } from '../services/tiles/tiles.service';
import { DasService } from '../services/das/das.service';
import { ResetBotService } from '../../../chatbot/services/resetBot.service';
import { OledService } from '../services/oled/oled.service';

@Component({
  selector: 'app-details-panel',
  templateUrl: './details-panel.component.html',
  styleUrls: ['./details-panel.component.scss']
})
export class DetailsPanelComponent implements OnInit {
  panelData = [];
  detailsPageName: string;
  isChromeBook = false;
  isNewPenel =  false; // IJR-1734 12th genGaming
  constructor(
    private router: ActivatedRoute,
    private processorService: ProcessorService,
    private batteryService: BatteryService,
    private detailsServices: DetailsService,
    private libraryService: LibraryV2DataService,
    private connectivityService: ConnectivityService,
    private osService: OperatingSystemService,
    private displayService: DisplayService,
    private graphicsService: GraphicsService,
    private storageService: StorageService,
    private wifiService: WifiService,
    private memoryService: MemoryService,
    private chromeBookLibraryService: LibraryChrombookV2DataService,
    private alwaysonsecurityService: AlwaysonsecurityService,
    private endlessprogramsandappsService: EndlessprogramsandappsService,
    private extendedbatterylifeService: ExtendedbatterylifeService,
    private rethinkstorageService: RethinkstorageService,
    private qrCodeService: QrCodeService,
    private tilesService: TilesService,
    private dasService: DasService,
    private resetBot: ResetBotService,
    private oledService: OledService
  ) {}

  ngOnInit(): void {
    // for testing
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // for testing
    if (this.qrCodeService.isChromeBook) {
      this.isChromeBook = true;
    }
    this.detailsPageName = this.router.snapshot.params.facttagname;
    this.setPanelData(this.detailsPageName);
    if (this.qrCodeService.isChromeBook) {
      this.isChromeBook = true;
    }
    // IJR-1734 12th genGaming start
    const contentType = this.qrCodeService.qrInput.ContentType;
    if (contentType === GlobalConstants.GAMINGGEN12 || contentType === GlobalConstants.CREATORGEN11 ||
      contentType === GlobalConstants.GAMINGGEN11 || contentType === GlobalConstants.EVO || contentType === GlobalConstants.CREATORGEN12 || contentType === GlobalConstants.CREATORGEN13||
      ((contentType === GlobalConstants.STANDARDGEN11 || contentType === GlobalConstants.STANDARDGEN12 ||
        contentType === GlobalConstants.STANDARDGEN13 || contentType === GlobalConstants.GAMINGGEN13 || contentType === GlobalConstants.GAMINGGEN14 ||
        contentType === GlobalConstants.STANDARDGEN14) &&
        !GlobalConstants.legacyProcessorArray.includes(this.qrCodeService.qrInput.ProcessorBadge))
    ) {
      this.isNewPenel = true;
    }
    // IJR-1734 12th genGaming end   
  }

  setPanelData(pageName): any{
    this.isChromeBook ? this.qrCodeService.chromebackground() : this.qrCodeService.setBackgroundImage();
    let header;
    switch (pageName) {
    case GlobalConstants.PROCESSOR_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.OTHER_PROCESSORS, GlobalConstants.PROCESSOR_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        
      this.getProcessorDetails();
      break;
    case GlobalConstants.GRAPHICS_NAME:
    case GlobalConstants.GRAPHICS_CARD:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getGraphicDetails(pageName);
      break;
    case GlobalConstants.MEMORY_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.MEMORY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getMemoryDetails();
      break;
    case GlobalConstants.STORAGE_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getStorageData();
      break;
    case GlobalConstants.DISPLAY_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.TOUCH_SCREEN, GlobalConstants.DISPLAY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getDisplayData();
      break;
    case GlobalConstants.BATTERY_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.BATTERY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getBatterDetails();
      break;
    case GlobalConstants.WIFI_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getWifiData();
      break;
    case GlobalConstants.CONNECTIVITY_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_4, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getConnectivityData();
      break;
    case GlobalConstants.OPERATING_SYSTEM_NAME:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.WINDOWS, GlobalConstants.OPERATING_SYSTEM_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getosData();
      break;
    case GlobalConstants.OPTANE_MEMORY:
      header = this.libraryService.getFactTagsDetails(GlobalConstants.INTEL_OPTANE_MEMORY, GlobalConstants.MEMORY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.getoptanememoryData();
      break;
    case GlobalConstants.ENDLESSPROGRAMSANDAPPS:
      header = this.chromeBookLibraryService.getFactTagsDetails(GlobalConstants.ENDLESSPROGRAMSANDAPPS).details;
      this.getEndlessProgramsAndApps();
      break;
    case GlobalConstants.RETHINKSTORAGE:
      header = this.chromeBookLibraryService.getFactTagsDetails(GlobalConstants.RETHINKSTORAGE).details;
      this.getRethinkStorage();
      break;
    case GlobalConstants.ALWAYSONSECURITY:
      header = this.chromeBookLibraryService.getFactTagsDetails(GlobalConstants.ALWAYSONSECURITY).details;
      this.getalwaysOnSecurity();
      break;
    case GlobalConstants.EXTENDEDBATTERYLIFE:
      header = this.chromeBookLibraryService.getFactTagsDetails(GlobalConstants.EXTENDEDBATTERYLIFE); // IIT2-2223
      this.getextendedBatteryLife();
      break;
    case GlobalConstants.SAMSUNGOLED:
      header = this.libraryService.getStaticText(GlobalConstants.tr_samsung_oled);
      this.getSamsungOled();
      break;
    case GlobalConstants.UNISON_TEXT:
      header = GlobalConstants.UNISON_TEXT;
      this.panelData = this.tilesService.getUnisonData();
      break;
    case GlobalConstants.AVENGERS_NAME:
    case GlobalConstants.ESL_NAME:
    case GlobalConstants.INFLUENCER_NAME:
    case GlobalConstants.VR_NAME:
    case GlobalConstants.ENTERTAINMENT_NAME:
    case GlobalConstants.PRODUCTIVITY_NAME:
    case GlobalConstants.CONTENT_NAME:
    case GlobalConstants.LOOKINSIDE_NAME:
    case GlobalConstants.EVOCONTENT_NAME:
    case GlobalConstants.CREATEMORE_NAME:
    case GlobalConstants.CONNECTMORE_NAME:
    case GlobalConstants.ACHIEVEMORE_NAME:
    case GlobalConstants.XBOX:
      header = this.tilesService.getTiles().find(tile => tile.tileUrlName === pageName);
      this.getTilesDetails(pageName);
      break;

    case GlobalConstants.DAS_NAME:
      this.getDasDetails();
      break;
    case GlobalConstants.STREAMING:
    case GlobalConstants.WEB_BROWSING:
    case GlobalConstants.VIDEO_CHATTING:
    case GlobalConstants.HIGHSPEED:
    case GlobalConstants.CASUAL_GAMING:
    case GlobalConstants.PHOTO_EDITING:
    case GlobalConstants.REALTIME:
    case GlobalConstants.VIDEO_EDITING:
    case GlobalConstants.THREE_D_MODELING:
      header = this.tilesService.getTiles().find(tile => tile.tileUrlName === pageName);
      this.getRebuildTilesDetails(pageName);
      break;  
    default:
      break;
    }
    if (pageName === GlobalConstants.DAS_NAME){
      this.detailsServices.setHeaderText(GlobalConstants.DAS_HEADER_VALUE);
    } else if (pageName === GlobalConstants.SAMSUNGOLED) {
      this.detailsServices.setHeaderText(header);
    } else if (pageName === GlobalConstants.UNISON_TEXT){
      const unisonDetails = this.libraryService.getUnisonTilesData();
      this.detailsServices.setHeaderText(unisonDetails?.tr_tileTitle);
    } else if(pageName === GlobalConstants.GRAPHICS_NAME && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge !== ''){
      this.detailsServices.setHeaderText(this.libraryService.getStaticText('tr_processorGraphicsSmall'));
    } else if(pageName === GlobalConstants.GRAPHICS_CARD || (pageName === GlobalConstants.GRAPHICS_NAME && this.qrCodeService?.qrInput?.SecondaryGraphicsBadge === '')){
      this.detailsServices.setHeaderText(header.tr_tileTitle);
    } else {
      // IJR-1761
      const title = (header.tileUrlName === GlobalConstants.PRODUCTIVITY_NAME) &&
        this.qrCodeService.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP ?
        header.tr_tileTitle1 : header.tr_tileTitle;
      this.detailsServices.setHeaderText(title);
    }
  }

  // TODO For Testing added call to dummy data.
  getProcessorDetails(): any{
    if (this.isChromeBook) {
      this.panelData = this.processorService.chromebookProcessorData();
    } else {
      
        this.panelData = this.processorService.processorData();
    }    
  }

  getBatterDetails(): any{
    this.panelData = this.batteryService.displayBatteryDetailPage();
  }

  getWifiData(): any{
   this.panelData = this.wifiService.displayWifiDetailPage();
  }

  getosData(): any{
    this.panelData = this.osService.operatingSystemData();
  }

  getStorageData(): any{
    this.panelData = this.storageService.displayStorageDetailPage();
  }

  getDisplayData(): any {
    this.panelData = this.displayService.displayData();
  }

  getConnectivityData(): any{
    this.panelData = this.connectivityService.connectivityData();
  }

  getMemoryDetails(): any{
    this.panelData = this.memoryService.displayMemoryDetailPage();
  }

  getGraphicDetails(pageTitles): any{
    this.panelData = this.graphicsService.displayGraphicsDetailPage(pageTitles);    
  }

  getoptanememoryData(): any{
    this.panelData = this.memoryService.displayOptaneMemoryDetailPage();
  }

  getEndlessProgramsAndApps(): any {
    this.panelData = this.endlessprogramsandappsService.displayEndlessProgramsAndApps();
  }

  getRethinkStorage(): any {
    this.panelData = this.rethinkstorageService.displayRethinkStorage();
  }

  getalwaysOnSecurity(): any {
    this.panelData = this.alwaysonsecurityService.displayAlwaysonsecurity();
  }
  getextendedBatteryLife(): any {
    this.panelData = this.extendedbatterylifeService.displayExtendedBatterylife();
  }

  getTilesDetails(pageName): any {
    this.panelData = this.tilesService.getTilesDetails(pageName);
  }

  getDasDetails(): any {
    this.panelData = this.dasService.displayDasDetailPage();
  }

  getSamsungOled(): any {
    this.panelData = this.oledService.displaySamsungOled();
  }

  onScroll(event): void{
    this.resetBot.resetBotIcon(true);
  }

  getRebuildTilesDetails(pageName): any {
    this.panelData = this.tilesService.getRebuildUsageTilesDetails(pageName);
  }
 }
