import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-errors',
  templateUrl: './compare-errors.component.html',
  styleUrls: ['./compare-errors.component.css']
})
export class CompareErrorsComponent implements OnInit {

  @Input() errorMessage: string;

  constructor() { }

  ngOnInit(): void {
    // document.getElementById('myBtn').click();
    setTimeout(() => {
      document.getElementById('myBtn').click();
    }, 500);
  }

}
