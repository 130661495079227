import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { QrCodeService } from '../qr-input-service/qr-input.service';
import { environment } from '../../../../environments/environment';
import { HttpMethodsService } from 'src/app/common/utility/http-methods.service';
import { MessageService } from '../message-service/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { PortinoInfo } from 'src/app/core/interfaces/portino-info';
import { LibraryV2DataService } from '../library-v2/library-v2-data.service';



@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    deviceId: string;
    sessionId: string;    
    buyNowSiteLink: string = '';
    BUY_NOW_API_URL = environment?.portinoAPI;
    private ngUnsubscribe = new Subject<boolean>();
    constructor(
        private http: HttpClient,
        private qrInput: QrCodeService,
        private messageService: MessageService,
        private httpsService: HttpMethodsService,
        private libraryV2DataService: LibraryV2DataService,
    ) {
        this.deviceId = this.qrInput.getDeviceid();
        this.sessionId = this.qrInput.getSessionId();
    }

    sendPostRequest(msg: any): Observable<any> {
        let requestUrl: string = environment.websocketApi + 'action/sessionId/' + this.sessionId + '/deviceId/' + this.deviceId + '/' + msg;
        return this.http.post<any>(requestUrl, null);
    }

    getbuyNowAPIData() {
        let portinoResponse: PortinoInfo[];
        this.buyNowSiteLink = '';
        const apiId = this.qrInput.qrInput?.ApiId;
        const skuId = this.qrInput.qrInput?.SkuId;
        if (skuId && apiId) {
          const apiEndPoint = apiId === GlobalConstants.W ? GlobalConstants.WALMART
                            : apiId === GlobalConstants.S ? GlobalConstants.SAMCLUB 
                            : null;
          const apiEndPointUrl = apiEndPoint? this.BUY_NOW_API_URL + apiEndPoint : '';
          let buyNowData;
          if (apiEndPointUrl) {
            buyNowData = this.httpsService?.get(apiEndPointUrl)?.pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((data) => {
                portinoResponse = data;
                if (portinoResponse?.length) {
                  this.buyNowSiteLink = portinoResponse?.find((i) => i?.sku === skuId)?.link || '';
                  const id = this.messageService?.setbuyNowId(this.buyNowSiteLink);
                  const errMsg = this.messageService?.setbuyNowApiErr('');
                }
              }, (error) => {
                  let errorMsg: string;
                  errorMsg = this.libraryV2DataService.getStaticText(GlobalConstants.BUYNOWTEXTERR);
                  const err = this.messageService?.setbuyNowApiErr(errorMsg);               
              });
          }
        } 
      }


}
