<div id="mobileHeader" class=mobile_header>
  <div id="headerItem" class="header_item">
    <span class="flex"><img class="header_icon" alt="hp logo">
      <span class=mt-2>{{deviceName}}</span></span>
  </div>
  <div id="headerItem1" class="header_item_1">
    <div id="remoteText" class="align_items ">
      {{remoteText}}
      <span class="align_icon">
        <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14H21C24.866 14 28 10.866 28 7C28 3.13401 24.866 0 21 0H7ZM21 12C23.7614 12 26 9.76142 26 7C26 4.23858 23.7614 2 21 2C18.2386 2 16 4.23858 16 7C16 9.76142 18.2386 12 21 12Z"
            fill="white" />
        </svg>
      </span>
    </div>
    <div id="frameToggleIcon">
      <img class="frame_icon" alt="toggle icon">
    </div>
  </div>
</div>