<div id="iposMobile" class="ipos_mobile mt-4 mb-4 text_padding">
    <div id="remoteCard" class="row card_design">
        <div id="headerDataList" class="col-6" *ngFor="let header of headerData">
            <div id="headerIcon" class="card text_index" [ngClass]="(title===header.targetURL) ?'active':''"
                [style.border-bottom-color]="checkColor ? segmentColor : defaultColor"
                (click)="routeTo(header.targetURL)">
                <span class="text_align"><img src="{{header.iconURL}}" alt="header icon"></span>
                <div id="headerSpecTitle" class="text-center">{{header.specTitle}}</div>
            </div>
        </div>
    </div>
</div>

<div id="remoteContent" class="content connected">
    <div id="remoteContentWrapper" class="wrapper" [style.background-color]="checkColor ? segmentColor : defaultColor">
        <div *ngIf="!buttonClick && !viewPcSpec" id="ftags" class="fact_tags">
            <div id="feactTagMenu" *ngFor="let item of factTagMenu" class="card ftag" (click)="FactTag(item.title)">
                <div id="ftagImage" class="d-flex ftag_width">
                    <span class="mr-3 ml-1"
                        *ngIf="((item.name==='processor') || (item.name==='graphics') && item.image)">
                        <img src="{{item.image}}" class="processor_image" alt="Fact-Tag image"></span>
                    <span class="ftag_width">
                        <div id="ftagBody" class="ftag_body">
                            <div id="ftagHeading" class="ftag_heading">{{item.title}}</div>
                            <div id="ftagDetail1" class="ftag_detail_1" [ngClass]="item.name!=='processor' ? 'margin_top_text':''">
                                <div id="ftagSubHeading" class="ftag_subhead" [ngClass]="item.name ==='processor' ? 'margin_top_text_1':''">
                                    {{item.description}}
                                </div>
                                <span class="chevron_right_ftag"></span>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div id="viewSpecsCard" class="card ftag view_specs_card" (click)="viewPcSpecFn()">
                <div id="viewSpecsBody" class="ftag_body">
                    <div id="viewSpecsDetail" class="ftag_detail_1">
                        <span>
                            <div id="viewSpecsHeading" class="ftag_heading">View All Specs</div>
                        </span>
                        <span class="chevron_right_ftag"></span>
                    </div>
                </div>
                <div id="staticRemoteText" class=view_popup>
                    {{staticRemoteText}}
                    <div id="remoteTextAlignment" class="text_align">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.781 6.21888C17.8508 6.28854 17.9062 6.37131 17.944 6.46243C17.9818 6.55354 18.0013 6.65122 18.0013 6.74988C18.0013 6.84853 17.9818 6.94621 17.944 7.03733C17.9062 7.12844 17.8508 7.21121 17.781 7.28088L7.28097 17.7809C7.14014 17.9217 6.94913 18.0008 6.74997 18.0008C6.55081 18.0008 6.3598 17.9217 6.21897 17.7809C6.07814 17.64 5.99902 17.449 5.99902 17.2499C5.99902 17.0507 6.07814 16.8597 6.21897 16.7189L16.719 6.21888C16.7886 6.14903 16.8714 6.09362 16.9625 6.05581C17.0536 6.018 17.1513 5.99854 17.25 5.99854C17.3486 5.99854 17.4463 6.018 17.5374 6.05581C17.6285 6.09362 17.7113 6.14903 17.781 6.21888Z"
                                fill="#E9E9E9" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.21912 6.21888C6.14928 6.28854 6.09386 6.37131 6.05605 6.46243C6.01824 6.55354 5.99878 6.65122 5.99878 6.74988C5.99878 6.84853 6.01824 6.94621 6.05605 7.03733C6.09386 7.12844 6.14928 7.21121 6.21912 7.28088L16.7191 17.7809C16.8599 17.9217 17.051 18.0008 17.2501 18.0008C17.4493 18.0008 17.6403 17.9217 17.7811 17.7809C17.9219 17.64 18.0011 17.449 18.0011 17.2499C18.0011 17.0507 17.9219 16.8597 17.7811 16.7189L7.28112 6.21888C7.21145 6.14903 7.12869 6.09362 7.03757 6.05581C6.94645 6.018 6.84877 5.99854 6.75012 5.99854C6.65147 5.99854 6.55379 6.018 6.46267 6.05581C6.37155 6.09362 6.28879 6.14903 6.21912 6.21888Z"
                                fill="#E9E9E9" />
                        </svg>
                    </div>
                </div>
            </div>

        </div>
        <div id="btnSection" *ngIf="buttonClick">
            <div id="exitBtn" class="d-flex justify-content-between align-items-center">
                <span class="ml-3 title_font">{{title}}</span>
                <span (click)="Back()"><img src='../../../assets/images/close_icon.svg' alt="Exit Page" style="padding: 2px;"></span>
            </div>
            <div id="staticBtn" class="font_type" [innerHtml]="staticButtonText" [ngClass]="highlightStatus_Up || highlightStatus_Down?'blurred':''">
            </div>
            <div id="lookAtDeviceBtn" class=look_at_device [ngClass]="highlightStatus_Up || highlightStatus_Down?'visible_on':'visible_off'">Look at the PC</div>
            <div id="ClickUpBtn" class="btn_text">Click up</div>
            <div id="remoteMiddleContent" class="content_middle_remote">
                <span class="dot" [ngClass]="highlightStatusClick_Up?'highlighted':''" (click)="ScrollUp()">
                    <div id="scrollUpBtn" class="scrollup_icon"></div>
                </span>
                <span class="dot" [ngClass]="highlightStatusClick_Down?'highlighted':''" style="margin-bottom: 0px;" (click)="ScrollDown()">
                    <div id="scrollDownBtn" class="scrolldown_icon"></div>
                </span>
            </div>
            <div id="ClickDownBtn" class="mb-4 btn_text">Click down</div>
        </div>
        <div id="ViewPcSpec" *ngIf="viewPcSpec">
            <div id="backBtn" class="d-flex" (click)="backFromViewPcSpec()">
                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.031 19.531C10.9613 19.6008 10.8786 19.6563 10.7875 19.6941C10.6963 19.7319 10.5987 19.7513 10.5 19.7513C10.4014 19.7513 10.3037 19.7319 10.2126 19.6941C10.1214 19.6563 10.0387 19.6008 9.969 19.531L0.969003 10.531C0.899157 10.4613 0.843743 10.3786 0.805934 10.2875C0.768124 10.1963 0.748662 10.0987 0.748662 10C0.748662 9.90135 0.768124 9.80367 0.805934 9.71255C0.843743 9.62143 0.899157 9.53867 0.969003 9.469L9.969 0.469002C10.1098 0.328173 10.3008 0.249056 10.5 0.249056C10.6992 0.249056 10.8902 0.328173 11.031 0.469002C11.1718 0.609833 11.251 0.800838 11.251 1C11.251 1.19917 11.1718 1.39017 11.031 1.531L2.5605 10L11.031 18.469C11.1008 18.5387 11.1563 18.6214 11.1941 18.7126C11.2319 18.8037 11.2513 18.9014 11.2513 19C11.2513 19.0987 11.2319 19.1963 11.1941 19.2875C11.1563 19.3786 11.1008 19.4613 11.031 19.531Z"
                        fill="white" />
                </svg>
                <span class="ml-3 title_font">Back</span>
            </div>
            <div id="middleContent" class="content_middle_remote transform">
                <span class="dot" (click)="scrollLeft()"><img class="scrollup_icon" alt="scroll up icon"></span>
                <span class="dot dot_margin" (click)="scrollRight()">
                    <img class="scrolldown_icon" alt="scroll down icon"></span>
            </div>
            <div id="viewPcSpecStaticBtn" class=font_type [innerHtml]="staticButtonText">
                
            </div>
        </div>
    </div>
</div>