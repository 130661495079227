import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIConstants } from './constants/api.constant';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class HttpMethodsService {

  constructor(private http: HttpClient) { }

  post(requestUrl: string, postObject: any): Observable<any> {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    return this.http.post(requestUrl, postObject, { headers });
  }
  put(requestUrl: string, putObject: any): Observable<any> {
    const headers = new HttpHeaders()
      .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    return this.http.put(requestUrl, putObject, { headers });
  }
  get(requestUrl: string): Observable<any> {
    return this.http.get(requestUrl);
  }
  validateConnection(sessionId: string, deviceId: string): Observable<any> {
    let requestUrl: string = environment.websocketApi +'session/sessionId/'+ sessionId + '/deviceId/'+ deviceId;
    return this.http.get(requestUrl);
  }
  resetConnection(sessionId: string, deviceId: string): Observable<any> {
    //const headers = new HttpHeaders()
    //  .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
    //// navigator.sendBeacon(environment.resetApi, postObject);    
    let requestUrl: string = environment.websocketApi +'session/sessionId/'+ sessionId + '/deviceId/'+ deviceId;
    return this.http.patch<any>(requestUrl, null);
  }
  public NotifyToDevice(sessionId: string, deviceId: string, msg: string): Observable<any>  {
    //const headers = new HttpHeaders()
    //  .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);    
    let requestUrl: string = environment.websocketApi +'action/sessionId/'+ sessionId + '/deviceId/'+ deviceId +'/'+ msg;
    return this.http.post<any>(requestUrl, null);
  }

  public updateConnectionId(sessionId: string, deviceId: string) {
    let updateConnection: string = environment.websocketApi + 'device/device/devices/' + deviceId;
        const headers = new HttpHeaders()
           .set(APIConstants.HEADER_NM_CONTENT_TYP, APIConstants.HEADER_VAL_APPLICATION_JSON);
        const patchObject = {
           "sessionID": sessionId
        };
        this.http.patch(updateConnection, patchObject, { headers }).subscribe(
           (data: any) => {
              //  console.log("send--" + JSON.stringify(data));
           },
           (error: any) => {
              // console.log(JSON.stringify(error));
           });
  }
  
}
