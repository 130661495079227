import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { wifiModels } from '../../../../common/constants/qrCodeMapping';

@Injectable({
  providedIn: 'root'
})
export class WifiService {

  panel = [];
  isNonIntel: boolean;
  isAppGaming = false;
  wifiValue: number;
  wifiManufacturer: string;
  wifiGenerationVariant: string;
  wifiFamily: string;
  wifiDisplay: string;
  wifiText: string;
  wifiModel: string;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    this.isNonIntel = qrData.ContentType === GlobalConstants.NON_INTEL_TEXT;
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.wifiValue = +qrData.WifiGeneration;
    this.wifiManufacturer = qrData.WifiManufacture ? qrData.WifiManufacture : '';
    this.wifiGenerationVariant = qrData.WifiGenerationVarient ? qrData.WifiGenerationVarient : '';
    this.wifiFamily = qrData.WifiFamily ? qrData.WifiFamily : '';
    this.wifiDisplay = qrData.WifiDisplay ? qrData.WifiDisplay : '';
    this.wifiModel = qrData.WifiModel ?  wifiModels[qrData?.WifiModel] : '';
  }

  displayWifiDetailPage() {
    this.panel = [];
    this.setDefaultData();
    if (this.isNonIntel) {
      const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.NON_INTEL_NAME, GlobalConstants.WIFI_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        subTitle: panelData.tr_subtitle
      };
      this.panel.push(panel1);
    } else if (this.isAppGaming) {
      if (((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiManufacturer === "KILLER") && this.wifiFamily === "KILLER_WIFI_6") && this.wifiGenerationVariant !== '6E') {

        const panelData = this.libraryService.getFactTagsDetails('VERSION_6_KILLER', GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails('VERSION_6_KILLER', GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle,
          badgeUrl: panelData.badgeUrl
        };

        panelData2.badgeIcons[2].tr_badgeTitle = this.qrCodeService?.qrInput?.WifiDisplay; // IIT2-3304

        const panel2 = {
          panelType: panelData2.panelType2,
          badgeIcons: panelData2.badgeIcons
        };

        panel1.title = this.wifiDisplay;
        this.panel.push(panel1);
        this.panel.push(panel2);
      } else if ((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiGenerationVariant === GlobalConstants.VALUE_6E && (this.wifiManufacturer === GlobalConstants.INTEL_CAPS || this.wifiManufacturer === 'KILLER')) && (this.wifiFamily === GlobalConstants.INTEL_WIFI_6E || this.wifiFamily === GlobalConstants.KILLER_WIFI_6E)) {
        const wifiVersion = this.wifiManufacturer === GlobalConstants.INTEL_CAPS ? GlobalConstants.INTEL_6E : GlobalConstants.VERSION_6E_KILLER;
        const panelData = this.libraryService.getFactTagsDetails(wifiVersion, GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(wifiVersion, GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle,
          badgeUrl: panelData.badgeUrl
        };

        const panel2 = {
          panelType: panelData2.panelType2,
          badgeIcons: panelData2.badgeIcons
        };

        panel1.title = this.wifiDisplay;
        if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS || this.wifiFamily === GlobalConstants.INTEL_WIFI_6E) {
          panel1.badgeUrl = panel1.badgeUrl.replace(GlobalConstants.IMAGE_PATH, GlobalConstants.WIFI_6E_BADGE);
        } else if ((this.wifiManufacturer === "KILLER" || this.wifiFamily === GlobalConstants.KILLER_WIFI_6E) &&
          this.wifiModel.includes(GlobalConstants.WIFI_MODELS_1675)) {
          panel1.badgeUrl = panel1.badgeUrl.replace(GlobalConstants.IMAGE_PATH, GlobalConstants.KILLER_BADGE_AX1675).replace(GlobalConstants.TEXT_PNG, GlobalConstants.TEXT_SVG); // IJR-1831
        } else if ((this.wifiManufacturer === "KILLER" || this.wifiFamily === GlobalConstants.KILLER_WIFI_6E) &&
          (this.wifiModel.includes(GlobalConstants.WIFI_MODELS_1690))) {
          panel1.badgeUrl = panel1.badgeUrl.replace(GlobalConstants.IMAGE_PATH, GlobalConstants.KILLER_BADGE_AX1690).replace(GlobalConstants.TEXT_PNG, GlobalConstants.TEXT_SVG); // IJR-1831
        }
        panel2.badgeIcons[2].tr_badgeTitle = this.wifiDisplay;
        this.panel.push(panel1);
        this.panel.push(panel2);
      } else if (this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiGenerationVariant === GlobalConstants.VALUE_6E && (this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== 'KILLER')) {
        const panelData = this.libraryService.getFactTagsDetails('THIRDPARTY_6E', GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails('THIRDPARTY_6E', GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle
        };

        const panel2 = {
          panelType: panelData2.panelType2,
          badgeIcons: panelData2.badgeIcons
        };
        panel1.title = this.wifiDisplay;
        panel2.badgeIcons[2].tr_badgeTitle = this.wifiDisplay;
        this.panel.push(panel1);
        this.panel.push(panel2);
      }
      //IJR-723 START
      else if (((this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== GlobalConstants.KILLER)
         && (this.wifiValue === GlobalConstants.MINUS_ONE && this.wifiGenerationVariant === GlobalConstants.UNKNOWN)) || (this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiValue < Number(GlobalConstants.WIRELESS_4))) {

        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6_KILLER, GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle,

        };

        panel1.title = this.wifiDisplay;
        this.panel.push(panel1);
      }
      //IJR-723 END
      else if (((this.wifiManufacturer === 'KILLER') &&
        (this.wifiValue === GlobalConstants.WIFI_VALUE_4 || this.wifiValue === GlobalConstants.WIFI_VALUE_5) ||
        ((this.wifiManufacturer === GlobalConstants.INTEL_CAPS || this.wifiManufacturer !== 'KILLER') && (this.wifiValue >= 4 && this.wifiValue <= 5)) || (this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== 'KILLER' && this.wifiValue === 6) ||
        (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === 6 && this.wifiFamily === 'INTEL_WIFI_6')) || (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue < 4) && this.wifiGenerationVariant !== GlobalConstants.VALUE_6E) {
        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_5, GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_5, GlobalConstants.WIFI_NAME,
          GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle
        };
        const panel2 = {
          panelType: panelData2.panelType2,
          badgeIcons: panelData2.badgeIcons,
        };


        if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_1) {
          this.wifiText = this.libraryService.getStaticText('tr_wifi1')
        } else if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_2) {
          this.wifiText = this.libraryService.getStaticText('tr_wifi2');
        } else if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_3) {
          this.wifiText = this.libraryService.getStaticText('tr_wifi3');
        } else if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_4) {
          this.wifiText = this.libraryService.getStaticText(GlobalConstants.TR_WIFI4)
        } else if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_5) {
          this.wifiText = this.libraryService.getStaticText(GlobalConstants.TR_WIFI5);
        } else if ((this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue === GlobalConstants.WIFI_VALUE_6) && this.wifiFamily === 'INTEL_WIFI_6') {
          this.wifiText = this.wifiDisplay;   // IJR-3145
          panel2.badgeIcons[2].tr_badgeTitle = this.wifiDisplay;    // IJR-3145
        } else if (this.wifiManufacturer !== GlobalConstants.INTEL_CAPS) {
          this.wifiText = this.wifiDisplay;
        }
        panel1.title = panel1.title
          .replace(GlobalConstants.XX, this.wifiText);

        this.panel.push(panel1);
        this.panel.push(panel2);

        if (this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue < 4) { // IIT2-3233
          this.panel.splice(-1);
        }
      } else if (this.wifiValue === GlobalConstants.WIFI_VALUE_7 && ((this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiFamily === GlobalConstants.INTEL_WIFI_7) || (this.wifiManufacturer === GlobalConstants.KILLER && this.wifiFamily === GlobalConstants.KILLER_WIFI_7))) {
        this.gamingWifiSeventhGeneration();
      }
    } else {
      //IJR-671 START
      if (((this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== 'KILLER') && (this.wifiValue === -1 && this.wifiGenerationVariant === 'UNKNOWN')) || ((this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== 'KILLER' && this.wifiValue >= 1 && this.wifiValue <= 6 && this.wifiGenerationVariant !== '6E') || (this.wifiManufacturer === 'KILLER' && this.wifiValue >= 1 && this.wifiValue <= 4))) {
        const panelData = this.libraryService.getFactTagsDetails('KILLER_VERSION_5', GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          badgeIcons: panelData.badgeIcons,
          noteSection: panelData.tr_noteSection // IJR - 1455

        };
        const badgeData3 = [];
        badgeData3.push(panelData2.badgeIcons[0], panelData2.badgeIcons[1], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData3,
        };

        if (((this.wifiManufacturer !== GlobalConstants.INTEL_CAPS && this.wifiManufacturer !== 'KILLER' && this.wifiValue >= 1 && this.wifiValue <= 6) || (this.wifiManufacturer === 'KILLER' && this.wifiValue >= 1 && this.wifiValue <= 4))) {
          const libData = this.libraryService.getStaticText('tr_wifiXX');
          panel1.badgeIcons[0].tr_badgeTitle = libData;
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace('[XX]', this.wifiValue);
          panel1.subTitle = panel1.subTitle.replace('5', this.wifiValue);
        } else {
          panel1.badgeIcons[0].tr_badgeTitle = this.wifiDisplay;
          panel1.subTitle = panel1.subTitle.replace('5', '');
        }


        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[8]);
        // }, 100);
      }
      //IJR-671 END
      else if ((this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiValue >= 1 && this.wifiValue <= 5) && this.wifiGenerationVariant !== GlobalConstants.VALUE_6E) {
        // // UAT-240 start
        // if (this.wifiValue === GlobalConstants.WIFI_VALUE_6) {
        //   const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6, GlobalConstants.WIFI_NAME,
        //     GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        //   const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
        //     GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        //   const panel1 = {
        //     panelType: panelData.panelType1,
        //     title: panelData.tr_title,
        //     subTitle: panelData.tr_subtitle,
        //     preTitle: panelData.tr_pretitle,
        //     badgeUrl: panelData.badgeUrl,
        //     noteSection: panelData.tr_noteSection
        //   };
        //   const badgeData2 = [];
        //   badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[1], panelData2.badgeIcons[2], panelData2.badgeIcons[3]);
        //   const panel2 = {
        //     panelType: panelData2.panelType2,
        //     title: panelData2.tr_title,
        //     subTitle: panelData2.tr_subtitle,
        //     badgeIcons: badgeData2,
        //   };
        //   this.panel.push(panel1);
        //   this.panel.push(panel2);
        // }
        // // UAT-240 end
        // else{
        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_5, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          badgeIcons: panelData.badgeIcons,
          noteSection: panelData.tr_noteSection
        };
        const badgeData2 = [];
        badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[1], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };

        panel1.subTitle = panel1.subTitle
          .replace(GlobalConstants.VALUE_5, this.wifiValue);
        panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle
          .replace(GlobalConstants.VALUE_5, this.wifiValue);
        this.panel.push(panel1);
        // For wifi<4, no need of second panel
        if (this.wifiValue > 3) {
          this.panel.push(panel2);
        }
      }
      //IJR-725 START
      else if ((this.wifiValue === GlobalConstants.WIFI_VALUE_5 && this.wifiManufacturer === "KILLER")) {

        const panelData = this.libraryService.getFactTagsDetails('KILLER_VERSION_5', GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          badgeIcons: panelData.badgeIcons,
          noteSection: panelData.tr_noteSection
        };
        const badgeData3 = [];
        badgeData3.push(panelData2.badgeIcons[0], panelData2.badgeIcons[1], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData3,
        };        
        panel1.badgeIcons[0].tr_badgeTitle = this.wifiDisplay;
        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[13]);
        // }, 100);
      }
      //IJR-725 END

      //IJR-660 START
      else if (((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiManufacturer === "KILLER") && this.wifiFamily === "KILLER_WIFI_6") && this.wifiGenerationVariant !== '6E') {
        const panelData = this.libraryService.getFactTagsDetails('VERSION_6_KILLER', GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          badgeUrl: panelData.badgeUrl,

        };
        const badgeData3 = [];
        badgeData3.push(panelData2.badgeIcons[0], panelData2.badgeIcons[7], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData3,
        };
        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[13]);
        // }, 100);
      }
      //IJR-660 END
      else if (this.wifiValue === GlobalConstants.WIFI_VALUE_6 && (this.wifiManufacturer !== 'KILLER' && this.wifiManufacturer !== GlobalConstants.INTEL_CAPS) && this.wifiGenerationVariant === GlobalConstants.VALUE_6E) {
        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_5, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          noteSection: panelData.tr_noteSection,
          preTitle: panelData.tr_pretitle,
          badgeIcons: panelData.badgeIcons

        };

        const badgeData2 = [];
        badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[2], panelData2.badgeIcons[9]); // IJR-915
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };
        const libData = this.libraryService.getStaticText(GlobalConstants.TR_WIFI6E);
        panel1.subTitle = panel1.subTitle.replace(GlobalConstants.VALUE_5, libData);
        panel1.preTitle = this.wifiDisplay;
        panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle
          .replace(GlobalConstants.VALUE_5, libData)
        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[8]);
        // }, 100);

      } else if ((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiGenerationVariant === GlobalConstants.VALUE_6E) && this.wifiFamily === GlobalConstants.INTEL_WIFI_6E) {
        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6E, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          badgeUrl: panelData.badgeUrl,
          noteSection: panelData.tr_noteSection

        };
        const badgeData2 = [];
        badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[2], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };

        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[8]);
        // }, 100);
      } else if ((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiManufacturer === GlobalConstants.KILLER && this.wifiGenerationVariant === GlobalConstants.VALUE_6E) && this.wifiFamily === GlobalConstants.KILLER_WIFI_6E) {

        const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6E_KILLER, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          noteSection: panelData.tr_noteSection,
          badgeUrl: panelData.badgeUrl

        };
        // IJR-1831
        if (this.wifiModel.includes(GlobalConstants.WIFI_MODELS_1690)) {
          panel1.badgeUrl = GlobalConstants.KILLER6E_1690_BADGE;
        }
        const badgeData2 = [];
        badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[4], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };

        this.panel.push(panel1);
        this.panel.push(panel2);
        // setTimeout(() => {
        //   this.panel[1].badgeIcons.push(panelData2.badgeIcons[8]);
        // }, 100);
      } else if ((this.wifiValue === GlobalConstants.WIFI_VALUE_6 && this.wifiManufacturer === GlobalConstants.INTEL_CAPS) && this.wifiFamily === 'INTEL_WIFI_6' && this.wifiGenerationVariant !== '6E') {
        
        let panelData;
        if (this.wifiModel === GlobalConstants.INTEL_WIFI_6_AX101 && this.qrCodeService.qrInput?.ContentType !== GlobalConstants.EVO) {
        panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6, GlobalConstants.WIFI_NAME,
        GlobalConstants.STANDARD, GlobalConstants.MODEL_AX101);
        } else {
          panelData = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_6, GlobalConstants.WIFI_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        }
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle,
          badgeUrl: panelData.badgeUrl,
          noteSection: panelData.tr_noteSection
        };
        const badgeData2 = [];
        badgeData2.push(panelData2.badgeIcons[0], panelData2.badgeIcons[1], panelData2.badgeIcons[9]);
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };

        this.panel.push(panel1);
        this.panel.push(panel2);
      } else if (this.wifiValue === GlobalConstants.WIFI_VALUE_7 && ((this.wifiManufacturer === GlobalConstants.INTEL_CAPS && this.wifiFamily === GlobalConstants.INTEL_WIFI_7) || (this.wifiManufacturer === GlobalConstants.KILLER && this.wifiFamily === GlobalConstants.KILLER_WIFI_7))) {
        this.standardWifiSeventhGeneration();
      }
    }

    return this.panel;
  }

  standardWifiSeventhGeneration() {
    const newContentType = this.wifiManufacturer === GlobalConstants.INTEL_CAPS ? GlobalConstants.VERSION_7 :  this.wifiManufacturer === GlobalConstants.KILLER ? 'KILLER_VERSION_7' : '';
    let panelData;
        panelData = this.libraryService.getFactTagsDetails(newContentType, GlobalConstants.WIFI_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.WIFI_NAME,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel1 = {
          panelType: panelData.panelType1,
          title: panelData.tr_title,
          subTitle: panelData.tr_subtitle,
          preTitle: panelData.tr_pretitle,
          badgeUrl: panelData.badgeUrl,
          noteSection: panelData.tr_noteSection
        };
        const badgeData2 = [];
        if(this.wifiManufacturer === GlobalConstants.INTEL_CAPS) {
          badgeData2.push(panelData2.badgeIcons[13], panelData2.badgeIcons[12], panelData2.badgeIcons[9]);
        } else {
          badgeData2.push(panelData2.badgeIcons[13], panelData2.badgeIcons[12], panelData2.badgeIcons[10]);
        }
        
        const panel2 = {
          panelType: panelData2.panelType2,
          title: panelData2.tr_title,
          subTitle: panelData2.tr_subtitle,
          badgeIcons: badgeData2,
        };
        this.panel.push(panel1);
        this.panel.push(panel2);
  }

  gamingWifiSeventhGeneration() {
    const newContentType = this.wifiManufacturer === GlobalConstants.INTEL_CAPS ? GlobalConstants.VERSION_7 :  this.wifiManufacturer === GlobalConstants.KILLER ? 'KILLER_VERSION_7' : '';
    const panelData = this.libraryService.getFactTagsDetails(newContentType, GlobalConstants.WIFI_NAME,
    GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    const panel1 = {
      panelType: panelData.panelType1,
      title: panelData.tr_title,
      subTitle: panelData.tr_subtitle,
      preTitle: panelData.tr_pretitle,
      badgeUrl: this.wifiManufacturer === GlobalConstants.KILLER ? panelData?.badgeUrl : ''
    };

    const panel2 = {
      panelType: panelData.panelType2,
      badgeIcons: panelData.badgeIcons
    };
    panel1.title = this.wifiDisplay;
    panel2.badgeIcons[2].tr_badgeTitle = this.wifiDisplay;
    this.panel.push(panel1);
    this.panel.push(panel2);
  }
}
