<div id="nonChromebookProcDDp" *ngIf="!isChrombookProcessorDDP" [ngClass]="isAppGaming ? 'gaming': isNonIntel ? 'nonIntel':''" [class.os-image-list]="panelData.panelType === imageOnlyPanel && !isWindows11NewContent">

    <div id="creatorProcInfoDetail" class="info-detailed" [class.creator-info-detailed]="isCreator" *ngIf="panelData.panelType === panelTypeHeaderIconTop || panelData.panelType === panelTypeHeaderBadgeIconTop || panelData.panelType === 'paneltype-das'"> <!-- II-3558 -->
      <div id="panelHeaderIconTop" class="d-flex" *ngIf="panelData.panelType==='header-IconTop'">
        <img *ngIf='panelData.badgeUrl' src="{{panelData.badgeUrl}}" alt="Processor badge"/>
        <img class="graphics-badge-margin" *ngIf='panelData.graphicsBadgeUrl && isCreator' src="{{panelData.graphicsBadgeUrl}}" alt="Graphics badge"/>
      </div>
      <div pid="panelHeaderBadgeIconTop" class="feature-row"  [class.altered-secondbb]="storageukbadgeukAlign" *ngIf="panelData.panelType === 'header-BadgeIconTop'">
        <div id="procThumbnailBadgeUrl" class="thumbnail-badge" *ngIf='panelData.badgeUrl'>
          <img class="img-fluid"  [class.evo-t4-badge-border]="isEvoT4" src="{{panelData.badgeUrl}}" alt="Processor badge" /><!-- IJR-1852- evo condition -->
        </div>
        <ng-container *ngIf=!isWifiThirdParty6E> <!--IJR-240 bug fix --> <!--UAT-238-->
          <div id="connectivityTextInfo" class="black-box" [class.altered-bb]= "connectivityTextAlign" [class.reduce-pad]="wifi5TextAlign" *ngFor="let badgeData of panelData.badgeIcons">
            <div id="connectivityPreTitle" class="preTitle-value" [innerHTML]="badgeData.tr_badgePreTitle"></div>
            <div id="connectivitybadgeTitle" class="value" [innerHTML]="badgeData.tr_badgeTitle"  [class.intelOne-display] ="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne"></div>
            <div id="connectivitySubTitle" class="subtitle-value" [class.intelOne-display] ="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne">{{badgeData.tr_badgeSubTitle}}</div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="iscreatorIntelOptane"> <!--IJR-949-->
        <div id="intelContentHeading" *ngIf="!isNonIntel" class="content-heading"><h2 [innerHTML]="panelData.title" [ngClass]="setFontIntelDisplayOne? 'intelOne-display':'latin-bold'"></h2></div>
        <div id="intelPanelPreTitle" *ngIf="panelData?.preTitle" class="creator-pretitle" [innerHTML]="panelData.preTitle"></div>
        <div id="intelPanelSubTitle" class="paragraph-style creator-p" [innerHTML]="panelData.subTitle"></div>
      </ng-container>
      <ng-container *ngIf="!iscreatorIntelOptane">
      <div id="nonCreatorIntelOptanePreTitle" *ngIf="panelData?.preTitle" class="img-info" [innerHTML]="panelData.preTitle"></div>
      <div id="intelContentHeading" *ngIf="!isNonIntel" class="content-heading">
        <h2 [innerHTML]="panelData.title" [class.chromebook-evo-title]="ischromebookEvo && pageName === 'extendedBatteryLife'" [ngClass]="[setFontIntelDisplayOne ? 'intelOne-display' : 'latin-bold',  pageName === 'das' ? 'das-title' : '']"></h2>  <!-- IJR-2802,2223 -->
      </div> <!-- II-3558 -->
      <div id="nonIntelPanelTitle" *ngIf="isNonIntel" class="content-heading" [innerHTML]="panelData.title" [ngClass]="pageName === 'das' ? 'das-title' : ''"></div> <!-- II-3558 -->
      <p *ngIf="pageName !== 'connectivity' || (pageName === 'connectivity' && connnectivityType === 'T3') " class="paragraph-style" [class.creator-font-size] ="isCreatorProcessor" [innerHTML]="panelData.subTitle"></p>
      <div id="connectivitySubTitle" *ngIf="pageName === 'connectivity' && connnectivityType === 'T4'" class="connectivity-paragraph-style" [innerHTML]="panelData.subTitle"></div>
      <div id="panelNoteSection" class="notesection-style" [innerHTML]="panelData.noteSection"></div>
      </ng-container>
    </div>

    <div id="creatorGamingInfoDetail" class="info-detailed" [class.creator-info-detailed]="isCreator" *ngIf="panelData.panelType === 'header-IconTopGaming' || panelData.panelType === 'header-BadgeIconTopGaming'">
      <div id="creatorPanelHeaderIconTop" class="d-flex" *ngIf="panelData.panelType ==='header-IconTopGaming'">
        <img *ngIf='panelData.badgeUrl' src="{{panelData.badgeUrl}}" alt="Processor badge"/>
      </div>
      <div id="panelHeaderBadgeIconTop" class="feature-row" *ngIf="panelData.panelType === 'header-BadgeIconTopGaming'">
        <div id="creatorProcThumbnailBadge" class="thumbnail-badge" *ngIf='panelData.badgeUrl'>
          <img class="img-fluid"  src="{{panelData.badgeUrl}}" alt="Processor badge" />
        </div>
        <div id="gamingBadgeData" class="gaming-black-box" *ngFor="let badgeData of panelData.badgeIcons" [ngStyle]="{'margin-left': isGgraphicsGamingPage ? '8px' : '' }"> <!-- Added ngstyle as part of IJR-1029 fix -->
          <div id="gamingBadgePreTitle" class="preTitle-value" [innerHTML]="badgeData.tr_badgePreTitle"></div>
          <div id="gamingBadgeTitle" [innerHTML]="badgeData.tr_badgeTitle"></div>
          <div id="gamingVadgeSubTitle" class="subtitle-value">{{badgeData.tr_badgeSubTitle}}</div>
        </div>
      </div>
      <div id="gamingPanelTitle" *ngIf="panelData.title" class="gaming-heading" [innerHTML]="panelData.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" ></div>  <!-- GLS-301308 -->
      <div id="gamingPanelSubTitle" class="subtitle" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':''" [innerHTML]='panelData.preTitle'></div>
      <p class="paragraph-style" [innerHTML]="panelData.subTitle"></p>
      <div id="gamingPanelNoteSection" class="notesection-style" [innerHTML]="panelData.noteSection"></div>
    </div>

    <div id="tableFOrmatPanel" class="info-detailed table-padding" *ngIf="panelData.panelType === panelTypeTableFormat">
      <div id="tablePanelTitle" class="subheader text-center" [innerHTML]="panelData.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" ></div>
      <section class="table-panel">
        <table class="table table-borderless compare_table compare_header_table" aria-hidden="true">
          <thead></thead>
          <tbody>
              <tr>
                  <!-- UAT-237 GLS-298985-->
                  <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [ngStyle]="{'color': setFontColor ? '#525252' : '' }" [innerHTML]="panelData?.table_head[0]?.tr_table_heading ? panelData?.table_head[0]?.tr_table_heading: '&nbsp;'" ></td>
                  <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [ngStyle]="{'color': setFontColor ? '#525252' : '' }" [innerHTML]="panelData?.table_head[1]?.tr_table_heading ? panelData?.table_head[1]?.tr_table_heading: '&nbsp;'" ></td>
                  <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [ngStyle]="{'color': setFontColor ? '#525252' : '' }" [innerHTML]="panelData?.table_head[2]?.tr_table_heading ? panelData?.table_head[2]?.tr_table_heading: '&nbsp;'" ></td>
                  <!-- UAT-237 GLS-298985 -->
                </tr>
          </tbody>
        </table>

        <div id="compareChartSection" class="compare_chart">
          <table class="table table-borderless compare_table" [ngClass]="isAppGaming ? 'gaming_table_col_gradients':'table_col_gradients'" aria-hidden="true">
            <colgroup>
              <col [ngClass]="gradientCol === 0 ? 'highlight_col': ''" />
              <col [ngClass]="gradientCol === 1 ? 'highlight_col': ''" />
              <col [ngClass]="gradientCol === 2 ? 'highlight_col': ''" />
            </colgroup>
            <thead>              
              <tr>
                <th scope="col" *ngFor="let colData of panelData.table_head">
                  <figure class="figure">
                    <img class="compare_chart_core_badge" src="{{colData.imgUrl}}"  alt="processor badge"/>
                    <figcaption class="figure-caption text-center"><div [innerHTML]="colData.tr_imgCaption"></div></figcaption>
                  </figure>
                </th>
              </tr>
            </thead>
            <tbody >
              <ng-container *ngFor="let tableData of panelData.tabletr">
              <tr>
                <td colspan="3" [innerHTML]="tableData.tr_row"></td>
              </tr>
              <tr>
                <td><span *ngIf="tableData?.td1!= (true || false)"></span><span
                  [ngClass]="tableData.td1=== true? 'check_mark': ''"></span></td>
                <td><span *ngIf="tableData?.td2!= (true || false)" ></span><span
                      [ngClass]="tableData.td2=== true? 'check_mark': ''"></span></td>
                <td><span *ngIf="tableData?.td3!= (true || false)"></span><span
                      [ngClass]="tableData.td3=== true? 'check_mark': ''"></span></td>
              </tr>                                  
              </ng-container>                                 
            </tbody>
          </table>        
        </div>
        <div id="i9GamingCompareChart" class="compare_chart" [class.i9Gaming-text-align]= "i9GamingTextAlign">
          <table *ngIf="panelData.table_footer" class="table-borderless compare_table" style="width:100%; margin:auto;" aria-hidden="true">
            <thead>
              <tr>
                  <th scope="col" class="table-data" *ngFor="let tableData of panelData.table_footer">
                    <figure class="figure">
                        <figcaption class="figure-caption text-center">
                          <div id="compareTableTitle" [innerHTML]="tableData.tr_title"></div><br />
                          <div id="compareTableSubTitle" [innerHTML]="tableData.tr_subTitle"></div>
                        </figcaption>
                    </figure>
                  </th>
              </tr>
            </thead>
        </table>
        </div>
      </section>
    </div>

    <div id="creatorInfoDetail" class="info-detailed" [class.creator-info-detailed]="isCreator" *ngIf="panelData.panelType === panelTypeBadgeHeading">
      <div id="creatorPanelTitle" class="subheader" *ngIf="panelData.title" [innerHTML]="panelData.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'"></div>
      <p *ngIf="panelData.subTitle" [innerHTML]="panelData.subTitle"></p>
      <div id="creatorBadgeDataCard" class="card-holder" *ngFor="let badgeData of panelData.badgeIcons">
        <!-- IJR-328 starts -->
        <div id="badgeIconHolder" class="icon-holder" *ngIf="badgeData?.badgeUrl">
          <img style=" width: 60px;height: 60px;" class="badge-rounded" [class.rounded]="wifiContent" src="{{badgeData?.badgeUrl}}" alt="badge-url" />
          <div id="rethinkStorageBadgeData" *ngIf="badgeData.badgeUrl.includes('one_linear')"  class="rethink-storage" [innerHTML]="badgeData?.badgeSubTitle"></div> <!-- IJR-2382: rethink storage -->
        </div>
        <div id="rethinkStoragePage" class="spec-holder"  [class.no-margin-block]="isRethinkStoragePage"> <!--Added no-margin-block for GLS-353025-->
          <strong *ngIf="badgeData?.tr_badgeTitle && !isTakeAndStore" [innerHTML]="badgeData?.tr_badgeTitle"></strong>
          <div id="rethinkStorageBadgeTitle" class="graphicPreTitle" *ngIf="badgeData?.tr_badgeTitle && isTakeAndStore" [innerHTML]="badgeData?.tr_badgeTitle"></div>
          <div id="rethinkStorageGraphicTitle" class="graphicTitle"  *ngIf="badgeData?.tr_badgePreTitle && pageName =='graphics'"  [innerHTML]="badgeData?.tr_badgePreTitle">
          </div>
            <div  id="rethinkStorageSubTitle"  *ngIf="badgeData?.tr_badgeSubTitle"  [innerHTML]="badgeData?.tr_badgeSubTitle">
            </div>
            <div   id="rethinkStorageBadgeSubTitle1" *ngIf="badgeData?.tr_badgeSubTitle1"  [innerHTML]="badgeData?.tr_badgeSubTitle1">
            </div>
            <div  id="rethinkStorageBadgeSubTitle2"  *ngIf="badgeData?.tr_badgeSubTitle2"  [innerHTML]="badgeData?.tr_badgeSubTitle2">
            </div>
            <div  id="rethinkStorageBadgePreTitle"  *ngIf="badgeData?.tr_badgePreTitle && pageName !='graphics'"  [innerHTML]="badgeData?.tr_badgePreTitle">
              <!-- IJR-328 ends -->
            </div>
        </div>
      </div>
      <div id="creatorPanelNoteSection" class="noteSection" *ngIf="panelData.noteSection" [innerHTML]="panelData.noteSection"></div>
    </div>

    <div id="gamingGraphicsInfoDetail" class="info-detailed" *ngIf="panelData.panelType === panelTypeGamingGraphics"> 
     <div id="graphicImageFlip3" class="graph-element" *ngIf="pageName === 'graphics' || pageName === 'memory'">
        <img class="{{graphicImageClass}}"  alt="graphic graph"  [ngClass]="graphicImageFlip()"/>
        <div id="graphicImageFlip1" class="{{typeValue}}" [ngClass]="graphTypeValue()" >{{gamingGraphOutput}}</div> 
      </div>
      <div id="connectivityPage" class="graph-element" *ngIf="pageName === 'connectivity' || pageName === 'wifi'">
        <img *ngIf="pageName === 'connectivity'" class="graph" [class.flip-graph]= "graphicImageFlip1" src="assets/images/thunderbolt-graph.png" alt="connectivity graph"/>
        <img *ngIf="pageName === 'wifi'" class="{{graphicImageClass}}" alt="wifi graph" [class.flip-graph4]= "graphicImageFlip4" 
        [class.flip-graph5]= "graphicImageFlip5" [class.flip-graph6]= "graphicImageFlip6" [class.flip-graph3-wifi]= "graphicImageFlip3"/>
      </div>
      <div id="typeSectionWrapper" class="type-section-wrapper" [class.altered-width]= "graphicsAlignWidth" [class.altered-width-connectivity]= "connectivityAlignWidth"> 
        <div id="" *ngFor="let badge of panelData.badgeIcons;let i = index" [attr.data-index]="i" >
          <div id="graphicSectionTitle" class="type-section" [class.altered-width]= "graphicsAlignWidth" [ngClass]="[i+1 == gamingHighlight? 'active':'','type-section'+i]" *ngIf="badge.tr_badgeTitle">
            <div id="connectivitySectionTitle" class="type" [class.altered-wifi-font-weight]= "isWifiGamingPanel" [class.altered-type-width]= "graphicsAlignWidth" [class.connectivityt3Align-type]="connectivityt3Align" [class.connectivityt3Align-type2]="connectivityt3Align2" [innerHTML]="badge.tr_badgeTitle" *ngIf="badge.tr_badgeTitle"></div>
            <div id="typeSectionDescription" class="description" [class.altered-type-width]= "graphicsAlignWidth" [class.connectivityt3Align-description]="connectivityt3Align" [innerHTML]="badge.tr_badgeSubTitle" *ngIf="badge.tr_badgeSubTitle"></div>
          </div>
        </div>
      </div>
    </div>

    <div id="gamingProcInfoDetail" class="info-detailed" [class.creator-info-detailed]="isCreator" *ngIf="panelData.panelType === panelTypeGamingProcessor">
      <div id="gamingProcessorIcon" class="d-flex">
        <img src="{{panelData.badgeUrl}}" alt="Processor icon" />
      </div>
      <div id="gamingProcPreTitle" class="img-info" [innerHTML]="panelData.preTitle"></div>
      <h2 [class.intelOne-display]= "setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="panelData.title"></h2> <!-- IJR-344 fix --><!-- IJR--544 fix -->
      <div id="nonGamingFeature" class="feature-row" [class.h45-non-gaming-feature-row]= "nonGamingFeatureRow" [class.creator-feature-row]= "isCreatorFeatureRow"> <!-- Story IJR-19, 66 changes -->
        
        <div id="nonGamingblackBoxProc"  *ngFor="let badgeData of panelData.badgeIcons; let i = index" class="black-box-processor" [ngClass]="i ===3? 'black-box black-box-smart-cache':'black-box'" 
          [ngClass]="(i ===3 && isSmartCache)? 'black-box black-box-smart-cache-width':'black-box'" 
          [ngClass]="(i === badgeIndex && isCreatorSmartCache)? 'black-box black-box-smart-cache-width':'black-box'">{{badgeData.tr_badgePreTitle}}
          <img [src]="badgeData.imgUrl" *ngIf="badgeData.imgUrl" class="svg-icon" alt="unlock Icon" />
          <div id="nonGamingProcTitle" [ngClass]="badgeData.tr_badgeSubTitle === ''? '':'badgetitle_font'" [innerHTML]="badgeData.tr_badgeTitle"></div>
          <div id="nonGamingProcSubTitle" [innerHTML]="badgeData.tr_badgeSubTitle"></div> <!--GLS-313287-->
        </div>      
      </div>
      <p *ngIf="!generic11thGen" [class.h45-non-gaming-p]= "nonGamingFeatureRow" [class.creator-font-size] ="isCreatorProcessor" [innerHTML]="panelData.subTitle"></p> <!-- Story IJR-19, 66 changes -->
    </div>

    <div id="badgeAndHeadingMemory" class="info-detailed" [class.creator-info-detailed]="isCreator" *ngIf="panelData.panelType === 'badgesAndHeadings-memory'">
      <div id="bhmProcBadge" class="d-flex">
        <img *ngIf='panelData.badgeUrl' src="{{panelData.badgeUrl}}" alt="Processor badge"/>
      </div>
      <div id="bhmPanelPreTitle" *ngIf="panelData?.preTitle" class="img-info" [innerHTML]="panelData.preTitle"></div>
      <div id="bhmPanelTitle" *ngIf="panelData.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display intelOptanMemoryTitle-bold':'latin-bold'" [innerHTML]="panelData.title"></div>   <!--GLS-297205-->
      <p *ngIf="panelData.subTitle" [innerHTML]="panelData.subTitle"></p>
      <div id="badgeDataCardHolder" class="card-holder" *ngFor="let badgeData of panelData.badgeIcons">
        <div id="badgeDataIconHolder" class="icon-holder" *ngIf="badgeData.badgeUrl">
          <img style=" width: 60px;height: 60px;" class="badge-rounded" src="{{badgeData.badgeUrl}}" alt="badge-url" />
        </div>
        <div id="specHolderMemory" class="spec-holder-memory">
          <strong *ngIf="badgeData.tr_badgeTitle && !isTakeAndStore" [innerHTML]="badgeData.tr_badgeTitle"></strong>
          <div id="specHolderMemoryTitle" *ngIf="badgeData.tr_badgeTitle && isTakeAndStore" [innerHTML]="badgeData.tr_badgeTitle"></div>
          <div id="graphicBadgePreTitle" class="graphicTitle"  *ngIf="badgeData.tr_badgePreTitle && pageName =='graphics'"  [innerHTML]="badgeData.tr_badgePreTitle">
          </div>
            <div id="graphicBadgeSubTitle"  *ngIf="badgeData.tr_badgeSubTitle"  [innerHTML]="badgeData.tr_badgeSubTitle">
            </div>
            <div id="graphicBadgeSubTitle1" *ngIf="badgeData.tr_badgeSubTitle1"  [innerHTML]="badgeData.tr_badgeSubTitle1">
            </div>
            <div id="graphicBadgeSubTitle2" *ngIf="badgeData.tr_badgeSubTitle2"  [innerHTML]="badgeData.tr_badgeSubTitle2">
            </div>
            <div id="badgePreTitle"  *ngIf="badgeData.tr_badgePreTitle && pageName !='graphics'"  [innerHTML]="badgeData.tr_badgePreTitle">
            </div>
        </div>
      </div>
      <div id="panelNoteSection" class="noteSection" *ngIf="panelData.noteSection" [innerHTML]="panelData.noteSection"></div>
    </div>

    <div id="procCompareInfoDetail" class="info-detailed proc-compare" *ngIf="panelData.panelType === panelTypeTableFormat2">
      <div id="procComparePanelTitle" class="subheader text-center" [innerHTML]="panelData.title"></div>

      <section class="table-panel">
        <div id="procCompareChart" class="compare_chart">
          <table class="table table-borderless compare_table" aria-hidden="true">
            <colgroup>
              <col *ngIf="gradientCol != 0" [attr.span]="gradientCol"/>
              <col class="pro_table_gradients">
            </colgroup>
            <thead>
              <tr *ngIf="panelData.panelType ==='tableFormat2'">
                <th scope="col" style="margin-top: -2px" *ngFor="let colData of panelData.table_head">
                  <div id="procImageCaption" class="proc-title" [innerHTML]="colData.tr_imgCaption"></div>
                </th>
              </tr>              
              <tr>
                <th scope="col" *ngFor="let colData of panelData.table_head">
                  <div id="compareProcBadge" class="proc-badge">
                    <img src="{{colData.imgUrl}}" alt="processor badge" />
                  </div>
                </th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tableData of panelData.tabletr">
                <td><span [innerHTML]="tableData.tr_td1_title"></span></td>
                <td><span [innerHTML]="tableData.tr_td2_title"></span></td>
                <td><span [innerHTML]="tableData.tr_td3_title"></span></td>
              </tr>  
            </tbody>
          </table>
        </div>
      </section>
    </div>

    <div id="osBadgeIcon" class="section-images" *ngIf="panelData.panelType === imageOnlyPanel && !isWindows11NewContent">
      <ng-container *ngFor="let badgeData of panelData.badgeIcons">
        <img *ngIf="badgeData.imgUrl" src="{{badgeData.imgUrl}}" alt="os-icon" />
      </ng-container>
    </div>

    <div id="window11NewContent" *ngIf="isWindows11NewContent">
      <iframe class="windows11-content" title="windows11NewContent" [src]="windows11NewContentURL"></iframe>
    </div>
</div>

<ng-container *ngIf="isChrombookProcessorDDP">
  <div id="chromebookProcDdp" class="info-detailed chrome-book">
    <div id="chromebookProcIcon" class="d-flex">
      <img *ngIf="panelData.badgeUrl" src="{{panelData.badgeUrl}}" alt="Processor icon" />
    </div>
    <div *ngIf="!isIntelProcessor" id="chromebookProcPreTitle" class="img-info" [innerHTML]="panelData.preTitle" ></div>
    <h2 *ngIf="!isIntelProcessor" [ngClass]="setFontIntelDisplayOne? 'intelOne-display':'latin-bold'" [class.chromebook-evo-title]="ischromebookEvo" [innerHTML]="panelData.title"></h2> 
    <p class="paragraph-style" *ngIf="panelData.subTitle && !isIntelProcessor" [innerHTML]="panelData.subTitle"></p>

    <div *ngIf="isIntelProcessor && isIntelInsidei3" id="chromebookProcPreTitle" class="img-info" [innerHTML]="panelData.preTitle" ></div>
    <div id="chromebookProcPreTitle" class="img-info" [innerHTML]="panelData.title" *ngIf="isIntelProcessor && !isIntelInsidei3"></div>
    <h2 *ngIf="isIntelProcessor" [ngClass]="setFontIntelDisplayOne? 'intelOne-display':'latin-bold'" [class.chromebook-evo-title]="ischromebookEvo" [innerHTML]="panelData.subTitle"></h2>
    <img *ngIf="isIntelProcessor" class="bannerImgIntelInside chrome_inside" src="{{intelInsideImage}}" alt="banner img">

    <ul *ngIf="panelData.badgeIcons && panelData.panelType === chromebookPanelTypeHeaderBadgeIconTop" [class.chromebookevo]="ischromebookEvo && !isChromeRplMtl" [class.chromebookRplMtl]="ischromebookEvo && isChromeRplMtl" [ngClass]="isChromebook13genEvo ? 'default_list' : ''"> <!-- IIT2-2222-->
        <li *ngFor="let badgeData of panelData.badgeIcons" [innerHTML]="badgeData.tr_badgeTitle"></li>        
    </ul>
    <img *ngIf="ischromebookEvo && isChromebookMTL" class="evo-badge" src="../../../assets/images/evo/14gen-evo.svg" alt="evo edition img"/>
    <div id="procSpecHighlight" class="spec_highlight" *ngIf="panelData.badgeIcons && panelData.panelType === chromebookPanelTypeHeaderBadgeIconTop11Gen">
      <ng-container *ngFor="let badgeData of panelData.badgeIcons">
        <div id="chromebook12GenData" class="data">
          <span [class.chromebook_12gen_subheading]="isChromebook12gen" [class.intelOne-display] ="setFontIntelDisplayOne && isChromebook12gen" [innerHTML]="badgeData.tr_badgeTitle"></span> <!-- IIT2-6440 -->
        </div>
        <div class="inline_separator"></div>
      </ng-container>     
    </div>    
  </div>
</ng-container>
