import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { LibraryChrombookV2DataService } from '../../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class RethinkstorageService {
  panel = [];
  constructor(
    private libraryChrombookV2DataService: LibraryChrombookV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  displayRethinkStorage(): any {
    // changed logic for story IJR-2382
    const storageDesciption = Number(this.qrCodeService?.qrInput?.StoorageSSDValues) > 0 || Number(this.qrCodeService?.qrInput?.StorageHDDValues) > 0;
    const totalStorage = Number(this.qrCodeService.qrInput.StoorageSSDValues) + Number(this.qrCodeService.qrInput.StorageHDDValues);
    this.panel = [];
    const panelData = this.libraryChrombookV2DataService.getFactTagsDetails(GlobalConstants.RETHINKSTORAGE).details;
    const data = Object.assign({}, panelData);
    const newBadgeIcons = data.badgeIcons;
    data.badgeIcons = [];
    if (storageDesciption) {
      if (totalStorage < 1000) {
        const rethinkStorage = this.qrCodeService.transformDecimal(totalStorage);
        data.badgeIcons.push({
          badgeUrl: newBadgeIcons[0].badgeUrl,
          tr_badgeSubTitle: newBadgeIcons[0].tr_badgeSubTitle
        },
        {
          badgeUrl: newBadgeIcons[1].badgeUrl,
          tr_badgeSubTitle: newBadgeIcons[1].tr_badgeSubTitle
            .replace(GlobalConstants.XX, rethinkStorage),
          badgeSubTitle: this.libraryChrombookV2DataService.getStaticText('tr_rethinkStorageDescriptionGB')
            .replace(GlobalConstants.XX, rethinkStorage)
        });
      } else {
        const rethinkStorage = (totalStorage / 1000).toFixed(1);
        const rethinkStorageTB = this.qrCodeService.transformDecimal(rethinkStorage);
        data.badgeIcons.push({
          badgeUrl: newBadgeIcons[0].badgeUrl,
          tr_badgeSubTitle: newBadgeIcons[0].tr_badgeSubTitle
        },
        {
          badgeUrl: newBadgeIcons[2].badgeUrl,
          tr_badgeSubTitle: newBadgeIcons[2].tr_badgeSubTitle
            .replace(GlobalConstants.XX, rethinkStorageTB),
          badgeSubTitle: this.libraryChrombookV2DataService.getStaticText('tr_rethinkStorageDescriptionTB')
          .replace(GlobalConstants.XX, rethinkStorageTB)
        });
      }
    } else {
      data.badgeIcons.push({
        badgeUrl: newBadgeIcons[0].badgeUrl,
        tr_badgeSubTitle: newBadgeIcons[0].tr_badgeSubTitle
      });
    }

    const panel1 = {
      panelType: GlobalConstants.HEADERBADGEICONTOP,
      title: data.tr_title,
      subTitle: data.tr_subtitle,
    };
    const panel2 = {
      panelType: GlobalConstants.BADGESANDHEADING,
      badgeIcons: data.badgeIcons,
    };
    this.panel.push(panel1);
    this.panel.push(panel2);
    return this.panel;
  }
}
