<div id="ftags" class="fact_tags">
  <div id="factTagsTitles" *ngFor="let item of factTagsTitles; let i=index" class="card ftag">
    <span class='ftag_width'>
      <div id="ftagBody" class="ftag_body">
        <div id="ftagHeading" class="ftag_heading">{{item}}</div>
        <div id="ftagDetails" class="ftag_detail_on">
          <div id="ftagSubhead" class="ftag_subhead">
            {{factTagsDescription[i]}}
          </div>
          <span class="chevron_right_ftag"></span>
        </div>
      </div>
    </span>
  </div>
  <div id="viewSpecsCard" class="card ftag view_specs_card">
    <div id="viewSpecsBody" class="ftag_body">
      <div id="viewSpecsDetails" class="ftag_detail_on">
        <span>
          <div id="viewSpecsFtagHeading" class="ftag_heading">View All Specs</div>
        </span>
        <span class="chevron_right_ftag"></span>
      </div>
    </div>
  </div>
</div>
