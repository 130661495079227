import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
@Component({
  selector: 'app-unconnected-storage',
  templateUrl: './unconnected-storage.component.html',
  styleUrls: ['./unconnected-storage.component.scss']
})
export class UnconnectedStorageComponent implements OnInit {
  storageTitle: string;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.storageTitle = GlobalConstants.STORAGE_TITLE;
    
  }

}
