import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  panel = [];
  isAppGaming = false;
  panelData: any;
  panelData2: any;
  isNonIntel: boolean;
  tbText: string;
  gbText: string;
  isSSDAvailable: string;
  isHDDAvailable: string;
  isOptaneAvailable: string;
  storageSSDValue: number;
  storageHDDValue: number;
  storageOptaneValue: number;
  sSDName: string;
  hDDName: string;
  isStandardEnhancement = false;

  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.isNonIntel = qrData.ContentType === GlobalConstants.NON_INTEL_TEXT;
    this.tbText = this.libraryService.getStaticText(GlobalConstants.TB_STATIC_TEXT);
    this.gbText = this.libraryService.getStaticText(GlobalConstants.GB_STATIC_TEXT);
    if (this.qrCodeService.qrInput.Language === 'he') {
      this.gbText = GlobalConstants.GB;
      this.tbText = GlobalConstants.TB;
    }
    this.isSSDAvailable = qrData.IsSSDAvailable;
    this.isHDDAvailable = qrData.IsHDDAvailable;
    this.isOptaneAvailable = qrData.IsStorageOptaneAvailable;
    this.storageSSDValue = Number(qrData.StoorageSSDValues);
    this.storageHDDValue = Number(qrData.StorageHDDValues);
    this.storageOptaneValue = Number(qrData.StorageOptaneValues);

    const hdd_storage_details = this.libraryService.getFactTagsDetails(GlobalConstants.HDD_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    const ssd_storage_details = this.libraryService.getFactTagsDetails(GlobalConstants.SSD_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    this.hDDName = hdd_storage_details?.badgeIcons[1]?.tr_badgeTitle;
    this.sSDName = ssd_storage_details?.badgeIcons[1]?.tr_badgeTitle;
    this.isStandardEnhancement = this.qrCodeService.standardEnhancement(); // IIT2-2885
  }

  ssdAndHddData(): void {
    let panelData = this.panelData;
    let panel1;
    let panel2;
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

    let ssdValue; 
    let hddValue;
    ssdValue = Number(this.storageSSDValue);
    hddValue = Number(this.storageHDDValue);
    let trans_SSDValue;
    let trans_HDDValue;
    let transformcapacitySSD = ssdValue;
    let transformcapacityHDD = hddValue;
    let title;

    if (ssdValue >= 1000) {
      if (isNaN(ssdValue / 1000)) {
        trans_SSDValue = ssdValue / 1000;
      } else {
        
        trans_SSDValue = this.storageFloorOff(ssdValue);
      }
      transformcapacitySSD = this.qrCodeService.transformDecimal(trans_SSDValue);

    }
    if (hddValue >= 1000) {
      if (isNaN(hddValue / 1000)) {
        trans_HDDValue = hddValue / 1000;
      } else {
        trans_HDDValue = this.storageFloorOff(hddValue);
      }
      transformcapacityHDD = this.qrCodeService.transformDecimal(trans_HDDValue);
    }

    if (ssdValue >= 1000 && hddValue >= 1000) {
      title = panelData.titleArray[7].tr_title.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue < 1000 && hddValue >= 1000) {
      title = panelData.titleArray[6].tr_title.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue >= 1000 && hddValue < 1000) {
      title = panelData.titleArray[5].tr_title.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue < 1000 && hddValue < 1000) {
      title = panelData.titleArray[4].tr_title.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    }

    panel1 = {
      panelType: panelData.panelType1,
      title: title,
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle,
      badgeIcons: this.panelData.badgeIcons
    };
    const badgeData2 = [];
    badgeData2.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]);
    panel2 = {
      panelType: data2.panelType2,
      badgeIcons: badgeData2
    };
    let gameValue = Math.round((Number(this.storageHDDValue) + Number(this.storageSSDValue)) / 40);
    let videoValue = Math.round((Number(this.storageHDDValue) + Number(this.storageSSDValue)) / 5);
    let photoValue = Math.round((Number(this.storageHDDValue) + Number(this.storageSSDValue)) * 1000 / 4);
    panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.GAMES_NUMBER,
      this.qrCodeService.transformDecimal(gameValue));
    panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
      this.qrCodeService.transformDecimal(videoValue));
    panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
      this.qrCodeService.transformDecimal(photoValue));

      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-3233
        let totalCapacity = ssdValue + hddValue;
        let transformSSDHDDValueTB;
        if(totalCapacity >= 1000) {
          const ssdHddValue = this.storageFloorOff(totalCapacity);
          transformSSDHDDValueTB = this.qrCodeService.transformDecimal(ssdHddValue);
        } else {
          transformSSDHDDValueTB = totalCapacity;
        }

        const checkType = totalCapacity >= 1000 ? GlobalConstants.TB : GlobalConstants.GB;
        const tbSTorage = panelData.badgeIcons.filter(item => item.storageType === checkType);
        panel1.badgeIcons = [];
        panel1.badgeIcons = [
          {
            tr_badgeTitle: tbSTorage[0].items[2].tr_badgeTitle.replace(GlobalConstants.CAPACITY, transformSSDHDDValueTB)
          }, 
          {
            tr_badgeTitle: tbSTorage[0].items[2].tr_badgeSubTitle
          }
        ];
      }
      

    this.panel.push(panel1, panel2);
  }

  ssdData(): void {
    let panelData = this.panelData;
    let panel1;
    let panel2;
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

    panel1 = {
      panelType: panelData.panelType1,
      title: panelData.titleArray[1].tr_title,
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle,
    };
    
    const badgeData2 = [];
    badgeData2.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]);
    panel2 = {
      panelType: data2.panelType2,
      badgeIcons: badgeData2
    };
    let gameValue;
    let videoValue;
    let photoValue
    if (Number(this.storageSSDValue) >= 1000) {
      const ssdTBValue = this.storageFloorOff(this.storageSSDValue);
      const transformssdTBValue = this.qrCodeService.transformDecimal(ssdTBValue);
      panel1.title = panel1.title.replace(GlobalConstants.XX, transformssdTBValue);
      panel1.title = panel1.title.replace(this.gbText, this.tbText);
      gameValue = Math.round(Number(this.storageSSDValue) / 40);
      videoValue = Math.round(Number(this.storageSSDValue) / 5);
      photoValue = Math.round((Number(this.storageSSDValue) * 1000 ) / 4);

      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-3233
        const tbSTorage = panelData.badgeIcons.filter(item => item.storageType === GlobalConstants.TB);
        panel1.badgeIcons = [];
        panel1.badgeIcons = [
          {
            tr_badgeTitle: tbSTorage[0].items[0].tr_badgeTitle.replace(GlobalConstants.CAPACITY, ssdTBValue)
          }, 
          {
            tr_badgeTitle: tbSTorage[0].items[0].tr_badgeSubTitle
          }
        ];
      }
    }
    else {
      panel1.title = panel1.title.replace(GlobalConstants.XX, this.storageSSDValue);
      gameValue = Math.round(Number(this.storageSSDValue) / 40);
      videoValue = Math.round(Number(this.storageSSDValue) / 5);
      photoValue = Math.round((Number(this.storageSSDValue) * 1000) / 4);

      if(this.qrCodeService.gamaingEnhancement()) {
        const tbSTorage = panelData.badgeIcons.filter(item => item.storageType === GlobalConstants.GB);
        panel1.badgeIcons = [];
        panel1.badgeIcons = [
          {
            tr_badgeTitle: tbSTorage[0].items[0].tr_badgeTitle.replace(GlobalConstants.CAPACITY, this.storageSSDValue)
          }, 
          {
            tr_badgeTitle: tbSTorage[0].items[0].tr_badgeSubTitle
          }
        ];
      }

    }
    panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.GAMES_NUMBER,
      this.qrCodeService.transformDecimal(gameValue));
    panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
      this.qrCodeService.transformDecimal(videoValue));
    panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
      this.qrCodeService.transformDecimal(photoValue));

     

    this.panel.push(panel1, panel2);
  }

  hddData(): void {
    let panelData = this.panelData;
    let panel1;
    let panel2;
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    panel1 = {
      panelType: panelData.panelType1,
      title: panelData.titleArray[0].tr_title,
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle,
    };
    const badgeData2 = [];
    badgeData2.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]);
    panel2 = {
      panelType: data2.panelType2,
      badgeIcons: badgeData2
    };
    let gameValue;
    let videoValue;
    let photoValue
    if (Number(this.storageHDDValue) >= 1000) {
      const hddTBValue = this.storageFloorOff(this.storageHDDValue);
      const transformhddTBValue = this.qrCodeService.transformDecimal(hddTBValue);
      panel1.title = panel1.title.replace(GlobalConstants.XX, transformhddTBValue);
      panel1.title = panel1.title.replace(GlobalConstants.GB, this.tbText);
      gameValue = Math.round(Number(this.storageHDDValue) / 40);
      videoValue = Math.round(Number(this.storageHDDValue) / 5);
      photoValue = Math.round((Number(this.storageHDDValue) * 1000) / 4);

      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-3233
        const tbSTorage = panelData.badgeIcons.filter(item => item.storageType === GlobalConstants.TB);
        panel1.badgeIcons = [];
        panel1.badgeIcons = [
          {
            tr_badgeTitle: tbSTorage[0].items[1].tr_badgeTitle.replace(GlobalConstants.CAPACITY, hddTBValue)
          }, 
          {
            tr_badgeTitle: tbSTorage[0].items[1].tr_badgeSubTitle
          }
        ];
      }

    }
    else {
      panel1.title = panel1.title.replace(GlobalConstants.XX, this.storageHDDValue);
      gameValue = Math.round(Number(this.storageHDDValue) / 40);
      videoValue = Math.round(Number(this.storageHDDValue) / 5);
      photoValue = Math.round((Number(this.storageHDDValue) * 1000) / 4);

      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-3233
        const tbSTorage = panelData.badgeIcons.filter(item => item.storageType === GlobalConstants.GB);
        panel1.badgeIcons = [];
        panel1.badgeIcons = [
          {
            tr_badgeTitle: tbSTorage[0].items[1].tr_badgeTitle.replace(GlobalConstants.CAPACITY, this.storageHDDValue)
          }, 
          {
            tr_badgeTitle: tbSTorage[0].items[1].tr_badgeSubTitle
          }
        ];
      }
    }
    panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.GAMES_NUMBER,
      this.qrCodeService.transformDecimal(gameValue));
    panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
      this.qrCodeService.transformDecimal(videoValue));
    panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
      this.qrCodeService.transformDecimal(photoValue));

    this.panel.push(panel1, panel2);
  }

  optaneData(): void {
    let panelData = this.panelData;
    let panel1;
    let panel2;
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
     
    // IJR-2956 - Start
    let storageTitle;
    const optaneValueTB = Number(this.storageOptaneValue);
    if (Number(this.storageSSDValue) >= 1000) {
      const ssdValue = this.storageFloorOff(this.storageSSDValue);
      const transformssdValue1 = this.qrCodeService.transformDecimal(ssdValue);
      storageTitle = panelData.titleArray[3].tr_title.replace(GlobalConstants.TOTALCAPACITY, transformssdValue1)
      .replace(GlobalConstants.OPTANEMEMORYSIZE, optaneValueTB);
    } else {
      storageTitle = panelData.titleArray[2].tr_title.replace(GlobalConstants.TOTALCAPACITY, this.storageSSDValue)
      .replace(GlobalConstants.OPTANEMEMORYSIZE, optaneValueTB);
    }
    // IJR-2956 - End

    panel1 = {
      panelType: panelData.panelType1,
      title: storageTitle, // IJR-2956
      preTitle: panelData.tr_pretitle,
      subTitle: panelData.tr_subtitle,
      badgeUrl: panelData.badgeUrl
    };
    const badgeData2 = [];
    badgeData2.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]);
    panel2 = {
      panelType: data2.panelType2,
      badgeIcons: badgeData2
    };
    let gameValue = Math.round((Number(this.storageSSDValue)) / 40);
    let videoValue = Math.round((Number(this.storageSSDValue)) / 5);
    let photoValue = Math.round((Number(this.storageSSDValue)) * 1000 / 4);
    panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.GAMES_NUMBER,
      this.qrCodeService.transformDecimal(gameValue));
    panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
      this.qrCodeService.transformDecimal(videoValue));
    panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
      this.qrCodeService.transformDecimal(photoValue));
      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-3233
        panel1.badgeIcons = [
          {
            tr_badgeTitle: GlobalConstants.SSD_TEXT
          }
        ];
      }
    this.panel.push(panel1, panel2);
  }

  displayStorageDetailPage(): any {
    this.panel = [];
    this.panelData = this.libraryService.getFactTagsDetails(GlobalConstants.HDD_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
    this.setDefaultData();
    if (this.isNonIntel) {
      const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.NON_INTEL_NAME, GlobalConstants.STORAGE_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: panelData.panelType1,
        title: panelData.tr_title,
        subTitle: panelData.tr_subtitle
      };
      this.panel.push(panel1);
    }
    else if (this.isAppGaming) {

      if (this.isOptaneAvailable === 'Y') {
        this.optaneData();
      } else {
        if (this.isSSDAvailable === 'Y' && this.isHDDAvailable === 'Y') {
          this.ssdAndHddData();
        } else {
          if (this.isSSDAvailable === 'Y') {
            this.ssdData();
          } else if (this.isHDDAvailable === 'Y') {
            this.hddData();
          }
        }
      }

    } else {

      if (this.isOptaneAvailable === 'Y') {
        this.optaneDataStandard();
      } else {
        if (this.isSSDAvailable === 'Y' && this.isHDDAvailable === 'Y') {
          // UAT-564
          if (this.storageSSDValue <= 0) {
            this.hddDataStandard();
          } else if (this.storageHDDValue <= 0) {
            this.ssdDataStandard();
          } else {
            this.ssdAndHddDataStandard();
          }
          // UAT-564
        } else {
          if (this.isSSDAvailable === 'Y') {
            this.ssdDataStandard();
          } else if (this.isHDDAvailable === 'Y') {
            this.hddDataStandard();
          }
        }
      }
    }
    return this.panel;
  }

  ssdAndHddDataStandard() {
    this.panelData = this.libraryService.getFactTagsDetails(GlobalConstants.SSD_AND_HDD, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

    let capacity;
    let hddValue;
    let ssdValue;
    let trans_SSDValue;
    let trans_HDDValue;

    capacity = Number(this.storageSSDValue) + Number(this.storageHDDValue);
    ssdValue = Number(this.storageSSDValue);
    hddValue = Number(this.storageHDDValue);

    
    let pre_tile;
    let transformcapacitySSD;
    let transformcapacityHDD;
    
    trans_SSDValue = ssdValue;
    trans_HDDValue = hddValue;
    transformcapacitySSD = ssdValue;
    transformcapacityHDD = hddValue;

    if (ssdValue >= 1000) {
      if (isNaN(ssdValue / 1000)) {
        trans_SSDValue = ssdValue / 1000;
      } else {
        trans_SSDValue = this.storageFloorOff(ssdValue);
      }
      transformcapacitySSD = this.qrCodeService.transformDecimal(trans_SSDValue);

    }
    if (hddValue >= 1000) {
      if (isNaN(hddValue / 1000)) {
        trans_HDDValue = hddValue / 1000;
      } else {
        trans_HDDValue = this.storageFloorOff(hddValue);
      }
      transformcapacityHDD = this.qrCodeService.transformDecimal(trans_HDDValue);
    }

    if (ssdValue >= 1000 && hddValue >= 1000) {
      pre_tile = this.panelData?.tr_preTitle3.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
      this.panelData.titleArray[0].tr_subtitle = this.panelData?.titleArray[0]?.tr_subtitle3.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue < 1000 && hddValue >= 1000) {
      pre_tile = this.panelData?.tr_preTitle2.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
      this.panelData.titleArray[0].tr_subtitle = this.panelData?.titleArray[0]?.tr_subtitle2.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue >= 1000 && hddValue < 1000) {
      pre_tile = this.panelData?.tr_preTitle1.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
      this.panelData.titleArray[0].tr_subtitle = this.panelData?.titleArray[0]?.tr_subtitle1.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    } else if (ssdValue < 1000 && hddValue < 1000) {
      pre_tile = this.panelData?.tr_preTitle.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
      this.panelData.titleArray[0].tr_subtitle = this.panelData?.titleArray[0]?.tr_subtitle.replace(GlobalConstants.CONVERTED_HDD, transformcapacityHDD).replace(GlobalConstants.CONVERTED_SSD, transformcapacitySSD);
    }

    
    // IJR-1890 std storage end

    if (capacity >= 1000) {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData?.titleArray[0]?.tr_title1,
        subTitle: this.panelData?.titleArray[0]?.tr_subtitle,
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle : ''
      };
      const transformcapacityInTB = this.qrCodeService.transformDecimal(this.storageFloorOff(capacity));
      panel1.badgeIcons[0].tr_badgeTitle = panel1?.badgeIcons[0]?.tr_badgeTitle1.replace(GlobalConstants.CAPACITY, transformcapacityInTB);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, transformcapacityInTB);
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      let songValue = Math.round(Number(capacity) * 1000 / 5);
      let videoValue = Math.round(Number(capacity) / 5);
      let photoValue = Math.round((Number(capacity) * 1000) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));

      // IJR-1890 STD - ADD PANEL 1 Pre Title start
      if(this.isStandardEnhancement) {
        panel1.preTitle = pre_tile;
      }
      // IJR-1890 STD - ADD PANEL 1 Pre Title end
      this.panel.push(panel1, panel2);
    }
    else {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData.titleArray[0].tr_title,
        subTitle: this.panelData.titleArray[0].tr_subtitle,
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle : ''
      };
      let capacityInGB = capacity;
      panel1.badgeIcons[0].tr_badgeTitle = panel1?.badgeIcons[0]?.tr_badgeTitle.replace(GlobalConstants.CAPACITY, capacityInGB);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, capacityInGB); // std 12 gen only
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      let songValue = Math.round(Number(capacity) * 1000 / 5);
      let videoValue = Math.round(Number(capacity) / 5);
      let photoValue = Math.round((Number(capacity) * 1000) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));

        // IJR-1890 STD - ADD PANEL 1 Pre Title start
        if(this.isStandardEnhancement) {
          panel1.preTitle = pre_tile;
        }
        // IJR-1890 STD - ADD PANEL 1 Pre Title end
        
      this.panel.push(panel1, panel2);


    }
  }

  ssdDataStandard() {
    this.panelData = this.libraryService.getFactTagsDetails(GlobalConstants.SSD_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

    if (Number(this.storageSSDValue) >= 1000) {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData.titleArray[1].tr_title,
        subTitle: this.panelData.titleArray[1].tr_subtitle,
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle1 : ''
      };
      this.storageSSDValue = this.storageFloorOff(this.storageSSDValue);
      const transformStorageSSDValue = this.qrCodeService.transformDecimal(this.storageSSDValue);
      panel1.subTitle = panel1?.subTitle.replace(GlobalConstants.CAPACITY, transformStorageSSDValue);
      panel1.badgeIcons[0].tr_badgeTitle = panel1?.badgeIcons[0]?.tr_badgeTitle1.replace(GlobalConstants.CAPACITY, transformStorageSSDValue);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, transformStorageSSDValue); // std 12 gen only
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      let songValue = Math.round(Number(this.qrCodeService?.qrInput?.StoorageSSDValues) * 1000 / 5);
      let videoValue = Math.round(Number(this.qrCodeService?.qrInput?.StoorageSSDValues) / 5);
      let photoValue = Math.round((Number(this.qrCodeService?.qrInput?.StoorageSSDValues) * 1000 ) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));
      this.panel.push(panel1, panel2);
    }
    else {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData.titleArray[0].tr_title,
        subTitle: this.panelData.titleArray[0].tr_subtitle,
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle : ''
      };
      panel1.subTitle = panel1?.subTitle.replace(GlobalConstants.CAPACITY, this.storageSSDValue);
      panel1.badgeIcons[0].tr_badgeTitle = panel1?.badgeIcons[0]?.tr_badgeTitle.replace(GlobalConstants.CAPACITY, this.storageSSDValue);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, this.storageSSDValue); // std 12 gen only
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      const storageFullValue = this.qrCodeService?.qrInput?.StoorageSSDValues;
      let songValue = Math.round(Number(storageFullValue) * 1000 / 5);
      let videoValue = Math.round(Number(storageFullValue) / 5);
      let photoValue = Math.round((Number(storageFullValue) * 1000) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));
      this.panel.push(panel1, panel2);
    }
  }

  hddDataStandard() {
    this.panelData = this.libraryService.getFactTagsDetails(GlobalConstants.HDD_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    let data2 = this.libraryService.getFactTagsDetails(GlobalConstants.COMMON, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    if (Number(this.storageHDDValue) >= 1000) {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData.titleArray[0].tr_title, // IJR - 1071
        subTitle: this.panelData.titleArray[0].tr_subtitle, // IJR - 1071
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle1 : ''
      };
      this.storageHDDValue = this.storageFloorOff(this.storageHDDValue);
      const transformStorageHDDValue = this.qrCodeService.transformDecimal(this.storageHDDValue);
      panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.CAPACITY, transformStorageHDDValue + GlobalConstants.SINGLE_SPACE + this.tbText);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, transformStorageHDDValue); // std 12 gen only
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      const storageHddFullValue = this.qrCodeService?.qrInput?.StorageHDDValues;
      let songValue = Math.round(Number(storageHddFullValue) * 1000 / 5);
      let videoValue = Math.round(Number(storageHddFullValue) / 5);
      let photoValue = Math.round((Number(storageHddFullValue) * 1000) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));
      this.panel.push(panel1, panel2);
    }
    else {
      const panel1 = {
        panelType: this.panelData.panelType1,
        title: this.panelData.titleArray[0].tr_title,
        subTitle: this.panelData.titleArray[0].tr_subtitle,
        badgeIcons: this.panelData.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? this.panelData.badgeUrl : '',
        preTitle: this.isStandardEnhancement ? this.panelData.tr_preTitle : ''
      };
      panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.CAPACITY, this.storageHDDValue + GlobalConstants.SINGLE_SPACE + this.gbText);
      panel1.preTitle = panel1.preTitle.replace(GlobalConstants.CAPACITY, this.storageHDDValue); // std 12 gen only
      const badgeData2 = [];
      badgeData2.push(data2.badgeIcons[0], data2.badgeIcons[2], data2.badgeIcons[3]);
      const panel2 = {
        panelType: data2.panelType2,
        badgeIcons: badgeData2,
        title: data2.tr_title,
        subTitle: data2.tr_subtitle
      };
      const storageHddFullValue = this.qrCodeService?.qrInput?.StorageHDDValues;
      let songValue = Math.round(Number(storageHddFullValue) * 1000 / 5);
      let videoValue = Math.round(Number(storageHddFullValue) / 5);
      let photoValue = Math.round((Number(storageHddFullValue) * 1000) / 4);
      panel2.badgeIcons[0].tr_badgeTitle = panel2.badgeIcons[0].tr_badgeTitle.replace(GlobalConstants.SONGS_NUMBER,
        this.qrCodeService.transformDecimal(songValue));
      panel2.badgeIcons[1].tr_badgeTitle = panel2.badgeIcons[1].tr_badgeTitle.replace(GlobalConstants.VIDEOS_NUMBER,
        this.qrCodeService.transformDecimal(videoValue));
      panel2.badgeIcons[2].tr_badgeTitle = panel2.badgeIcons[2].tr_badgeTitle.replace(GlobalConstants.PHOTOS_NUMBER,
        this.qrCodeService.transformDecimal(photoValue));
      this.panel.push(panel1, panel2);
    }
  }
  optaneDataStandard() {
    let pretitle;
    this.panelData2 = this.libraryService.getFactTagsDetails(GlobalConstants.OPTANE_TEXT, GlobalConstants.STORAGE_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
    let ssdValue1;
    if (Number(this.storageSSDValue) >= 1000) {
      ssdValue1 = this.storageFloorOff(this.storageSSDValue);
      const transformSsdValue1 = this.qrCodeService.transformDecimal(ssdValue1);
      pretitle =  this.panelData2.tr_pretitle1.replace(GlobalConstants.TOTALCAPACITY, transformSsdValue1)
      .replace(GlobalConstants.OPTANEMEMORYSIZE, Number(this.storageOptaneValue));
    } else {
      pretitle = this.panelData2.tr_pretitle.replace(GlobalConstants.TOTALCAPACITY, Number(this.storageSSDValue))
      .replace(GlobalConstants.OPTANEMEMORYSIZE, Number(this.storageOptaneValue));
    }
    const panel1 = {
      panelType: this.panelData2.panelType1,
      title: this.panelData2.tr_title,
      preTitle: pretitle,
      subTitle: this.panelData2.tr_subtitle,
      badgeIcons: this.panelData2.badgeIcons,
      badgeUrl: this.panelData2.badgeUrl
    };
    const panel2 = {
      panelType: this.panelData2.panelType2,
      title: this.panelData2.titleArray[0].tr_title,
      subTitle: this.panelData2.titleArray[0].tr_subtitle,
      badgeIcons: this.panelData2.panel2BadgeIcons
    };
    this.panel.push(panel1, panel2);
  }

  storageFloorOff(number: number) {
    return Math.floor((Number(number) / 1000) * 10) / 10;
  }
}
