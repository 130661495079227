import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class MemoryService {
  panel = [];
  isNonIntel: boolean;
  memoryValue: string;
  isAppGaming = false;
  ProcessorName: string;
  totalInstall: number;
  gbText: string;
  tbText: string;
  isStandardEnhancement = false;

  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    this.isNonIntel = qrData.ContentType === GlobalConstants.NON_INTEL_TEXT;
    this.totalInstall = Number(qrData.ActualMemory);
    this.memoryValue = qrData.TotalMemory;
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.ProcessorName = qrData.ProcessorDescription;
    this.tbText = this.libraryService.getStaticText(GlobalConstants.TB_STATIC_TEXT);
    this.gbText = this.libraryService.getStaticText(GlobalConstants.GB_STATIC_TEXT);
    this.isStandardEnhancement = this.qrCodeService.standardEnhancement(); // IIT2-2885
  }
  displayMemoryDetailPage() {
    this.panel = [];
    this.setDefaultData();
    const qrData = this.qrCodeService.getQrData();

    // optane memory condition
    if (qrData.Optane_Msg === 'Y' && Number(qrData.OptaneMemorySize) > 0) {
      const data = this.libraryService.getFactTagsDetails('OPTANE', 'memory', 'STANDARD', 'DEFAULT');
      const data2 = this.libraryService.getFactTagsDetails('COMMON', 'memory', 'STANDARD', 'DEFAULT');

      const val1 = Number(qrData.OptaneMemorySize) + Number(qrData.ActualMemory);
      const val2 = qrData.OptaneMemorySize;
      const val3 = qrData.ActualMemory;

      const panel = {
        panelType: data.panelType1,
        title: data.tr_title,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle,
        badgeIcons: data.badgeIcons,
        badgeUrl: this.isStandardEnhancement ? data.badgeUrl : '' // only std image
      };

      panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace('[XX]', val1);
      panel.title = panel.title.replace('[Value1]', val1);
      panel.title = panel.title.replace('[Value2]', val2);
      panel.title = panel.title.replace('[Value3]', val3);

      const panel2 = {
        title: data2.tr_title,
        panelType: data2.panelType2,
        badgeIcons: data2.badgeIcons,
        noteSection: data2.tr_noteSection
      };
      this.panel.push(panel);
      this.panel.push(panel2);
    }

    else {
      if (this.totalInstall < 8) {
        const data = this.libraryService.getFactTagsDetails('GOOD', 'memory', 'STANDARD', 'DEFAULT');
        const data2 = this.libraryService.getFactTagsDetails('COMMON', 'memory', 'STANDARD', 'DEFAULT');
        const data3 = this.libraryService.getFactTagsDetails('STANDARD_COMMON_12TH_MEMORY', 'memory', 'STANDARD', 'DEFAULT');
        const panel = {
          panelType: data.panelType1,
          title: data.tr_title.replace('[XX]', this.totalInstall),
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle,
          badgeIcons: data.badgeIcons,
          badgeUrl: this.isStandardEnhancement ? data.badgeUrl : '' // only std image
        };

        panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace('[XX]', this.totalInstall);
        const panel2 = {
          title: data2.tr_title,
          panelType: data2.panelType2,
          badgeIcons: data2.badgeIcons,
          noteSection: data2.tr_noteSection
        };

        const panel3 = {
          panelType: data3.panelType2,
          badgeIcons: data3.badgeIcons,
          panelType2: data3.gamingPanleType2,
          badgeIcons2: data3.enhancementCompare
        };
        this.changeEnhancementValue(data3); // IIT2-4762
        this.panel.push(panel);
        if (this.isStandardEnhancement) {
          this.panel.push(panel3);
        } else {
          this.panel.push(panel2);
        }
      }
      else if (this.totalInstall >= 8 && this.totalInstall < 16) {
        const data = this.libraryService.getFactTagsDetails('BETTER', 'memory', 'STANDARD', 'DEFAULT');
        const data2 = this.libraryService.getFactTagsDetails('COMMON', 'memory', 'STANDARD', 'DEFAULT');
        const data3 = this.libraryService.getFactTagsDetails('STANDARD_COMMON_12TH_MEMORY', 'memory', 'STANDARD', 'DEFAULT');
        const panel = {
          panelType: data.panelType1,
          title: data.tr_title.replace('[XX]', this.totalInstall),
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle.replace('[memory_total]', this.totalInstall),
          badgeIcons: data.badgeIcons,
          badgeUrl: this.isStandardEnhancement ? data.badgeUrl : '' // only std image
        };
        panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace('[XX]', this.totalInstall);

        const panel2 = {
          title: data2.tr_title,
          panelType: data2.panelType2,
          badgeIcons: data2.badgeIcons,
          noteSection: data2.tr_noteSection
        };

        const panel3 = {
          panelType: data3.panelType2,
          badgeIcons: data3.badgeIcons,
          panelType2: data3.gamingPanleType2,
          badgeIcons2: data3.enhancementCompare
        };
        this.changeEnhancementValue(data3);

        this.panel.push(panel);
        if (this.isStandardEnhancement ) {
          this.panel.push(panel3);
        } else {
          this.panel.push(panel2);
        }
      }
      else if (this.totalInstall >= 16) {
        const data = this.libraryService.getFactTagsDetails('BEST', 'memory', 'STANDARD', 'DEFAULT');
        const data2 = this.libraryService.getFactTagsDetails('COMMON', 'memory', 'STANDARD', 'DEFAULT');
        const data3 = this.libraryService.getFactTagsDetails('STANDARD_COMMON_12TH_MEMORY', 'memory', 'STANDARD', 'DEFAULT');
        const panel = {
          panelType: data.panelType1,
          title: data.tr_title.replace('[XX]', this.totalInstall),
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle,
          badgeIcons: data.badgeIcons,
          badgeUrl: this.isStandardEnhancement ? data.badgeUrl : ''
        };
        panel.badgeIcons[0].tr_badgeTitle = panel.badgeIcons[0].tr_badgeTitle.replace('[XX]', this.totalInstall);
        const panel2 = {
          title: data2.tr_title,
          panelType: data2.panelType2,
          badgeIcons: data2.badgeIcons,
          noteSection: data2.tr_noteSection
        };        
        const panel3 = {
          panelType: data3.panelType2,
          badgeIcons: data3.badgeIcons,
          panelType2: data3.gamingPanleType2,
          badgeIcons2: data3.enhancementCompare
        };

        this.changeEnhancementValue(data3);
        
        this.panel.push(panel);
        if (this.isStandardEnhancement) {
          this.panel.push(panel3);
        } else {
          this.panel.push(panel2);
        }
      }
    }
    if (this.isNonIntel) {
      this.panel = [];
      let titleArrayNum;
      titleArrayNum = Number(this.memoryValue) > 4 ? 0 : 1;
      const panelData = this.libraryService.getFactTagsDetails(GlobalConstants.NON_INTEL_NAME, GlobalConstants.MEMORY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: panelData.panelType1,
        title: panelData.titleArray[titleArrayNum].tr_title,
        subTitle: panelData.titleArray[titleArrayNum].tr_subtitle
      };
      if (Number(this.memoryValue) > 4) {
        panel1.title = panel1.title.replace(GlobalConstants.XX, this.memoryValue);
        panel1.subTitle = panel1.subTitle.replace(GlobalConstants.MEMORY_PLACEHOLDER, this.memoryValue);
      }
      this.panel.push(panel1);
    }
    if (this.isAppGaming) {
      this.panel = [];
      let data3;
      if(this.qrCodeService.gamaingEnhancement()) { // IIT2-2886
        data3 = this.libraryService.getFactTagsDetails('GAMING_12TH_MEMORY', 'memory', 'GAMING', 'DEFAULT');
      } else {
        data3 = this.libraryService.getFactTagsDetails('DEFAULT', 'memory', 'GAMING', 'DEFAULT');
      }
      const panel = {
        panelType: data3.panelType1,
        title: data3.tr_title.replace('[XX]', this.totalInstall),
        preTitle: data3.tr_pretitle,
        subTitle: data3.tr_subtitle
      };
      const panel3 = {
        panelType: data3.panelType2,
        badgeIcons: data3.badgeIcons,
        panelType2: data3.gamingPanleType2,
        badgeIcons2: data3.enhancementCompare
      };
      this.panel.push(panel);
      this.panel.push(panel3);

      // optane memory condition for gaming
      if (qrData.Optane_Msg === 'Y' && Number(qrData.OptaneMemorySize) > 0) {
        this.panel = [];
        const data = this.libraryService.getFactTagsDetails('OPTANE', 'memory', 'STANDARD', 'DEFAULT');
        const data2 = this.libraryService.getFactTagsDetails('COMMON', 'memory', 'STANDARD', 'DEFAULT');

        const val1 = Number(qrData.OptaneMemorySize) + Number(qrData.ActualMemory);
        const val2 = qrData.OptaneMemorySize;
        const val3 = qrData.ActualMemory;

        const firstPanel = {
          panelType: data.panelType1,
          title: data.tr_title,
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle
        };
        
        firstPanel.title = firstPanel.title.replace('[Value1]', val1).replace('[Value2]', val2).replace('[Value3]', val3);

        const panel2 = {
          title: data2.tr_title,
          panelType: data2.panelType2,
          badgeIcons: data2.badgeIcons,
          noteSection: data2.tr_noteSection
        };
        this.panel.push(firstPanel);
        this.panel.push(panel2);
      }
    }
    return this.panel;
  }

  displayOptaneMemoryDetailPage() {
    this.panel = [];
    this.setDefaultData();
    if (this.isAppGaming) {
      const data1 = this.libraryService.getFactTagsDetails('INTEL_OPTANE', 'memory', 'GAMING', 'DEFAULT');
      const badgeData1 = data1.badgeIcons;
      let optanememorygamingValue1 = data1.tr_pretitle;
      let optanememorygamingValue2 = data1.tr_pretitle1;
      let optmemgamingfullValue1;
      let optmemgamingfullValue2;
      if (Number(this.qrCodeService.qrInput.OptaneMemory) >= 1000) {
        optmemgamingfullValue1 = (Number(this.qrCodeService.qrInput.OptaneMemory) / 1000).toFixed(1);
        optanememorygamingValue1 = optanememorygamingValue1.replace(GlobalConstants.XX, optmemgamingfullValue1);
        optanememorygamingValue1 = optanememorygamingValue1.replace(this.gbText, this.tbText);
        optmemgamingfullValue2 = (Number(this.qrCodeService.qrInput.OptaneMemory) / 1000).toFixed(1);
        optanememorygamingValue2 = optanememorygamingValue2.replace(GlobalConstants.XX, optmemgamingfullValue2);
        optanememorygamingValue2 = optanememorygamingValue2.replace(this.gbText, this.tbText);

      } else {
        optmemgamingfullValue1 = this.qrCodeService.qrInput.OptaneMemory;
        optanememorygamingValue1 = optanememorygamingValue1.replace(GlobalConstants.XX, optmemgamingfullValue1);
        optanememorygamingValue2 = optanememorygamingValue2.replace(GlobalConstants.XX, optmemgamingfullValue1);
      }
      const panel1 = {
        panelType: data1.panelType1,
        badgeUrl: data1.badgeUrl,
        title: data1.tr_title,
        preTitle: optanememorygamingValue1,
        badgeIcons: badgeData1,
        preTitle1: optanememorygamingValue2
      };
      // GLS-298419 - start
      panel1.badgeIcons[0].tr_badgePreTitle = panel1.badgeIcons[0].tr_badgePreTitle.replace('[optane_legal_processor_name]',
      this.qrCodeService.qrInput.CpuDisplay).replace(GlobalConstants.NUMBER_IN_SUPERSCRIPT, optmemgamingfullValue1);  // GLS-313766,361326
      panel1.badgeIcons[1].tr_badgePreTitle = panel1.badgeIcons[1].tr_badgePreTitle.replace('[optane_legal_processor_name]',
      this.qrCodeService.qrInput.CpuDisplay).replace(GlobalConstants.NUMBER_IN_SUPERSCRIPT, optmemgamingfullValue1); // GLS-313766,361326
        // GLS-298419 - end
      this.panel.push(panel1);
    }
    else {
      const data = this.libraryService.getFactTagsDetails('INTEL_OPTANE', 'memory', 'STANDARD', 'DEFAULT');
      const data2 = this.libraryService.getFactTagsDetails('INTEL_OPTANE', 'memory', 'STANDARD', 'DEFAULT');
      let optanememorystandardValue = data2.tr_pretitle;
      let optmemfullValue;
      if (Number(this.qrCodeService.qrInput.OptaneMemory) >= 1000) {
        optmemfullValue = (Number(this.qrCodeService.qrInput.OptaneMemory) / 1000).toFixed(1);
        optanememorystandardValue = optanememorystandardValue.replace(GlobalConstants.XX, optmemfullValue);
        optanememorystandardValue = optanememorystandardValue.replace(this.gbText, this.tbText);
      } else {
        optmemfullValue = this.qrCodeService.qrInput.OptaneMemory;
        optanememorystandardValue = optanememorystandardValue.replace(GlobalConstants.XX, optmemfullValue);
      }
      const panel = {
        panelType: data.panelType1,
        badgeUrl: data.badgeUrl,
        title: data.tr_title,
        preTitle: optanememorystandardValue,
        subTitle: data.tr_subtitle
      };

      const panel2 = {
        title: data2.titleArray[0].tr_title,
        panelType: data2.panelType2,
        badgeIcons: data2.badgeIcons,
        noteSection: data2.tr_noteSection
      };
      this.panel.push(panel);
      this.panel.push(panel2);
    }
    return this.panel;
  }

  changeEnhancementValue(enhancementPanelData) {
    if (this.qrCodeService?.qrInput?.ContentType[0] === GlobalConstants.CREATOR_CONTENT) {
      enhancementPanelData.enhancementCompare.imgUrl = GlobalConstants.WHITE_TICK;
    }
  }
}
