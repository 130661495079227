<div id="mobileContentHeight" class="height_imp">
    <div id="mobileContentImg" class="logo_image">
        <img [style.visibility]="checkManufacturerIntel ? 'visible' : 'hidden'" class="intel_logo logo_margin"
            alt="intel logo">
        <div id="mobileIcon" class="button_margin">
            <img class="mobile_icon" alt="offline experience icon">
        </div>
        <div id="titleText" class=font_format>
            {{titleText}}
        </div>
        <span class=desc_font>{{subtitleText}}</span>
        <button type="button" class="btn btn-primary button_margin" (click)="gotoMobileHomePage()">
            <div id="rightArrowIcon" class=align-center>
                <span>{{welcomeBtnText}}</span>
                <span class=ml-3><img class="right_arrow_icon" alt="right icon"></span>
            </div>
        </button>
    </div>
</div>