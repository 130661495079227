import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fact-tag',
  templateUrl: './fact-tag.component.html',
  styleUrls: ['./fact-tag.component.scss']
})
export class FactTagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
