<div id="appGaming" class="container scrollbar pt-4" [ngClass]="isAppGaming ? 'gaming':'' " [class.scroll-mtl-vpro]="isMTLVpro && pageName ==='processor'">
  <div id="gamingPanel" class="panel-group">
    <ng-container *ngFor="let panel of panelData; let i = index">
      <div id="arcPanelHeading" *ngIf="panel.panelType!==evoVideoPanel" [class.panel]="panel.panelType !== 'oled_first_panel' && panel.panelType !=='unison-panel'"
      [class.arc-detailed]="panel.panelType===arcPanelHeading && !otherArcModel"
      class="panel-default tabel_format{{i}} std_{{(isStd12Content || isStd11Content )}}"
      [ngClass]="{'windows11-panel':isWindows11,'creator-info-detailed':isCreator, 'rebuild-panel': panel.panelType === 'rebuild-usage-panel' ,'main-mtl': isMTLVpro && pageName === 'processor'}"
      [class.evo-content-padding]="isEvoContentPanel" [class.compare_panel_tableformat]="isCompareTableFormat"
      [style.background-color]="setRebuildBackgroundColor(panel?.backgroundColor)">
      <div id="panelInfoDetail" class="info-detailed"
      [class.gaming-arc-margin]="panel.panelType===arcPanelHeading && isAppGaming"
          *ngIf="(panel.panelType === 'header-IconTopGaming'  || panel.panelType === 'header-BadgeIconTop' || panel.panelType === 'header-IconTop' || panel.panelType === 'paneltype-das'  || panel.panelType === arcPanelHeading) && !isStoargePage ">
          <!-- 12th gen gaming II-3564 -->
          <div id="gaming12Gen" class="d-flex"
            *ngIf="panel.panelType ==='header-IconTopGaming' || (panel.panelType === 'paneltype-das' && isAppGaming)">
            <!-- 12th gen gaming II-3564 --> <!--IJR-2391-->
                <img *ngIf="pageName === 'wifi' && !isShowWifiBadge" class="right-margin-class" src="{{wifiBadge}}" alt="wifi badge"/>
                <img *ngIf='panel.badgeUrl' src="{{panel.badgeUrl}}" alt="Processor badge"
             [ngClass]="{'img-rounded':pageName === 'wifi', 'wifi7_badge': isGamingWifi7Killer}" />
                <div id="memoryPage" class="memory_badge" *ngIf="pageName === 'memory'"></div>
              </div>
              <div id="panelFeature" class="feature-row"
              *ngIf="panel.panelType === 'header-BadgeIconTopGaming' || panel.panelType === arcPanelHeading">
              <div id="thumbnailBadgeUrl" class="thumbnail-badge" *ngIf='panel.badgeUrl'
                [ngClass]="{'arcBadgeWidth': panel.panelType === arcPanelHeading, 'gaming-arc': isAppGaming}">
 
                  <img class="img-fluid"  src="{{panel.badgeUrl}}" alt="Processor badge" />
              
                </div>                  
              </div>
              <div id="wifiPage" *ngIf="pageName === 'wifi' && wifiTitle"> <!--IJR-1887-->
                <img *ngIf="wifiBadge1" class="right-margin-class" src="{{wifiBadge}}" alt="wifi badge"/>
                <div id="wifiTitle" *ngIf= "wifiTitle" [innerHTML]='panel.badgeIcons[0].tr_badgeTitle'></div>
              </div>
              <div id="wifiPreTitle" *ngIf="pageName ==='wifi' && wifiBadgeTitle"  [innerHTML]='panel.preTitle'></div> <!--IJR-1887-->
              <img *ngIf="pageName === 'wifi' && wifiBadge1 && !wifiTitle && !isShowWifiBadge" class="right-margin-class" src="{{wifiBadge}}"
           alt="wifi badge" />
         <img class="img-fluid std_imgWidth" [class.wifi7_badge]="isStdKillerWifi" src="{{panel.badgeUrl}}" alt="standard badge"
           *ngIf="(isStd12Content || isCreator || isStd11Content) && pageName ==='wifi' && wifiBadge2 " />
         <!--IJR-1887-->
         <img class="img-fluid std_imgWidth" src="{{panel.badgeUrl}}" alt="standard badge"
           *ngIf="(isStd12Content || isCreator || isStd11Content) && pageName !== 'os' && pageName !=='wifi' && panel.badgeUrl && panel.panelType !== arcPanelHeading" /><!--IJR-1887-->
         <img class="ml-3 img-fluid std_imgWidth" *ngIf='panel.graphicsBadgeUrl && isCreator'
           [src]="panel.graphicsBadgeUrl" alt="Graphics badge" />
         <img class="ml-3 img-fluid std_imgWidth" *ngIf='panel.connectivityBadge && isEvo'
           [src]="panel.connectivityBadge" alt="connectivity badge" /> <!--IIT2-1953-->
         <div id="memoryAndOsPage"
           *ngIf="(pageName === 'optane_memory' || pageName === 'os' ||
           ((pageName ==='graphics' || pageName ==='graphicsCard') && !isArcGraphics)) &&
           (isStd12Content || isCreator || isStd11Content)"
           class="subtitle" [ngClass]="panel.panelType === arcPanelHeading && isArcNewModel ? '' : 'subtitle-text'" [class.intelOne-display]="setFontIntelDisplayOne"
           [innerHTML]='panel.preTitle'></div> <!--  IIT2-2801 -->
        
         <div id="graphicPage" class="arc-detail-page"
           *ngIf="(( pageName ==='graphics' || pageName ==='graphicsCard') && panel.panelType === arcPanelHeading) && (isStd12Content || isCreator || isStd11Content||isAppGaming)">
           <section>
             <div id="arcOnePanel" class="arc_one_panel" [ngClass]="i==0 ? 'one_panel_margin' : ''" *ngIf="i==0 && !otherArcModel">
               <img class="img_badge" *ngIf='panel.titleArray[0]?.imgUrl' [class.arc_pro_badge] ="isArcPro" src="{{panel.titleArray[0]?.imgUrl}}" alt="Processor badge" />
               <div id="arcPreTitle" [innerHTML]="preTitleArc"></div>
               <div id="arcSubPanel" class="ht_header_panel" [class.intelOne-display]="setFontIntelDisplayOne" [class.hungary-arc-heading]="isHungaryLang"
                 [innerHTML]='panel.titleArray[0].subtitle'></div>
             </div>
             <div id="integratedArcLastPanel" *ngIf = "i==4 && isArcIntegrated">
              <div id="arcIntegratedPanel" class="arc_one_panel arc_last_panel" [class.arc_border]="isArcNewModel" style="background: url({{panel.titleArray[0].imgUrl}});">
                <div id="evenPanelHeader" class="integrated_panel_header" [class.title_align]="isArcNewModel"
                  [innerHTML]='panel.titleArray[0].title'></div>
                 <div id="expandableContent" class="integrated_panel">
                    <span    [innerHTML]='panel.titleArray[0].subtitle'></span>
                 </div>
              </div>
             </div>
         
             <div id="arcSecondPanel" *ngIf="i>0 && !(i==4 && isArcIntegrated)" >         
                <div id="arcEvenPanel" class="arc_one_panel arc_even_panel" [class.arc_border]="isArcNewModel" style="background: url({{panel.titleArray[0].imgUrl}});">
                 <div id="evenPanelHeader" class="ht_header_text" [class.title_align]="isArcNewModel" [ngClass]="panel?.titleArray[0]?.align === 'right' ? 'ht_header_even' : 'ht_header_odd'"  [class.ht_header_odd_p2] = "isArcIntegrated && i==2 && !isRTL" [class.RTL_ht_header_even]="isRTL && panel?.titleArray[0]?.align === 'right'" [class.RTL_ht_header_odd]="isRTL && panel?.titleArray[0]?.align === 'left'" [class.width-pl-text] = "language === 'pl' && i==3 && isArcPro"
                   [innerHTML]='panel.titleArray[0].title'></div>
                 <div id="downArrow" [hidden]="showSubText[i]" class="arrow_down" (click)="showSubTitle(i)"><img src="assets/images/arc_graphics/Vector.png" alt="vector">
                 </div>
                </div>
               <div id="expandableContent" class="expandable_content" *ngIf="showSubText[i]">
                 <span    [innerHTML]='panel.titleArray[0].subtitle'></span>
                 <div id="upArrow" class="arrow_up" (click)="showSubTitle(i)"><img src="assets/images/arc_graphics/Vector_up.png" alt="vector up">
                 </div>
               </div>                     
              </div>
           </section>
         </div>

         <div id="dasPage" *ngIf="panel.title && !isCreator" class="gaming-heading"
           [class.heading-font]="!setFontIntelDisplayOne" [class.intelOne-display]="setFontIntelDisplayOne" [ngClass]="pageName === 'das' ? 'das-title' : ''" [class.hu-lang-title] = "pageName === 'display'"
           [innerHTML]="panel.title"></div> <!-- 12th gen gaming II-3564 -->
         <div id="memoryPage"
           *ngIf="(pageName !== 'memory' && pageName !== 'optane_memory' && ((isStd11Content || isStd12Content) && pageName !== 'graphics' && pageName !=='graphicsCard')) && ((isStd12Content || isStd11Content) && pageName !== 'os')&& !isIntelInside && !wifiBadgeTitle || pageName ==='processor' "
           [ngClass]="{'mt-3':isCreator}" class="subtitle" [class.intelOne-display]="setFontIntelDisplayOne" [class.intelInsidePreTitle]="isIntelInside "
           [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]='panel.preTitle'>
          </div>

           <div id="creatorSection" *ngIf="panel.title && isCreator" class="gaming-heading 22"
           [class.intelOne-display]="setFontIntelDisplayOne" [ngClass]="{'das-title':pageName === 'das'}"
           [innerHTML]="panel.title"></div> <!-- 12th gen gaming II-3564 -->
         <!--IJR-2247-->
         <div id="connectivityPage"
           *ngIf="(pageName !== 'memory' && pageName !== 'graphics' && pageName !=='graphicsCard') && (isAppGaming || isCreator) && pageName !=='processor'"
           class="subtitle subtitle-text" [class.intelOne-display]="setFontIntelDisplayOne"
           [class.clear-font-bold]="!setFontIntelDisplayOne"
           [ngClass]="((connnectivityType === 'T4' || connnectivityType === 'T3') && pageName === 'connectivity') ? 'mb-3 creator-connectivity-text' : ''"
           [innerHTML]='panel.preTitle'></div> <!-- bug IJR-2203 fix -->
         <p *ngIf="(pageName !== 'connectivity' || (pageName === 'connectivity' && connnectivityType === 'T3')) && panel.subTitle "
           class="paragraph-style subtext-pad" [class.intelInsideSubTitle]="isIntelInside " [ngClass]="(pageName === 'das' && isStd12Content) ? 'mt-3' : ''"
           [innerHTML]="panel.subTitle"
           [class.intelOne-display]="setFontIntelDisplayOne && pageName !== 'wifi'"
           [style.padding-top]="pageName === 'optane_memory' && isStd12Content ? '0px !important':''"
           [ngClass]="isEvo13GenProcessorPage ? 'ml-1' : ''"></p> <!--IJR-1891-->
          <div *ngIf="pageName==='processor' && panel.panelType ==='header-IconTop' && isIntelInside">
            <div *ngIf="i===0">
              <img class="bannerImgIntelInside" src="{{panel.bannerImgUrl}}" alt="banner img">
            </div>
          </div><!--IJR-1887-->
         <div id="connectivityInfo" *ngIf="pageName === 'connectivity' && connnectivityType === 'T4'" class="connectivity-paragraph-style"
           [innerHTML]="panel.subTitle"></div> <!--IJR-1891-->
              <div id="connectivityPanelNoteSection" class="notesection-style" [innerHTML]="panel.noteSection"></div>
              <img *ngIf="panel.targetURL" src="{{panel.targetURL}}" class="processor-panel-img" alt="tile-image"/>
          </div>

          <div id="imagePanel" class="section-images" *ngIf="panel.panelType === imageOnlyPanel && !isWindows11NewContent">
            <ng-container *ngFor="let badgeData of panel.badgeIcons"> 
              <img *ngIf="badgeData.imgUrl" src="{{badgeData.imgUrl}}" alt="os-icon" />
            </ng-container>
          </div>

          <!-- IIT2-2231-windows11 new content starts -->
          <div id="window11Content" *ngIf="isWindows11NewContent">
            <iframe class="windows11-content" title="windows11NewContent" [src]="windows11NewContentURL"></iframe>
          </div>
          <!-- IIT2-2231-windows11 new content ends -->

          <div id="badgeHeadingPanel" class="info-detailed" *ngIf="panel.panelType === panelTypeBadgeHeading">
            <div id="badgeSubHeader" class="subheader" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" *ngIf="panel.title" [innerHTML]="panel.title"></div>
            <p *ngIf="panel.subTitle" [innerHTML]="panel.subTitle"></p>
            <div id="badgeIconData" class="card-holder" *ngFor="let badgeData of panel.badgeIcons; let last = last;">
              <div id="badgeIconHolder" class="icon-holder" *ngIf="badgeData?.badgeUrl">
                <img class="badge-rounded wifi7killer_{{last}}" src="{{badgeData?.badgeUrl}}" alt="badge-url" />
              </div>
              <div id="badgeInfoHolder" class="spec-holder">
                <strong *ngIf="badgeData?.tr_badgeTitle" [innerHTML]="badgeData?.tr_badgeTitle"></strong>
                <div id="badgeSubtitle" *ngIf="badgeData?.tr_badgeSubTitle" [innerHTML]="badgeData?.tr_badgeSubTitle"></div>
                <div id="badgeSubTitle1" *ngIf="badgeData?.tr_badgeSubTitle1" [innerHTML]="badgeData?.tr_badgeSubTitle1"></div>
                <div id="badgeSubTitle2" *ngIf="badgeData?.tr_badgeSubTitle2" [innerHTML]="badgeData?.tr_badgeSubTitle2"></div>
                <div id="badgePreTitle" *ngIf="badgeData?.tr_badgePreTitle && pageName !='graphics'" [innerHTML]="badgeData?.tr_badgePreTitle">
                </div>
              </div>
            </div>
            <div id="badgePanelNoteSection" class="noteSection" *ngIf="panel.noteSection" [innerHTML]="panel.noteSection"></div>
          </div>
          
          <div id="gamingGraphicInfo" class="info-detailed" *ngIf="panel.panelType === panelTypeGamingGraphics && pageName !== 'memory'"> 
            <div id="graphicImageFlip3" class="graph-element" *ngIf="pageName === 'graphics'">
              <img class="{{graphicImageClass}}"  alt="graphic graph" [ngClass]="graphicImageFlip()"/>
              <div id="graphicImageFlip1" class="{{typeValue}}" [ngClass]="graphTypeValue()" [innerHTML]="gamingGraphOutput"></div> 
            </div>
            
             <div id="gamingConnectivityPage" class="graph-element" *ngIf="pageName === 'connectivity' || pageName === 'wifi'">
               <img *ngIf="pageName === 'connectivity'" class="graph" src="{{thunderboltGraph}}" [class.flip-graph]= "graphicImageFlip1" alt="connectivity graph"/>
               <img *ngIf="pageName === 'wifi'" class="{{graphicImageClass}}" alt="wifi graph" [class.flip-graph4]= "graphicImageFlip4" 
               [class.flip-graph5]= "graphicImageFlip5" [class.flip-graph6]= "graphicImageFlip6" [class.flip-graph3-wifi]= "graphicImageFlip3"/>
              </div>
             <div id="gamingConnectivityAlignment" class="type-section-wrapper" [class.altered-width-connectivity1]= "connectivityAlignWidth"> 
               <div id="connectivityBadgeIcon" *ngFor="let badge of panel.badgeIcons; let i = index" [attr.data-index]="i" >
                 <div id="badgeDesc" class="type-section" [ngClass]="[i+1 == gamingHighlight? 'active':'','type-section'+i]" *ngIf="badge.tr_badgeTitle">
                   <div id="connectivityBadgeTitle" class="type" [innerHTML]="badge.tr_badgeTitle" *ngIf="badge.tr_badgeTitle" [class.connectivityt3Align-type]="connectivityt3Align"></div>
                   <div id="connectivityBadgeSubtitle" class="description" [innerHTML]="badge.tr_badgeSubTitle" *ngIf="badge.tr_badgeSubTitle"></div>
                 </div>
               </div>
             </div>
          </div>
          <!-- 12th gen gaming processor start-->
          <div id="gaming12GenProcessor" class="info-detailed" *ngIf="(panel.panelType === gaming12thGenProcessor && !isMTLVpro) || (panel.panelType === gaming12thGenProcessor && isMTLVpro && i==0)" >
            <div id="gamingProcessorIcon" class="d-flex">
              <img src="{{panel.badgeUrl}}" alt="Processor icon"
                [class.vpro_processor_badge]="(is12thGenVPro || is13thGenVPro) && pageName === 'processor'"
                [class.vpro_processor_badge_mtl]="isMTLVpro && pageName === 'processor'" />
            </div>
            <div id="procPanelTitle" class="img-info" [class.img-info-vPro]="isMTLVpro && pageName === 'processor'" [innerHTML]="panel.preTitle"></div>
            <div id="procPanelSubtitle" *ngIf="panel.subTitle && (is12thGenVPro || is13thGenVPro || isMTLVpro)"
              class="mb-2" [class.mtl_Vpro]="isMTLVpro && pageName === 'processor'" [innerHTML]="panel.subTitle"></div><!-- IIT2-5563 -->
            <h2 class="mb-4" [class.intelOne-display]="setFontIntelDisplayOne"  [class.intelOne-display-Vpro]="isMTLVpro" [innerHTML]="panel.title"
              [class.creator-margin-bottom]="isCreator13thGen" [class.ru-lang-title]="pageName === 'processor'"></h2>
            <p *ngIf="isStd11Content" class="paragraph-style" [innerHTML]="panel.subTitle"></p> <!-- IJR - IIT2-2254 -->
            <div *ngIf="isLunarLake && panel.titleArray && pageName === 'processor'">
              <ul> 
                <li *ngFor="let item of panel?.titleArray">
                  <div id="specTitle" class="spec_title" id="ruPanelTitle" [innerHTML]="item?.tr_title"></div>
                </li>        
              </ul>
            </div>
            <img *ngIf="(isStd12Content || isCreator12thGen||isCreator13thGen) && !isStd12i9 && !isCreator13i9"
              class="banner-center" [class.banner-center-mtl] ="isMTLVpro && pageName === 'processor'"[class.vpro_banner]="is12thGenVPro || is13thGenVPro || isLunarLake"
              [class.creator_banner]="isCreator13thGen" src="{{panel.bannerImgUrl}}" alt="Banner img" /> <!--IJR-1883-->
            <div id="procPanelFooter" class="panel-footer1" *ngIf="!(isMTLVpro && pageName === 'processor')">
              <div id="gen12BadgeInfo"
                [ngClass]="(!badgeData.tr_badgeTitle && !badgeData.tr_badgePreTitle && !badgeData.tr_badgeSubTitle) || (isGamingNonKi5 && !badgeData.tr_badgeTitle) ? 'hideSquare': 'showSquare'"
                class="square" [class.square-standard]="isStd12Content || isCreator12thGen"
                [class.square-creator]=" isCreator13thGen" *ngFor="let badgeData of panel.badgeIcons"
                [class.std13thgen_i9_square]="is13thGenI9Std"> <!--IJR-1883,2946,IIT2-3722-->
                <div id="gen13BadgeTitle"
                  [class.gaming13thgen_coreValue]="is13thGenGaming || isCreator13thGen || is14thGenGaming"
                  [innerHTML]="badgeData.tr_badgeTitle"> </div>
                <div id="badgeCoreDetails" *ngIf="!isGamingNonKi5" class="core-details-text">
                  <div id="badgePreTitle" [innerHTML]="badgeData.tr_badgePreTitle"></div>
                  <div id="badgeSubTitle" [innerHTML]="badgeData.tr_badgeSubTitle"></div>
                  <div *ngIf="badgeData.tr_badgeSubTitle1" id="badgeSubTitle1" [innerHTML]="badgeData.tr_badgeSubTitle1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="panel.panelType === gaming12thGenProcessor && isMTLVpro">
            <div *ngIf="i!=0">
              <div *ngFor="let item of panel.badgeIcons; let j=index">
                <div class="display-mtl" [ngClass]="j%2==0?'right-align-vpro':'left-align-vpro'" [class.text_pl_line_height]="language === 'pl' && j==2"
                  style="background: url({{item.badgeUrl}});">
                  <div  [class.rtl_right]="isRTL && j%2==0" [class.rtl_left]="isRTL && j%2!==0" [innerHTML]="item.tr_badgeTitle" class="align-vpro" [class.vpro-width-pl-text] = "language === 'pl' && j==2"></div>
                  <div id="downArrow" [hidden]="showVproDesc[j]" class="arrow_down" (click)="showVproTitle(j)"><img
                      src="assets/images/arc_graphics/Vector.png" alt="vector">
                  </div>
                </div>
                <div id="expandableContent" class="expandable_content_mtl expandable_content" *ngIf="showVproDesc[j]">
                  <span [innerHTML]='item.tr_badgeSubTitle'></span>
                  <div id="upArrow" class="arrow_up" (click)="showVproTitle(j)"><img
                      src="assets/images/arc_graphics/Vector_up.png" alt="vector up">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="processorPadeDetails" class="info-detailed processor-page-wrap "  [ngClass]="{'standard11-info-detailed': isStd11Content}" [class.creator-info-detailed]="isCreator" *ngIf="(panel.panelType === panelTypeGamingProcessor)">
            <div id="processorIcon" class="d-flex">
              <img [src]="panelData[0].badgeUrl" alt="Processor icon" />
            </div>
            <div id="procPreTitle" class="img-info" [innerHTML]="panelData[0].preTitle"></div>
            <h2 class="mb-2" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="panelData[0].title"></h2> <!-- IJR-344 fix --><!-- IJR--544 fix -->
            <div id="nonGamingFeature" class="feature-row d-flex text-center" [class.h45-non-gaming-feature-row]= "nonGamingFeatureRow">
              <div id="badgeData" *ngFor="let badgeData of panelData[0].badgeIcons; let i = index" class="black-box-processor black-box mb-3" [ngClass]="{'processor-smart-cache':i===3}">{{badgeData.tr_badgePreTitle}}
                <img [src]="badgeData.imgUrl" *ngIf="badgeData.imgUrl" class="svg-icon" alt="unlock Icon" />
                <div id="badgeTitle" [ngClass]="badgeData.tr_badgeSubTitle === ''? '':'badgetitle_font'" [innerHTML]="badgeData.tr_badgeTitle"></div>
                <div id="badgeSubTitle" [innerHTML]="badgeData.tr_badgeSubTitle"></div>
              </div>
            </div>
            <p *ngIf="!generic11thGen" [class.h45-non-gaming-p]= "nonGamingFeatureRow" [class.creator-font-size] ="isCreatorProcessor" [class.gaming-11-gen-subtitle] ="isG11Gaming" [innerHTML]="panelData[0].subTitle"></p>
          </div>

          <div id="gamingPanel1" class="panel panel-default mb-2" [ngClass]="isStd12Content || isStd11Content ? 'std_true': 'std_false'" [class.intelOne-display]="setFontIntelDisplayOne" *ngIf="panel?.panelType2 === 'gaming-panelType1'">
            <div id="gamingPanelHeader" class="panel-header">
              <div id="comparePanelSpec" class="compare-second-panel-spec">
                <p [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="panel.badgeIcons2?.tr_title"></p>
              </div>
            </div>
            <div id="gamingPanelFooter" class="panel-footer1">
              <div id="panelProcComparision" class="panel-processor-comparison">
                <div id="procColumn1" class="colum-1-processor" *ngFor="let secondPanel of panel.badgeIcons2.badgeIcons; let i = index">
                  <div id="processorIcon" class="processor-icon">
                    <div id="secondPanelBadgeIconTile" *ngIf="secondPanel.tr_badgeIconTile" class="panel_subtitle" [class.overflowcolumn-font-size]="isOverflowColumn" [innerHTML]="secondPanel.tr_badgeIconTile"></div>
                    <img id="secondPanelProccBadge" *ngIf="secondPanel.imgUrl" src="{{secondPanel.imgUrl}}" alt="Processor badge">
                    <div id="secondPanelMemoryIcon" class="memory-icon" *ngIf="secondPanel.tr_badgeSubTitle">
                      <div id="secondPanelSubTitle" [innerHTML]="secondPanel.tr_badgeSubTitle">
                      </div>
                    </div>
                  </div>
                  <div id="imageSelection" class="selection-img_{{i}}" [class.currentHighlight]="secondPanel.active">
                    <div id="textHighlight" *ngIf="secondPanel.active" class="currentTextHighlight">
                      <img *ngIf="panel.badgeIcons2?.imgUrl" src="{{panel.badgeIcons2?.imgUrl}}" class="currentTickAlign" alt="graph image">
                      <span [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="panel.badgeIcons2?.tr_table_heading"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 12th gen gaming processor end-->

          <!-- IJR-1747 - STORAGE START -->
        <div id="storagePanel" class="info-detailed" *ngIf="(panel.panelType === 'header-IconTopGaming' || panel.panelType === 'header-BadgeIconTop') && isStoargePage">
          <div id="storagePageIcon" class="storage_page_icons" *ngIf="!isOptaneMemory"></div>
          <div id="gamingStorageFeature" class="feature-row double-badge" *ngIf="!isOptaneMemory && isAppGaming">
            <div id="gamingStorageBadgeIcon" class="gaming-black-box" *ngFor="let badgeData of panel.badgeIcons" >
              <div id="gamingStorageBadgeTitle" [innerHTML]="badgeData.tr_badgeTitle"></div>
            </div>
          </div>

          <div id="optaneStorageIcon" class="storage_page_icons" *ngIf="isOptaneMemory && isAppGaming && panel.badgeUrl"></div>

          <div id="optaneStorageInfo" class="std_dflex" *ngIf="isOptaneMemory &&  (isStd11Content || isStd12Content || isCreator)">
            <div id="optaneStoragePageIcon" class="storage_page_icons"></div>
            <div id="optaneSsdImage" class="optaneSSD_img" ></div>
          </div>

          <div id="optaneGamingBadge" class="feature-row adjust_badge" *ngIf="isOptaneMemory && isAppGaming && panel.badgeUrl">
            <div id="optaneGamingBlackBox" class="gaming-black-box_optane optaneSSD_img" >
            </div>
            <div id="gamingBadgeIcons" class="gaming-black-box" *ngFor="let badgeData of panel.badgeIcons" >
              <div id="gaminBlackBoxBadgeTitle" [innerHTML]="badgeData.tr_badgeTitle"></div>
            </div>
          </div>
          <div id="creatorSubTitle" *ngIf="!isAppGaming || isCreator" class="subtitle" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]='panel.preTitle'></div>
          <div id="gamingHeaderPanelTitle" *ngIf="panel.title" class="gaming-heading" [innerHTML]="panel.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" ></div>
          <div id="gamingSubTitle" *ngIf="isAppGaming" class="subtitle" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':''" [innerHTML]='panel.preTitle'></div>
          <p class="paragraph-style subtext-pad" [innerHTML]="panel.subTitle"></p>
          <div id="panelNoteSection" class="notesection-style" [innerHTML]="panel.noteSection"></div>
        </div>
        
        <!-- IJR-1747 - STORAGE END -->

        <!-- IJR-1746 Gaming start -->
        <div id="grahicInfoDetail" class="info-detailed" *ngIf="panel.panelType === 'header-BadgeIconTopGaming' && pageName === 'graphics'">
          
          <div id="nonIrisGraphicSection" class="feature-row" *ngIf="!irisGraphics">
            <div id="gamingGraphuiBlackBox" class="gaming-black-box_gaming" >
              <div id="graphicPageIcon" class="graphics_page_icons"></div>
            </div>
            <div id="graphicPanelBadgeIcon" class="gaming-black-box" *ngFor="let badgeData of panel.badgeIcons" >
              <div id="graphicBadgeTitle" [innerHTML]="badgeData.tr_badgeTitle"></div>
            </div>
            
          </div>

          <div id="irisGraphicsSection" class="" *ngIf="irisGraphics">
            <div id="irisGraphicPageIcon" class="graphics_page_icons"></div>
            <div id="irisGraphicFeature" class="feature-row">
              <div id="irisGraphicGamingBlackBox" class="gaming-black-box_gaming adjust_badge" >
                <img  src="{{panel.badgeUrl}}" alt="optane badge"/>
              </div>
              <div id="irisGraphicsPanelBadgeIcon" class="gaming-black-box" *ngFor="let badgeData of panel.badgeIcons" >
                <div id="irisGraphicPanelBadgeTitle" [innerHTML]="badgeData.tr_badgeTitle"></div>
              </div>                
            </div>
          </div>

          <div id="gamingPanelTitle" *ngIf="panel.title" class="gaming-heading 444" [innerHTML]="panel.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" ></div>  <!-- GLS-301308 -->
          <div id="gamingPanelSubtitle" class="subtitle" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':''" [innerHTML]='panel.preTitle'></div>
          <p class="paragraph-style" [innerHTML]="panel.subTitle"></p>
          <div id="gamingPanelNoteSection" class="notesection-style" [innerHTML]="panel.noteSection"></div>
        </div>
        <!-- IJR-1746 Gaming end -->

        <!-- optane memeory -->

        <div id="optaneMemoryInfoDetail" class="info-detailed" *ngIf="panel.panelType === 'badgesAndHeadings-memory'">
          <div id="optaneMemoryProcBadge" class="d-flex">
            <img *ngIf='panel.badgeUrl' src="{{panel.badgeUrl}}" alt="Processor badge"/>
          </div>
          <div id="optaneMemoryPanelPretitle1" *ngIf="panel.preTitle1" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display intelOptanMemoryTitle-bold':'latin-bold'" [class.optane_th]="language === 'th'" [innerHTML]="panel.preTitle1" class="optane_memory"></div>
          <div id="optaneMemoryCardHolder" class="card-holder" *ngFor="let badgeData of panel.badgeIcons">
            <div id="badgeIconHolder" class="icon-holder" *ngIf="badgeData.badgeUrl">
              <img style=" width: 60px;height: 60px;" class="badge-rounded" src="{{badgeData.badgeUrl}}" alt="badge-url" />
            </div>
            <div id="memorySpecHolder" class="spec-holder-memory">
              <strong *ngIf="badgeData.tr_badgeTitle && !isTakeAndStore" [innerHTML]="badgeData.tr_badgeTitle"></strong>
              <div id="takeAndStoreBadgeTitle" *ngIf="badgeData.tr_badgeTitle && isTakeAndStore" [innerHTML]="badgeData.tr_badgeTitle"></div>
              <div class="graphicTitle"  *ngIf="badgeData.tr_badgePreTitle && pageName =='graphics'"  [innerHTML]="badgeData.tr_badgePreTitle">
              </div>
                <div  id="graphicBadgeSubtitle" *ngIf="badgeData.tr_badgeSubTitle"  [innerHTML]="badgeData.tr_badgeSubTitle">
                </div>
                <div id="graphicBadgeSubTitle1" *ngIf="badgeData.tr_badgeSubTitle1"  [innerHTML]="badgeData.tr_badgeSubTitle1">
                </div>
                <div id="graphicBadgeSubtitle2" *ngIf="badgeData.tr_badgeSubTitle2"  [innerHTML]="badgeData.tr_badgeSubTitle2">
                </div>
                <div id="badgePreTitle" *ngIf="badgeData.tr_badgePreTitle && pageName !='graphics'"  [innerHTML]="badgeData.tr_badgePreTitle">
                </div>
            </div>
          </div>
          <div id="panelDataNoteSection" class="noteSection" *ngIf="panelData.noteSection" [innerHTML]="panelData.noteSection"></div>
        </div>
        <!-- optane memory end -->
        <!-- Details panel for tiles starts -->
        <div id="tilesDetailsPanel" class="tile-details" [ngClass]="xboxPage ? 'xbox-h5' : ''" [class.std-tile-details]="isStd12Content || isStd11Content" [class.evo-first-panel-padding]="isEvoContentPanel" *ngIf="panel.panelType === gamingTilePanel">
          <img src="{{panel.badgeUrl}}" class="tile-page-panel-img" alt="tile-image"/>
          <h5 [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [class.text_line_height]="language === 'th'" [innerHTML]="panel.title"></h5>
          <p *ngIf="panel.preTitle" class="tile-pretitle" [innerHTML]="panel.preTitle"></p>
          <p [ngClass]="xboxPage ? 'xbox-p' : ''" class="tile-subtitle" [ngStyle]="{'margin-top':panel.preTitle ? '0px' : ''}" [innerHTML]="panel.subTitle"></p>
          <div id="tilePanelVideo" class="tile-page-panel-video" *ngIf="panel.videoPosterUrl">
            <video [muted]="!isInfluencerPage" preload="none" poster="{{panel.videoPosterUrl}}" (click)="toggleVideo()" (ended)="showPlayIcon()" #videoPlayer>
              <source src="{{panel.videoUrl}}" type="video/mp4">
              <track *ngIf="panel.subtitleUrl" label="English" kind="subtitles" mode="showing" [src]="panel?.subtitleUrl" default>
            </video>
            <img class="play-icon" #videoIcon (click)="toggleVideo()" alt="play-Icon"/>
          </div>
        </div>
        <!-- Details panel for tiles ends -->
        <!-- Details panel2 for tiles starts -->
        <div id="gamingTilePanel1" *ngIf="panel.panelType === gamingTilePanel1">
          <div id="gamilTilePanel1Body" class="panel-body" [ngClass]="{'creator-info':isCreator}">
            <ng-container  *ngFor="let element of panel.titleArray">
              <div id="tilePanel1BoxText" class="box1_text">
                <p class="box_heading_features" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="element.preTitle"></p>
                <p [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="element.tr_title"></p>
                <button type="button" class="btn btn-primary" [innerHTML]="learnMoreText" (click)="gotoDetailPage(element.detailPageName)"></button>  <!--redirecting to detail page-->
              </div>
            </ng-container>              
          </div>
        </div>
        <!-- Details panel2 for tiles ends -->

        <!-- Details panel2 for xbox tile1 starts -->
        <div id="xboxTilecontent" *ngIf="panel.panelType === 'xbox-content-tile-panel'">
          <div id="xboxPanelBody" class="panel-body">
            <ng-container  *ngFor="let element of panel.titleArray">
              <div id="xboxPanelBoxText" class="box1_text_xbox">
                <div id="xboxSubheader" class="subheader" *ngIf="element.tr_title" [innerHTML]="element.tr_title"></div>
                <p *ngIf="element.tr_subtitle" [innerHTML]="element.tr_subtitle"></p>
              </div>
            </ng-container>              
          </div>
        </div>
        <!-- Details panel2 for xbox tile1 ends -->

          <!-- Evo content tile panel starts -->
          <div id="evoContentTile" class="single_padding_panel" *ngIf="panel.panelType === 'evo-content-tile-panel'">
            <div id="evoTilePanelHeader" class="panel-header" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne">
                <div id="evoTilePanelTitle" [innerHTML]="panel.title"></div>
            </div>
            <div id="evoPanelBody" class="panel-body">
                <div id="evoPanelText" class="mx-auto text-center">
                    <div id="evoPanelTickBox" class="tick_consolidate_box">
                      <img class="laptop-image-box" alt="laptop image">
                    </div>
                </div>
            </div>
            <div id="evoPanelFooter" class="panel-footer">
              <ngb-accordion #acc="ngbAccordion">
                <ngb-panel *ngFor="let content of evoContentArray; let i= index" id="panel-{{i}}">
                  <ng-template ngbPanelTitle>
                    <div class="d-flex">
                      <div id="accordianTickImage" class="d-flex acoordion_width">
                        <img class="tick-image" alt="tick image">
                        <div id="rtlTitle" [class.rtl_title]="isRTL" [innerHTML]="content.tr_title"></div>
                      </div>
                      <img [ngClass]="{'evo-up-image': isOpen(i), 'evo-down-image': !isOpen(i)}" alt="down image">
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <span [class.rtl_subtitle]="isRTL" [innerHTML]="content.tr_subtitle"></span>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>              
            </div>
          </div>
          <!-- Evo content tile panel ends -->
          <!-- 12thGen vpro second panel starts-->
          <div id="vproPanel" *ngIf="panel.panelType === panelTypeTableFormat && !is13thGenVPro">
            <div id="vproPanelTitle" class="subHeader text-center" [innerHTML]="panel.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'"></div>
            <section class="table-panel">
              <table class="table table-borderless compare_table compare_header_table" aria-hidden="true">
                <thead></thead>
                <tbody>
                    <tr>
                      <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[0]?.tr_table_heading ? panel?.table_head[0]?.tr_table_heading: '&nbsp;'" ></td>
                      <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[1]?.tr_table_heading ? panel?.table_head[1]?.tr_table_heading: '&nbsp;'" ></td>
                      <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[2]?.tr_table_heading ? panel?.table_head[2]?.tr_table_heading: '&nbsp;'" ></td>
                      <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[3]?.tr_table_heading ? panel?.table_head[3]?.tr_table_heading: '&nbsp;'" ></td>
                    </tr>
                </tbody>
              </table>
              <div id="vproCompareChart" class="compare_chart">
                <table class="table table-borderless compare_table table_col_gradients_vpro" aria-hidden="true">
                  <colgroup>
                    <col [ngClass]="gradientCol === 0 ? 'highlight_col': ''" />
                    <col [ngClass]="gradientCol === 1 ? 'highlight_col': ''" />
                    <col [ngClass]="gradientCol === 2 ? 'highlight_col': ''" />
                    <col [ngClass]="gradientCol === 3 ? 'highlight_col': ''" />

                </colgroup>
                <thead>              
                  <tr>
                    <th scope="col" *ngFor="let colData of panel.table_head">
                      <figure class="figure">
                        <img class="compare_chart_core_badge" src="{{colData.imgUrl}}" alt="processor badge"/>
                      </figure>
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <ng-container *ngFor="let tableData of panel.tabletr">
                  <tr>
                    <td class="bg-shadow" colspan="4" [innerHTML]="tableData.tr_row"></td>
                  </tr>
                  <tr>
                    <td>
                      <span *ngIf="tableData?.td1!= (true || false)"></span>
                      <span [ngClass]="tableData.td1=== true? 'check_mark': ''"></span>
                    </td>
                    <td>
                      <span *ngIf="tableData?.td2!= (true || false)" ></span>
                      <span [ngClass]="tableData.td2=== true? 'check_mark': ''"></span>
                    </td>
                    <td>
                      <span *ngIf="tableData?.td3!= (true || false)"></span>
                      <span [ngClass]="tableData.td3=== true? 'check_mark': ''"></span>
                    </td>
                    <td>
                      <span *ngIf="tableData?.td4!= (true || false)"></span>
                      <span [ngClass]="tableData.td4=== true? 'check_mark': ''"></span>
                    </td>
                  </tr>                                  
                  </ng-container>                                 
                </tbody>
              </table>        
            </div>
          </section>
        </div>
        <!-- 12thGen vpro second panel ends-->

        <!-- 13TH gEN vpro START -->

        <div id="vpro13thGenPanel" *ngIf="panel.panelType === panelTypeTableFormat && is13thGenVPro">
          <div id="vpro13thgenPanelTitle" class="subHeader text-center" [innerHTML]="panel.title" [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'"></div>
          <section class="table-panel">
            <table class="table table-borderless compare_table compare_header_table" aria-hidden="true">
              <thead></thead>
              <tbody class="vpro13thGenCurrentDevice">
                  <tr>
                    <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[0]?.tr_table_heading ? panel?.table_head[0]?.tr_table_heading: '&nbsp;'" ></td>
                    <td [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="panel?.table_head[1]?.tr_table_heading ? panel?.table_head[1]?.tr_table_heading: '&nbsp;'" ></td>
                  </tr>
              </tbody>
            </table>
            <div id="vproCompareChart" class="compare_chart vpro13thGenChart">
              <table class="table table-borderless compare_table table_col_gradients_vpro" aria-hidden="true">
                <colgroup>
                  <col [ngClass]="gradientCol === 0 ? 'highlight_col': ''" />
                  <col [ngClass]="gradientCol === 1 ? 'highlight_col': ''" />

              </colgroup>
              <thead>              
                <tr>
                  <th scope="col" *ngFor="let colData of panel.table_head">
                    <figure class="figure">
                      <img class="compare_chart_core_badge" src="{{colData.imgUrl}}" alt="processor badge"/>
                    </figure>
                  </th>
                </tr>
              </thead>
              <tbody >
                <ng-container *ngFor="let tableData of panel.tabletr">
                <tr>
                  <td class="bg-shadow" colspan="2" [innerHTML]="tableData.tr_row"></td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="tableData?.td1!= (true || false)"></span>
                    <span [ngClass]="tableData.td1=== true? 'check_mark': ''"></span>
                  </td>
                  <td>
                    <span *ngIf="tableData?.td2!= (true || false)" ></span>
                    <span [ngClass]="tableData.td2=== true? 'check_mark': ''"></span>
                  </td>
                </tr>                                  
                </ng-container>                                 
              </tbody>
            </table>        
          </div>
        </section>
      </div>

        <!-- 13TH GEN VPRO END -->

        <!-- oled -->
        <div id="oledDetailPage" class="oled-detail-page" [ngClass]="isStd12Content || isStd11Content ? 'std_true': ''" *ngIf="panel.panelType === 'oled_first_panel'">
          <section>
            <img  class="header_panel_section" alt="first_panel">
          </section>
          <section class="oled-padding">
            <div id="oledPanelHeading" class="ht_header" [class.intelOne-display]="setFontIntelDisplayOne" [innerHTML]="panel?.tr_header"></div>
            <div id="oleadPanelSubHeader" class="ht_subheader" [innerHTML]="panel?.tr_sub_header"></div>
            <div>
              <div id="oledDetailInfo" class="oled-container" >
                <img class="panel2-background" alt="second_panel">
                <div id="oledPanel2ImgText" class="panel2-img-text" [class.intelOne-display]="setFontIntelDisplayOne" [innerHTML]="panel?.tr_title1">
                </div>
              </div>
            </div>
          </section>
          <section class="oled-padding">
            <div id="oledHtHeader" class="ht_header" [class.intelOne-display]="setFontIntelDisplayOne"
              [innerHTML]="panel?.tr_header2"></div>
            <div id="oledHtSubheader" class="ht_subheader" [innerHTML]="panel?.tr_sub_header2"></div>
            <div id="oledPanel3Background" class="panel3-background"></div>
          </section>
          <section>
            <div id="oledContainer" class="oled-container">
              <img class="panel4-background" alt="fourth_panel">
              <div id="oledPanel4Header" class="ht_header pane4text pane4text-header" [class.intelOne-display]="setFontIntelDisplayOne"
                [innerHTML]="panel?.tr_header3"></div>
              <div id="oledPanel4Text" class="pane4text" [class.intelOne-display]="setFontIntelDisplayOne" [innerHTML]="panel?.tr_sub_header3">
              </div>
            </div>
          </section>
          <section>
            <div id="oledPanel5" class="oled-container">
              <img class="panel5-background" alt="fifth_panel">
              <div id="oledPanel5Content" class="panel_content" [class.intelOne-display]="setFontIntelDisplayOne">
                <div id="oledLeftPanel" class="panel_left_set" [class.rtl_leftpanel]="isRTL">
                  <div id="dcpLeftInfo" class="dcp-info">{{dci}}</div>
                  <div id="oledDisplayTypeLeft" class="display-type" [innerHTML]="panel?.tr_header4"></div>
                </div>
                <div id="oledRightPanel" class="panel_right_set" [class.rtl_rightpanel]="isRTL">
                  <div id="dcpRightInfo" class="dcp-info">{{dci}}</div>
                  <div id="oledDisplayTypeRight" class="display-type" [innerHTML]="panel?.tr_sub_header4"></div>
                </div>
              </div>
            </div>
          </section>
          <section class="footer_panel">
            <div id="oledFooterPanel" class="wide-panel">
              <img  class="oled-footer-background" alt="footer">
            </div>
          </section>
        </div>

        <!-- rebuild usage modal-->

        <div id="rebuildUsagePanelInfo" class="info-detailed" *ngIf="panel.panelType === 'rebuild-usage-panel'">
          <div id="rebuildUsagePanelFeature" class="feature-row">
            <div id="panelThumbnail" class="thumbnail-badge">
              <img class="img-fluid imgfit"  src="{{panel.badgeURL1}}" alt="Processor badge" />
            </div>                  
          </div>
          <p class="pretitle" [innerHTML]="panel.title"></p>
          <h5 [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" [innerHTML]="panel?.subTitle"></h5>
          <div id="rebuildUsagePanelDesc" class="panel-desc">
            <div id="ruPanelSubTitle1" [innerHTML]="panel?.subTitle1"></div>
            <ul> 
              <li *ngFor="let item of panel?.titleArray">
                <div id="ruPanelTitle" [innerHTML]="item?.tr_title"></div>
              </li>        
            </ul>
          </div>
        </div>

        <div *ngIf="pageName==='processor' && panel.panelType ===intelInsidePanel " >
        
          <div class="info-detailed">
            <div [innerHTML]="panel.tileData.tr_pretitle" [class.intelOne-display]="setFontIntelDisplayOne"  class="intelInsideTitle"></div>
            <div [innerHTML]="panel.tileData.tr_subtitle"  class="intelInsideDetailPreTitle"></div>
            <img class="img-fluid imgUrlIntelInside" src="{{panel.tileData.imageUrl}}" alt="intel inside">           
           </div>
         </div>

        <div id="unisonOtherPanel" class="unison-detail" *ngIf="panel.panelType === 'unison-panel'" [ngClass]="{'rtlPanel':isRTL}">
          <div *ngFor="let item of panel?.titleArray; let j=index ">
            <div id="unisonImage" class="unison-other-panel unison-img{{j}}" style="background: url({{item?.imgUrl}});">
              <div id="unisonOtherHeader" class="ht_header_panel-text unison-page{{j}}" [innerHTML]="item?.tr_title"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="video_panel" *ngIf="pageName==='processor' && isMTL && isMTL && !isMTLVpro">        
          <video class="mtlVideo" [muted]="true" poster="{{posterImage}}" preload="auto"  (click)="toggleVideo()" (ended)="showPlayIcon()" #videoPlayer>
            <source src="{{panel.videoUrl}}" type="video/mp4">
            <track *ngIf="panel.subtitleUrl" label="English" kind="subtitles" mode="showing" [src]="panel?.subtitleUrl" default>
          </video>
          <img class="play-icon2" #videoIcon (click)="toggleVideo()" alt="play-Icon"/>
      </div>
      
    </ng-container>        
  </div>

</div>