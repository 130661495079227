import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'app-unconnected-graphics',
  templateUrl: './unconnected-graphics.component.html',
  styleUrls: ['./unconnected-graphics.component.scss']
})
export class UnconnectedGraphicsComponent implements OnInit {
  graphics: string;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.graphics = GlobalConstants.GRAPHICS_TITLE;
  }

}
