<div id="headerInfo" [dir]="direction">
<div id="mobileHeaderMainLayout" class=mobile_header *ngIf="isMainLayout" [ngClass]="!deviceDetails.specTitle && !deviceDetails.deviceLogo ? 'rem_height': ''" [class.mobile-header-chromebook]="isChromeBook" [class.ai-ipos-header]="isAiIpos"> <!-- II-3382 -->
  <div id="allSpecsInfo" class="d-flex align-items-center">
    <span *ngIf="(router.url == '/all-specs')">
      <img [ngClass]="!isChromeBook ? 'left_arrow_icon' : 'left_arrow_icon_black'" alt="left arrow icon" (click)="goToOffline()"></span>
      <div id="deviceDetailsSpecTitle" *ngIf="deviceDetails.specTitle"> <!-- IJR-3529 -->
        <span class="flex"><img src="../../../assets/images/{{deviceDetails.deviceLogo}}.svg"
            alt="partner logo" class="image-margin logo-styles" onerror="this.style.display='none'"> <!--IJR-3611 fix added onerroe-->
          <span class="mt-2 intelOne-display"
            [ngClass]="isStandardEnhancement ? 'std12_text' : ''" [innerHTML]="deviceDetails?.specTitle"></span></span>
      </div>
  </div>
  <div *ngIf="isEvoMTL">
    <img  src="../../../assets/images/evo/14gen-evo.svg" alt="Intel-EVO" />
  </div>
  
</div>
<div id="chromebookMobileHeader" class="mobile_header" *ngIf="!isMainLayout && !isNewHeader" [class.mobile-header-chromebook]="isChromeBook"> <!--chromebook-->
  <div id="displayPageName" [class.page_name_nonintel]="isNonIntel" class="page_name"  [ngClass]="setFontIntelDisplayOne ? 'intelOne-display':'latin-bold'" [innerHTML]="displayPageName"></div> <!-- GLS-301308 -->
  <div id="mobileCloseIcon" class="mobile-closeicon">
    <img (click)="closeLayout()" src="../../../assets/images/close-button.svg" alt="close" /> <!-- IJR-845 -->
  </div>
</div>
<!-- IJR-1736 12th genGaming starts -->
<div id="gaming12Gen" class="d-flex new-mobile_header detailed-header" *ngIf="!isMainLayout && isNewHeader" [class.ai-ipos-header]="isAiIpos" >
  <div id="homeLogo" *ngIf = "homePagecount > 2" class="mobile-header-home-icon" [class.std12_home_icon_background]="isStandardEnhancement || is11GenCreator" [class.evo_home_icon_background]="isEvo" [class.std11_home_icon_background]="contentType==='S11'" (click)="goHome()">
      <span><img class="mobile-home-icon" alt="home logo"></span>
  </div>
  <div id="mobileBgHeader" class="mobile-bg-header">
      <div class="row align-item">
          <div id="headerFactTagName" [class.intelOne-display]="setFontIntelDisplayOne" [class.clear-font-bold]="!setFontIntelDisplayOne" class="header-fact-tag-names">
            <span [ngClass]="isStandardEnhancement ? 'std12_text' : ''" [innerHTML]="displayPageName"></span>
          </div>
          <div id="headerCloseIcon" class="header-cross-img">
              <img [ngClass]="isStandardEnhancement ? 'std12_close_btn': 'new-close-icon'" alt="close logo" (click)="goBack()">
          </div>
      </div>
  </div>
</div>
<!-- IJR-1736 12th genGaming ends -->
</div>
