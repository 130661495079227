import { Injectable } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class OledService {
  panel = [];

  constructor(
    private libraryV2DataService: LibraryV2DataService
  ) { }

    displaySamsungOled(): any {
      this.panel = [];
      const data = this.libraryV2DataService.getSamsungOled();
      const panel = {
        panelType: data[0].panelType,
        badgeUrl: data[0].badgeUrl,
        tr_header: data[1].tr_header,
        tr_sub_header: data[1].tr_sub_header,
        tr_title1: data[1].tr_title1,
        tr_header2: data[2].tr_header,
        tr_sub_header2: data[2].tr_sub_header,
        tr_header3: data[3].tr_header,
        tr_sub_header3: data[3].tr_sub_header,
        tr_header4: data[4].tr_header,
        tr_sub_header4: data[4].tr_sub_header
      }    
      this.panel.push(panel);
      return this.panel;
    }
}
