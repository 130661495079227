import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { LibraryChrombookV2DataService } from '../../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class AlwaysonsecurityService {
  panel = [];
  constructor(
    private libraryChrombookV2DataService: LibraryChrombookV2DataService
  ) { }

  displayAlwaysonsecurity(): any {
    this.panel = [];
    const data = this.libraryChrombookV2DataService.getFactTagsDetails(GlobalConstants.ALWAYSONSECURITY).details;
    const panel1 = {
      panelType: GlobalConstants.HEADERBADGEICONTOP,
      title: data.tr_title,
      subTitle: data.tr_subtitle,
    };
    this.panel.push(panel1);
    return this.panel;
  }
}
