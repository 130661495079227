// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  websocketApi: 'https://www.instorecontactless-dev.com/',
  connectApi: 'https://www.instorecontactless-dev.com/',
  resetApi: 'https://www.instorecontactless-dev.com/',
  gaPropertyId:'UA-177963797-2',
  gtmContainerId:'GTM-PJHMVJ9',
  apiBaseUrlAsset: 'https://www.iposcentral-dev.com/asset-library-dev-original',
  chatbotURL: 'https://ngwwgt2ehk6t7ka4wuyjjcew3a0tijlt.lambda-url.us-east-1.on.aws/',
  chatbotStudioURL: 'https://chatbot.iposcentral-uat.com/',
  portinoAPI: 'https://ipos.skuapi.portinos.com/v1/api/get_products?retailer=',
  qrCodeURL: 'https://www.iposcentral-dev.com/device/Touchless/'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
