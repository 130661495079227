import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.css']
})
export class QrScannerComponent implements OnInit {

  @Output() readonly goBacktoComparePage = new EventEmitter<string>();
  @Output() readonly isScannerClosed = new EventEmitter<boolean>();
  @ViewChild('scanner', { static: true }) scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResult: Result;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  constructor(
    // private modalCtrl: ModalController
    private location: LocationStrategy
  ) {
    // start IJR-2463
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
        this.isScannerClosed.emit(true);
    });
    // End IJR-2463
   }

  ngOnInit(): void {
    this.scannerInit();
  }

  scannerInit() {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasDevices = true;
      this.availableDevices = devices;
      this.currentDevice = null;
      // this.compareWith = this.compareWithFn;
      this.onDeviceSelectChange(devices[devices.length - 1].deviceId);
    });
    this.scanner.device = this.currentDevice;
    this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.scanComplete.subscribe((result: Result) => this.qrResult = result);
    this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    console.log(resultString);
    // if (resultString)
    this.goBacktoComparePage.emit(resultString);
    // this.modalCtrl.dismiss({ valor: resultString });
  }

  onDeviceSelectChange(selected: string) {
    for (const device of this.availableDevices) {
      if (device.deviceId === selected) {
        this.currentDevice = device;
      }
    }
  }

}
