import { Injectable } from '@angular/core';
import { ChromeBook } from '../../interfaces/library-v2-data.i';

declare const Library_ChromeBook_mobile: any;
declare const Library_ChromeBook_mobile_da: any;
declare const Library_ChromeBook_mobile_de: any;
declare const Library_ChromeBook_mobile_esMX: any;
declare const Library_ChromeBook_mobile_es: any;
declare const Library_ChromeBook_mobile_fi: any;
declare const Library_ChromeBook_mobile_fr: any;
declare const Library_ChromeBook_mobile_gls: any;
declare const Library_ChromeBook_mobile_is: any;
declare const Library_ChromeBook_mobile_it: any;
declare const Library_ChromeBook_mobile_ja: any;
declare const Library_ChromeBook_mobile_ko: any;
declare const Library_ChromeBook_mobile_nl: any;
declare const Library_ChromeBook_mobile_nb: any;
declare const Library_ChromeBook_mobile_sv: any;
declare const Library_ChromeBook_mobile_zhTW: any;

@Injectable({
  providedIn: 'root'
})
export class LibraryChrombookV2DataService {
  language: string;
  chromebookData: ChromeBook;
  chromebookLibraryName: ChromeBook;

  constructor() { }

  getLibraryV2Data(lang: string): any {
    this.language = lang; 
    const languageLibraryMapping = this.createLanguageLibraryDataMapping();
    if (languageLibraryMapping[this.language.toLowerCase()]) {
      this.chromebookLibraryName = languageLibraryMapping[this.language.toLowerCase()];
    } else {
      this.chromebookLibraryName = Library_ChromeBook_mobile;
    }
    // TODO: Handle errors from parsing Json below...
    this.chromebookData = JSON.parse(JSON.stringify(this.chromebookLibraryName)) as ChromeBook;
    return this.chromebookData;
  }

  getStaticText(text: string): any {
    return this.chromebookData.uiText[text];
  }

  getFactTagsDetails(contentType: string): any {
    return this.chromebookData[contentType];
  }

  getProcessorFactTagsDetails(cpuFamily: string, contentType: string, iposType: string, deviceType: string): any {
    return this.chromebookData[contentType]
      .find(item => item.contentType === cpuFamily).iposTypes
      .find(item => item.iposType === iposType).deviceTypes
      .find(item => item.deviceType === deviceType).details;
  }
  // IIT2-2223 start
  getExtendedBatteryLifeFactTagsDetails(contentType: string, iposType: string): any {
    return this.chromebookData[contentType].iposTypes.find(item => (item.iposType === iposType)).details;
  }
  // IIT2-2223 end
  createLanguageLibraryDataMapping() {
    return {
      'en-us': Library_ChromeBook_mobile,
      'da': Library_ChromeBook_mobile_da,
      'de': Library_ChromeBook_mobile_de,
      'es-mx': Library_ChromeBook_mobile_esMX,
      'es': Library_ChromeBook_mobile_es,
      'fi': Library_ChromeBook_mobile_fi,
      'fr': Library_ChromeBook_mobile_fr,
      'is': Library_ChromeBook_mobile_is,
      'it': Library_ChromeBook_mobile_it,
      'ja': Library_ChromeBook_mobile_ja,
      'ko': Library_ChromeBook_mobile_ko,
      'nl': Library_ChromeBook_mobile_nl,
      'nb': Library_ChromeBook_mobile_nb,
      'sv': Library_ChromeBook_mobile_sv,
      'zh-tw': Library_ChromeBook_mobile_zhTW,
      'gls': Library_ChromeBook_mobile_gls
    };
  }
}
