import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { FactTag } from '../../../core/interfaces/fact-tag.i';
import { QrCode } from '../../../core/interfaces/qr-input.i';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { NgbModal, NgbModalOptions,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Idle } from '@ng-idle/core';
import { DetailsService } from '../../details-page/services/details.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { QrConstants } from '../../../core/services/qr-input-service/qr.constants';
declare let gtag: Function;
import { LibraryChrombookV2DataService } from '../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';
import { Utils } from '../../../common/utility/utils';
import { PcSpecsService } from '../../details-page/services/pc-specs/pc-specs.service';
import { MessageService } from '../../../core/services/message-service/message.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';
import { WebSocketService } from 'src/app/core/services/websocket-service/websocket.service';
@Component({
  selector: 'app-mobile-content',
  templateUrl: './mobile-content.component.html',
  styleUrls: ['./mobile-content.component.scss']
})
export class MobileContentComponent implements OnInit {
  closeResult = ''; 
  private ngUnsubscribe = new Subject<boolean>();
  public qrList: QrCode;
  public factTagMenu: FactTag[] = [];
  reponseMsg: any;
  isNonIntel: boolean;
  evoVideoUrl: string;
  scanToCompareText: string;
  viewAllSpecsText: string;
  price: string;
  storeLogo: string;
  openModalPopup = false;
  legalContent: any;
  legalText: string;
  graphicsColor: boolean;
  checkIntelIris: boolean;
  nonIntelOs: string;
  batteryValue: string;
  checkOSType: string;
  isWinUWP: boolean;
  displayValue: string;
  isTouchScreen: boolean;
  isLinux: boolean;
  processorValue: string;
  osShortTesxt = 'os';
  private modalOption: NgbModalOptions = {};
  newFactTagsItem: any[];
  oddViewAllSpecs: boolean;
  direction: string;
  viewAllSpecsTitle: string;
  isprimaryCardDetected: boolean;
  isSecondaryGraphicsDetected: boolean;
  isIrisMaxPrimary: boolean;
  isIrisXEType: boolean;
  isChromeBook: boolean;
  endlessProgramsAndAppsValue: string;
  viewMoreTitle: string;
  disableProcessorsFacttag = false;
  isCreator = false;
  isGen12Or11 = false; // IJR-1734 12th genGaming or 11th genGaming
  setFontIntelDisplayOne: boolean;
  isStandardEnhancement = false;
  isStoreLogo = false;
  contentType: string;
  legalVersion: string;  
  buyNowText: string;
  tryagainText: string;
  buyNowSiteLink: string = '';
  buyNowErrText: string = '';
  scanBtnBorderColor = false;
  isStdMtl= false;
  isAiIpos = false;
  @ViewChild("buynowmodal") modalContent: TemplateRef<any>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  constructor(
    private qrService: QrCodeService,
    private router: Router,
    private libraryV2DataService: LibraryV2DataService,
    private modalService: NgbModal,
    private idle: Idle,
    private detailServices: DetailsService,
    private libraryChrombookV2DataService: LibraryChrombookV2DataService,
    private pcSpecsService: PcSpecsService,
    private messageService: MessageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private webSocketService: WebSocketService,
    private modal: NgbModal) { }

  ngOnInit(): void {
    // for testing
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // for testing
    const language = this.qrService.qrInput.Language;
    this.isStdMtl = GlobalConstants.STD_MTL_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge) || GlobalConstants.STD_LNL_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge);
    this.isAiIpos = this.qrService.isAiIpos();
    this.contentType = this.qrService.qrInput.ContentType;
    this.scanBtnBorderColor = this.contentType.includes(GlobalConstants.CREATOR_CONTENT) || this.contentType === GlobalConstants.STANDARDGEN11 || this.contentType === GlobalConstants.STANDARDGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 || this.contentType === GlobalConstants.STANDARDGEN14 || this.contentType.includes('C');
    // IJR-1734 12th genGaming
    if (this.contentType === GlobalConstants.GAMINGGEN12 || this.contentType === GlobalConstants.CREATORGEN11 || this.contentType === GlobalConstants.GAMINGGEN11 ||
      this.contentType === GlobalConstants.EVO || this.contentType === GlobalConstants.CREATORGEN12 ||this.contentType === GlobalConstants.CREATORGEN13 ||
      this.contentType === GlobalConstants.GAMINGGEN13 || this.contentType === GlobalConstants.GAMINGGEN14 ||
      ((this.contentType === GlobalConstants.STANDARDGEN11 || this.contentType === GlobalConstants.STANDARDGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 ||
        this.contentType === GlobalConstants.STANDARDGEN14) &&
        !GlobalConstants.legacyProcessorArray.includes(this.qrService.qrInput.ProcessorBadge))
    ) {
      this.isGen12Or11 = true;
    }
    this.isChromeBook = this.qrService.isChromeBook;
    if (this.qrService.qrInput.ContentType[0] === GlobalConstants.CREATOR_CONTENT) {
      this.isCreator = true;
    }
    if (this.isChromeBook) {
      this.disableProcessorsFacttag = !Utils.isChromebookProcessor(this.qrService.qrInput.ProcessorBadge, this.qrService.qrInput.ContentType);
    }
    this.setFont();
    this.qrService.storelogoimgdata.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      if (data && data !== '') {
        this.storeLogo = data;
        this.isStoreLogo = false;
      }
      else {
        this.isStoreLogo = true;
      }
    });
    this.direction = (language === 'ar-sa' || language === 'he') ? 'rtl' : 'ltr';
    this.qrService.getComparePageFactTags()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((factTags) => {
        if (factTags.length > 1) {
          this.scanToCompareText = this.libraryV2DataService.getStaticText(GlobalConstants.tr_backToScan);
        } else {
          this.scanToCompareText = this.libraryV2DataService.getStaticText(GlobalConstants.tr_scanToCompare);
        }
      });
    this.buyNowText = this.libraryV2DataService.getStaticText(GlobalConstants.BUYNOW);
    this.tryagainText = this.libraryV2DataService.getStaticText(GlobalConstants.TRYAGAIN);
    this.checkOSType = this.qrService?.qrInput?.Os;
    this.isTouchScreen = this.qrService?.qrInput?.TouchScreen === GlobalConstants.CHECK_Y;
    this.isLinux = this.qrService?.qrInput?.Os === GlobalConstants.ANA;
    this.viewAllSpecsText = this.libraryV2DataService.getStaticText(GlobalConstants.tr_viewallspecs);
    this.nonIntelOs = this.libraryV2DataService.getStaticText(GlobalConstants.tr_operatingSystem);
    this.batteryValue = this.libraryV2DataService.getStaticText(GlobalConstants.tr_batteryTitle);
    this.displayValue = this.libraryV2DataService.getStaticText(GlobalConstants.TR_DISPLAY_TITLE);
    this.processorValue = this.libraryV2DataService.getStaticText(GlobalConstants.tr_processorTitle);
    this.viewAllSpecsTitle = this.libraryV2DataService.getStaticText(GlobalConstants.tr_viewallspecsCamel);
    this.factTagMenu = this.qrService.getRemoteFactTag();
    // this.isChromeBook ? this.endlessProgramsAndAppsValue = this.libraryChrombookV2DataService.getStaticText('tr_endlessProgramsAndApps') : '';
    if (this.isChromeBook) {
      this.viewMoreTitle = this.libraryChrombookV2DataService.getStaticText('tr_viewMore');
      this.endlessProgramsAndAppsValue = this.libraryChrombookV2DataService.getStaticText('tr_endlessProgramsAndApps')
    }
    // IJR-98 MULTIPLE GRAPHICS CARD START
    this.factTagMenu.forEach((element, key) => {
      if (element.name === GlobalConstants.GRAPHICS_NAME) {
        this.isprimaryCardDetected = true;
      } else if (element.name === GlobalConstants.GRAPHICS_CARD) {
        this.isSecondaryGraphicsDetected = true;
      }
    });

    if (this.isprimaryCardDetected && this.isSecondaryGraphicsDetected) {
      let newFilterFactTagArray = this.factTagMenu.map((arrayElement) => Object.assign({}, arrayElement));
      newFilterFactTagArray.forEach((ele, key) => {
        if (ele.name === GlobalConstants.GRAPHICS_CARD && this.isprimaryCardDetected && this.isSecondaryGraphicsDetected) {
            ele.homeTitle = this.libraryV2DataService.getStaticText('tr_graphicTitleCamel');
        }
      });
      this.factTagMenu = newFilterFactTagArray;
    }

    const newFactTagArray = this.factTagMenu.map((arrayElement) => Object.assign({}, arrayElement));
    newFactTagArray.forEach((ele, key) => {
      // IJR-256 Graphics change
      if (ele.name === GlobalConstants.GRAPHICS_NAME) {
        if ((this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN12 || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN11 
          || this.qrService.qrInput.ContentType === GlobalConstants.EVO || this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN13 ||
          this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN14) && this.isprimaryCardDetected && this.isSecondaryGraphicsDetected) { // IIT2-4825
          ele.title = this.libraryV2DataService.getStaticText('tr_processorGraphicsCaps');
          ele.homeTitle = this.libraryV2DataService.getStaticText('tr_processorGraphicsSmall');
        } else {
          ele.title = this.libraryV2DataService.getStaticText('tr_graphicTitle');
        }
      }

      if (this.qrService.qrInput.ContentType !== GlobalConstants.STANDARDGEN12 && this.qrService.qrInput.ContentType !== GlobalConstants.STANDARDGEN11 && this.qrService.qrInput.ContentType !== GlobalConstants.EVO &&
        this.qrService.qrInput.ContentType !== GlobalConstants.STANDARDGEN13 && this.qrService.qrInput.ContentType !== GlobalConstants.STANDARDGEN14) { // IIT2-4825
        if (ele.name === GlobalConstants.GRAPHICS_NAME && this.isprimaryCardDetected && this.isSecondaryGraphicsDetected) {
          newFactTagArray.splice(key, 1);
        }
      }


    });
    this.factTagMenu = newFactTagArray;
    // IJR-98 MULTIPLE GRAPHICS CARD END
    this.isNonIntel = this.qrService.qrInput.ContentType === 'NI' ? true : false;
    let fagTagItem = [];
    const isIntelXe = this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE || this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX;
    const isGraphicsCardIris = this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE || this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX;
    if (isGraphicsCardIris) {
      this.isIrisMaxPrimary = true;
    }
    this.checkIntelIris = isIntelXe;
    if (isIntelXe || isGraphicsCardIris) { // UAT-503 Bug Fix Start
      if (this.isprimaryCardDetected && this.isSecondaryGraphicsDetected) {
        this.isIrisXEType = isGraphicsCardIris;
      } else {
        this.isIrisXEType = isIntelXe;
      }
    } // UAT-503 Bug Fix End
    this.factTagMenu.forEach((value, key) => {
      // if(fagTagItem.length < 5) {
      if (value.title === this.libraryV2DataService.getStaticText('tr_processorGraphicsCaps') && !value.image) { // IJR-98 Multiple Graphics
        //GLS-298593
        if (isIntelXe) {
          value.imageURL = GlobalConstants.GRAPHICS_INTEL_IRIS;
        } else {
          if (this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN12) {
            if (!this.qrService.isSingleGraphics && this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX &&
              this.qrService.qrInput.GraphicsBadge !== QrConstants.BADGE_GIN010) { //IJR-3031
              value.imageURL = GlobalConstants.GRAPHICS_INTEL_IRIS;
            } else {
              value.imageURL = GlobalConstants.GRAPHICS_12THGEN_NONIRIX_ICON;
            }
          } else {
            value.imageURL = GlobalConstants.GRAPHICS_NONIRIX_ICON;
          }
        }
        //GLS-298593
      }
      // IJR-98 GRAPHICS Card START 
      else if (value.title === this.libraryV2DataService.getStaticText('tr_graphicTitle') ||value.title === this.libraryV2DataService.getStaticText('tr_graphicsCardCaps') && !value.image) { // IJR-98 Multiple Graphics
        if (isGraphicsCardIris) {
          value.imageURL = GlobalConstants.GRAPHICS_INTEL_IRIS;
        } else {
          if (this.qrService.qrInput.ContentType === GlobalConstants.STANDARDGEN12) {
            value.imageURL = GlobalConstants.GRAPHICS_12THGEN_NONIRIX_ICON;
          } else {
            value.imageURL = GlobalConstants.GRAPHICS_NONIRIX_ICON;
          }
        }
      } // IJR-98 GRAPHICS Card End
      else if (value.title === this.libraryV2DataService.getStaticText('tr_memoryTitle')) {
        if (isIntelXe) {
          value.imageURL = GlobalConstants.MOMORY_XE;
        } else {
          value.imageURL = GlobalConstants.MOMORY_NON_XE;
        }
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_batteryTitle')) {
        value.imageURL = GlobalConstants.BATTERY_ICON;
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_displayTitle')) {
        if (isIntelXe) {
          value.imageURL = GlobalConstants.DISPLAY_XE;
        } else {
          value.imageURL = GlobalConstants.DISPLAY_NON_XE;
        }
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_operatingSystemTitle')) {
        value.imageURL = GlobalConstants.OS_ICON;
        this.isWinUWP = this.checkOSType === QrConstants.W10;
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_storageTitle')) {
        value.imageURL = GlobalConstants.STORAGE_ICON;
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_connectivityTitle')) {
        value.imageURL = GlobalConstants.CONNECTIVITY_ICON;
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_wifiTitle')) {
        value.imageURL = GlobalConstants.WIFI_ICON;
      } else if (value.title === this.libraryV2DataService.getStaticText('tr_IntelOptaneMemory')) {
        value.imageURL = GlobalConstants.MOMORY_NON_XE;
      }

      if (this.isNonIntel) {
        if (value.title !== this.libraryV2DataService.getStaticText('tr_batteryTitle') &&
          value.title !== this.libraryV2DataService.getStaticText('tr_IntelOptaneMemory') &&
          value.title !== this.libraryV2DataService.getStaticText('tr_connectivityTitle')
        ) {
          if (fagTagItem.length < 5) {
            if (this.isNonIntel && value.title !== this.libraryV2DataService.getStaticText('tr_processorTitle')) {
              fagTagItem.push(value);
            }
          }
        }
      } else {
        if (!this.isChromeBook) {
          if (fagTagItem.length < 5) {
            fagTagItem.push(value);
          }
        }
        else {
          if (fagTagItem.length < 6) {
            fagTagItem.push(value);
          }
        }
      }
      if (value.title === this.libraryV2DataService.getStaticText('tr_graphicTitle') ||
        value.title === this.libraryV2DataService.getStaticText('tr_processorGraphicsCaps')) { // IJR-98 Multiple Graphics Start
        if (this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE || this.qrService.qrInput.GraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX) {
          this.graphicsColor = true;
        } else {
          if (!this.qrService.qrInput.GraphicsBadge && this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX) {
            this.graphicsColor = true;
          }
        }
      } else { // UAT-503 Bug Fix Start
        if (value.name === GlobalConstants.GRAPHICS_CARD && (this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX ||
          this.qrService.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE)) {
          this.graphicsColor = true;
        }
      } // UAT-503 Bug Fix END
    });
    this.pcSpecsService.pcSpecsDetailsDetails(this.factTagMenu); // IJR-1734 12th genGaming 
    if (!this.isChromeBook) {
      if ((!this.graphicsColor && !this.isNonIntel) || this.isNonIntel) {
        fagTagItem.push({
          name: this.viewAllSpecsText, title: this.viewAllSpecsText, desc: this.viewAllSpecsText,
          homeTitle: this.viewAllSpecsTitle
        }); // home page case fix
      }
      let newArrayList = [];
      if (this.isNonIntel) {
        let oddList = [];
        let evenList = [];
        fagTagItem.forEach((item, key) => {
          if (key % 2 === 0) {
            evenList.push(item)
          } else {
            oddList.push(item)
          }
        });

        newArrayList = [...evenList, ...oddList];
        this.factTagMenu = newArrayList;
        this.newFactTagsItem = Object.assign([], newArrayList);
        this.oddViewAllSpecs = false;
        if (this.newFactTagsItem.length % 2 === 0) {
          this.oddViewAllSpecs = true;
        }
      } else {
        let intelAddList = [];
        let intelEvenList = [];
        fagTagItem.forEach((item, key) => {
          if (key % 2 === 0) {
            intelEvenList.push(item)
          } else {
            intelAddList.push(item)
          }
        });

        newArrayList = [...intelAddList, ...intelEvenList];
        if (this.graphicsColor && !this.isNonIntel) {
          newArrayList.push({
            name: this.viewAllSpecsText, title: this.viewAllSpecsText, desc: this.viewAllSpecsText,
            homeTitle: this.viewAllSpecsTitle
          });  // home page case fix
        }
        this.factTagMenu = newArrayList;
      }
    } else {
      const chromeList = [];
      fagTagItem = (fagTagItem.filter(value => value.name !== 'das'));
      fagTagItem.forEach((item, key) => {
        if (key === 0 || key === (fagTagItem.length - 1)) {
          chromeList.push(item);
        } else if (key === 1) {
          chromeList.push(item);
          if ((this.qrService.qrInput.IsConnected !== 'YN') || 
          (this.qrService.qrInput.IsConnected === 'YN' && fagTagItem.length <= 3)) {
            chromeList.push({
              name: this.viewAllSpecsText, title: this.viewAllSpecsText, desc: this.viewAllSpecsText,
              homeTitle: this.viewMoreTitle
            });
          }
        } else if (key === 2) {
          chromeList.push(item);
        }
      });
      if (this.qrService.qrInput.IsConnected === 'YN' && fagTagItem.length > 3 && fagTagItem.length % 2 === 0) {
        chromeList.push({
          name: this.viewAllSpecsText, title: this.viewAllSpecsText, desc: this.viewAllSpecsText,
          homeTitle: this.viewMoreTitle
        });
      }
      this.factTagMenu = this.chromebookFacttagIcon(chromeList); // IIT2-2221
}
    this.price = this.qrService.qrInput.Price;
    this.legalVersion = '&nbsp;' + this.qrService.qrInput?.LegalVersion; // IIT2-6659
    if (!this.qrService.isChromeBook) {
      this.legalContent = this.libraryV2DataService.getHeaders('legal').tr_paragraph_array;
      this.legalContent = this.legalContent.map(ele => ele.replace('®', '<sup>®</sup>')) // II-3287 - Legal text issue
      this.legalContent = this.legalContent.map(ele => ele.replace('[ZhCn Only Legal Text General]', ''));  // zh-cn legalcontent
      this.legalContent = this.legalContent.map(ele => ele.replace('[ZhCn Only Legal Text Point12]', ''));
      this.legalContent = this.legalContent.map(ele => ele.replace('[ZhCn Only Legal Text Point13]', ''));
      this.legalText = this.libraryV2DataService.getHeaders('legal').tr_specTitle;
      
    } else {
      this.legalContent = this.libraryChrombookV2DataService.getFactTagsDetails('legalText').tr_paragraph_array;
      this.legalText = this.libraryChrombookV2DataService.getFactTagsDetails('legalText').tr_specTitle;
    }
    this.idle.onIdleStart.subscribe(() => {
      this.modalService.dismissAll()
    })

    if (this.isNonIntel) {
      let nonIntelFactTag = [];
      const itemList = [
        this.libraryV2DataService.getStaticText('tr_graphicTitle'),
        this.libraryV2DataService.getStaticText('tr_displayTitle'),
        this.libraryV2DataService.getStaticText('tr_storageTitle'),
        this.libraryV2DataService.getStaticText('tr_memoryTitle'),
        this.libraryV2DataService.getStaticText('tr_operatingSystemTitle'),
        this.libraryV2DataService.getStaticText('tr_wifiTitle'),
      ];
      const newFactTagList = this.qrService.getRemoteFactTag();
      itemList.forEach(element => {
        newFactTagList.forEach(element1 => {
          if (element === element1.title) {
            if (nonIntelFactTag.length < 5) {
              nonIntelFactTag.push(element1);
            }
          }
        });
      });
      this.factTagMenu = nonIntelFactTag;
    }
    // IJR -1756
    if (this.contentType === GlobalConstants.GAMINGGEN12 || this.contentType === GlobalConstants.STANDARDGEN12 ||
      this.contentType === GlobalConstants.CREATORGEN11 || this.contentType === GlobalConstants.EVO ||
      this.contentType === GlobalConstants.GAMINGGEN11 || this.contentType === GlobalConstants.STANDARDGEN11 ||
      this.contentType === GlobalConstants.CREATORGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 ||
      this.contentType === GlobalConstants.GAMINGGEN13 || this.contentType === GlobalConstants.GAMINGGEN14 ||
      this.contentType === GlobalConstants.STANDARDGEN14) {
      this.messageService.setHomePageCount(1);
    }
    this.setFontIntelDisplayOne = this.qrService.setenusLang();
    if (this.contentType === GlobalConstants.STANDARDGEN12 ||
      this.contentType === GlobalConstants.STANDARDGEN13 ||
      this.contentType === GlobalConstants.CREATORGEN12||
      this.contentType=== GlobalConstants.CREATORGEN13 ||
      this.contentType=== GlobalConstants.STANDARDGEN14) {
      this.isStandardEnhancement = true;
    }
    this.getbuyNowAPIData();
    this.scanBtnBorderColor = !this.buyNowSiteLink || (this.contentType.includes(GlobalConstants.CREATOR_CONTENT) && this.contentType !== GlobalConstants?.CREATORGEN11 && this.contentType !== GlobalConstants?.CREATORGEN10) || this.contentType === GlobalConstants.STANDARDGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 || this.contentType.includes('C');
  }

  open(legalModal) {
    this.openModalPopup = true;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.centered = true;
    this.modalOption.windowClass = 'legal-text-popup'
    this.modalService.open(legalModal, this.modalOption);
  }
  close() {
    this.openModalPopup = false;
    this.modalService.dismissAll()
  }

  gotoViewAllSpecs(): void {
    let allSpecsText = this.isChromeBook ? GlobalConstants.VIEW_MORE_TEXT: GlobalConstants.VIEW_ALL_SPECS_TEXT;
    this.router.navigate(['all-specs']);
    this.googleAnalyticsService.sendGtag(GlobalConstants.VIEW_ALL_SPECS_TEXT, { event_category: allSpecsText, event_label: '', 'view_all_specs': 1 });
    this.googleAnalyticsService.factTagAnalytics(allSpecsText, GlobalConstants.FACTTAG);  // II-3852
    
  }

  gotoDetailPage(factTagValue, index) {
    if(this.viewAllSpecsText === factTagValue) {
      this.gotoViewAllSpecs();
    } else {
      if ((!(this.isNonIntel && ((factTagValue === GlobalConstants.DISPLAY_NAME && this.isLinux) || factTagValue === GlobalConstants.BATTERY_NAME || (factTagValue === GlobalConstants.DISPLAY_NAME && !this.isTouchScreen)) ))) {
        this.detailServices.setCurrentPage('mob-content');
        this.googleAnalyticsService.factTagAnalytics(factTagValue,GlobalConstants.FACTTAG);   // II-3852
        this.router.navigateByUrl('/specs/'+factTagValue);
      }
    }
  }

  gotoComparePage() {
    this.router.navigate(['compare']);
    this.googleAnalyticsService.sendGtag( GlobalConstants.COMPARE_DEVICE, { event_category: GlobalConstants.COMPARE_DEVICE, event_label: GlobalConstants.COMPARE_DEVICE, 'compare_device': 1 });
  }

  // GLS - 297009 Start
  setFont() {
    const currentLanguage = this.qrService.qrInput.Language;
    this.direction = (currentLanguage === 'ar-sa' || currentLanguage === 'he') ? 'rtl' : 'ltr';
    switch (currentLanguage) {
      case 'ar-sa':
        return 'arabic-font';
      case 'cs':
        return 'latin-font';
      case 'da':
        return 'latin-font';
      case 'de':
        return 'latin-font';
      case 'el':
        return 'greek-font';
      case 'es':
        return 'latin-font';
      case 'es-mx':
        return 'latin-font';
      case 'fi':
        return 'latin-font';
      case 'fr':
        return 'latin-font';
      case 'he':
        return 'hebrew-font';
      case 'hu':
        return 'latin-font';
      case 'id':
        return 'latin-font';
      case 'it':
        return 'latin-font';
      case 'ja':
        return 'japanese-font';
      case 'ko':
        return 'korean-font';
      case 'nl':
        return 'latin-font';
      case 'nb':
        return 'latin-font';
      case 'pl':
        return 'latin-font';
      default:
        return this.setFont1(currentLanguage);
    }
  }
  setFont1(language) {
    switch (language) {
      case 'pt':
        return 'latin-font';
      case 'pt-br':
        return 'latin-font';
      case 'ro':
        return 'latin-font';
      case 'ru':
        return 'cyrl-font';
      case 'sk':
        return 'latin-font';
      case 'sv':
        return 'latin-font';
      case 'th':
        return 'thai-font';
      case 'tr':
        return 'latin-font';
      case 'uk':
        return 'cyrl-font';
      case 'vi':
        return 'vietnamese-font';
      case 'zh-cn':
        return 'hans-font';
      case 'zh-tw':
        return 'hant-font';
      default:
        return 'default-font';
    }
  }
  // GLS 297009 - END
    // IIT2-2221 start
  chromebookFacttagIcon(factTagmenu) {
    factTagmenu.forEach(data => {
      if (data.name === GlobalConstants.RETHINKSTORAGE) {
        data.imageURL = GlobalConstants.STORAGE_ICON
      } else if (data.name === GlobalConstants.ALWAYSONSECURITY) {
        data.imageURL = GlobalConstants.ALWAYSONSECURITY_ICON
      } else if (data.name === GlobalConstants.ENDLESSPROGRAMSANDAPPS) {
        data.imageURL = GlobalConstants.ENDLESSPROGRAMSANDAPPS_ICON
      } else if(data.name === GlobalConstants.EXTENDEDBATTERYLIFE) {
        data.imageURL = GlobalConstants.BATTERY_ICON;
      }
    });
    return factTagmenu;
  }
  // IIT2-2221 end
  getbuyNowAPIData() {
    let buyNowDataId;
    buyNowDataId = this.messageService?.getbuyNowId?.pipe(takeUntil(this.ngUnsubscribe))?.subscribe(
      buyNowData  => {
       this.buyNowSiteLink = buyNowData;
       this.buyNowErrText = '';
       this.close();
       this.scanBtnBorderColor = !this.buyNowSiteLink || (this.contentType.includes(GlobalConstants.CREATOR_CONTENT) && this.contentType !== GlobalConstants?.CREATORGEN10 && this.contentType !== GlobalConstants?.CREATORGEN11 ) || this.contentType === GlobalConstants.STANDARDGEN12 || this.contentType === GlobalConstants.STANDARDGEN13 || this.contentType.includes('C');
     });
    let buyNowErrId;
    if(this.buyNowSiteLink=='') {
      buyNowErrId = this.messageService?.getBuyNowApiErr?.pipe(takeUntil(this.ngUnsubscribe))?.subscribe(
        buynowApiErr => {
            this.buyNowErrText = buynowApiErr;
        });
    }   
  }   

  goToProductURL(){
    window.open(this.buyNowSiteLink, GlobalConstants.OPEN_NEW_TAB);
  }

  tryagain() {
      this.webSocketService.getbuyNowAPIData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    if(this.buyNowSiteLink=='' && this.buyNowErrText!='') {
      this.modalService.open(this.modalContent, { windowClass: GlobalConstants.BUYNOWTEXTPOPUP });
    }    
  }
  video() {
    this.videoplayer?.nativeElement.play();
  }
}
