import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgIdleModule } from '@ng-idle/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FactTagComponent } from './common/components/fact-tag/fact-tag.component';
import { HeaderComponent } from './modules/header/header.component';
import { FooterComponent } from './modules/footer/footer.component';
import { HomeComponent } from './modules/home/home.component';
import { RemoteComponent } from './modules/remote/remote.component';
import { MobileComponent } from './modules/mobile/mobile.component';
import { HttpMethodsService } from '../app/common/utility/http-methods.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { ViewAllSpecsComponent } from './modules/mobile/view-all-specs/view-all-specs.component';
import { MobileContentComponent } from './modules/mobile/mobile-content/mobile-content.component';
import { TutorialComponent } from './common/components/tutorial/tutorial.component';
import { NavBarComponent } from './common/components/information-icon/nav-bar/nav-bar.component';
import { CardRowComponent } from './common/components/information-icon/card-row/card-row.component';
import { FactTagsComponent } from './common/components/information-icon/fact-tags/fact-tags.component';
import { UnconnectedProcessorComponent } from './modules/mobile/unconnected-processor/unconnected-processor.component';
import { UnconnectedGraphicsComponent } from './modules/mobile/unconnected-graphics/unconnected-graphics.component';
import { UnconnectedOsComponent } from './modules/mobile/unconnected-os/unconnected-os.component';
import { OfflineTutorialComponent } from './common/components/offline-tutorial/offline-tutorial.component';
import { MobileHomeComponent } from './common/components/information-icon/mobile-home/mobile-home.component';
import { MobileCardsComponent } from './common/components/information-icon/mobile-cards/mobile-cards.component';
import { UnconnectedMemoryComponent } from './modules/mobile/unconnected-memory/unconnected-memory.component';
import { UnconnectedStorageComponent } from './modules/mobile/unconnected-storage/unconnected-storage.component';
import { CompareDevicesComponent } from './modules/mobile/compare-devices/compare-devices.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrScannerComponent } from './common/components/qr-scanner/qr-scanner.component';
import { DetailsPanelComponent } from './modules/details-page/details-panel/details-panel.component';
import { PanelComponent } from './common/components/panel/panel.component';
import { CompareErrorsComponent } from './common/components/compare-errors/compare-errors.component';
import { GoogleAnalyticsService } from './core/services/google-analytics-service/google-analytics.service';
import { DatePipe } from '@angular/common';
//chatbot imports starts
import { ToastrModule } from 'ngx-toastr';

//chatbot import ends
import { FormsModule } from '@angular/forms';
import { TileContentComponent } from './modules/mobile/tile-content/tile-content.component';
import { PcSpecsComponent } from './modules/mobile/pc-specs/pc-specs.component';
import { NewPenelComponent } from './common/components/new-penel/new-penel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WrapperService } from './chatbot/services/wrapper.service';
import { NavigationBarComponent } from './modules/mobile/navigation-bar/navigation-bar.component';
import { TrendingComponent } from './modules/mobile/trending/trending.component';

@NgModule({
  declarations: [
    AppComponent,
    FactTagComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MobileComponent,
    RemoteComponent,
    ViewAllSpecsComponent,
    MobileContentComponent,
    TutorialComponent,
    NavBarComponent,
    CardRowComponent,
    FactTagsComponent,
    UnconnectedProcessorComponent,
    UnconnectedGraphicsComponent,
    UnconnectedOsComponent,
    OfflineTutorialComponent,
    MobileHomeComponent,
    MobileCardsComponent,
    UnconnectedMemoryComponent,
    UnconnectedStorageComponent,
    CompareDevicesComponent,
    QrScannerComponent,
    DetailsPanelComponent,
    PanelComponent,
    CompareErrorsComponent,
    TileContentComponent,
    PcSpecsComponent,
    NewPenelComponent,
    NavigationBarComponent,
    TrendingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgIdleModule.forRoot(),
    HttpClientModule,
    NgbModule,
    ZXingScannerModule,
    SlickCarouselModule,
    FormsModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpMethodsService, GoogleAnalyticsService,{ provide: 'googleTagManagerId', useValue: environment.gtmContainerId }, DatePipe,WrapperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
