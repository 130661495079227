import { Component, OnInit, OnDestroy, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { QrCodeService } from '../app/core/services/qr-input-service/qr-input.service';
import { IdleService } from '../app/core/services/idle-service/idle.service';
import { MessageService } from '../app/core/services/message-service/message.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LibraryV2DataService } from './core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from './common/constants/global.constant';
import { environment } from 'src/environments/environment';
declare let gtag: Function;
import { WrapperService } from './chatbot/services/wrapper.service';
import { HttpMethodsService } from './common/utility/http-methods.service';
@Component({
  selector: 'ipos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'IPOS Remote and Mobile Experience';
  private ngUnsubscribe = new Subject<boolean>();
  notification: string;
  checkIfWelcomeScreen: boolean;
  passHeaderData: string;
  language: string;
  direction: string;
  sound: any;
  isVisitedDetailedHome: boolean;
  soundLoop: any;
  isChatBotEnabled = false;
  isPcSpecs = false;
  sendPreviousPageData: string;
  isShowheader = false;
  qrResult: any;
  isShowLoader: boolean = false;
  isMTL= false;
  isMTLVpro = false;
  isAiIpos = false;
  storeLogo : string;
  imgdata : any; 

  constructor(
    public router: Router,
    private activeroute: ActivatedRoute,
    private qrInputService: QrCodeService,
    private msgService: MessageService,
    private idleService: IdleService,
    private gtmService: GoogleTagManagerService,
    private libraryServiceData: LibraryV2DataService,
    private wrapperService: WrapperService,
    private changeDetector: ChangeDetectorRef,
    private httpService: HttpMethodsService,
  ) { 
    router.events.subscribe((val) => {​​
      if (val instanceof NavigationStart) {​​
        if((window.location.hostname === 'localhost' || window.location.hostname === 'www.instorecontactless-dev.com'
        || window.location.hostname === 'dev2.instorecontactless-dev.com') && 
        (this.qrInputService.qrInput.ContentType === GlobalConstants.GAMINGGEN10 || this.qrInputService.qrInput.ContentType === GlobalConstants.GAMINGGEN11 ||
          this.qrInputService.qrInput.ContentType === GlobalConstants.GAMINGGEN9)) { // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
          // this.playBackgroundSound(val.url); //commented soniq changes for prod release
        }
        if(this.qrInputService.getQrData().IsConnected !== 'y') {​​
          this.passHeaderData = libraryServiceData.getHeaders('mobile').tr_specTitle
        }​​ 
        else {​​
          this.passHeaderData = libraryServiceData.getHeaders('remote').tr_specTitle;
          if(val.url === '/mob-content'){
            this.passHeaderData = libraryServiceData.getHeaders('mobile').tr_specTitle
          }
        }​​
      }​​
    }​​);
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        gtag('config', environment.gaPropertyId, 
              {
                'page_path': val.urlAfterRedirects
              }
             );
     }
})
  }

  ngOnInit(): void {
    this.gtmService.addGtmToDom();
    this.loadPage();
    this.loadChatBot(); // IIC-37
    this.setIdle();
    this.msgService.$messageSync
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(msg => {
        this.notification = msg;
      });
    // Header not to be shown on Welcome Screens
    this.checkIfWelcomeScreen = (window.location.pathname === '/home' ||
    window.location.pathname === '/mobile' ||
    window.location.pathname === '/') ? true : false;
  }

  setFont() {
    //this.language = this.appConfig.language;
  this.language = this.qrInputService.qrInput.Language;
  this.direction = (this.language === 'ar-sa' || this.language === 'he') ? 'rtl' : 'ltr';
    switch (this.language) {
      case 'ar-sa':
        return 'arabic-font';
      case 'cs':
        return 'latin-font';
      case 'da':
        return 'latin-font';
      case 'de':
        return 'latin-font';
      case 'el':
        return 'greek-font';
      case 'es':
        return 'latin-font';
      case 'es-mx':
        return 'latin-font';
      case 'fi':
        return 'latin-font';
      case 'fr':
        return 'latin-font';
      case 'he':
        return 'hebrew-font';
      case 'hu':
        return 'latin-font';
      case 'id':
        return 'latin-font';
      case 'it':
        return 'latin-font';
      case 'ja':
        return 'japanese-font';
      case 'ko':
        return 'korean-font';
      case 'nl':
        return 'latin-font';
      case 'nb':
        return 'latin-font';
      case 'pl':
        return 'latin-font';
      default:
        return this.setFont1(this.language);
    }
  }
  setFont1(language) {
    switch (language) {
      case 'pt':
        return 'latin-font';
      case 'pt-br':
        return 'latin-font';
      case 'ro':
        return 'latin-font';
      case 'ru':
        return 'cyrl-font';
      case 'sk':
        return 'latin-font';
      case 'sv':
        return 'latin-font';
      case 'th':
        return 'thai-font';
      case 'tr':
        return 'latin-font';
      case 'uk':
        return 'cyrl-font';
      case 'vi':
        return 'vietnamese-font';
      case 'zh-cn':
        return 'hans-font';
      case 'zh-tw':
        return 'hant-font';
      default:
        return 'default-font';
    }
  }


  ngAfterViewInit(): void { }

  loadPage(): void {
    this.activeroute.queryParams.subscribe(params => {
      this.setQrInput(params);
    });
  }

  setQrInput(params: Params): void {
      if(params['qr']) {
        const deviceid = params['qr'];
        const qrURL = environment.qrCodeURL + deviceid;
        this.isShowLoader = true;
        this.qrResult = this.httpService?.get(qrURL)?.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.isShowLoader = false;
          const newURL = data?.qrCodeUrl;
          params = {
            'rt': newURL.split('rt=')[1]
          } as Params;
          const qrValues = params['rt'].split('~');
          document.querySelector("html").setAttribute("lang", qrValues[3]);
          this.qrInputService.setQrDataFromParams(params, GlobalConstants.INITIAL_SCAN);
          this.isShowheader = true;
        }, (error) => {             
        });
      } else {
        if(params['rt']) {
          const qrValues = params['rt'].split('~');
          document.querySelector("html").setAttribute("lang", qrValues[3]);
          this.qrInputService.setQrDataFromParams(params, GlobalConstants.INITIAL_SCAN);
          this.isShowheader = true;
        }
       
      }
      this.isAiIpos = GlobalConstants.EVO_MTL_BADGES?.includes(this.qrInputService.qrInput.ProcessorBadge) || GlobalConstants.STD_MTL_BADGES?.includes(this.qrInputService.qrInput.ProcessorBadge) || GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrInputService.qrInput.ProcessorBadge) || GlobalConstants.LUNAR_BADGES?.includes(this.qrInputService.qrInput.ProcessorBadge);   
      this.storeLogo = this.qrInputService.qrInput.StoreLogo;
    this.imgdata = async (URLa) => {
      var res = await fetch(URLa);
      var blob = await res.blob();

      const result = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          resolve(reader.result);
        }, false);

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })

      return result
    };
    if (this.storeLogo) {
      this.imgdata(this.storeLogo).then(res => {
        this.qrInputService.checkStoreLogo(res)
      })
    }
    else {
      this.qrInputService.checkStoreLogo('');
    }
      
  }
  setIdle(): void {
    this.idleService.onUserIdle();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    // event.preventDefault();
    this.qrInputService.resetConnection();
    return "false";
  }


  getDetailPageData(data) {
    this.isVisitedDetailedHome = data === 'visited';
  }
  // IIC-37
  loadChatBot() {
    this.activeroute.queryParams.subscribe(params => {
      if (params['rt']) {
        let values = params['rt'].split('~');
        if (values[62].toLowerCase().includes('s') || values[62].toLowerCase().includes('y') || values[62].toLowerCase().includes('a')) {
          this.isChatBotEnabled = true;
        }
        if (values[4] === GlobalConstants.STANDARDGEN12 || values[4] === GlobalConstants.STANDARDGEN11 ||
          values[4] === GlobalConstants.CREATORGEN11 || values[4] === GlobalConstants.EVO ||
          values[4] === GlobalConstants.GAMINGGEN11 || values[4] === GlobalConstants.GAMINGGEN12 ||
          values[4] === GlobalConstants.CREATORGEN12 || values[4] === GlobalConstants.STANDARDGEN13 ||
          values[4] === GlobalConstants.GAMINGGEN13||values[4] === GlobalConstants.CREATORGEN13 ||
          values[4] === GlobalConstants.GAMINGGEN14 || values[4] === GlobalConstants.STANDARDGEN14) {
          this.isPcSpecs = true;
        }
        if((GlobalConstants.STD_MTL_BADGES.includes(values[5]) || GlobalConstants.EVO_MTL_BADGES.includes(values[5])) && values[5]!== GlobalConstants.INT162){
          this.isMTL=true;
        }
        this.isMTLVpro = (GlobalConstants.STD_MTL_BADGES?.includes(values[5])) && (values[76] === GlobalConstants.CHECK_Y);
      }
    });
  }

  passPreviousPageData(event:any) {
    this.sendPreviousPageData = event;
  }
  // IIT2-4422 start
  detailscan(event: any) {
    this.isChatBotEnabled = !event.chatbot;
    this.isPcSpecs = event.pcspecs;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  // IIT2-4422 end


}
