export class DisplayConstants {
    static readonly SCREEN_4K = '4K';
    static readonly SCREEN_DCI_4K = 'DCI_4K';
    static readonly SCREEN_UHD_4K = '4K_UHD';
    static readonly SCREEN_HD = 'HD';
    static readonly SCREEN_FHD = 'FHD';
    static readonly SCREEN_STANDARDS_ALL = ['4K', 'DCI_4K', '4K_UHD', 'HD', 'FHD'];
    static readonly CONTENT_TYPE_DISPLAY = 'DISPLAY';
    static readonly CATEGORY_DISPLAY = 'display';
    static readonly SCREEN_DIAGONAL = '[screenDiagonal]';
    static readonly SCREEN_STANDARD_PLACEHOLDER = '[screenResolutionType]';
    static readonly SCREEN_RESOLUTION_X = '[screenResolutionX]';
    static readonly SCREEN_RESOLUTION_Y = '[screenResolutionY]';
    static readonly SCREEN_RESOLUTION_PRODUCT = '[screenResolution]';
    static readonly SCREEN_RESOLUTION_MILLION = 1000000;
    static readonly SCREEN_HEIGHT = '[screenHeight]';
    static readonly SCREEN_WIDTH = '[screenWidth]';
    static readonly NON_INTEL_CONTENT_TYPE = 'NON_INTEL';
    static readonly NON_INTEL_CODE = 'NI';
    static readonly YY = '[YY]';
    static readonly TOUCHSCREEN_YES = 'Y';
    static readonly TOUCHSCREEN_NO = 'N';
    static readonly TITLE_ARRAY_0 = 0;
    static readonly TITLE_ARRAY_1 = 1;
    static readonly TITLE_ARRAY_2 = 2;
}
