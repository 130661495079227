<ngb-carousel #ngcarousel [showNavigationArrows]="false" (slide)="slideActivate($event)" [interval]="0">
    <ng-template ngbSlide>
        <div id="mobileHome" class=container1_off>
          <app-mobile-home></app-mobile-home>
          <div id="mobileCardContent" class="content unconnected content_padding">
            <div id="unconnectedProcessorCard" class="card align_items_index unconnected_processor_card">
              <div id="tootlTipText1" class=tooltiptextu1>{{tooltipText1}}</div>
              <div id="processorHeading" class="mt-1 font_processor">{{processorHeading}}</div>
              <div id="specValueText" class="mt-1">{{specValueText}}</div>
            </div>
            <app-mobile-cards></app-mobile-cards>
            <div id="" class="row mr-1">
              <div id="viewAllSpecsCard" class="col-6 pr-0">
                <button type="button" class="btn btn-primary span_width">
                  <div id="viewAllSpecsText" class=align-center>
                    <span class=mr-2><img class="view_specs_icon_url" alt = "view all specs icon"></span>
                    <span class="view_all_specs">{{viewallspecsText}}</span>
                  </div>
                </button>
              </div>
              <div id="compareCard" class="col-6 pr-0">
                <button type="button" class="btn btn-primary span_width">
                  <div id="compareText" class=align-center>
                    <span class=mr-2><img class="compare_icon_url" alt = "compare icon"></span>
                    <span class="compare">{{compareText}}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
         
          <div id="btnSection" class="overlay">
            <div id="skipBtn" class="top_content" (click)="back()">{{skipBtn}}</div>
            <div id="btnSection" class="bottom_section">
              <div id="prevBtn" class="prev">{{prevBtn}}</div>
              <div id="middleThreeDots" class="middle">
                <span class="dot2 dot3"></span>
                <span class="dot2"></span>
                <span class="dot2"></span>
              </div>
              <div id="nextBtn" (click)="goToNext()">{{nextBtn}}</div>
            </div>
          </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div id="slideContainer1" class=container1_off>
          <app-mobile-home></app-mobile-home>
          <div id="unconnectedContent"  class="content unconnected content_padding">
            <div id="unconnectedProcCard" class= "card unconnected_processor_card">
              <div id="processorHeading" class="mt-1 font_processor">{{processorHeading}}</div>
              <div id="specValueText" class="mt-1">{{specValueText}}</div>
        </div>
        <app-mobile-cards></app-mobile-cards>
            <div id="mobileCardDetail" class="row mr-1">
              <div id="viewAllSpecsSection" class="col-6 pr-0 dot_index">
                <div id="tooltipTextU2" class="tooltiptextu2">{{tooltipText2}}</div>
                <button type="button" class="btn btn-primary span_width">
                  <div id="viewAllSpecsInfo" class=align-center>
                    <span class=mr-2><img class="view_specs_icon_url" alt = "view all specs icon"></span>
                    <span class="view_all_specs">{{viewallspecsText}}</span>
                  </div>
                </button>
              </div>
              <div id="compareBtnSection" class="col-6 pr-0">
                <button type="button" class="btn btn-primary span_width">
                  <div id="compareIcon" class=align-center>
                    <span class=mr-2><img class="compare_icon_url" alt = "compare icon"></span>
                    <span class="compare">{{compareText}}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div id="mobileCardOverlay" class="overlay">
            <div id="skipBtn" class="top_content" (click)="back()">{{skipBtn}}</div>
            <div id="overlayBottomSection" class="bottom_section">
              <div id="prevBtn" (click)="getToPrev()">{{prevBtn}}</div>
              <div id="middleThreeDots" class="middle">
                <span class="dot2"></span>
                <span class="dot2 dot3"></span>
                <span class="dot2"></span>
              </div>
              <div id="nextBtn" (click)="goToNext()">{{nextBtn}}</div>
            </div>
          </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div id="mobileHomeContainer" class=container1_off>
          <app-mobile-home></app-mobile-home>
          <div id="mobileHomeContent" class="content unconnected content_padding">
            <div id="mobileHomeProcCard" class= "card unconnected_processor_card">
              <div id="mobileHomeProcHeading" class="mt-1 font_processor">{{processorHeading}}</div>
              <div id="mobileHomeSpecValue" class="mt-1 font_processor">{{specValueText}}</div>
         </div>
         <app-mobile-cards></app-mobile-cards>
            <div id="mobileCard" class="row mr-1">
              <div id="viewAllSpecsBtn" class="col-6 pr-0">
                <button type="button" class="btn btn-primary span_width">
                  <div id="viewAllSpecsText" class=align-center>
                    <span class=mr-2><img class="view_specs_icon_url" alt = "view all specs icon"></span>
                    <span class="view_all_specs">{{viewallspecsText}}</span>
                  </div>
                </button>
              </div>
              <div  class="col-6 pr-0 dot_index">
                <div id="tooltipTextU3" class="tooltiptextu3">{{tooltipText3}}</div>
                <button type="button" class="btn btn-primary span_width">
                  <div id="compareIcon" class=align-center>
                    <span class=mr-2><img class="compare_icon_url" alt = "compare icon"></span>
                    <span class="compare">{{compareText}}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div id="tutorialOverlay" class="overlay">
            <div id="tutorialBottomSection" class="bottom_section">
              <div id="bottomPrevBtn" (click)="getToPrev()">{{prevBtn}}</div>
              <div id="tutorialMiddleThreeDots" class="middle">
                <span class="dot2"></span>
                <span class="dot2"></span>
                <span class="dot2 dot3"></span>
              </div>
              <div id="gotItBackBtn"><button type="button" class="btn btn-primary" (click)="back()">{{gotItBtn}}</button>
              </div>
            </div>
          </div>
        </div>
    </ng-template>
  </ngb-carousel>