import { Injectable } from '@angular/core';
import {Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
  private headerText = new Subject<any>();
  private currentPage = new Subject<any>();
  isMainLayout = this.headerText.asObservable();
  previewPage = this.currentPage.asObservable();

  constructor() { }

  setHeaderText(data: any){
    this.headerText.next(data);
  }

  setCurrentPage(data: any){
    this.currentPage.next(data);
  }
}
