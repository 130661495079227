import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';

@Component({
  selector: 'app-unconnected-os',
  templateUrl: './unconnected-os.component.html',
  styleUrls: ['./unconnected-os.component.scss']
})
export class UnconnectedOsComponent implements OnInit {
  os: string;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.os = this.libraryV2DataService.getStaticText('tr_operatingSystem');
  }

}
