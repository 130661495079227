import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { WebSocketService } from '../../core/services/websocket-service/websocket.service';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';
import { QrCode } from 'src/app/core/interfaces/qr-input.i';
import { FactTag } from 'src/app/core/interfaces/fact-tag.i';
import { GlobalConstants } from '../../common/constants/global.constant';
import { Router } from '@angular/router';
import { LibraryV2DataService } from '../../core/services/library-v2/library-v2-data.service';
// import { LibraryV2Data,HeaderDetails } from '../../core/interfaces/library-v2-data.i';
import { QrConstants } from '../../core/services/qr-input-service/qr.constants';
@Component({
  selector: 'app-body',
  templateUrl: './remote.component.html',
  styleUrls: ['./remote.component.scss']
})

export class RemoteComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe = new Subject<boolean>();
  public qrList: QrCode;
  public factTagMenu: FactTag[] = [];
  reponseMsg: any;
  buttonClick: boolean = false;
  spinner: boolean = false;
  title: string;
  segmentColor: string;
  defaultColor: string;
  checkColor: boolean;
  staticButtonText: 'HeaderDetails';
  libraryV2Data: 'LibraryV2Data';
  headerData = [];
  factTagsData = [];
  viewPcSpec: boolean = false;
  staticRemoteText: string;
  highlightStatus_Up: boolean = false;
  highlightStatus_Down: boolean = false;
  clickCounter: number = 0;
  highlightStatusClick_Up: boolean = false;
  highlightStatusClick_Down: boolean = false;


  constructor(
    private websocket: WebSocketService,
    private qrService: QrCodeService,
    private libraryServiceData: LibraryV2DataService,
    private router: Router
    ) 
    {
      this.libraryV2Data = this.libraryServiceData?.getLibraryV2Data(this.qrService.qrInput.Language);
      // document.body.style.backgroundImage = this.libraryServiceData.getBackgroundDetails(this.qrService.qrInput.ContentType)
     }

  ngOnInit(): void {
    if (!this.qrService.enableRemote) {
      //this.reponseMsg = 'disable';
      //this.router.navigateByUrl('/home');
      // this.qrService.loadPage();
    }
    else {
      this.factTagMenu = this.qrService.getRemoteFactTag();
      this.setHeaderData();
    }
    this.qrList = this.qrService.getQrData();
    if (this.qrList.Segment === ''){
      this.checkColor = false;
      this.defaultColor = GlobalConstants.SEGMENT_DEFAULT_COLOR;
    }
    else{
      this.checkColor = true;
      this.segmentColor = this.qrList.Segment;
    }

    this.staticButtonText = this.libraryServiceData.getStaticText('tr_scrollText');
    // console.log(this.staticButtonText);
    // console.log("hello------->",this.librarV2Service.getUiStaticText())
    this.staticRemoteText = this.libraryServiceData.getStaticText('tr_remoteText')
    // this.qrService.getPage().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
    //   console.log(response);
    //   if(response) {
    //     this.FactTag(response);
    //   }
    // })
    // this.FactTag(sessionStorage.getItem('selectedFacttag'))
    if(sessionStorage.getItem(GlobalConstants.SELECTED_FACTTAGE)){
      if(sessionStorage.getItem(GlobalConstants.SELECTED_FACTTAGE)===GlobalConstants.VIEW_ALL_SPECS_NAME){
        this.viewPcSpec = true;
      }else{
        this.buttonClick = true;
        this.title = sessionStorage.getItem(GlobalConstants.SELECTED_FACTTAGE)
      }
    }
    this.highlightStatus_Down = false;
    this.highlightStatus_Up = false;
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public sendMsg(msg: any): void {
    this.spinner = true;
    this.websocket.sendPostRequest(msg).subscribe(
      (data: any) => {   
        // console.log("remote",data)     
        if (data.status !== 200) {
          this.reponseMsg = data.body;
          this.spinner = false;
        }
      },
      (error: any) => {
        this.reponseMsg = JSON.stringify(error);
      });
  }


  public ScrollUp(): void {
    this.highlightStatusClick_Up = true;
    setTimeout(() => {
      this.highlightStatusClick_Up = false;
    }, 1000)

    if(this.clickCounter>=6){
      this.highlightStatus_Down = false;
      this.highlightStatus_Up = false;
    } 
    else{
      this.highlightStatus_Up = true;
      setTimeout(() => {
        this.highlightStatus_Up = false;
      }, 1000);
      this.clickCounter++;
    }
    
    this.sendMsg(GlobalConstants.SCROLL_UP_MSG);
  }
  public ScrollDown(): void {
    this.highlightStatusClick_Down = true;
    setTimeout(() => {
      this.highlightStatusClick_Down = false;
    }, 1000)

      if(this.clickCounter>=6){
        this.highlightStatus_Down = false;
        this.highlightStatus_Up = false;
      } 
      else{
        this.highlightStatus_Down = true;
        setTimeout(() => {
          this.highlightStatus_Down = false;
        }, 1000);
        this.clickCounter++;
      }
    this.sendMsg(GlobalConstants.SCROLL_DOWN_MSG);
  }
  public FactTag(title: any): void {
    // this.qrService.setPage(title)
    this.buttonClick = true;
    switch(title) {
      case 'PROCESSOR': {
        this.title = GlobalConstants.PROCESSOR_TITLE;
        this.sendMsg(GlobalConstants.PROCESSOR_NAME);
        break;
      }
      case 'STORAGE': {
        this.title = GlobalConstants.STORAGE_TITLE;
        this.sendMsg(GlobalConstants.STORAGE_NAME);
        break;
      }
      case 'MEMORY': {
        this.title = GlobalConstants.MEMORY_TITLE;
        this.sendMsg(GlobalConstants.MEMORY_NAME);
        break;
      }
      case 'OPERATING SYSTEM': {
        this.title = GlobalConstants.OPERATING_SYSTEM_TITLE;
        this.sendMsg(GlobalConstants.OPERATING_SYSTEM_NAME);
        break;
      }
      case 'GRAPHICS': {
        this.title = GlobalConstants.GRAPHICS_TITLE;
        this.sendMsg(GlobalConstants.GRAPHICS_NAME);
        break;
      }
      default: break;
    }
    sessionStorage.setItem(GlobalConstants.SELECTED_FACTTAGE,this.title)

  }
  public Back(): void {
    this.buttonClick = false;
    sessionStorage.removeItem(GlobalConstants.SELECTED_FACTTAGE)
  }

  public backFromViewPcSpec(): void{
    this.viewPcSpec = false
    sessionStorage.removeItem(GlobalConstants.SELECTED_FACTTAGE)
  }

  public scrollRight(): void {
    this.sendMsg(GlobalConstants.SCROLL_RIGHT_MSG)
  }  

  public scrollLeft(): void {
    this.sendMsg(GlobalConstants.SCROLL_LEFT_MSG)
  }  

  public viewPcSpecFn(): void{
    this.viewPcSpec = true;
    this.title = GlobalConstants.VIEW_ALL_SPECS_NAME
    this.sendMsg(this.title)
    sessionStorage.setItem(GlobalConstants.SELECTED_FACTTAGE,this.title)
  }

  setHeaderData(): void{
    const qrData = this.qrService.getQrData();
    const headerData = ((qrData.Os === QrConstants.WINDOWS || qrData.Os === QrConstants.SUPPRESS_WIN_OS) &&
    (qrData.ContentType === GlobalConstants.STANDARDGEN11 || qrData.ContentType === GlobalConstants.STANDARDGEN10 || 
      (qrData.ContentType === 'ath' && Number(qrData.ProcessorDescription.slice(0,2)) >=10 || 
      (qrData.ContentType === 'evo' && Number(qrData.ProcessorDescription.slice(0,2)) >=10 )))) ? GlobalConstants.HEADING_3 : GlobalConstants.HEADING_2;
    headerData.forEach(data => {
      const headerDetail = this.libraryServiceData.getHeaders(data);
      this.headerData.push({
        specTitle: headerDetail.tr_specTitle,
        iconURL : headerDetail.iconURL,
        targetURL : headerDetail.targetURL
      });
    });
  }

routeTo(pageName): void{    
    this.buttonClick = false;
    this.viewPcSpec = false;
    let message;
    switch (pageName){
      case 'PcSpec': {
        message = GlobalConstants.PC_SPEC_MSG;
        break;
      }
      case 'WhyThisPC': {
        message = GlobalConstants.WHY_THIS_PC_MSG;
        break;
      }
      default : {
        message = GlobalConstants.HOME_MSG;
        break;
      }
    }
    this.title = pageName;
    this.sendMsg(message);
    sessionStorage.removeItem(GlobalConstants.SELECTED_FACTTAGE)
  }
}
