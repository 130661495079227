export class QrConstants {
  static UNK000 = 'UNKNOWN';
  static AMD000 = 'AMD';
  static INT000 = 'INTEL';
  static INT001 = 'INTEL_ATOM_1';
  static INT002 = 'INTEL_ATOM_X3_1';
  static INT003 = 'INTEL_ATOM_X5_1';
  static INT004 = 'INTEL_ATOM_X7_1';
  static INT005 = 'INTEL_CELERON_1';
  static INT006 = 'INTEL_CELERON_2';
  static INT007 = 'INTEL_CORE_2_DUO';
  static INT008 = 'INTEL_CORE_2_EXTREME';
  static INT009 = 'INTEL_CORE_2_QUAD';
  static INT010 = 'INTEL_CORE_2_SOLO';
  static INT056 = 'INTEL_CORE_M_1';
  static INT057 = 'INTEL_CORE_M3_1';
  static INT058 = 'INTEL_CORE_M3_2';
  static INT059 = 'INTEL_CORE_M3_3';
  static INT060 = 'INTEL_CORE_M5_1';
  static INT061 = 'INTEL_CORE_M7_1';
  static INT062 = 'INTEL_PENTIUM_1';
  static INT063 = 'INTEL_PENTIUM_2';
  static INT064 = 'INTEL_PENTIUM_GOLD_1';
  static INT065 = 'INTEL_PENTIUM_SILVER_1';
  static INT066 = 'INTEL_PENTIUM_SILVER_2';
  static INT067 = 'INTEL_PENTIUM_GOLD_2';
  static INT068 = 'INTEL_CELERON_3';
  static INT069 = 'EVO_I5';
  static INT070 = 'EVO_I7';
  static INT011 = 'INTEL_CORE_I3_1';
  static INT012 = 'INTEL_CORE_I3_2';
  static INT013 = 'INTEL_CORE_I3_3';
  static INT014 = 'INTEL_CORE_I3_4';
  static INT015 = 'INTEL_CORE_I3_5';
  static INT016 = 'INTEL_CORE_I3_6';
  static INT017 = 'INTEL_CORE_I3_7';
  static INT018 = 'INTEL_CORE_I3_8';
  static INT019 = 'INTEL_CORE_I3_9';
  static INT020 = 'INTEL_CORE_I3_10';
  static INT021 = 'INTEL_CORE_I3_VPRO_1';
  static INT022 = 'INTEL_CORE_I5_1';
  static INT023 = 'INTEL_CORE_I5_2';
  static INT024 = 'INTEL_CORE_I5_3';
  static INT025 = 'INTEL_CORE_I5_4';
  static INT026 = 'INTEL_CORE_I5_5';
  static INT027 = 'INTEL_CORE_I5_6';
  static INT028 = 'INTEL_CORE_I5_7';
  static INT029 = 'INTEL_CORE_I5_8';
  static INT030 = 'INTEL_CORE_I5_9';
  static INT031 = 'INTEL_CORE_I5_10';
  static INT032 = 'INTEL_CORE_I5_GRAPHICS_1';
  static INT033 = 'INTEL_CORE_I5_VPRO_1';
  static INT034 = 'INTEL_CORE_I5_XSERIES_1';
  static INT035 = 'INTEL_CORE_I7_1';
  static INT036 = 'INTEL_CORE_I7_2';
  static INT037 = 'INTEL_CORE_I7_3';
  static INT038 = 'INTEL_CORE_I7_4';
  static INT039 = 'INTEL_CORE_I7_5';
  static INT040 = 'INTEL_CORE_I7_6';
  static INT041 = 'INTEL_CORE_I7_7';
  static INT042 = 'INTEL_CORE_I7_8';
  static INT043 = 'INTEL_CORE_I7_9';
  static INT044 = 'INTEL_CORE_I7_EXTREME_1';
  static INT045 = 'INTEL_CORE_I7_EXTREME_2';
  static INT046 = 'INTEL_CORE_I7_GRAPHICS_1';
  static INT047 = 'INTEL_CORE_I7_VPRO_1';
  static INT048 = 'INTEL_CORE_I7_VPRO_2';
  static INT049 = 'INTEL_CORE_I7_XSERIES_1';
  static INT050 = 'INTEL_CORE_I9_1';
  static INT051 = 'INTEL_CORE_I9_2';
  static INT052 = 'INTEL_CORE_I9_4';
  static INT053 = 'INTEL_CORE_I9_EXTREME_1';
  static INT054 = 'INTEL_CORE_I9_VPRO_1';
  static INT055 = 'INTEL_CORE_I9_XSERIES_1';
  static INT071 = 'INTEL_CORE_I9_8';
  static INT072 = 'INTEL_CORE_I5_H35';
  static INT073 = 'INTEL_CORE_I7_H35';
  static INT074 = 'INTEL_CORE_I7_SPECIAL_EDITION_H35';
  static INT075 = 'INTEL_CORE_I5_RKL';
  static INT076 = 'INTEL_CORE_I7_RKL';
  static INT077 = 'INTEL_CORE_I9_RKL';
  static INT078 = 'FALLBACK_PROCESSOR';
  static INT079 = 'INTEL_CORE_I9_7';
  static WIN = 'WINDOWS';
  static WINDOWS = 'WIN';
  static SUPPRESS_WIN_OS = 'WNA';
  static SUPPRESS_OTHER_OS = 'ANA';
  static W10 = 'W10';
  static W11 = 'W11';
  static WINDOWSUWP = 'WINDOWSUWP';
  static LIN = 'LINUX';
  static CHROME = 'CHR';
  static GAM000 = 'AMD';
  static GNV000 = 'NVIDIA';
  static GUN000 = 'GRAPHICS_UNKNOWN';
  static GIN000 = 'INTEL_HD';
  static GIN001 = 'INTEL_HD_GRAPHICS';
  static GIN002 = 'INTEL_IRIS';
  static GIN003 = 'INTEL_IRIS_PLUS';
  static GIN004 = 'INTEL_IRIS_PRO';
  static GIN005 = 'INTEL_LEGACY';
  static GIN006 = 'INTEL_UHD';
  static GIN007 = 'INTEL_IRIS_XE';
  static GIN008 = 'INTEL_IRIS_XE_MAX';
  static GIN009 =  'INTEL_UHD_620';
  static GIN0010 = 'INTEL_UHD_630';
  static GIN012 = 'INTEL_ARC_INT';
  static GIN013 = 'INTEL_GRAPHICS';
  static INTEL_IRIS_XE = 'GIN007';
  static INTEL_IRIS_XE_MAX = 'GIN008';
  static RAM_4GB = '4GB';
  static GRAPHICS_UNKNOWN = 'GUN000';
  static INTEL_HD_GRAPHICS = 'GIN001';
  static SYSTEM_UNKNOWN = 'UN';  
  static BODY = 'body';
  static BACKGROUND_IMAGE = 'background-image';
  static DEVICE_NAME = 'HP Envy 17T';
  static S3_USERS = '/users/';
  static S3_ASSET_LIBRARY = 'asset-library';
  static S3_STORE_LOGOS = '/store-logos/';
  static SLASH = '/';
  static HYPHEN = '-';
  static UHD_FEATURING_IRIS_XE = 'UHD_FEATURING_IRIS_XE';
  static OTHERS = 'OTHERS';
  static BADGE_GIN0010 = 'GIN0010';
  static BADGE_GIN009 = 'GIN009';
  static BADGE_GIN008 = 'GIN008';
  static BADGE_GIN007 = 'GIN007';
  static BADGE_GIN006 = 'GIN006';
  static BADGE_GIN004 = 'GIN004';
  static BADGE_GIN003 = 'GIN003';
  static BADGE_GIN002 = 'GIN002';
  static BADGE_GIN001 = 'GIN001';
  static BADGE_GIN000 = 'GIN000';
  static BADGE_GIN010 = 'GIN010';
  static BADGE_GIN012 = 'GIN012';
  static BADGE_GIN013 = 'GIN013';
  static WINDOWS_NONWIN = 'NONWIN';
  static WINDOWS_GENERIC = 'WINDOWS_GENERIC';
  static BELOW_7TH_GEN = '7GEN_BELOW_PROCESSOR';
  // Story IJR-16-H45 processor changes --start
  static INT080 = 'INTEL_CORE_I5_H45';
  static INT081 = 'INTEL_CORE_I7_H45';
  static INT082 = 'INTEL_CORE_I9_H45_PERFORMANCE';
  static INT083 = 'INTEL_CORE_I9_H45_ENTHUSIAST';
  // Story IJR-16-H45 processor changes --end
  // IJR-5 Hybrid cpu start
  static INT084 = 'INTEL_CORE_I3_HYBRID';
  static INT085 = 'INTEL_CORE_I5_HYBRID';
  // IJR-5 Hybrid Cpu end
  static INT086 = 'INTEL_CORE_I9_11'; //Story IJR-66 changes
  static INT087 = 'INTEL_CORE_I9_10'; //Story IJR-66 changes  
  static INT088 = 'INTEL_CORE_I9_11_UNLOCKED'; //Story IJR-66 changes

  // 9GenGaming mobile stories: IJR-154 Epic --starts
  static INT089 = 'INTEL_CORE_I5_9_GAMING';
  static INT090 = 'INTEL_CORE_I7_9_GAMING';
  static INT091 = 'INTEL_CORE_I9_9_GAMING';
  // 9GenGaming mobile stories: IJR-154 Epic --ends
  static ATHENA = 'ath';
  static INT092 = 'INTEL_PENTIUM_3';
  static INT093 = 'INTEL_CORE_I9_9';
  static INT098 = 'INTEL_CORE_I7_1195G7';
  static UHD_CONTENT = 'Intel_UHD'; // IJR-1021 Bug Fix
  // 12th gen gaming k series start
  static INT099 = 'INTEL_CORE_I5_K_GAMING';
  static INT100 = 'INTEL_CORE_I7_K_GAMING';
  static INT101 = 'INTEL_CORE_I9_K_GAMING';
  // 12th gen gaming k series end
  // 12th gen standard start
  static INT102 = 'INTEL_CORE_I3_12';
  static INT103 = 'INTEL_CORE_I5_12';
  static INT104 = 'INTEL_CORE_I7_12';
  static INT105 = 'INTEL_CORE_I9_12';
  // 12th gen standard end
  static WINDOWS_11_UWP = 'W11U'; // IJR-2118
  static INT106 = 'INTEL_CORE_I9_H35'; //IJR-2624
  static INT108 = 'INTEL_CORE_I5_NON_K_GAMING'; //For i5 Non K 
  static BADGE_GIN011 = 'GIN011'; // IIT2-1970

  static ARC001 = 'ARC_GRAPHICS'; // IIT2-2350
  static ARC_MODEL={
    "A350M":'INTEL_ARC_A350M',
    "A370M":"INTEL_ARC_A370M",
    "A5XXM":"INTEL_ARC_A5XXM",
    "A730M":"INTEL_ARC_A730M",
    "A770M":"INTEL_ARC_A770M",
    "A310":"INTEL_ARC_A310",
    "A380":"INTEL_ARC_A380",
    "A580":"INTEL_ARC_A580",
    "A750":"INTEL_ARC_A750",
    "A770":"INTEL_ARC_A770",
    "ARC002": "INTEL_ARC_PRO_AXXX",
    "ARC003": "INTEL_ARC_PRO_AXXXM",
    "GIN012": "INTEL_ARC_INT"
  }
    static ARC_GRAPHICS = ['A350M','A370M','A730M','A770M','A310','A380','A580','A750','A770','A530M','A550M','A570M','ARC002','ARC003'];
  static UWP = 'UWP'; //IIT2-2558

  //IIT2-2869
  static INT109 = 'EVO_I5_12GEN';
  static INT110 = 'EVO_I7_12GEN';

  static WINDOWS_DEFAULT = 'NONWIN';
  static WINDOWS_10_UWP = 'W10';
  static WINDOWS_10_UWP_S_MODE = 'W10S';
  static WINDOWS_11 = 'W11';
  static WINDOWS_11_UWP_S_MODE = 'W11S';  
  static INT111 = 'INT111'; // IIT2-4515
  // HX-series
  static INT112 = 'INTEL_CORE_I7_HX_GAMING';
  static INT113 = 'INTEL_CORE_I9_HX_GAMING';
  static INT114 = 'INTEL_CORE_I9_HX_STANDARD';
  static INT115 = 'INTEL_CORE_I5_12GEN_VPRO'; // IIT2-5563
  static INT116 = 'INTEL_CORE_I7_12GEN_VPRO'; // IIT2-5563
  static INT117 = 'INTEL_CORE_I9_12GEN_VPRO'; // IJR-4300
  static INT118 = 'EVO_I9_12GEN'; // IIT2-6367

  // 13th Gen
  static INT119 = 'INTEL_CORE_I3_13';
  static INT120 = 'INTEL_CORE_I5_13';
  static INT121 = 'INTEL_CORE_I7_13';
  static INT122 = 'EVO_I5_13GEN';
  static INT123 = 'EVO_I7_13GEN';
  static INT124 = 'EVO_I9_13GEN';
  static INT125 = 'INTEL_CORE_I9_13';
  static INT132 = 'INTEL_CORE_I5_13_CREATOR';
  static INT133 = 'INTEL_CORE_I7_13_CREATOR';
  static INT135 = 'INTEL_CORE_I9_HX_CREATOR';
  static INT134 = 'INTEL_CORE_I9_H_CREATOR';
  static INT136 = 'INTEL_CORE_I9_K_CREATOR';
  static I_TEXT = 'I';
  static WIFI_MANUFACTURER = {'I': 'INTEL', 'K': 'KILLER', 'U': 'UNKNOWN'};
  static FALLBACK_ARC_GRAPHICS = ['A530M', 'A550M', 'A570M'];
  static INT145 = 'INTEL_CORE_I3_14'; 
  static INT146 = 'INTEL_CORE_I5_14';
  static INT147 = 'INTEL_CORE_I7_14'; //desktop
  static INT148 = 'INTEL_CORE_I9_14';
  static INT149 = 'INTEL_CORE_I3_14_1';
  static INT150 = 'INTEL_CORE_I5_14_1';
  static INT151 = 'INTEL_CORE_I7_14_1';
  static INT157 = 'EVO_MTL_ULTRA_5';
  static INT158 = 'EVO_MTL_ULTRA_7';
  static INT159 = 'EVO_MTL_ULTRA_9';
  static INT160 = 'STD_MTL_ULTRA_5';
  static INT161 = 'STD_MTL_ULTRA_7';
  static INT162 = 'STD_MTL_ULTRA_9';
  static INT163 = 'INTEL_CORE_I5_13GEN_VPRO';
  static INT164 = 'INTEL_CORE_I7_13GEN_VPRO';
  static INT165 = 'INTEL_CORE_I9_13GEN_VPRO';
  static INT139 = 'INTEL_CORE_ULTRA_5_MTL';
  static INT140 = 'INTEL_CORE_ULTRA_7_MTL';
  static INT141 = 'INTEL_CORE_ULTRA_9_MTL';
  static INT142 = 'INTEL_CORE_3';
  static INT143 = 'INTEL_CORE_5';
  static INT144 = 'INTEL_CORE_7'; 
  static INT166 = "EVO_LNL_ULTRA_5";
  static INT167 = "EVO_LNL_ULTRA_7";
  static INT168 = "STD_LNL_ULTRA_5";
  static INT169 = "STD_LNL_ULTRA_7";
}
