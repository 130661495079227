import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  $messageSync: Observable<any>;
  // Need replaysubject so data loads on first-time page load
  private displayMessage = new ReplaySubject<any>(1);

  private homePageCount = new BehaviorSubject(1);
  getHomePageCount = this.homePageCount.asObservable(); // IJR-1756

  private previousPage = new BehaviorSubject([]);
  getPreviousPage = this.previousPage.asObservable(); // IJR-1757

  private landingPage = new BehaviorSubject(false);
  islandingPage = this.landingPage.asObservable();

  private buyNowId = new BehaviorSubject('');
  getbuyNowId = this.buyNowId.asObservable();

  private buyNowApiErr = new BehaviorSubject('');
  getBuyNowApiErr = this.buyNowApiErr.asObservable();

  constructor() {
    this.$messageSync = this.displayMessage.asObservable();
  }
  sendMessage(msg: any): void {
    this.displayMessage.next(msg);
  }
  setHomePageCount(count: any): void {
    this.homePageCount.next(count);
  }
  setPreviousPage(page: any): void {
    this.previousPage.next(page);
  }
  setLandingPage(isPage: boolean): void {
    this.landingPage.next(isPage);
  }
  setbuyNowId(buyNowId: string): void {
    this.buyNowId.next(buyNowId);
  }
  setbuyNowApiErr(buyNowApiErr: string): void {
    this.buyNowApiErr.next(buyNowApiErr);
  }
}
