import { Injectable } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { QrCode } from '../../../../core/interfaces/qr-input.i';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {
  panelData = [];
  isAppGaming = false;
  connectivityType: string;
  isAppEvo =  false; // IJR-1852
  qrDatails: QrCode;
  isStandardEnhancement = false;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    // qrData.Connectivity = "T3";
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.connectivityType = (qrData.Connectivity) ? qrData.Connectivity : 'T3';
    this.isAppEvo = (qrData.ContentType === GlobalConstants.EVO) ? true : false; // IJR-1852
    this.qrDatails = this.qrCodeService.getQrData();
    this.isStandardEnhancement = this.qrCodeService.standardEnhancement(); // IIT2-2885
  }

  connectivityData(): any {
    this.setDefaultData();
    this.panelData = [];
    const qrData = this.qrCodeService.getQrData();
    if (qrData.Connectivity === 'T3') {
      this.connectivityDataT3();
    } else if (qrData.Connectivity === 'T4') {
      this.connectivityDataT4();
    }

    return this.panelData;
  }

  connectivityDataT3(): void {

    if (this.isAppGaming) {
      const data = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_3, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);

      const data1 = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_3, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      const panel = {
        panelType: data.panelType1,
        title: data.tr_title,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle.replace('[XX]', 'thunderbolt_3'),
        badgeUrl: this.qrCodeService.gamaingEnhancement() ? data.badgeUrl : ''
      };
      const panel3 = {
        panelType: data1.panelType2,
        badgeIcons: data1.badgeIcons
      };

      this.panelData.push(panel);
      this.panelData.push(panel3);
    } else {
      const data = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_3, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_3, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const badgeData = [];
      badgeData.push(data.badgeIcons[0]);
      const badgeData2 = [];
      badgeData2.push(data.badgeIcons[1], data.badgeIcons[2], data.badgeIcons[3]);
      const creatorContent = [GlobalConstants.CREATORGEN11, GlobalConstants.CREATORGEN12, GlobalConstants.CREATORGEN13].includes(this.qrDatails?.ContentType);
      const panel = {
        panelType: data.panelType1,
        title: creatorContent ? this.libraryService.getStaticText('tr_Thunderbolt3') : data.tr_title,
	      preTitle: creatorContent ? data.tr_title1 : data.tr_pretitle,
        subTitle: data.tr_subtitle,
        badgeIcons: badgeData,
        badgeUrl: this.isStandardEnhancement || this.qrDatails.ContentType === GlobalConstants.EVO ?
          GlobalConstants.CONNECTIVITY_VERSION_3_BADGE : ''  // IJR-1891
      };
      const panel2 = {
        title: data.titleArray[0].tr_title,
        panelType: data.panelType2,
        badgeIcons: badgeData2,
        noteSection: data.tr_noteSection

      };
      this.panelData.push(panel);
      this.panelData.push(panel2);
    }
  }


  connectivityDataT4(): void {
    if (this.isAppGaming) {
      const data = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_4, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      const data1 = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_4, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);

      const panel = {
        panelType: data.panelType1,
        title: data.tr_title,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle.replace('3', '4'),
        badgeUrl: this.qrCodeService.gamaingEnhancement() ? data.badgeUrl : ''
      };

      const panel3 = {
        panelType: data1.panelType2,
        badgeIcons: data1.badgeIcons,
        noteSection: data.tr_noteSection
      };

      this.panelData.push(panel);
      this.panelData.push(panel3);

    } else if (this.isAppEvo) { // IJR-1852 - starts
      const data = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_4, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.EVO_CAPITAL, GlobalConstants.DEFAULT_TYPE);
      const badgeData = [];
      badgeData.push(data.badgeIcons[0], data.badgeIcons[1], data.badgeIcons[2], data.badgeIcons[3]);
      const panel = {
        panelType: data.panelType1,
        title: data.tr_title,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle,
        badgeUrl: data.badgeUrl,
        connectivityBadge: data.tileBG
      };
      const panel2 = {
        title: data.titleArray[0].tr_title,
        panelType: data.panelType2,
        badgeIcons: badgeData,
        noteSection: data.tr_noteSection
      };
      this.panelData.push(panel);
      this.panelData.push(panel2);
      // IJR-1852 - ends
    } else {
      const data = this.libraryService.getFactTagsDetails(GlobalConstants.VERSION_4, GlobalConstants.CONNECTIVITY_NAME,
        GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        // IJR - 1891 start
      let title;
      let preTitle;
      if (this.isStandardEnhancement) {
        title = data.badgeIcons[0].tr_badgePreTitle;
        preTitle = data.tr_title1;
      } else {
        title = data.tr_title;
        preTitle = data.tr_pretitle;
      }
      // IJR - 1891 end
      const badgeData = [];
      badgeData.push(data.badgeIcons[0]);
      const badgeData2 = [];
      badgeData2.push(data.badgeIcons[1], data.badgeIcons[2], data.badgeIcons[3], data.badgeIcons[4]);
      const panel = {
        panelType: data.panelType1,
        title: title,
        preTitle: preTitle,
        subTitle: data.tr_subtitle,
        badgeIcons: badgeData,
        badgeUrl: this.isStandardEnhancement ? data.badgeUrl : '' // IJR - 1891
      };
      const panel2 = {
        title: data.titleArray[0].tr_title,
        panelType: data.panelType2,
        badgeIcons: badgeData2,
        noteSection: data.tr_noteSection
      };
      this.panelData.push(panel);
      this.panelData.push(panel2);
    }
  }
}
