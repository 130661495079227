import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-fact-tags',
  templateUrl: './fact-tags.component.html',
  styleUrls: ['../../information-icon/fact-tags/fact-tags.component.scss']
})
export class FactTagsComponent implements OnInit {

  processorHeading2: string;
  processorsubHeading: string;
  graphicsHeading: string;
  graphicssubHeading: string;
  memoryHeading: string;
  memorysubHeading: string;
  storageHeading: string;
  storagesubHeading: string;
  osHeading: string;
  ossubHeading: string;
  libraryV2Data: LibraryV2Data;
  factTagsTitles = [];
  factTagsDescription = [];
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
     this.processorHeading2 = this.libraryV2DataService.getStaticText('tr_processorTitle');
     this.processorsubHeading = this.libraryV2DataService.getStaticText('tr_coachmarkMemory');
     this.graphicsHeading = this.libraryV2DataService.getStaticText('tr_graphicTitle');
    this.graphicssubHeading = this.libraryV2DataService.getStaticText('tr_coachmarkMemory');
    this.storageHeading = this.libraryV2DataService.getStaticText('tr_storageTitle');
     this.storagesubHeading = this.libraryV2DataService.getStaticText('tr_coachmarkMemory');
     this.memoryHeading = this.libraryV2DataService.getStaticText('tr_memoryTitle');
     this.memorysubHeading = this.libraryV2DataService.getStaticText('tr_coachmarkMemory');
     this.osHeading = this.libraryV2DataService.getStaticText('tr_operatingSystemTitle');
     this.ossubHeading = this.libraryV2DataService.getStaticText('tr_coachmarkMemory');
    this.factTagsTitles.push(this.processorHeading2, this.graphicsHeading,
      this.memoryHeading, this.storageHeading, this.osHeading);
    this.factTagsDescription.push(this.processorsubHeading, this.graphicssubHeading,
      this.memorysubHeading, this.storagesubHeading, this.ossubHeading);

  }

}
