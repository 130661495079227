import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-body',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {
  private ngUnsubscribe = new Subject<boolean>();
  welcomeBtnText: string;
  titleText: string;
  subtitleText: string;
  libraryV2Data: LibraryV2Data;
  constructor(private qrService: QrCodeService,
    private libraryV2DataService: LibraryV2DataService, private router: Router) { }
  checkManufacturerIntel: boolean;

  ngOnInit(): void {
    // Intel logo from offline screen will be hidden if manufacturer is Non-Intel
    this.checkManufacturerIntel = this.qrService.qrInput.ContentType !== 'NI' ? true : false;
    this.titleText = this.libraryV2DataService.getStaticText('tr_welcomePageTitle');
    this.subtitleText = this.libraryV2DataService.getStaticText('tr_welcomePagembSubtitle');
    this.welcomeBtnText = this.libraryV2DataService.getStaticText('tr_welcomePageBtnText');
  }

  gotoMobileHomePage(): void {    
    this.router.navigateByUrl('/mob-content');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
