import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-mobile-home',
  templateUrl: './mobile-home.component.html',
  styleUrls: ['../../tutorial/tutorial.component.scss']
})
export class MobileHomeComponent implements OnInit {
  mobileText: string;
  deviceName: string;
  priceFooter: string;
  libraryV2Data: LibraryV2Data;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.mobileText = this.libraryV2DataService.getHeaders('mobile').tr_specTitle;
    this.deviceName = this.libraryV2DataService.getStaticText('tr_deviceName');
    this.priceFooter = this.libraryV2DataService.getStaticText('tr_price');
  }

}
