import { Injectable } from '@angular/core';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { DisplayConstants } from '../../services/display/display.consts';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  panel = [];
  isAppGaming: boolean;
  isNonIntel: boolean;
  inchText: string;
  screenStandard: string;
  screenStandards: string[];
  screenResolutionx: string;
  screenResolutiony: string;
  screenResolutionProduct: number;
  screenWidth: string;
  screenHeight: string;
  isTouchScreen: string;
  diagonalValue: string;
  display4K: boolean;
  display4KGeneric = false;
  displayTouchScreen: boolean;
  displayNormal: boolean;
  transformdiagonalValue: string;
  transformScreenResolutionProduct: string;
  transformScreenHeight: string;
  transformscreenWidth: string;
  constructor(
    private libraryService: LibraryV2DataService,
    private qrCodeService: QrCodeService
  ) { }

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    this.isAppGaming = this.qrCodeService.isAppGaming();
    this.isNonIntel = qrData.ContentType === DisplayConstants.NON_INTEL_CODE;
    this.screenStandard = qrData.DisplayType;
    this.screenStandards = [DisplayConstants.SCREEN_DCI_4K, DisplayConstants.SCREEN_UHD_4K, DisplayConstants.SCREEN_4K, DisplayConstants.SCREEN_FHD, DisplayConstants.SCREEN_HD];
    this.isTouchScreen = qrData.TouchScreen;
    this.screenResolutionx = qrData.ResolutionWidth;
    this.screenResolutiony = qrData.ResolutionHeight;
    this.diagonalValue = qrData.ScreenSize;
    this.display4K = this.screenStandard.includes(DisplayConstants.SCREEN_4K);
    this.display4KGeneric = !this.screenStandard.includes(DisplayConstants.SCREEN_4K) &&
      ((Number(this.screenResolutionx) >= 3840 && Number(this.screenResolutiony) >= 2160) ||
      (Number(this.screenResolutiony) >= 3840 && Number(this.screenResolutionx) >= 2160));
    this.displayTouchScreen = !this.screenStandard.includes(DisplayConstants.SCREEN_4K) &&
      this.isTouchScreen === DisplayConstants.TOUCHSCREEN_YES;
    this.displayNormal = !this.screenStandard.includes(DisplayConstants.SCREEN_4K) &&
      this.isTouchScreen === DisplayConstants.TOUCHSCREEN_NO;
    this.screenResolutionProduct = Number(((Number(this.screenResolutionx) * Number(this.screenResolutiony)) / Math.pow(10, 6)));
    this.screenWidth = qrData.DimenstionWidth;
    this.screenHeight = qrData.DimenstionHeight;
    this.inchText = this.libraryService.getStaticText('tr_inchUnit');
    this.transformdiagonalValue = this.qrCodeService.transformDecimal(this.diagonalValue);
    // this.transformScreenResolutionProduct = this.qrCodeService.transformDecimal(this.screenResolutionProduct);
    this.transformScreenHeight = this.qrCodeService.transformDecimal(this.screenHeight);
    this.transformscreenWidth = this.qrCodeService.transformDecimal(this.screenWidth);

    // IJR - 1272
    if (['ja', 'ko', 'zh-tw', 'zh-cn'].includes(this.qrCodeService.qrInput.Language)) {​​​
      const newscreenResolutionProduct = (Math.round(this.screenResolutionProduct * 10) * 10);  // GLS-400953
      this.transformScreenResolutionProduct = this.qrCodeService.transformDecimal(newscreenResolutionProduct);
    }​​​ else {​​​
      const newscreenResolutionProduct = (Math.round(this.screenResolutionProduct * 10) / 10); // GLS-400953
      this.transformScreenResolutionProduct = this.qrCodeService.transformDecimal(newscreenResolutionProduct);
    }​​​
  }

  displayData(): any {
    this.panel = [];
    this.setDefaultData();
    const contentType = this.qrCodeService.qrInput?.ContentType;
    const isDisplayBadge = GlobalConstants.CONTENT_TYPESS.includes(contentType) || contentType === GlobalConstants.EVO;
    if (this.isAppGaming) {
      const data = this.libraryService.getFactTagsDetails(DisplayConstants.CONTENT_TYPE_DISPLAY, DisplayConstants.CATEGORY_DISPLAY, GlobalConstants.GAMING, GlobalConstants.DEFAULT_TYPE);
      const badgeData = data.badgeIcons;
      const panel1 = {
        panelType: data.panelType1,
        title: data.tr_title,
        preTitle: data.tr_pretitle,
        subTitle: data.tr_subtitle,
        noteSection: data.tr_noteSection,
        badgeUrl: this.qrCodeService.gamaingEnhancement() ? data.badgeUrl : ''
      };
      panel1.title = panel1.title.replace(DisplayConstants.SCREEN_DIAGONAL,
         (Number(this.diagonalValue) > 0 ? this.transformdiagonalValue : ''))
        .replace(this.inchText, (Number(this.diagonalValue) > 0 ? this.inchText : ''))
        .replace('</br>', (Number(this.diagonalValue) > 0 ? '</br>' : ''))
        .replace(DisplayConstants.SCREEN_RESOLUTION_X, this.screenResolutionx)
        .replace(DisplayConstants.SCREEN_RESOLUTION_Y, this.screenResolutiony);
      const panel2 = {
        panelType: data.panelType2,
        badgeIcons: badgeData
      };
      panel2.badgeIcons[0].tr_badgeTitle = this.screenStandards.includes(this.screenStandard) ?
       (this.screenStandard === DisplayConstants.SCREEN_DCI_4K || this.screenStandard === DisplayConstants.SCREEN_UHD_4K ?
        DisplayConstants.SCREEN_4K : this.screenStandard) : '';
      panel2.badgeIcons[0].tr_badgeSubTitle = panel2.badgeIcons[0].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_RESOLUTION_X, this.screenResolutionx)
        .replace(DisplayConstants.SCREEN_RESOLUTION_Y, this.screenResolutiony);
      panel2.badgeIcons[0].tr_badgePreTitle = panel2.badgeIcons[0].tr_badgePreTitle.replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
      panel2.badgeIcons[1].tr_badgeTitle = (Number(this.diagonalValue) > 0) ? panel2.badgeIcons[1].tr_badgeTitle.replace(DisplayConstants.SCREEN_DIAGONAL, this.transformdiagonalValue) : '';
      panel2.badgeIcons[1].tr_badgeSubTitle = (Number(this.screenHeight) > 0) ? panel2.badgeIcons[1].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_HEIGHT, this.transformScreenHeight) : '';
      panel2.badgeIcons[1].tr_badgePreTitle = (Number(this.screenWidth) > 0) ? panel2.badgeIcons[1].tr_badgePreTitle.replace(DisplayConstants.SCREEN_WIDTH, this.transformscreenWidth) : '';
      if (this.isTouchScreen === DisplayConstants.TOUCHSCREEN_NO) {
        panel2.badgeIcons.splice(2);
      }
      this.checkTouchScreen(panel2);
      this.panel.push(panel1);
      this.panel.push(panel2);

      return this.panel;
    } else if (this.isNonIntel) {
      const data = this.libraryService.getFactTagsDetails(DisplayConstants.NON_INTEL_CONTENT_TYPE, DisplayConstants.CATEGORY_DISPLAY, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      const panel1 = {
        panelType: data.panelType1,
        title: data.titleArray[0].tr_title,
        subTitle: data.titleArray[0].tr_subTitle
      };
      panel1.title = panel1.title.replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
      this.panel.push(panel1);

      return this.panel;
    } else {
      if (this.display4K) {
        const data = this.libraryService.getFactTagsDetails(DisplayConstants.SCREEN_4K, DisplayConstants.CATEGORY_DISPLAY, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const badgeData2 = [];
        badgeData2.push(data.badgeIcons[0]);
        const panel = {
          panelType: data.panelType1,
          title: data.tr_title,
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle,
          badgeIcons: badgeData2,
          noteSection: data.tr_noteSection,
          badgeUrl: isDisplayBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrCodeService.qrInput.ProcessorBadge) ? GlobalConstants.DISPLAY_BADGE_12TH_CREATOR_11TH : ''
        };
        const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.TOUCH_SCREEN, DisplayConstants.CATEGORY_DISPLAY, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const badgeData3 =[];
        (this.isTouchScreen === DisplayConstants.TOUCHSCREEN_YES && this.qrCodeService.qrInput.Os !== 'ANA') ?
        badgeData3.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]) :
        badgeData3.push(data2.badgeIcons[1], data2.badgeIcons[2]);
        const panel2 = {
          panelType: data2.panelType2,
          badgeIcons: badgeData3
        };
        panel2.badgeIcons[0].tr_badgeTitle = DisplayConstants.SCREEN_4K;
        panel2.badgeIcons[0].tr_badgeSubTitle = panel2.badgeIcons[0].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_RESOLUTION_X, this.screenResolutionx)
          .replace(DisplayConstants.SCREEN_RESOLUTION_Y, this.screenResolutiony);
        panel2.badgeIcons[0].tr_badgePreTitle = panel2.badgeIcons[0].tr_badgePreTitle.replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
        panel2.badgeIcons[1].tr_badgeTitle = (Number(this.diagonalValue) > 0) ? panel2.badgeIcons[1].tr_badgeTitle.replace(DisplayConstants.SCREEN_DIAGONAL, this.transformdiagonalValue) : '';
        panel2.badgeIcons[1].tr_badgeSubTitle = (Number(this.screenHeight) > 0) ? panel2.badgeIcons[1].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_HEIGHT, this.transformScreenHeight) : '';
        panel2.badgeIcons[1].tr_badgePreTitle = (Number(this.screenWidth) > 0) ? panel2.badgeIcons[1].tr_badgePreTitle.replace(DisplayConstants.YY, this.transformscreenWidth) : '';
        this.checkTouchScreen(panel2);
        this.panel.push(panel);
        this.panel.push(panel2);

        return this.panel;
      } else if (this.display4KGeneric){   // greater than UHD UAT-317
        const data = this.libraryService.getFactTagsDetails(DisplayConstants.SCREEN_4K, DisplayConstants.CATEGORY_DISPLAY,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const panel = {
          panelType: data.panelType1,
          title: data.tr_title,
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle,
          noteSection: data.tr_noteSection,
          badgeUrl: isDisplayBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrCodeService.qrInput.ProcessorBadge) ? GlobalConstants.DISPLAY_BADGE_12TH_CREATOR_11TH : ''
        };
        const data2 = this.libraryService.getFactTagsDetails(GlobalConstants.TOUCH_SCREEN, DisplayConstants.CATEGORY_DISPLAY,
          GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const badgeData3 = [];
        (this.isTouchScreen === DisplayConstants.TOUCHSCREEN_YES && this.qrCodeService.qrInput.Os !== 'ANA') ?
        badgeData3.push(data2.badgeIcons[1], data2.badgeIcons[2], data2.badgeIcons[3]) :
        badgeData3.push(data2.badgeIcons[1], data2.badgeIcons[2]);
        const panel2 = {
          panelType: data2.panelType2,
          badgeIcons: badgeData3
        };
        panel2.badgeIcons[0].tr_badgeTitle = this.screenStandard; //IIT2-2868
        panel2.badgeIcons[0].tr_badgeSubTitle = panel2.badgeIcons[0].tr_badgeSubTitle
          .replace(DisplayConstants.SCREEN_RESOLUTION_X, this.screenResolutionx)
          .replace(DisplayConstants.SCREEN_RESOLUTION_Y, this.screenResolutiony);
        panel2.badgeIcons[0].tr_badgePreTitle = panel2.badgeIcons[0].tr_badgePreTitle
          .replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
        panel2.badgeIcons[1].tr_badgeTitle = (Number(this.diagonalValue) > 0) ? panel2.badgeIcons[1].tr_badgeTitle
          .replace(DisplayConstants.SCREEN_DIAGONAL, this.transformdiagonalValue) : '';
        panel2.badgeIcons[1].tr_badgeSubTitle = (Number(this.screenHeight) > 0) ? panel2.badgeIcons[1].tr_badgeSubTitle
          .replace(DisplayConstants.SCREEN_HEIGHT, this.transformScreenHeight) : '';
        panel2.badgeIcons[1].tr_badgePreTitle = (Number(this.screenWidth) > 0) ? panel2.badgeIcons[1].tr_badgePreTitle
          .replace(DisplayConstants.YY, this.transformscreenWidth) : '';
          this.checkTouchScreen(panel2);
        this.panel.push(panel);
        this.panel.push(panel2);

        return this.panel;
      } else {
        const data = this.libraryService.getFactTagsDetails((this.displayTouchScreen && this.qrCodeService.qrInput.Os !== 'ANA') ?
          GlobalConstants.TOUCH_SCREEN : DisplayConstants.CONTENT_TYPE_DISPLAY,
          DisplayConstants.CATEGORY_DISPLAY, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        const badgeData = [];
        badgeData.push(data.badgeIcons[0]);
        const badgeData2 = [];
        if (this.displayTouchScreen){
          badgeData2.push(data.badgeIcons[1], data.badgeIcons[2], data.badgeIcons[3]);
        }else {
          badgeData2.push(data.badgeIcons[1], data.badgeIcons[2]);
        }
        const panel1 = {
          panelType: data.panelType1,
          title: data.tr_title,
          preTitle: data.tr_pretitle,
          subTitle: data.tr_subtitle,
          badgeIcons: badgeData,
          noteSection: data.tr_noteSection,
          badgeUrl: isDisplayBadge && !GlobalConstants.legacyProcessorArray.includes(this.qrCodeService.qrInput.ProcessorBadge) ? GlobalConstants.DISPLAY_BADGE_12TH_CREATOR_11TH : ''
        };
        panel1.title = panel1.title.replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
        panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle
        .replace(DisplayConstants.SCREEN_DIAGONAL, Number(this.diagonalValue) > 0 ? this.transformdiagonalValue : '' )
        .replace('”', Number(this.diagonalValue) > 0 ? '”' : '' )
        .replace(DisplayConstants.SCREEN_STANDARD_PLACEHOLDER,
          this.screenStandards.includes(this.screenStandard) ? this.screenStandard : '' );
          // GLS-306734 - start
        if (!this.screenStandards.includes(this.screenStandard)) {
          panel1.badgeIcons[0].tr_badgeTitle = panel1.badgeIcons[0].tr_badgeTitle.replace('<br/>', '');
          }
        // GLS-306734 - end

        const panel2 = {
          panelType: data.panelType2,
          badgeIcons: badgeData2
        };
        panel2.badgeIcons[0].tr_badgeTitle = this.screenStandards.includes(this.screenStandard) ? this.screenStandard : '';
        panel2.badgeIcons[0].tr_badgeSubTitle = panel2.badgeIcons[0].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_RESOLUTION_X, this.screenResolutionx)
          .replace(DisplayConstants.SCREEN_RESOLUTION_Y, this.screenResolutiony);
        panel2.badgeIcons[0].tr_badgePreTitle = panel2.badgeIcons[0].tr_badgePreTitle.replace(DisplayConstants.SCREEN_RESOLUTION_PRODUCT, this.transformScreenResolutionProduct);
        panel2.badgeIcons[1].tr_badgeTitle = (Number(this.diagonalValue) > 0) ? panel2.badgeIcons[1].tr_badgeTitle.replace(DisplayConstants.SCREEN_DIAGONAL, this.transformdiagonalValue) : '';
        panel2.badgeIcons[1].tr_badgeSubTitle = (Number(this.screenHeight) > 0) ? panel2.badgeIcons[1].tr_badgeSubTitle.replace(DisplayConstants.SCREEN_HEIGHT, this.transformScreenHeight) : '';
        panel2.badgeIcons[1].tr_badgePreTitle = (Number(this.screenWidth) > 0) ? panel2.badgeIcons[1].tr_badgePreTitle.replace(DisplayConstants.YY, this.transformscreenWidth) : '';
        if (this.displayTouchScreen) {
          if (Number(this.diagonalValue) < 1 && !this.screenStandards.includes(this.screenStandard)) {
            panel1.badgeIcons = [];
          }
          this.checkTouchScreen(panel2);
        }
        if (this.displayNormal) {
          if (Number(this.screenHeight) <= 0 && Number(this.screenWidth) <= 0 && Number(this.diagonalValue) <= 0) {
            panel2.badgeIcons.splice(1);
          }
        }
        this.panel.push(panel1);
        this.panel.push(panel2);

        return this.panel;
      }
    }
  }

  checkTouchScreen(panelData) {
    if (Number(this.screenHeight) <= 0 && Number(this.screenWidth) <= 0 && Number(this.diagonalValue) <= 0 ) {
      this.isTouchScreen === DisplayConstants.TOUCHSCREEN_NO ? panelData.badgeIcons.splice(1) : panelData.badgeIcons.splice(1, 1);
    }
  }
}
