import { Component, OnInit } from '@angular/core';
import { LibraryV2DataService } from '../../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../../core/interfaces/library-v2-data.i';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['../../tutorial/tutorial.component.scss']
})
export class NavBarComponent implements OnInit {
  remoteText: string;
  deviceName: string;
  libraryV2Data: LibraryV2Data;
  constructor(private libraryV2DataService: LibraryV2DataService) { }

  ngOnInit(): void {
    this.remoteText = this.libraryV2DataService.getHeaders('remote').tr_specTitle;
    this.deviceName = this.libraryV2DataService.getStaticText('tr_deviceName');
  }

}
