import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { NgbCarousel, NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data } from '../../../core/interfaces/library-v2-data.i';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  tooltipText1: string;
  tooltipText2: string;
  tooltipText3: string;
  tooltipText4: string;
  skipBtn: string;
  nextBtn: string;
  prevBtn: string;
  gotItBtn: string;
  scrollText: string;
  homeText: string;
  whythispcText: string;
  pcspecsText: string;
  deviceName: string;
  processorHeading: string;
  remoteText: string;
  libraryV2Data: LibraryV2Data;
  constructor(private location: Location,
    private libraryV2DataService: LibraryV2DataService) { }

  @ViewChild('ngcarousel', { static: true }) ngCarousel: NgbCarousel;

  ngOnInit(): void {
    this.tooltipText1 = this.libraryV2DataService.getStaticText('tr_toolTipTextc1');
    this.tooltipText2 = this.libraryV2DataService.getStaticText('tr_toolTipTextc2');
    this.tooltipText3 = this.libraryV2DataService.getStaticText('tr_toolTipTextc3');
    this.tooltipText4 = this.libraryV2DataService.getStaticText('tr_toolTipTextc4');
    this.skipBtn = this.libraryV2DataService.getStaticText('tr_skipButton');
    this.prevBtn = this.libraryV2DataService.getStaticText('tr_prevButton');
    this.nextBtn = this.libraryV2DataService.getStaticText('tr_nextButton');
    this.gotItBtn = this.libraryV2DataService.getStaticText('tr_gotItButton');
    this.scrollText = this.libraryV2DataService.getStaticText('tr_scrollText');
    this.homeText = this.libraryV2DataService.getHeaders('homepage').tr_specTitle;
    this.whythispcText = this.libraryV2DataService.getHeaders('why_this_pc').tr_specTitle;
    this.pcspecsText = this.libraryV2DataService.getHeaders('pc_specs').tr_specTitle;
    this.remoteText = this.libraryV2DataService.getHeaders('remote').tr_specTitle;
    this.processorHeading = this.libraryV2DataService.getStaticText('tr_processorHeading');
    this.deviceName = this.libraryV2DataService.getStaticText('tr_deviceName');
  }

  slideActivate(ngbSlideEvent: NgbSlideEvent) {
  }
  // Move to previous slide
  getToPrev() {
    this.ngCarousel.prev();
  }

  // Move to next slide
  goToNext() {
    this.ngCarousel.next();
  }

  // Move to last accessed page
  back(): void {
    this.location.back();
  }
}

