import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { QrCodeService } from '../../../../core/services/qr-input-service/qr-input.service';

@Injectable({
  providedIn: 'root'
})
export class DasService {

  panel = [];
  isNonIntel: boolean;
  isAppGaming: boolean;
  DasTete: string;
  DasTronc: string;
  DasMembre: string;
  libraryService: any;
  dasDetails: any[];
  constructor(
    private qrCodeService: QrCodeService
  ) {}

  setDefaultData(): void {
    const qrData = this.qrCodeService.getQrData();
    this.isNonIntel = qrData.ContentType === GlobalConstants.NON_INTEL_TEXT;
    this.isAppGaming = (qrData.ContentType === GlobalConstants.GAMINGGEN11 || qrData.ContentType === GlobalConstants.GAMINGGEN10 ||
      qrData.ContentType === GlobalConstants.GAMINGGEN9 || qrData.ContentType === GlobalConstants.GAMINGGEN12) ? true : false; // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
    this.DasTete = qrData.DasTete;
    this.DasTronc=qrData.DasTronc;
    this.DasMembre=qrData.DasMembre;
  }

  displayDasDetailPage(): any{
    this.panel = [];
    this.setDefaultData();
    let temptitle = "";
    this.dasDetails = [];
    if(this.DasTronc){
      temptitle = `${GlobalConstants.DAS_TRONC_VALUE.replace(GlobalConstants.XX,this.DasTronc)}`;  // IJR-3064
    }
    if(this.DasMembre){
      temptitle =  temptitle ? `${temptitle} , <br> ${GlobalConstants.DAS_MEMBRE_VALUE.replace(GlobalConstants.XX,this.DasMembre)}` : `${GlobalConstants.DAS_MEMBRE_VALUE.replace(GlobalConstants.XX,this.DasMembre)}`; // IJR-3064
    }
    if(this.DasTete){
      temptitle = temptitle ? `${temptitle} , <br> ${GlobalConstants.DAS_TETE_VALUE.replace(GlobalConstants.XX,this.DasTete)}` : `${GlobalConstants.DAS_TETE_VALUE.replace(GlobalConstants.XX,this.DasTete)}`;  // IJR-3064
    }
    this.qrCodeService.getQrData();
      const panel1 = {
        panelType: GlobalConstants.DAS_PANEL_TYPE,
        title: temptitle,
        subTitle: GlobalConstants.DAS_DETAIL_VALUE,
        badgeUrl: this.isAppGaming ? GlobalConstants.PC_SPECS_DAS_BADGE : !this.isAppGaming && GlobalConstants.STANDARDGEN12 ? GlobalConstants.PC_SPECS_DAS_BADGE : ""
      };
      this.panel.push(panel1);
  
    return this.panel;
  }
}
