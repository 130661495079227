export class GlobalConstants {
  static readonly INTEL = 'Intel';
  static readonly WELCOME = 'WELCOME';
  static readonly GET_STARTED = 'Get Started';
  static readonly HOME_PAGE_NAME = 'Home';
  static readonly WHY_THIS_PC_PAGE_NAME = 'WhyThisPC';
  static readonly PC_SPEC_PAGE_NAME = 'PcSpec';
  static readonly VIEW_ALL_SPECS_NAME = 'view-all-specs';
  static readonly HOME_MSG = 'home';
  static readonly WHY_THIS_PC_MSG = 'why';
  static readonly PC_SPEC_MSG = 'pcspec';
  static readonly SCROLL_UP_MSG = 'scrollup';
  static readonly SCROLL_DOWN_MSG = 'scrolldown';
  static readonly PROCESSOR_NAME = 'processor';
  static readonly PROCESSOR_TITLE = 'Processor';
  static readonly GRAPHICS_NAME = 'graphics';
  static readonly GRAPHICS_TITLE = 'Graphics';
  static readonly OPERATING_SYSTEM_NAME = 'os';
  static readonly OPERATING_SYSTEM_TITLE = 'Operating System';
  static readonly MEMORY_NAME = 'memory';
  static readonly MEMORY_TITLE = 'Memory';
  static readonly STORAGE_NAME = 'storage';
  static readonly STORAGE_TITLE = 'Storage';
  static readonly SEGMENT_DEFAULT_COLOR = '#0068B5';
  static readonly HEADING_3 = ['homepage', 'why_this_pc'];
  static readonly HEADING_2 = ['homepage'];
  static readonly STANDARDGEN10 = 'S10';
  static readonly STANDARDGEN11 = 'S11';
  static readonly STANDARDGEN12 = 'S12';
  static readonly GAMINGGEN11 = 'G11';
  static readonly NON_INTEL = 'AMD';
  static readonly DEFAULT = 'default';
  static readonly ATOM = 'ATOM';
  static readonly CELERON = 'CELERON';
  static readonly PENTIUM = 'PENTIUM';
  static readonly CORE_2 = 'CORE 2';
  static readonly CORE_M = 'CORE M';
  static readonly FACTTAG_DEFAULT_VALUE = 'UNKNOWN';
  static readonly CPU_FAMILY_ATOM = 'INTEL_ATOM';
  static readonly CPU_FAMILY_CELERON = 'INTEL_CELERON';
  static readonly CPU_FAMILY_PENTIUM = 'INTEL_PENTIUM';
  static readonly CPU_FAMILY_CORE_2 = 'INTEL_CORE_2';
  static readonly CPU_FAMILY_CORE_M = 'INTEL_CORE_M';
  static readonly ATHENA = 'athena';
  static readonly EVO = 'evo';
  static readonly SCROLL_LEFT_MSG = 'scrollLeft';
  static readonly SCROLL_RIGHT_MSG = 'scrollRight';
  static readonly SELECTED_FACTTAGE = 'selectedFacttag';
  static readonly DISPLAY_NAME = 'display';
  static readonly WIFI_NAME = 'wifi';
  static readonly CONNECTIVITY_NAME = 'connectivity';
  static readonly BATTERY_NAME = 'battery';
  static readonly OTHER_PROCESSORS = 'OTHER_PROCESSORS';
  static readonly GAMING = 'GAMING';
  static readonly STANDARD = 'STANDARD';
  static readonly COMMON = 'COMMON';
  static readonly DEFAULT_TYPE = 'DEFAULT';
  static readonly TOUCH_SCREEN = 'TOUCH_SCREEN';
  static readonly VERSION_4 = 'VERSION_4';
  static readonly VERSION_3 = 'VERSION_3';
  static readonly WINDOWS = 'WINDOWS';
  static readonly WINDOWS11 = 'WINDOWS_11';
  static readonly WINDOWSUWP = 'WINDOWSUWP';
  static readonly WINDOWS_GENERIC = 'WINDOWS_GENERIC';
  static readonly WIFI_HIGHLIGHT = [0, 4, 5, 6];
  static readonly WIRELESS_4 = '4';
  static readonly WIRELESS_5 = '5';
  static readonly WIRELESS_3 = 3;
  static readonly WIRELESS_2 = 2;
  static readonly WIRELESS_1 = 1;
  static readonly WIRELESS_6 = '6';
  static readonly GAMINGGEN10 = 'G10';
  static readonly THREE = 3;
  static readonly TWO = 2;
  static readonly FOUR = 4;
  static readonly EIGHT = 8;
  static readonly ONE = 1;
  static readonly SIX = 6;
  static readonly SIXTEEN = 16
  static readonly THIRTY_TWO = 32;
  static readonly TWELVE = 12;
  static readonly ZERO = 0;
  static readonly GRAPH_1 = 'graph1';
  static readonly GRAPH_2 = 'graph2';
  static readonly GRAPH_3 = 'graph3';
  static readonly GRAPH_4 = 'graph4';
  static readonly GRAPH_5 = 'graph5';
  static readonly GRAPH_6 = 'graph6';
  static readonly GRAPH_11 = 'graph11';
  static readonly GRAPH_13 = 'graph13';
  static readonly GRAPH_21 = 'graph21';
  static readonly GRAPH_23 = 'graph23';
  static readonly GRAPH_31 = 'graph31';
  static readonly GRAPH_33 = 'graph33';
  static readonly TYPE_VALUE_1 = 'type-value1';
  static readonly TYPE_VALUE_2 = 'type-value2';
  static readonly TYPE_VALUE_3 = 'type-value3';
  static readonly TYPE_VALUE_11 = 'type-value11';
  static readonly TYPE_VALUE_21 = 'type-value21';
  static readonly TYPE_VALUE_31 = 'type-value31';
  static readonly TYPE_VALUE_13 = 'type-value13';
  static readonly TYPE_VALUE_23 = 'type-value23';
  static readonly TYPE_VALUE_33 = 'type-value33';
  static readonly PANEL_TYPE_TABLE_FORMAT = 'tableFormat';
  static readonly PANEL_TYPE_TABLE_FORMAT2 = 'tableFormat2';
  static readonly PANEL_TYPE_HEADER_ICON_TOP = 'header-IconTop';
  static readonly PANEL_TYPE_HEADER_BADGE_ICON_TOP = 'header-BadgeIconTop';
  static readonly PANEL_TYPE_GAMING_GRAPHICS = 'gaming-graphics';
  static readonly PANEL_TYPE_BADGES_HEADING = 'badgesAndHeadings';
  static readonly PANEL_TYPE_GAMING_PROCESSOR = 'gaming-processorType2';
  static readonly EVO_CAPITAL = 'EVO';
  static readonly GOOD = 'GOOD';
  static readonly BETTER = 'BETTER';
  static readonly BEST = 'BEST';
  static readonly XX = '[XX]';
  static readonly BATTERY_6 = 6;
  static readonly BATTERY_9 = 9;
  static readonly BATTERY_8 = 8;
  static readonly DYNAMIC_BATTERY_HOURS = '[Dynamic.battery.values.hours]';
  static readonly NON_INTEL_TEXT = 'NI';
  static readonly SNA = 'SNA';
  static readonly PRICE_NAME = 'price';
  static readonly DEVICE_ID = 'deviceId';
  static readonly INITIAL_SCAN = 'InitialScan';
  static readonly COMPARE_SCAN = 'CompareScan';
  static readonly GAMES_NUMBER = '[num_games]';
  static readonly VIDEOS_NUMBER = '[num_videos]';
  static readonly PHOTOS_NUMBER = '[num_photos]';
  static readonly SINGLE_SPACE = ' ';
  static readonly GB = 'GB';
  static readonly TB = 'TB';
  static readonly XX_WITHOUT_BRACKET = 'XX';
  static readonly OPTANE = /Optane/;
  static readonly SSD = /SSD/;
  static readonly HDD = /HDD/;
  static readonly NON_INTEL_NAME = 'NON_INTEL';
  static readonly MEMORY_PLACEHOLDER = '[memory_total]';
  static readonly X = '[x]';
  static readonly ACER = 'AC';
  static readonly ASUS = 'AS';
  static readonly DELL = 'DE';
  static readonly DREAMHACK = 'DR';
  static readonly FUJITSU = 'FU';
  static readonly HUAWEI = 'HU';
  static readonly SYSTEM_INTEL = 'IN';
  static readonly LENOVO = 'LE';
  static readonly MICROSOFT = 'MI';
  static readonly MOTOROLA = 'MO';
  static readonly MSI = 'MS';
  static readonly RAZER = 'RA';
  static readonly SAMSUNG = 'SA';
  static readonly SONY = 'SO';
  static readonly TOSHIBA = 'TO';
  static readonly VIZIO = 'VI';
  static readonly HP = 'HP';
  static readonly HDD_TEXT = 'HDD';
  static readonly SSD_TEXT = 'SSD';
  static readonly OPTANE_MEMORY = 'optane_memory';
  static readonly INTEL_OPTANE_MEMORY = 'INTEL_OPTANE';
  static readonly SONGS_NUMBER = '[num_songs]';
  static readonly PLUS = ' + ';
  static readonly SSD_AND_HDD = 'SSD_AND_HDD';
  static readonly CONVERTED_SSD = '[convertedSSD]';
  static readonly CONVERTED_HDD = '[convertedHDD]';
  static readonly CAPACITY = '[capacity]';
  static readonly OPTANE_TEXT = 'OPTANE';
  static readonly TB_STATIC_TEXT = 'tr_TBText';
  static readonly GB_STATIC_TEXT = 'tr_GBText';
  static readonly DEVICE_TYPE_DESKTOP = 'DP';
  static readonly DEVICE_TYPE_LAPTOP = 'LP';
  static readonly DEVICE_TYPE_ALLINONE = 'A1';
  static readonly DEVICE_TYPE_TWOINONE = 'T1';
  static readonly LAPTOP_NAME = 'Laptop';
  static readonly DESKTOP_NAME = 'Desktop';
  static readonly TWO_IN_ONE = 'TwoInOne';  
  static readonly FROM_TEXT = 'from';
  static readonly COMPARE_PAGE = 'ComparePage';
  static readonly OTHER_OLD_PROCESSOR = 'OTHER_OLD_PROCESSORS';
  static readonly PENTIUM_SILVER = 'PENTIUM_SILVER';
  static readonly PENTIUM_GOLD = 'PENTIUM_GOLD';
  static readonly NVIDIA_TEXT = 'NVIDIA';
  static readonly OTHERS = 'OTHERS';
  static readonly INTEL_CAPS = 'INTEL';
  static readonly VERSION_6E_KILLER = 'VERSION_6E_KILLER';
  static readonly VALUE_6E = '6E';
  static readonly VERSION_5 = 'VERSION_5';
  static readonly INTEL_WIFI_6E = 'INTEL_WIFI_6E';
  static readonly KILLER_WIFI_6E = 'KILLER_WIFI_6E';
  static readonly TR_WIFI6 = 'tr_wifi6';
  static readonly TR_WIFI5 = 'tr_wifi5';
  static readonly TR_WIFI4 = 'tr_wifi4';
  static readonly WIFI_VALUE_4 = 4;
  static readonly WIFI_VALUE_5 = 5;
  static readonly WIFI_VALUE_6 = 6;
  static readonly WIFI_VALUE_3 = 3;
  static readonly WIFI_VALUE_2 = 2;
  static readonly WIFI_VALUE_1 = 1;
  static readonly VERSION_6 = 'VERSION_6';
  static readonly KILLER = 'KILLER';
  static readonly VERSION_6E = 'VERSION_6E';
  static readonly TR_WIFI6E = 'tr_wifi6e';
  static readonly VALUE_5 = '[XX]';
  static readonly IMAGE_PATH = '[image_path]';
  static readonly KILLER_AX1675I = 'KILLER_WIFI_6E_AX1675I';
  static readonly KILLER_AX1675X = 'KILLER_WIFI_6E_AX1675X';
  static readonly KILLER_AX1690 = 'Intel® Killer™ Wi-Fi 6E AX1690';
  static readonly WIFI_6E_BADGE = 'wifi-6E-Gig-badge';
  static readonly KILLER_BADGE_AX1675 = 'standard_wifi_killer_AX1675_badge';
  static readonly KILLER_BADGE_AX1690 = 'standard_wifi_killer_AX1690_badge';
  static readonly DETAILS_SCAN = 'DetailsScan';
  static readonly GRAPHICS_NONINTEL_ICON = '../../../../assets/images/home-deafult-display.svg';
  static readonly MOMORY_XE = '../../../../assets/images/home-memory-icon.svg';
  static readonly MOMORY_NON_XE = '../../../../assets/images/default-home-memory.svg';
  static readonly BATTERY_ICON = '../../../../assets/images/home-battery-icon.svg';
  static readonly DISPLAY_XE = '../../../../assets/images/home-display-icon.svg';
  static readonly DISPLAY_NON_XE = '../../../../assets/images/home-deafult-display.svg';
  static readonly OS_ICON = '../../../../assets/images/OS.svg';
  static readonly STORAGE_ICON = '../../../../assets/images/default-storage-icon.svg';
  static readonly CONNECTIVITY_ICON = '../../../../assets/images/connection.png';
  static readonly WIFI_ICON = '../../../../assets/images/Wi-Fi.png';
  static readonly THUNDERBOLD_3 = 'T3';
  static readonly THUNDERBOLD_4 = 'T4';
  static readonly MOB_CONTENT = 'mob-content';
  static readonly ALL_SPECS = 'all-specs';
  static readonly NUM_FORMAT = /\B(?=(\d{3})+(?!\d))/g;
  static readonly EDITION = 'Edition';
  static readonly EDITION_SMALL = 'edition';
  static readonly EN_US_SMALL = 'en-us';
  static readonly GRAPHICS_NONIRIX_ICON = '../../../../assets/images/graphics-non-iris.svg';
  static readonly GRAPHICS_CARD = 'graphicsCard';
  static readonly GRAPHICS_INTEL_IRIS = '../../../../assets/images/iris-xe-max_graphics.svg';  // GLS-298593
  // GA-Processor multi-lang issue start
  static readonly INTEL_CORE_I3 = 'Intel® Core™ i3';
  static readonly INTEL_CORE_I5 = 'Intel® Core™ i5';
  static readonly INTEL_CORE_I7 = 'Intel® Core™ i7';
  static readonly INTEL_CORE_I9 = 'Intel® Core™ i9';
  static readonly INTEL_PENTIUM_SILVER = 'Intel® Pentium® Silver';
  static readonly INTEL_PENTIUM_GOLD = 'Intel® Pentium® Gold';
  static readonly INTEL_CELERON = 'Intel® Celeron®';
  static readonly INTEL_PENTIUM = 'Intel® Pentium®';
  // GA-Processor multi-lang issue end
  static readonly UNKNOWN = 'UNKNOWN'; // IJR-240 bug fix
  static readonly GAMINGGEN9 = 'G9'; // 9GenGaming mobile stories: IJR-154 Epic
  static readonly ANA = 'ANA'; // IJR-522 bug fix
  // IJR - 661 start
  static readonly INT075 = 'INT075';
  static readonly INT076 = 'INT076';
  static readonly INT077 = 'INT077';
  static readonly INT095 = 'INT095';
  static readonly INT096 = 'INT096';
  static readonly INT097 = 'INT097';
  // IJR - 661 end
  static readonly ATHENA_CONTENT_TYPE = 'Athena';
  // II - 3392 start
  static readonly BACKGROUND = 'background';
  static readonly CHROMEBOOK_BACKGROUND = '#00285A';
  static readonly NONE = 'none';
  // II - 3392 end
  // chromebook start
  static readonly HEADERBADGEICONTOP = 'header-BadgeIconTop';
  static readonly BADGESANDHEADING = 'badgesAndHeadings';
  static readonly ENDLESSPROGRAMSANDAPPS = 'endlessProgramsAndApps';
  static readonly RETHINKSTORAGE = 'rethinkStorage';
  static readonly ALWAYSONSECURITY = 'alwaysOnSecurity';
  static readonly EXTENDEDBATTERYLIFE = 'extendedBatteryLife';
  static readonly CHROMEBOOKATHENA = 'CATH';
  static readonly CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP = 'chromebook-header-BadgeIconTop';
  static readonly CHROMEBOOK_PANEL_TYPE_HEADER_ICON_TOP_11GEN = 'chromebook-header-BadgeIconTop-11Gen';
  static readonly CHROMEBOOK = 'C';
  static readonly CELERON_BADGE_ARRAY = ['INT005', 'INT006', 'INT068','INT111'];
  static readonly CHROMEBOOKBACKGROUND = 'chromebook-background';
  static readonly CHROMEBOOKWHITEBACKGROUND = '#E8E8E8';
  static readonly CHROMEBOOK_CELERON_AND_PENTIUM = 'CCP';
  static readonly PROCESSOR_BADGE = 'assets/images/processor/[XX].svg';
  // chromebook end
  static readonly HEADERICONTOPGAMING = 'header-IconTopGaming';
  static readonly INT083 = 'INT083';
  static readonly TOTALCAPACITY = '[total_capacity]';
  static readonly OPTANEMEMORYSIZE = '[optane_memory_size]';
  static readonly OPERATING_SYSTEM = 'operatingSystem';
  static readonly DATE_FORMAT = '[dd]';
  static readonly MONTH_FORMAT = '[mm]';
  static readonly YEAR_FORMAT = '[yyyy]';
  static readonly PDF = '.pdf';
  static readonly CREATOR = 'Creator';
  static readonly INTEL_IRIS_XE_BADGE = 'assets/images/badges/intel-iris-xe.svg';
  static readonly INTEL_IRIS_XE_MAX_BADGE = 'assets/images/badges/intel-iris-xe-max.svg';
  static readonly CREATORGEN10 = 'R10';
  static readonly CREATORGEN11 = 'R11';
  static readonly CREATORGEN12 = 'R12';
  static readonly CREATORGEN13 = 'R13';
  static readonly CREATOR_CONTENT = 'R';
  static readonly IMAGE_ONLY_PANEL_TYPE = 'image-only-panel';
  static readonly HEADER_BADGE_ICON_TOP_GAMING = 'header-BadgeIconTopGaming';
  static readonly INT089 = 'INT089';
  static readonly INT090 = 'INT090';
  static readonly INT091 = 'INT091';
  static readonly NUMBER_IN_SUPERSCRIPT ='[number in superscript]';
  static readonly GAMINGGEN12 = 'G12'; // IJR-1736 12th genGaming
  // IJR-1734 12th genGaming starts
  static readonly PC_SPECS_MOMORY_ICON = '../../../../assets/images/pc-specs-icons/memory.svg';
  static readonly PC_SPECS_BATTERY_ICON = '../../../../assets/images/pc-specs-icons/battery.svg';
  static readonly PC_SPECS_DISPLAY_ICON = '../../../../assets/images/pc-specs-icons/display.svg';
  static readonly PC_SPECS_OS_ICON = '../../../../assets/images/pc-specs-icons/os.svg';
  static readonly PC_SPECS_STORAGE_ICON = '../../../../assets/images/pc-specs-icons/storage.svg';
  static readonly PC_SPECS_CONNECTIVITY_ICON = '../../../../assets/images/pc-specs-icons/connection.svg';
  static readonly PC_SPECS_WIFI_ICON = '../../../../assets/images/pc-specs-icons/wi-fi.svg';
  static readonly PC_SPECS_OPTANE_MEMORY_ICON = '../../../../assets/images/intel_optane_memory.svg';  // IJR-2252
  static readonly PC_SPECS_GRAPHICS_ICON = '../../../../assets/images/pc-specs-icons/graphics-badge.svg';
  // IJR-1734 12th genGaming ends
  static readonly INTEL_CORE_K_GAMING = 'INTEL_CORE_K_GAMING';
  static readonly PROCESSOR_COMPARE = 'processor-compare';
  static readonly GAMING_12_GEN_PROCESSOR = 'gaming-processorType12';
  static readonly INT099 = 'INT099';
  static readonly INT100 = 'INT100';
  static readonly INT101 = 'INT101';
  static readonly WIFI_12_GEN_BADGE = '../../../../assets/images/badges/wifi-badge.svg';
  static readonly GRAPH3_WIFI = 'graph3-wifi';
  static readonly AVENGERS_NAME = 'avengers';
  static readonly ESL_NAME = 'esl';
  static readonly INFLUENCER_NAME = 'influencer';
  static readonly VR_NAME = 'vr';
  static readonly I5_GAMING_TILE = '../../../../assets/images/gaming/i5_gaming_tile.svg';
  static readonly I7_GAMING_TILE = '../../../../assets/images/gaming/i7_gaming_tile.svg';
  static readonly I9_GAMING_TILE = '../../../../assets/images/gaming/i9_gaming_tile.svg';
  static readonly GAMING_TILE_PANEL = 'gaming-tile-panel';
  static readonly AVENGERS_VIDEO_TYPE = ['en-us', 'fr', 'de', 'ja', 'ko', 'pl', 'pt-br', 'ru', 'zh-cn', 'es-mx', 'th', 'zh-tw', 'vi'];
  static readonly AVENGERS_THUMBNAIL_TYPE = ['uk', 'tr', 'sv', 'es', 'sk', 'ro', 'pt', 'nb', 'it', 'hu', 'he', 'el', 'fi', 'cs', 'da', 'nl'];
  static readonly NO_MORE_AVENGERS = ['id', 'ar-sa', 'is'];
  static readonly THUNDERBOLT_GRAPH = '../../../../assets/images/thunderbolt-graph.png';
  static readonly UAT_DOMAIN = 'www.instorecontactless-uat.com'; // GLS UAT testing
  static readonly SPECS = '/specs/';
  static readonly MOB_CONTENT_PATH = '/mob-content';
  static readonly CHAT_BOT = 'Chat bot';
  static readonly BUTTON_CLICK = 'Button Click';
  static readonly NEW_KILLER_AX1690 = 'KILLER_WIFI_6E_AX1690'; // Correct model
  static readonly INT087 = 'INT087';  // UAT-337
  // standard 12th Gen starts
  static readonly STD_PC_SPECS_MEMORY_ICON = '../../../../assets/images/pc-specs-icons/Standard/memory_alt.svg';
  static readonly STD_PC_SPECS_GRAPHICS_ICON = '../../../../assets/images/pc-specs-icons/Standard/graphics.svg';
  static readonly PC_SPECS_ARC_GRAPHICS_ICON='../../../../assets/images/arc-badge.svg';
  static readonly STD_PC_SPECS_DISPLAY_ICON = '../../../../assets/images/pc-specs-icons/Standard/display.svg';
  static readonly STD_PC_SPECS_OS_ICON = '../../../../assets/images/pc-specs-icons/Standard/OS.svg';
  static readonly STD_PC_SPECS_STORAGE_ICON = '../../../../assets/images/pc-specs-icons/Standard/storage.svg';
  static readonly STD_PC_SPECS_BATTERY_ICON = '../../../../assets/images/pc-specs-icons/Standard/battery.svg';
  static readonly STD_PC_SPECS_CONNECT_ICON = '../../../../assets/images/pc-specs-icons/Standard/connectivity.svg';
  static readonly STD_PC_SPECS_WIFI_ICON = '../../../../assets/images/pc-specs-icons/Standard/Wi-Fi.svg';
   // standard 12th gen ends
  static readonly DAS_NAME = 'das';
  static readonly DAS_TITLE = 'DAS';
  static readonly DAS_TRONC_VALUE = 'DAS tronc: [XX] W/kg';
  static readonly DAS_MEMBRE_VALUE = 'DAS membre: [XX] W/kg';
  static readonly DAS_TETE_VALUE = 'DAS tête: [XX] W/kg';
  static readonly DAS_DETAIL_VALUE = `On entend par " débit d'absorption spécifique " de l'énergie (DAS) le débit avec lequel l'énergie produite par un équipement est absorbée par une unité de masse du tissu du corps et exprimée en watts par kilogramme (W/ kg), mesuré sur l'ensemble du corps ou sur une de ses parties. Le DAS maximal autorisé est de 2 W/kg pour la tête et le tronc et de 4 W/kg pour les membres.`;
  static readonly DAS_HEADER_VALUE = 'DAS (débit d’absorption spécifique)';
  static readonly DAS_PANEL_TYPE = 'paneltype-das';
  static readonly PC_SPECS_DAS_ICON = '../../../../assets/images/pc-specs-icons/das.svg';
  static readonly PC_SPECS_DAS_BADGE = '../../../../assets/images/12th_gen_gaming_das.svg';
  static readonly PC_SPECS_NON_GAMING_DAS = '../../../../assets/images/pc-specs-icons/non-gaming-das.svg';
  static readonly ENTERTAINMENT_NAME = 'entertainment';
  static readonly PRODUCTIVITY_NAME = 'productivity';
  static readonly CONTENT_NAME = 'content';
  static readonly LOOKINSIDE_NAME = 'lookinside';
  static readonly BATTERY_BADGE = '../../../../assets/images/badges/battery-badge.svg';
  static readonly STANDARD_TITLE = 'Standard';
  static readonly DEFAULT_TITLE = 'Default';
  static readonly P_CORE = '[p_core]';
  static readonly E_CORE = '[e_core]';
  static readonly P_THREAD = '[p_thread]';
  static readonly E_THREAD = '[e_thread]';
  static readonly UWP = 'UWP'; // IJR-2118
  static readonly PROCESSOR_BADGE_LABEL = '[processor_badge]';
  static readonly I3_UWP_TILE_BADGE = 'i3_uwp_tile_badge';
  static readonly I5_UWP_TILE_BADGE = 'i5_uwp_tile_badge';
  static readonly I7_UWP_TILE_BADGE = 'i7_uwp_tile_badge';
  static readonly I9_UWP_TILE_BADGE = 'i9_uwp_tile_badge';
  // 12th gen standard start
  static readonly INT102 = 'INT102';
  static readonly INT103 = 'INT103';
  static readonly INT104 = 'INT104';
  static readonly INT105 = 'INT105';
  // 12th gen standard end
  static readonly BLACK_TICK = '../../../../assets/images/processorCompareTickBlack.svg';
  static readonly CONNECTIVITY_VERSION_3_BADGE = '../../../../assets/images/badges/connectivity-badge.svg'; // IJR-1891
  static readonly DISPLAY_BADGE_12TH_STD = '../../../../assets/images/display-badge-12th-std.svg';
  static readonly DISPLAY_BADGE_12TH_CREATOR_11TH = '../../../../assets/images/standard12-std-display-badge.png';
  static readonly PROCESSOR_VALUE = '[processor_value]';
  static readonly STORAGE_VALUE = '[storage_value]';
  static readonly DISPLAY_VALUE = '[display_value]';
  static readonly MEMORY_VALUE = '[memory_value]';
  static readonly GRAPHICS_VALUE = '[graphics_value]';
  static readonly WIFI_VALUE = '[wifi_value]';
  static readonly BATTERY_VALUE = '[battery_value]';
  static readonly GAMING_TILE_PANEL2 = 'gaming-tile-panel2';
  static readonly QR_SERVICE = 'qrService';
  static readonly TILE_SERVICE = 'tileService';
  static readonly BATTERY_VALUE_UPPERCASE = 'tr_batteryHoursTextUpperCase';

  static readonly GRAPHICS_12THGEN_NONIRIX_ICON = '../../../../assets/images/secondary_12thGenStd_graphics.svg'; //IJR-2066 Multiple Graphics
  // IJR-1831 start
  static readonly KILLER6E_1690_BADGE = '../../../../assets/images/standard_wifi_killer_AX1690_badge.svg';
  static readonly WIFI_MODELS_1675 = '1675';
  static readonly WIFI_MODELS_1690 = '1690';
  static readonly TEXT_PNG = 'png';
  static readonly TEXT_SVG = 'svg';
  // IJR-1831 end
  static readonly LOOKINSIDEPC_DESKTOP = '../../../../assets/images/standard/lookinside-desktop-tile.svg'; // IJR-2918
  static readonly I3_CREATOR_TILE_BADGE = 'i3_tile';
  static readonly I5_CREATOR_TILE_BADGE = 'i5_tile';
  static readonly I7_CREATOR_TILE_BADGE = 'i7_tile';
  static readonly I7_CREATOR_SPECIAL_EDITION_BADGE = 'i7_special_tile';
  static readonly I9_CREATOR_TILE_BADGE = 'i9_tile';
  static readonly I5_CREATOR_TILE_BADGE_XE_GRAPHICS = 'i5-IrisXe_tile';
  static readonly I5_CREATOR_TILE_BADGE_XEMAX_GRAPHICS = 'i5-IrisXeMax_tile';
  static readonly I7_CREATOR_TILE_BADGE_XE_GRAPHICS = 'i7-IrisXe_tile';
  static readonly I7_CREATOR_TILE_BADGE_XEMAX_GRAPHICS = 'i7-IrisXeMax';
  static readonly I7_SPECIAL_BADGE_XE_GRAPHICS = 'i7-special-IrisXe';
  static readonly I7_SPECIAL_BADGE_XEMAX_GRAPHICS = 'i7-special-IrisXeMax';
  static readonly INT030 = 'INT030';
  static readonly INT043 = 'INT043';
  static readonly INT074 = 'INT074';  // special edition i7
  static readonly INT086 = 'INT086';
  static readonly INT088 = 'INT088';
  // G11 Gaming Tile
  static readonly I5_G11_GAMING_TILE = 'assets/images/gaming/H45_i5_gaming_tile.svg';
  static readonly I7_G11_GAMING_TILE = 'assets/images/gaming/H45_i7_gaming_tile.svg';
  static readonly I9_G11_GAMING_TILE = 'assets/images/gaming/H45_i9_gaming_tile.svg';
  // G11 Gaming Tile end
  static readonly INTEL_6E = 'INTEL_6E';
  static readonly SINGLE_GRAPHICS = 'S';
  static readonly GAMING_PANEL_TYPE1 = 'gaming-panelType1';
  static readonly EVOCONTENT_NAME = 'evocontent';
  static readonly CREATEMORE_NAME = 'createmore';
  static readonly CONNECTMORE_NAME = 'connectmore';
  static readonly ACHIEVEMORE_NAME = 'achievemore';
  static readonly I5_TEXT = 'i5';
  static readonly I7_TEXT = 'i7';
  static readonly CPU_BADGE_VALUE = '[cpu_fam]';
  static readonly CONNECTIVITY_VALUE = '[connectivity_value]';
  static readonly EVO_CONTENT_TILE_PANEL = 'evo-content-tile-panel';
  // IIT2-2221 start
  static readonly CHROMEBOOKEVO = 'CEVO';
  static readonly ENDLESSPROGRAMSANDAPPS_ICON = '../../../../assets/images/endlessprogramsandapps.svg';
  static readonly ALWAYSONSECURITY_ICON = '../../../../assets/images/alwaysonsecurity.svg';
  // IIT2-2221 end
  // 12th gen gaming Non K series
  static readonly INT108 = 'INT108';
  static readonly AVENGERS_TILE_PNG = '../../../../assets/images/gaming/avengers-tile-image.png';
  static readonly I9_UWP_TILE_BADGE_PNG = '../../../../assets/images/standard/i9_uwp_tile_badge.png';
  static readonly FACT_TAG_CLICKS = 'Fact tag clicks';  // II-3852
  static readonly OPTANE_MEMORY_TEXT = 'optane memory';
  static readonly VIEW_ALL_SPECS_TEXT = 'view all specs';
  static readonly VIEW_MORE_TEXT = 'view more';
  // IIT2-2223 start
  static readonly CHROMEBOOKTEXT = 'CHROMEBOOK';
  static readonly CHROMEBOOKEVOTEXT = 'CHROMEBOOKEVO';
  // IIT2-2223 end

  // IIT2-2254 start
  static readonly STANDARD_TEXT = 'S';
  static readonly I3_TEXT_CAPS = 'I3';
  static readonly I5_TEXT_CAPS = 'I5';
  static readonly I7_TEXT_CAPS = 'I7';
  static readonly I9_TEXT_CAPS = 'I9';
  static readonly SPECIAL_EDITION_TEXT = 'SPECIAL_EDITION';
  // IIT2-2254 end

  static readonly ARCGRAPHICS_IMG = '../../../../assets/images/arc-full-img.svg'; // IIT2-2350
  static readonly ARC_PANEL_HEADING = 'header-BadgeIconTop-arc';
  static readonly EVO_VIDEO_PANEL = 'evo-mtl-video-panel';
  static readonly INTEL_INSIDE_PANEL = 'intel-inside-image-panel';
  static readonly WINDOWS11_CONTENT_PATH = 'assets/windows11-html-content/index.html'; // IIT2-2231-windows11
  static readonly  ARC_PANEL_GRAPHICS_CONTENT = 'graphics';
  //IIT2-2499 start
  static readonly G12_Gaming_Laptop_Tile_i5 = '../../../../assets/images/gaming/i5_H_HomePg_Tile_Gaming_Laptop.svg';
  static readonly G12_Gaming_Laptop_Tile_i7 = '../../../../assets/images/gaming/i7_H_HomePg_Tile_Gaming_Laptop.svg';
  static readonly G12_Gaming_Laptop_Tile_i9 = '../../../../assets/images/gaming/i9_H_HomePg_Tile_Gaming_Laptop.svg';
  //IIT2-2499 end
  static readonly legacyProcessorArray = ['INT006','INT063','INT064','INT065','INT066','INT067','INT068','INT005','INT062','INT111'];  // IIT2-2254
  
  //IIT2-2869
  static readonly GEN11_EVO = '11GEN_EVO';
  static readonly GEN12_EVO = '12GEN_EVO';
  static readonly EVO_REDESIGN_ULTRA_5 = 'EVO_REDESIGN_ULTRA_5';
  static readonly EVO_REDESIGN_ULTRA_7 = 'EVO_REDESIGN_ULTRA_7';
  static readonly STD_MTL_REDESIGN_ULTRA_5 = 'MTL_STD_REDESIGN_ULTRA_5';
  static readonly STD_MTL_REDESIGN_ULTRA_7_9 = 'MTL_STD_REDESIGN_ULTRA_7_9';

  static readonly EVO_12GEN_PROCESSOR_BADGES = ['INT109', 'INT110', 'INT118']; // IIT2-2871 
  static readonly IRIS_GRAPHICS_BADGES = ['GIN007', 'GIN008'];
  // IIT2-2986
  static W11S = 'W11S';
  static W10 =  'W10' ;
  static W10S = 'W10S';

  static readonly XBOX = 'xboxcontent';
  static readonly STANDARD11 = 'STANDARD_11'; // IIT2-2887

  static readonly INTEL_IRIS_PLUS = '../../../../assets/images/badges/intel-IRIS-plus.png'; // IIT2-3304
  // IIT2-2538
  static readonly INT019 = 'INT019';
  static readonly INT034 = 'INT034';
  static readonly i3_UWP_TILE_DESKTOP = 'assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png';
  static readonly i7_UWP_TILE_DESKTOP = 'assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png';
  static readonly SRT = 'SRT';
  static readonly SAVED_SET = 'Saved set';
  static readonly ADD_TO_COMPARE_SET = 'Add to compare set';
  static readonly SSDANDHDD = 'SSD AND HDD';
  static readonly OPTANE_HYBRID = 'OPTANE HYBRID';
  // google analytics os names
  static readonly WINDOWS_10 = 'WINDOWS 10';
  static readonly WINDOWS_10_UWP = 'WINDOWS 10 UWP';
  static readonly WINDOWS_10_SMODE = 'WINDOWS 10 S MODE';
  static readonly WINDOWS_11 = 'WINDOWS 11';
  static readonly WINDOWS_11_UWP = 'WINDOWS 11 UWP';
  static readonly WINDOWS_11_SMODE = 'WINDOWS 11 S MODE';
  static readonly CHROMEOS = 'CHROME OS';
  static readonly LINUXOS = 'LINUX OS';
  static readonly WINDOWS_GENERIC_TEXT = 'WINDOWS GENERIC';
  static readonly IS_SMALL_CASE = 'is'; // IIT2-4403
  // google analytics
  static readonly TILE_CLICKS = 'Tile Clicks';
  static readonly SCREEN_BUTTONS = "Screen Buttons";
  static readonly FACTTAG = "facttag";
  static readonly TILE = "tile";
  static readonly LEARN_MORE = "learn_more";
  static readonly IPOS_INSTALLATION_TEXT = 'IPOS installation';
  static readonly IPOS_CONTENT_TEXT = 'IPOS content';
  static readonly GAMING_CONTENT = 'G';
  static readonly ATHENA_CONTENT = 'ath';
  static readonly GAMING_NAME = 'Gaming';
  static readonly NON_INTEL_CONTENT = 'Non Intel';
  static readonly CHROMEBOOK_ATHENA_TEXT = 'Chromebook Athena';
  static readonly CHROMEBOOK_EVO_TEXT = 'Chromebook Evo';
  static readonly CHROMEBOOK_TEXT = 'Chromebook';
  // google analytics
  static readonly INTEL_PENTIUM_1_BADGE = 'assets/images/processor/INTEL_PENTIUM_1.svg';
  static readonly INTEL_CELERON_1_BADGE = 'assets/images/processor/INTEL_CELERON_1.svg';
  static readonly TR_STORAGE_TITLE = "tr_storageTitle";
  static readonly TR_GRAPHICS_TITLE = "tr_graphicTitle";
  static readonly TR_MEMORY_TITLE = "tr_memoryTitle";
  static readonly TR_DISPLAY_TITLE = "tr_displayTitle";
  static readonly TR_PRICE_TITLE = "tr_priceTitle";
  static readonly EVENT = 'event';
  // HX-Gaming IIT2-4645 start
  static readonly INTEL_CORE_HX_GAMING = 'INTEL_CORE_HX_GAMING';
  static readonly INT112 = 'INT112';
  static readonly INT113 = 'INT113';
  // HX-Gaming IIT2-4645 end
  // HX-Std
  static readonly INT114 = 'INT114';
  static readonly G12_Gaming_Hx_i7 = '../../../../assets/images/gaming/i7_Hx_HomePg_Tile_Gaming.svg';
  static readonly G12_Gaming_Hx_i9 = '../../../../assets/images/gaming/i9_Hx_HomePg_Tile_Gaming.svg';
  static readonly G12_Gaming_H_i9 = '../../../../assets/images/gaming/i9_H_HomePg_Tile.svg';
  static readonly G12_Gaming_K_i9 = '../../../../assets/images/gaming/Tile_K45_i9.svg';
  static readonly EVOUWP_11GEN = '11GEN_EVO_UWP'; // IIT2-4639
  static readonly EVOUWP_12GEN = '12GEN_EVO_UWP'; // IIT2-4639
  static readonly GEN12_CREATOR_I5 = '../../../../assets/images/standard/i5_uwp_tile_badge.svg'; //IIT2-4760
  static readonly GEN12_CREATOR_I7 = '../../../../assets/images/standard/i7_uwp_tile_badge.svg';
  static readonly GEN13_CREATOR_I7 = '../../../../assets/images/standard/i7_uwp_tile_badge.svg'; //IIT2-4760
  static readonly WHITE_TICK = '../../../../assets/images/processorCompareTick.svg'; // IIT2-4762
  static readonly CREATOR_12 = 'CREATOR_12';
  static readonly UNLOCKED_UNSUPPORTED_PROCESSOR_BADGES = ['INT072', 'INT073', 'INT074'];
  static readonly CHECK_Y = 'Y';
  static readonly VERSION_6_KILLER = 'VERSION_6_KILLER';
  static readonly MINUS_ONE = -1;
  static readonly tr_viewallspecs = "tr_viewallspecs";
  static readonly tr_operatingSystem = "tr_operatingSystem";
  static readonly tr_batteryTitle = "tr_batteryTitle";
  static readonly tr_processorTitle = "tr_processorTitle";
  static readonly tr_viewallspecsCamel = "tr_viewallspecsCamel";
  static readonly tr_backToScan = "tr_backToScan";
  static readonly tr_scanToCompare = "tr_scanToCompare";
  static readonly VPRO_12THGEN_BADGES = ['INT115', 'INT116', 'INT117'];
  static readonly MAX_TURBO_FREQUENCY = '[max_turbo_frequency]';
  static readonly STANDARD12_VPRO = 'STANDARD12_VPRO'; // IIT2-5562
  static readonly GEN12_VPRO_I5 = 'assets/images/standard/VPRO12_i5.svg';
  static readonly GEN12_VPRO_I7 = 'assets/images/standard/VPRO12_i7.svg';
  static readonly VPRO_TILEDETAILS = [{"tr_tileTitle": "","tileUrlName": 'processor',"tileBadgeUrl": ""}];
  static readonly VPRO_I5 = 'VPRO12_i5';
  static readonly VPRO_I7 = 'VPRO12_i7';
  static readonly HOMETILE_VPRO_I5 = 'assets/images/standard/i5_VPRO.svg';
  static readonly HOMETILE_VPRO_I7 = 'assets/images/standard/i7_VPRO.svg';
  static readonly INT115 = 'INT115';
  static readonly INT116 = 'INT116'; 
  static readonly WINDOWS11_FOLDER_PATH = 'assets/windows11-content/modernLifeHTML/index.html?language=';
  static readonly INT117 = 'INT117'; // IJR-4300
  static readonly HOMETILE_VPRO_I9 = 'assets/images/standard/i9_VPRO.svg';
  static readonly VPRO_I9 = 'VPRO12_i9';
  static readonly CHROMEBOOK_GENERATION_12 = 'C12';
  static readonly I9_TEXT = 'i9';
  static readonly CHROMEBOOKEVOGEN12 = 'CHROMEBOOKEVOGEN12'; // IIT2-6337
  static readonly RETAILER_ID = 'Retailer Id';
  static readonly RETAILER_NAME = 'Retailer Name';
  static readonly AUTO_DETECT = 'Auto Detect';
  static readonly NOT_AVAILABLE = 'NA'; // IIT2-6690
  static readonly SAMSUNGOLED = 'samsungOled';
  static readonly DCI_P3 = 'DCI-P3';
  static readonly SAMSUNG_OLED_ICON = '../../../../assets/images/pc-specs-icons/samsung_oled.svg';
  static readonly tr_samsung_oled = 'tr_samsung_oled';
  static readonly i3 = ['INT016', 'INT017', 'INT018', 'INT019', 'INT084', 'INT102'];
  static readonly i5 = ['INT027', 'INT028', 'INT029', 'INT030', 'INT069', 'INT072',
    'INT075', 'INT080', 'INT085', 'INT089', 'INT095', 'INT099', 'INT103', 'INT108', 'INT109'];
  static readonly i7 = ['INT040', 'INT041', 'INT042', 'INT043', 'INT070', 'INT073',
    'INT074', 'INT076', 'INT081', 'INT090', 'INT096', 'INT098', 'INT100', 'INT104', 'INT110', 'INT112'];
  static readonly i9 = ['INT071', 'INT077', 'INT079', 'INT082', 'INT083', 'INT086',
    'INT087', 'INT088', 'INT091', 'INT093', 'INT097', 'INT101', 'INT105', 'INT106', 'INT113', 'INT114', 'INT117', 'INT118'];
  static readonly vproDevices = ['INT021','INT033','INT047','INT048','INT054','INT115','INT116','INT117', 'INT163','INT164','INT165'];
  static readonly OS_TYPE = 'OS type';
  static readonly PROCESSOR_MANUFACTURER = 'Processor Manufacturer';
  static readonly PROCESSOR_GENERATION = 'Processor generation';
  static readonly PROCESSOR_FAMILY = 'Processor family';
  static readonly SAMSUNG_OLED = 'Samsung Oled';
  static readonly PANEL_TYPE = 'Panel type';
  static readonly ATHENAEVO = 'Athena / EVO';
  static readonly VPRO = 'vPro';
  static readonly CHAT_ACCEPTED_BY_AGENT = 'Chat accepted by Agent';
  static readonly VRAM = 'vram';
  static readonly CLOCK_SPEED = 'clockspeed';
  static readonly STANDARD_REBUILD_I3 = 'STANDARD_REBUILD_I3';
  static readonly STANDARD_REBUILD_I5 = 'STANDARD_REBUILD_I5';
  static readonly STANDARD_REBUILD_I7 = 'STANDARD_REBUILD_I7';
  static readonly STANDARD_REBUILD_I9 = 'STANDARD_REBUILD_I9';
  static readonly CREATOR_REBUILD_I5 = 'CREATOR_REBUILD_I5';
  static readonly CREATOR_REBUILD_I7 = 'CREATOR_REBUILD_I7';
  static readonly CREATOR_REBUILD_I9 = 'CREATOR_REBUILD_I9';

  static readonly STREAMING = 'streaming';
  static readonly WEB_BROWSING = 'webbrowsing';
  static readonly VIDEO_CHATTING = 'videochatting';
  static readonly HIGHSPEED = 'highspeed';
  static readonly CASUAL_GAMING = 'casualgaming';
  static readonly PHOTO_EDITING = 'photoediting';
  static readonly REALTIME = 'realtime';
  static readonly VIDEO_EDITING = 'videoediting';
  static readonly THREE_D_MODELING = 'threedmodeling';

  static readonly STANDARDGEN13 = 'S13';
  static readonly GAMINGGEN13 = 'G13';
  static readonly EVO_13GEN_PROCESSOR_BADGES = ['INT122', 'INT123', 'INT124'];
  static readonly GEN13_EVO = '13GEN_EVO';
  static readonly INT125 = 'INT125';
  static readonly INTEL_CORE_I9_13TH_GEN_K = 'INTEL_CORE_I9_13TH_GEN_K';
  static readonly INTEL_CORE_HX_GAMING_13 = 'INTEL_CORE_HX_GAMING_13';
  static readonly EVOUWP_13GEN = '13GEN_EVO_UWP';
  static readonly INTEL_CORE_13TH_GEN_GAMING = 'INTEL_CORE_13TH_GEN_GAMING';

  static readonly INT126 = 'INT126';
  static readonly INT127 = 'INT127';
  static readonly INT128 = 'INT128';
  static readonly INT129 = 'INT129';
  static readonly INT130 = 'INT130';
  static readonly INT131 = 'INT131';
  static readonly INT132 = 'INT132';
  static readonly INT133 = 'INT133';
  static readonly INT134 = 'INT134';
  static readonly INT135 = 'INT135';
  static readonly INT136 = 'INT136';
  
  static readonly INT137 = 'INT137';

  static readonly INTEL_INSIDE="INTEL_INSIDE";
  static readonly STANDARD_AI_IPOS = "S14_AI_IPOS";
  static readonly EVO_AI_IPOS = "EVO_AI_IPOS";
  static readonly GAMING_13TH_BADGES = ['INT126', 'INT127', 'INT128', 'INT129', 'INT130', 'INT131'];
  static readonly GEN13GAMING = 'gen13Gaming';
  static readonly THIRD_PARTY_GRAPHICS_BADGES = ['GAM000', 'GNV000', 'GUN000'];
  static readonly G13_Gaming_HX_i9 = '../../../../assets/images/gaming/i9_HX_home.svg';
  static readonly INTEL_CORE_I9_CREATOR = "INTEL_CORE_I9_CREATOR";
  static readonly HE_LANGUAGE = 'he';
  static readonly ARC_GRAPHICS_FALLBACK = 'assets/images/arc_graphics/fallback_arc_graphics.svg';
  static readonly INTEL_PROCESSOR_BADGES = ['INT137', 'INT138'];
  static readonly INTEL_INSIDE_I3_BADGE = 'assets/images/processor/INTEL_CORE_I3_9.svg';
  static readonly INTEL_INSIDE_I3_HOME_TILE_BADGE = 'assets/images/standard/13th_gen_i3_std_tile_badge.png';
  static readonly HUNGARY_LANG_CODE = 'hu';
  static readonly UNISON_TEXT = 'unison';
  static readonly CONTENT_TYPESS = ['S11', 'S12', 'S13', 'R11', 'R12', 'R13', 'S14'];
  static readonly UNDERSCORE_WHITE = '_WHITE'
  static readonly BUYNOW = 'tr_textBuyNow';
  static readonly WALMART = 'Walmart.com';
  static readonly SAMCLUB = 'samsclub';
  static readonly W = 'W';
  static readonly S= 'S';
  static readonly OPEN_NEW_TAB= '_blank';
  static readonly BUYNOWTEXTPOPUP = 'buynow-text-popup';
  static readonly TRYAGAIN = 'tr_textTryagain';
  static readonly BUYNOWTEXTERR = 'tr_textBuynowErr';
  static readonly MODEL_AX101 = 'MODEL_AX101';
  static readonly INTEL_WIFI_6_AX101 = "INTEL_WIFI_6_AX101";
  static readonly VIDEO_PLAY = "Video Play";
  static readonly RESET_CHATBOT = "Reset chat bot";
  static readonly CLOSE_CHATBOT = "Close chat bot";
  static readonly OPEN_CHATBOT = "Open chat bot";
  static readonly USER_MESSAGE = "User message";
  static readonly BOT_MESSAGE = "Bot message";
  static readonly HOME_CLICK = "Home Button Click"
  static readonly SCAN_TO_COMPARE = "Scan to add device";
  static readonly DELETE_DEVICE = "Deleted device";
  static readonly COMPARE_DEVICE = "Compare device";
  static readonly HOME_BUTTON = 'Home button';
  static readonly SCANNED_MOBILE_DEVICE_INFO = 'scanned_mobile_device_information';
  static readonly COMPARE_ACTION_TRACKER = "compare_action_tracker";
  static readonly FALLBACK = "Fall back"; 
  static readonly TRANSFER_TO_AGENT = "Transfer to agent"; 
  static readonly EVO_VPRO_BADGES = {
    'INT109': 'assets/images/evo/Intel_Evo_vPRO_i5.svg',
    'INT110': 'assets/images/evo/Intel_Evo_vPRO_i7.svg',
    'INT118': 'assets/images/evo/Intel_Evo_vPRO_i9.svg',
    'INT069': 'assets/images/evo/EVO_VPRO_I5.svg',
    'INT070': 'assets/images/evo/EVO_VPRO_I7.svg',
    'INT122': 'assets/images/evo/Intel_Evo_vPRO_i5.svg',
    'INT123': 'assets/images/evo/Intel_Evo_vPRO_i7.svg',
    'INT124': 'assets/images/evo/Intel_Evo_vPRO_i9.svg',
  };
  static readonly INT120 = 'INT120';
  static readonly INT121 = 'INT121';
  static readonly INTEL_INSIDE_IMAGE = 'assets/images/intel_inside/panel1_bg.svg';
  static readonly INT138 = 'INT138';
  static readonly INTEL_INSIDE_13 = 'INTEL_INSIDE_13';
  static readonly VPRO_EVO_I5_BADGE = 'assets/images/evo/EVO_VPRO_i5_tile.svg';
  static readonly VPRO_EVO_I7_BADGE = 'assets/images/evo/EVO_VPRO_i7_tile.svg';
  static readonly VPRO_EVO_I9_BADGE = 'assets/images/evo/EVO_VPRO_i9_tile.svg';
  static readonly VPRO_EVO_I5_BADGE_1 = 'assets/images/evo/EVO_VPRO_i5_tile_1.svg';
  static readonly VPRO_EVO_I7_BADGE_1 = 'assets/images/evo/EVO_VPRO_i7_tile_1.svg';
  static readonly VPRO_EVO_I9_BADGE_1 = 'assets/images/evo/EVO_VPRO_i9_tile_1.svg';
  static readonly MOBILE_ARC_CONTENT = 'Graphics_Boost_Workflow';
  static readonly DESKTOP_ARC_CONTENT = 'Graphics_Level_Up_Your_Game';
  static readonly ARC_PANEL_CONTENT = 'Arc_Graphics_Panel_Content';
  static readonly GAMINGGEN14 = 'G14';
  static readonly INTEL_CORE_ABOVE13TH_GEN_GAMING = 'INTEL_CORE_ABOVE13TH_GEN_GAMING';
  static readonly INT139 = 'INT139';
  static readonly INT140 = 'INT140';
  static readonly INT141 = 'INT141';
  static readonly INT142 = 'INT142';
  static readonly INT143 = 'INT143';
  static readonly INT144 = 'INT144';
  static readonly GAMING_14TH_BADGES = ['INT139', 'INT140', 'INT141', 'INT142', 'INT143', 'INT144'];
  static readonly STANDARDGEN14 = 'S14';
  static readonly INT145 = 'INT145';
  static readonly INT146 = 'INT146';
  static readonly INT147 = 'INT147';
  static readonly INT148 = 'INT148';
  static readonly STD_14TH_BADGES = ['INT145', 'INT146', 'INT147', 'INT148'];
  static readonly INTEL_CORE_14TH_GEN_GAMING = 'INTEL_CORE_14TH_GEN_GAMING';
  static readonly INT149 = 'INT149';
  static readonly INT150 = 'INT150';
  static readonly INT151 = 'INT151';
  static readonly STD_1ST_ULTRA_BADGES = ['INT149', 'INT150', 'INT151'];
  static readonly GAMING_1ST_GEN_ULTRA_BADGES = ['INT152', 'INT153', 'INT154'];
  static readonly INTEL_CORE_1ST_GEN_ULTRA_GAMING = 'INTEL_CORE_1ST_GEN_ULTRA_GAMING';
  static readonly INT152 = 'INT152';
  static readonly INT153 = 'INT153';
  static readonly INT154 = 'INT154';
  static readonly ULTRA_5_TEXT = 'ULTRA_5';
  static readonly ULTRA_7_TEXT = 'ULTRA_7';
  static readonly ULTRA_9_TEXT = 'ULTRA_9';
  static readonly L_CORE = '[l_core]';
  static readonly L_THREAD = '[l_thread]';
  static readonly EVO_MTL_BADGES = ['INT157' , 'INT158' , 'INT159'];
  static readonly STD_MTL_BADGES = ['INT160' , 'INT161' , 'INT162'];
  static readonly VPRO_13TH_GEN_BADGE = ['INT163', 'INT164', 'INT165'];
  static readonly INT157 = 'INT157';
  static readonly INT158 = 'INT158';
  static readonly INT159 = 'INT159';
  static readonly INT160 = 'INT160';
  static readonly INT161 = 'INT161';
  static readonly INT162 = 'INT162';
  static readonly INT163 = 'INT163';
  static readonly INT164 = 'INT164';
  static readonly INT165 = 'INT165';
  static readonly MTL_VIDEO = 'evo_std_mtl_processor';
  static readonly MTL_ARC_VIDEO = 'mtl_arc_processor';
  static readonly WIFI_VALUE_7 = 7;
  static readonly INTEL_WIFI_7 = 'INTEL_WIFI_7';
  static readonly VERSION_7 = 'VERSION_7';
  static readonly KILLER_WIFI_7 = 'KILLER_WIFI_7';
  static readonly EVO_STD_MTL_PROC = 'assets/lang/en-us/redesign/evo_std_mtl_processor.mp4';
  static readonly INTEL_RPL_MTL_vPro = 'INTEL_RPL_MTL_vPro';
  static readonly CHROME_RPL_MTL_BADGES = ['INT139','INT140','INT141','INT142','INT143','INT144','INT145','INT146','INT147','INT148'];
  static readonly MTL_ARC_PROCESSOR_POSTER ="assets/images/processor/mtl_video_processor_poster.png";
  static readonly MTL_PROCESSOR_POSTER ="assets/images/processor/mtl_non_arc_video_processor_poster.png";  
  static readonly CHROME_MTL_BADGES = ['INT139','INT140','INT141'];
  static readonly CHROME_RPL_BADGES = ['INT142','INT143','INT144','INT145','INT146','INT147','INT148'];
  static readonly VPRO_BANNER = "assets/lang/[XX]/INTEL_VPRO_ULTRA.svg"
  static readonly ARC_A5XXM_SERIES = ['A530M', 'A550M', 'A570M'];
  static readonly ARC_A5XXM = 'A5XXM';
  static readonly ARC_PRO = 'ARC002';
  static readonly ARC_PRO_MOBILE = 'ARC003';
  static readonly AMD_PROC_BADGE = 'UNK000';
  static readonly QUALCOMM_PROC_BADGE = 'UNK001';
  static readonly INT166 = 'INT166';
  static readonly INT167 = 'INT167';
  static readonly INT168 = 'INT168';
  static readonly INT169 = 'INT169';
  static readonly LUNAR_BADGES = ['INT166', 'INT167', 'INT168', 'INT169'];
  static readonly LUNAR_ULTRA_5 = 'LNL_STD_REDESIGN_ULTRA_5';
  static readonly LUNAR_ULTRA_7 = 'LNL_STD_REDESIGN_ULTRA_7';  
  static readonly STD_LNL_BADGES = ['INT168', 'INT169'];
  static readonly EVO_LNL_BADGES = ['INT166', 'INT167'];
  static readonly GAMING_AI_IPOS = 'GAMING_AI_IPOS';
  
}
