import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/common/constants/global.constant';
 import { LibraryV2Data, SpecDetails } from '../../../core/interfaces/library-v2-data.i';
 import {Panel} from '../../../core/interfaces/library-v2-data.i';
 import {mapping} from '../../../mapping.js';
 import { QrConstants } from '../qr-input-service/qr.constants';

declare const Library_mobile: any;
declare const Library_mobile_arSA: any;
declare const Library_mobile_cs: any;
declare const Library_mobile_da: any;
declare const Library_mobile_de: any;
declare const Library_mobile_el: any;
declare const Library_mobile_esMX: any;
declare const Library_mobile_es: any;
declare const Library_mobile_fi : any;
declare const Library_mobile_fr: any;
declare const Library_mobile_gls: any;
declare const Library_mobile_he: any;
declare const Library_mobile_hu: any;
declare const Library_mobile_id : any;
declare const Library_mobile_is : any;
declare const Library_mobile_it: any;
declare const Library_mobile_ja: any;
declare const Library_mobile_ko: any;
declare const Library_mobile_nl: any;
declare const Library_mobile_nb: any;
declare const Library_mobile_pl: any;
declare const Library_mobile_ptBR: any;
declare const Library_mobile_ptPT: any;
declare const Library_mobile_ro: any;
declare const Library_mobile_ru: any;
declare const Library_mobile_sk: any;
declare const Library_mobile_sv: any;
declare const Library_mobile_th: any;
declare const Library_mobile_tr: any;
declare const Library_mobile_uk: any;
declare const Library_mobile_vi: any;
declare const Library_mobile_zhCN : any;
declare const Library_mobile_zhTW : any;


@Injectable({
  providedIn: 'root'
})

export class LibraryV2DataService {
  libraryV2Data: LibraryV2Data;
  libraryName: LibraryV2Data;
  language: string;
  
  constructor(
  ) { 
    
  }

  getLibraryV2Data(lang: string): any {
    this.language = lang;
    const languageLibraryMapping = this.createLanguageLibraryDataMapping();
    if(this.language !== undefined && this.language !== null){
      if (languageLibraryMapping[this.language?.toLowerCase()]) {
        this.libraryName = languageLibraryMapping[this.language.toLowerCase()];
      }else {
        this.libraryName = Library_mobile;
      }
    }
    else{
      this.libraryName = Library_mobile;
    }
    
    this.libraryV2Data = JSON.parse(JSON.stringify(this.libraryName)) as LibraryV2Data;
    return this.libraryV2Data;
  }

  getStaticText(text: string): any {
    return this.libraryV2Data.uiText[text];
  }

  getHeaders(tabName: string) {
    return this.libraryV2Data.headerTabs
      .find(item => item.tabName === tabName).headerDetails;
  }
  getNavigation(tabName: string) {
    return this.libraryV2Data.headerTabs
      .find(item => item.tabName === tabName).navigationTabs;
  }
  getTrending() {
    return this.libraryV2Data.trending;
  }

  getFactTagsDetails(cpuFamily: string, contentType: string, iposType: string, deviceType: string): any {
    return this.libraryV2Data[contentType]
      .find(item => item.contentType === cpuFamily).iposTypes
      .find(item => item.iposType === iposType).deviceTypes
      .find(item => item.deviceType === deviceType).details     
       
    }
    getArcPages(model:string){
      let {panel1,panel2, panel3,panel4,panel5,panel6,panelAlignment}= mapping[model];    
      let {panel1Align, panel2Align, panel3Align, panel4Align, panel5Align, panel6Align}=panelAlignment;
      const panel1Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel1);
      const panel2Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel2);
      const panel3Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel3);
      const panel4Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel4);
      const panel5Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel5);

  
    let panel: Panel[]=[] ;
     panel.push(this.setPanelData(panel1Data,panel1Align));
     panel.push(this.setPanelData(panel2Data,panel2Align));
     panel.push(this.setPanelData(panel3Data,panel3Align));
     panel.push(this.setPanelData(panel4Data,panel4Align));
     panel.push(this.setPanelData(panel5Data,panel5Align));
     if(panel6 != ""){
      const panel6Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
        GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,"Arc_Graphics_Panel_Content",panel6);
      panel.push(this.setPanelData(panel6Data,panel6Align));
    } 
    return panel;
    }
    
    setPanelData(data:SpecDetails,panelAlign:string) {    
      let panel:Panel={
        noteSection:'',
        panelType:'header-BadgeIconTop-arc',
        titleArray:[
          {
            align:panelAlign,
            title:data.tr_specTitle,
            subtitle:data.tr_specText,
            imgUrl:data.targetURL,
  
          }
        ]
      }  
      return panel;
    }
    getArcGraphicsContent(cpuFamily: string, contentType: string, iposType: string, deviceType: string,factTagType:string,specName:string) {
      return this.libraryV2Data[contentType]
      .find(item => item.contentType === cpuFamily).iposTypes
      .find(item => item.iposType === iposType).factTagTypes
      .find(item=> item.factTagType===factTagType).navText
      .find(item=>item.specName===specName).specDetails
 
  }

  getBackgroundDetails(contentType): string {
    return this.libraryV2Data.backgroundMedia
      .find(item => item.uiType === contentType).backgroundURL;
  }

  getHybridDetails() {
    return this.libraryV2Data.hybridDetails;
  }

  getTilesData(tilesType: string, deviceType: string): any {
    return this.libraryV2Data.tiles.find(item => item.tilesType === tilesType).tilesDeviceTypes
      .find(item => item.deviceType === deviceType).tilesDetails;
  }
  getVrDetails(tilesType: string, deviceType:string):any{
    return this.libraryV2Data.tiles.find(item => item.tilesType === tilesType).tilesDeviceTypes
    .find(item => item.deviceType === deviceType).tilesDetails[3];
  }
  getSamsungOled() {
    return this.libraryV2Data.samsungOled;
  }

  getRebuildTilesData(tilesType: string, deviceType: string): any {
    return this.libraryV2Data.rebuildUsageModel.find(item => item.tilesType === tilesType).tilesDeviceTypes
      .find(item => item.deviceType === deviceType).tilesDetails;
  }

  getUnisonTilesData() {
    return this.libraryV2Data.unison;
  }
  createLanguageLibraryDataMapping() {
    return {
      'en-us': Library_mobile,
      'ar-sa': Library_mobile_arSA,
      'cs': Library_mobile_cs,
      'da': Library_mobile_da,
      'de': Library_mobile_de,
      'el': Library_mobile_el,
      'es-mx': Library_mobile_esMX,
      'es': Library_mobile_es,
      'fi': Library_mobile_fi,
      'fr': Library_mobile_fr,
      'he': Library_mobile_he,
      'hu': Library_mobile_hu,
      'id': Library_mobile_id,
      'is': Library_mobile_is,
      'it': Library_mobile_it,
      'ja': Library_mobile_ja,
      'ko': Library_mobile_ko,
      'nl': Library_mobile_nl,
      'nb': Library_mobile_nb,
      'pl': Library_mobile_pl,
      'pt-br': Library_mobile_ptBR,
      'pt': Library_mobile_ptPT,
      'ro': Library_mobile_ro,
      'ru': Library_mobile_ru,
      'sk': Library_mobile_sk,
      'sv': Library_mobile_sv,
      'th': Library_mobile_th,
      'tr': Library_mobile_tr,
      'uk': Library_mobile_uk,
      'vi': Library_mobile_vi,
      'zh-cn': Library_mobile_zhCN,
      'zh-tw': Library_mobile_zhTW,
      'gls': Library_mobile_gls
    };
  }

  getArcFalbackGraphicsDetails(model) {
    const graphicsContent = model.includes('M') ?  GlobalConstants.MOBILE_ARC_CONTENT : GlobalConstants.DESKTOP_ARC_CONTENT;
    const panel1Data= this.getArcGraphicsContent(QrConstants.ARC001, GlobalConstants.GRAPHICS_NAME,
      GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE,GlobalConstants.ARC_PANEL_CONTENT, graphicsContent);
    let panel: Panel[]=[] ;
    panel.push(this.setPanelData(panel1Data,''));
    return panel;
  }
  
}
