import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../../common/constants/global.constant';
import { LibraryChrombookV2DataService } from '../../../../core/services/library-chromebook-v2/library-chrombook-v2-data.service';

@Injectable({
  providedIn: 'root'
})
export class EndlessprogramsandappsService {

  panel = [];
  constructor(
    private libraryChrombookV2DataService: LibraryChrombookV2DataService
  ) {
  }


displayEndlessProgramsAndApps(): any {
  this.panel = [];
  const data = this.libraryChrombookV2DataService.getFactTagsDetails(GlobalConstants.ENDLESSPROGRAMSANDAPPS).details;
  const panel1 = {
    panelType: GlobalConstants.HEADERBADGEICONTOP,
    title: data.tr_title,
    subTitle: data.tr_subtitle,
  };
  const panel2 = {
    panelType: GlobalConstants.BADGESANDHEADING,
    badgeIcons: data.badgeIcons,
  };
  this.panel.push(panel1);
  this.panel.push(panel2);
  return this.panel;
}
}
