<div id="compareDevice" *ngIf="!loadComponent" class="flex-grow-1 d-flex flex-column" [ngClass]="{'overflow-hidden': scrollhidden,'h-100':scrollhidden}" [ngStyle]="{'background': isChromeBook ? '#CCCCCC' : '' }">
    <div id="compareDeviceHeader" class="mobile_header" [ngClass]="isChromeBook ? 'chrome-background':''" [class.ai-ipos-header]="isAiIpos" [class.nonIntel]="isNonIntel" [class.intelInside]="isIntelInside">
        <div id="compareDeviceText" class="left-header">
            <a [ngClass]="isChromeBook || isIntelInside || (isStdAbove11 && !isMTL) ? 'back-button-chrome':'back-button'" (click)="gotoMobContentPage()"></a>
            <span class="page_name" [ngClass]="[setFontIntelDisplayOne ? 'intelOne-display':'header_other_lang', (isStdAbove11 && !isMTL) ? 'bg_black': '']" [class.pageNameintelInside]="isIntelInside"  [innerHTML]="compareDevicesText"></span>
        </div>
        <button class="btn save-option" [ngClass]="isChromeBook ? 'save-option-chrome':''" *ngIf="displayFactTag.length>1" (click)="print()">
            <span [innerHTML]="saveText"></span>
            <img class="" [ngClass]="isChromeBook ? 'save-file-chrome':'save-file'" alt="save Icon" />
        </button>
    </div>
    <div id="exportsPDF" class="compare-wrapper" [class.nonIntel]="isNonIntel" #exports
        [ngClass]="[(displayFactTag.length===1)?'scan-element':'' || 
        (displayFactTag.length===2)?'scan-element-optional':'' || (displayFactTag.length===3)?'scan-element-optional three-columns':'']">

        <div id="hiddenTable" [ngClass]="[(displayFactTag.length===1)?'parent-cd':'content-wrap']" class="hidden-table">
                <div id="compareDetail" class="compare-detailed">
                    <table class="table table-borderless compare_table" aria-describedby="devicesTable1">
                        <thead>
                            <tr>
                                <th scope="col" *ngFor="let proc of processor; let i = index" class="align-vertical" >
                                    <div id="removeDevice" class="remove-action" *ngIf="displayFactTag.length>1">
                                        <img class="remove-device" alt="Remove Icon" (click)="removeDevice(i)" />
                                    </div>
                                    <div id="oemBridge" class="item-wrapper" *ngIf="isOemBadge"> <!--IIT2-2386--> 
                                        <figure class="figure" *ngIf="deviceDescription[i].title">    <!--IJR-3529-->
                                            <img src="../../../assets/images/{{deviceDescription[i].image}}.svg" alt="logo" onerror="this.style.display='none'"/> <!--IJR-3611 fix added onerroe-->
                                            <figcaption class="figure-caption text-center intelOne-display oem-text-break" [ngClass]="deviceDescription[i].image==='' ? 'top-badge-margin': ''"
                                            (click)="displayFactTag.length>1 && checkDeviceDetails(i)"  [innerHTML]="deviceDescription[i].title"> <!-- Added oem-text-break for IIT2-4252-->
                                            </figcaption>
                                        </figure>
                                    </div>
                                </th>
                            </tr>
                            <ng-container *ngIf="isProcessorAvailable">
                                <tr>
                                    <td *ngFor="let proc of processor" class="processor-wrapper">
                                        <div id="procWrapper" class="item-wrapper">
                                            <div id="procImage" class="spec-badge" *ngIf="proc.image">
                                                <img src="{{proc.image}}" alt="processor"/>
                                            </div>
                                            <div id="procDesc" class="proc-desc" [class.rtllang]="isRTL" [innerHTML]="proc.description"></div>
                                        </div>
                                    </td>
                                </tr>
                                </ng-container>
                        </thead>
                    </table>
                    <div class="horizontal-line" data-html2canvas-ignore="true"></div>
                </div>
                <div id="compareDetail" class="compare-detailed">
                    <table class="table table-borderless compare_table" [class.nonIntel]="isNonIntel" aria-describedby="devicesTable">
                        <thead>
                        <tr>
                            <th scope="col" class="empty-content"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="isOSAvailable">
                                <tr>
                                    <td *ngFor="let os of operatingSystem" [innerHTML]="os.title" class="title_space" id="osTitle"></td>
                                </tr>
                                <tr>
                                    <td *ngFor="let os of operatingSystem">
                                        <div id="osDesc" class="item-wrapper" [innerHTML]="os.description"></div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="isGraphicsAvailable">
                            <tr>
                                <td *ngFor="let graphic of graphics" [innerHTML]="graphic.title" class="title_space"></td>  <!--GLS-306582 -->
                            </tr>
                            <tr>
                                <td class="graphics-td-style" *ngFor="let graphic of graphics">
                                    <div id="graphicsWrapper" class="item-wrapper">
                                        <div id="graphicsImage" class="spec-badge" *ngIf="graphic.image">
                                            <img src="{{graphic.image}}" alt="graphics"/>
                                        </div>
                                        <div id="graphicsDesc" [innerHTML]="graphic.description"></div>
                                    </div>
                                </td>
                            </tr>
                            </ng-container>
                            <ng-container *ngIf="isMemoryAvailable">
                            <tr>
                                <td *ngFor="let mem of memory" [innerHTML]="mem.title" class="title_space"></td>  <!--GLS-306582 -->
                            </tr>
                            <tr>
                                <td *ngFor="let mem of memory">
                                    <div id="memoryDesc" class="item-wrapper" [innerHTML]="mem.description"></div>  <!--GLS-306582 -->
                                </td>
                            </tr>
                            </ng-container>
                            <ng-container *ngIf="isStorageAvailable">
                            <tr>
                                <td *ngFor="let strg of storage" [innerHTML]='strg.title' class="title_space"></td>
                            </tr>
                            <tr>
                                <td *ngFor="let strg of storage">
                                    <div id="storageDesc" class="item-wrapper" [innerHTML]="strg.description"></div>  <!--GLS-306582 -->
                                </td>
                            </tr>
                            </ng-container>
                            <ng-container *ngIf="isDisplayAvailable">
                            <tr>
                                <td *ngFor="let dis of display" [innerHTML]="dis.title" class="title_space"></td>  <!--GLS-306582 -->
                            </tr>
                            <tr>
                                <td *ngFor="let dis of display">
                                    <div id="displayDesc" class="item-wrapper" [innerHTML]="dis.description"></div>  <!--GLS-306582 -->
                                </td>
                            </tr>
                            </ng-container>
                            <ng-container *ngIf="isPriceAvailable">
                            <tr>
                                <td *ngFor="let pri of price" [innerHTML]="pri.title" class="title_space"></td>  <!--GLS-306582 -->
                            </tr>
                            <tr>
                                <td *ngFor="let pri of price">
                                    <div id="priceDesc" class="item-wrapper" [innerHTML]="pri.description"></div>  <!--GLS-306582 -->
                                </td>
                            </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
        </div>
        <div id="scannerSection" class="scanner-div" *ngIf="displayFactTag.length===1" [ngStyle]="{'background': isChromeBook ? '#E6F0F8' : '' }">
            <div id="scannerInfo">
                <app-compare-errors *ngIf="hasErrors" [errorMessage]="scanErrorText"></app-compare-errors>
                <ng-container *ngIf="!isUatEnv">
                    <button class="btn scan-icon-button" (click)="loadScannerComponent()">
                        <img class="scan-image" alt="scan"/>
                    </button><br>
                </ng-container>
                <ng-container *ngIf="isUatEnv"> <!-- GLS UAT testing-->
                    <button class="btn scan-icon-button" (click)="getQrData(qrUrl)">
                        <img class="scan-image" alt="scan" />
                    </button><br>
                </ng-container>
                <span [innerHTML]="scanToAddDevicesText" [ngClass]="setFontIntelDisplayOne ? '':'other_lang'"  [ngStyle]="{'color': isChromeBook || isIntelInside || (isStdAbove11) ? '#262626' : '' }"></span>  <!--GLS-306582 -->
            </div>
        </div>
        <ng-template #tipContent><span [innerHTML]="scanErrorText"></span></ng-template>  <!--GLS-294737-->
    </div>
    <app-compare-errors *ngIf="hasErrors && displayFactTag.length>1" [errorMessage]="scanErrorText"></app-compare-errors>    
    <ng-container *ngIf="!isUatEnv">
        <button class="btn scan-icon-button btn-block" *ngIf="displayFactTag.length>=2" (click)="loadScannerComponent()" [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip" [ngClass]="[(displayFactTag.length===3)?'blur-button':'']">
            <img class="scan-image" alt="scan 1"/>
            <span class="content-space" [innerHTML]="scanToAddDevicesText"></span>  <!--GLS-306582 -->
        </button>
    </ng-container>
    <ng-container *ngIf="isUatEnv"> <!-- GLS UAT testing-->
        <button class="btn scan-icon-button btn-block" *ngIf="displayFactTag.length>=2" (click)="getQrData(qrUrl)"
            [ngbTooltip]="tipContent" triggers="manual" #tt="ngbTooltip"
            [ngClass]="[(displayFactTag.length===3)?'blur-button':'']">
            <img class="scan-image" alt="scan 1" />
            <span class="content-space" [innerHTML]="scanToAddDevicesText"></span>
        </button>
        <label class="urlText">URL:
            <input type="text" class="urltextbx" (change)="  getQrInputData($event)" name="input" required>
        </label>
    </ng-container>
</div>   
<app-qr-scanner *ngIf="loadComponent" (goBacktoComparePage)="getURL($event)" (isScannerClosed)="closeScanner($event)"></app-qr-scanner>