import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { FactTag, FactTagInfo, ProcessorFactTagInfo } from '../../../core/interfaces/fact-tag.i';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { Router, Params } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Utils } from './../../../common/utility/utils';
import { QrConstants } from '../../../core/services/qr-input-service/qr.constants';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';
import { DatePipe } from '@angular/common';
import { LibraryChrombookV2DataService } from 'src/app/core/services/library-chromebook-v2/library-chrombook-v2-data.service';
import { environment } from '../../../../../src/environments/environment';
import { HttpMethodsService } from '../../../../app/common/utility/http-methods.service';
declare let gtag: Function;
declare let html2pdf: any;

@Component({
  selector: 'app-compare-devices',
  templateUrl: './compare-devices.component.html',
  styleUrls: ['./compare-devices.component.scss']
})
export class CompareDevicesComponent implements OnInit, OnDestroy {

  @ViewChild('tt', { static: false }) mytooltip: NgbTooltip;
  @ViewChild('exports', { static: false }) exports: ElementRef;

  factTagMenu: FactTag[] = [];
  isNonIntel: boolean;
  isIntelInside:boolean = false;
  displayFactTag = [];
  loadComponent;
  processor: ProcessorFactTagInfo[] = [];
  graphics: FactTagInfo[] = [];
  memory: FactTagInfo[] = [];
  storage: FactTagInfo[] = [];
  display: FactTagInfo[] = [];
  price: FactTagInfo[] = [];
  compareDevicesText: string;
  scanToAddDevicesText: string;
  saveText: string;
  scannedQrUrl;
  params: Params;
  scanErrorText: string;
  deviceId = [];
  deviceDescription: FactTagInfo[] = [];
  initialScanUrl: string;
  scrollhidden: boolean;
  hasErrors: boolean;
  private ngUnsubscribe = new Subject<boolean>();
  scan = '';
  deviceTypeSave = '';
  deviceModelSave = '';
  hardDriveSave = '';
  manufacturerSave = '';
  osSave = '';
  processorManufacturerSave = '';
  processorGenerationSave = '';
  processorNameSave = '';
  screenResolutionSave = '';
  wifiGenerationSave = '';
  productPriceSave = '';
  deviceIdSave = '';
  appendFinalGa = '';
  deleteFinalGa = '';
  isProcessorAvailable: boolean;
  isGraphicsAvailable: boolean;
  isMemoryAvailable: boolean;
  isStorageAvailable: boolean;
  isDisplayAvailable: boolean;
  isPriceAvailable: boolean;
  qrCode: any;
  isSave = false;
  setFontIntelDisplayOne = false;
  isChromeBook = false;
  operatingSystem: FactTagInfo[] = [];
  isOSAvailable: boolean;
  checkDeviceDetailsFlag: boolean = false; // IJR-1799 fix
  qrUrl: string; //for GLS UAT testing
  isUatEnv: boolean; //for GLS UAT testing
  isOemBadge = false; // GLS-418245
  isAiIpos = false;
  // TODO: Static qr Urls added for testing purpose and can be removed
  // qr1 = 'https://uat2.instorecontactless-uat.com/?rt=rtyFQhEX~stIrRJeV~1a7a9138-599cf3-b91c-428cce748a01~en-us~S11~INT043~11th%20Gen%20Intel®%20Core™%20i7%20processor~GIN000~Intel®%20HD%20Graphics%20630~~256%20GB%20SSD~WIN~~0068B5~~Dell%20Latitude%205580~y~Intel®%20Wi-Fi%206%20(Gig%2B)~N~WUXGA~24.1~17~T4~DE~1920~1200~4~12.8~20.4~INTEL~DP~6~INTEL~cd0bf8d7-7711-425b-b277-5ade78556a84~~~~INTEL_WIFI_6~6E~4~8~8~4~4.7~~~16~N~0~16~Windows*%2010%20Enterprise~Y~256~N~0~N~0~~~~~~N~~~~~0.2~0.3~0.1';
  // qr2 = 'https://uat2.instorecontactless-uat.com/?rt=~~1a7a9138-599c-48f3-b91c-428c48a01~en-us~C8~INT027~8th%20Gen%20Intel®%20Core™%20i5%20processor~~~~60~~Njk5JA==~~~~YY~~~~~8~~HP~~~~~~INTEL~~~~cd0bf8d7-7711-425b-b277-5ade78556a84';
  // qr1 = 'http://localhost:4200/?rt=~~1a7a9138-599c-48f3-b91c-568cce748a01~en-us~C7~INT026~8th%20Gen%20Intel®%20Core™%20i5%20processor~~~~10~~Njk5JA==~~~~YY~~~~~17~~HP~~~~~~INTEL~~~~cd0bf8d7-7711-425b-b277-5ade78556a84';
  isRTL = false;
  clearTimeOutValue: any;
  qrResult: any;
  isStdAbove11: boolean;
  isMTL: boolean;

  constructor(
    private qrService: QrCodeService,
    private libraryV2DataService: LibraryV2DataService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private datepipe: DatePipe,
    private libraryChromebookV2dataService: LibraryChrombookV2DataService,
    private httpService: HttpMethodsService,) { }

  ngOnInit(): void {
    this.isIntelInside=(this.qrService?.qrInput?.ContentType===GlobalConstants.STANDARDGEN12 && GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrService?.qrInput?.ProcessorBadge));
    this.hasErrors = false;   
    this.displayFactTag = [];
    this.deviceId = [];
    this.compareDevicesText = this.libraryV2DataService.getStaticText('tr_compareDevice');
    this.scanToAddDevicesText = this.libraryV2DataService.getStaticText('tr_scanMsg');
    // this.scanErrorText = this.libraryV2DataService.getStaticText('tr_scanErrorText2');
    this.saveText = this.libraryV2DataService.getStaticText('tr_save');
    this.isNonIntel = this.qrService.qrInput.ContentType === 'NI' ? true : false;
    this.isChromeBook = this.qrService.isChromeBook;
    this.isRTL =this.qrService?.qrInput?.Language === "ar-sa" || this.qrService?.qrInput?.Language === "he";
    
    this.factTagMenu = [];
    this.factTagMenu = this.qrService.getRemoteFactTag()
    this.qrService.getComparePageFactTags()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((factTags) => {
        if (factTags.length) {
          this.displayFactTag = factTags;
          this.getFactTagsDetailsToDisplay();
        } else {
          if (this.factTagMenu.length > 0) {
            this.addPriceFacttag();
            this.getDisplayFactTag(this.factTagMenu);
          }
        }
      });
    this.googleAnalytics(this.qrService.qrInput);
    this.scrollhidden = true;
    this.setFontIntelDisplayOne = this.qrService.setenusLang();
    this.isUatEnv = window.location.hostname ===  GlobalConstants.UAT_DOMAIN ? true: false; // GLS Uat testing
    this.isStdAbove11 = !this.isIntelInside && this.qrService?.qrInput?.ContentType===GlobalConstants.STANDARDGEN12 ||
    this.qrService?.qrInput?.ContentType===GlobalConstants.STANDARDGEN13 || this.qrService?.qrInput?.ContentType===GlobalConstants.STANDARDGEN14;
    this.isMTL = GlobalConstants.STD_MTL_BADGES.includes(this.qrService.qrInput.ProcessorBadge) || GlobalConstants.STD_LNL_BADGES.includes(this.qrService.qrInput.ProcessorBadge);
    this.isAiIpos= this.qrService.isAiIpos();
  }

  loadScannerComponent() {
    this.hasErrors = false;
    if (this.displayFactTag.length === 3) {
      this.scanErrorText = this.libraryV2DataService.getStaticText('tr_scanErrorText');
      this.mytooltip.open();
      return;
    } else {
      this.loadComponent = true;
      this.googleAnalyticsService.sendGtag( GlobalConstants.SCAN_TO_COMPARE, { event_category:GlobalConstants.SCAN_TO_COMPARE, event_label: GlobalConstants.SCAN_TO_COMPARE, 'scan_to_add_device': 1 })
    }
  }

  getURL(data) {
    this.loadComponent = false;
    this.scannedQrUrl = data;
    // this.qrCodePopup = '';
    // this.getQrData(this.scannedQrUrl,this.qrCodePopup);
    this.getQrData(this.scannedQrUrl);
  }

  gotoMobContentPage(): void {
    const url = this.qrService.getInitialScannedUrl();
    this.params = {};
    this.params = {
      'rt': url
    } as Params;
    this.qrService.setQrDataFromParams(this.params, GlobalConstants.DETAILS_SCAN);
    this.qrService.setComparePageFactTags(this.displayFactTag);
    this.router.navigate(['/mob-content']);
  }

  // getQrData(dataUrl,qrCodePopup): void {
  getQrData(dataUrl): void { 
    if (this.displayFactTag.length === 3) {
      this.scanErrorText = this.libraryV2DataService.getStaticText('tr_scanErrorText');
      this.mytooltip.open();
      return;
    }
    this.scan += ' Scan |';
    this.googleAnalyticsService.sendGtag(GlobalConstants.SCAN_TO_COMPARE, { event_category:GlobalConstants.SCAN_TO_COMPARE, event_label: GlobalConstants.SCAN_TO_COMPARE, 'scan_to_add_device': 1 })
    if(dataUrl.includes('?qr=')) {      
      const deviceid = dataUrl?.split('?qr=')[1];
      const qrURL = environment.qrCodeURL + deviceid;
      this.qrResult = this.httpService?.get(qrURL)?.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        dataUrl =  data?.qrCodeUrl;
        const url = new URL(dataUrl);
        this.params = {
          'rt': url.searchParams.get('rt')
        } as Params;
        const splitValues = decodeURI(this.params['rt']).split('~');
        this.setQrCodeData(splitValues);
      }, (error) => {            
      });
    } else {
      const url = new URL(dataUrl);
      this.params = {
        'rt': url.searchParams.get('rt')
      } as Params;
      const splitValues = this.params['rt'].split('~');
      this.setQrCodeData(splitValues);
    }
  }

  setQrCodeData(values) {
    this.qrCode = {
      RetailerId: values[0],
      StoreId: values[1],
      DeviceId: values[2],
      Language: values[3],
      ContentType: values[4],
      ProcessorBadge: values[5],
      ProcessorDescription: values[6],
      GraphicsBadge: values[7],
      GraphicsDescription: values[8],
      Memory: values[9],
      Storage: values[10],
      Os: values[11],
      Price: values[12] ? this.decodePrice(values[12]) : '',
      Segment: '#' + values[13],
      StoreLogo: '',
      DeviceName: values[15],
      IsConnected: values[16],
      Wifi: values[17],
      TouchScreen: values[18],
      DisplayType: values[19],
      ScreenSize: values[20],
      Battery: values[21],
      Connectivity: values[22],
      SystemManufacturer: values[23],
      ResolutionWidth: values[24],
      ResolutionHeight: values[25],
      MemoryTotal: values[26],
      DimenstionHeight: values[27],
      DimenstionWidth: values[28],
      CpuManufacture: values[29] === QrConstants.I_TEXT || values[29] === GlobalConstants.INTEL_CAPS ? GlobalConstants.INTEL_CAPS : GlobalConstants.NON_INTEL_CONTENT,
      DeviceType: values[30],
      WifiGeneration: values[31],
      WifiManufacture: values[32].length > 1 ? values[32] : QrConstants.WIFI_MANUFACTURER[values[32]],
      UserId: values[33],
      OptaneMemory: values[34],
      WifiDisplay: values[35],
      WifiGenerationVarient: values[36],
      WifiFamily: values[37],
      VarientType: values[38],
      GraphicsMemory: values[39],
      CacheValue: values[40],
      ThreadValue: values[41],
      CoreValue: values[42],
      ClockSpeed: values[43],
      IsUnlocked: values[44],
      WifiModel: values[45],
      TotalMemory: values[46],
      Optane_Msg: values[47],
      OptaneMemorySize: values[48],
      ActualMemory: values[49],
      OSDescription: values[50],
      IsSSDAvailable: values[51],
      StoorageSSDValues: values[52],
      IsHDDAvailable: values[53],
      StorageHDDValues: values[54],
      IsStorageOptaneAvailable: values[55],
      StorageOptaneValues: values[56],
    };
  
    this.qrService.setQrDataFromParams(this.params, GlobalConstants.COMPARE_SCAN);
    this.factTagMenu = [];
    this.factTagMenu = this.qrService.getRemoteFactTag();
    this.addPriceFacttag();
    this.getDisplayFactTag(this.factTagMenu);
    // this.qrService.setRemoteFactTag(this.displayFactTag[0]);
  }

  getDisplayFactTag(factTags): void {
    // TODO : If Same device check conditions
    if (this.deviceId.length) {
      const device = factTags.find(ele => ele.name === 'deviceId').description;
      if (!this.deviceId.includes(device)) {
        this.displayFactTag.push(factTags);
        this.getFactTagsDetailsToDisplay();
        if (this.qrCode !== undefined) {
          this.isSave = true;
          this.googleAnalytics(this.qrCode);
        }
      } else {
        this.scanErrorText = this.libraryV2DataService.getStaticText('tr_scanErrorText2');
        this.hasErrors = true;
        return;
        //     // this.displayFactTag.push(factTags);
        //     // this.getFactTagsDetailsToDisplay();
      }
    } else {
      this.displayFactTag.push(factTags);
      this.getFactTagsDetailsToDisplay();
    }
  }

  removeDevice(index): void {
    this.hasErrors = false;
    this.displayFactTag.splice(index, 1);
    this.getFactTagsDetailsToDisplay();
    const url = this.qrService.getQrUrlSource();
    url.splice(index, 1);
    this.qrService.setQrUrlSource(url);
    this.scan += ' Delete |';
    this.googleAnalyticsService.sendGtag(GlobalConstants.DELETE_DEVICE, { event_category:GlobalConstants.DELETE_DEVICE, event_label: GlobalConstants.DELETE_DEVICE, 'delete_device': 1 });
    this.removeGa(index);
    this.isSave = false;
  }

  getFactTagsDetailsToDisplay(): void {
    this.processor = [];
    this.graphics = [];
    this.memory = [];
    this.storage = [];
    this.display = [];
    this.price = [];
    this.deviceId = [];
    this.deviceDescription = [];
    this.isProcessorAvailable = false;
    this.isGraphicsAvailable = false;
    this.isMemoryAvailable = false;
    this.isStorageAvailable = false;
    this.isDisplayAvailable = false;
    this.isPriceAvailable = false;
    this.operatingSystem = [];
    this.isOSAvailable = false;
    let isChromeUrl = this.qrService.qrInput.ContentType[0] === GlobalConstants.CHROMEBOOK ? true : false;
    // IIT2-3765 start
    // Created isChromeFlag to check if device is chrome/nonchrome when only one device is available after delete
    let isChromeFlag = false; // IIT2-3765
    if (this.displayFactTag.length === 1) {
      // Checking chromebook facttags only to return true
      isChromeFlag = this.displayFactTag[0]?.some(element => {
        if (element.name === GlobalConstants.EXTENDEDBATTERYLIFE || element.name === GlobalConstants.ENDLESSPROGRAMSANDAPPS ||
            element.name === GlobalConstants.RETHINKSTORAGE || element.name === GlobalConstants.ALWAYSONSECURITY) {
          return true;
        } else {
          return false;
        }
      });
      // Changing isChromeUrl to add empty facttags when only one device is available
      isChromeUrl = isChromeFlag;
    }
    // IIT2-3765 end
     
    this.displayFactTag.forEach((factTagsElememt) => {
      const newSecGraphicsDetails = factTagsElememt.filter(item => item.name === GlobalConstants.GRAPHICS_CARD);
      factTagsElememt.forEach(element => {
        if (GlobalConstants.PROCESSOR_NAME === element.name) {
          if (element.description && element.description !== '--') { // IJR-2562: Added '--' condition as a part of bug fix for all below facttags
            this.isProcessorAvailable = true;
          }
          if (this.isNonIntel) {
            this.processor.push({
              name: element.name,
              title: element.title,
              description: element.description
            });
          } else {
            this.processor.push({
              name: element.name,
              title: element.title,
              description: element.description,
              image: element.image
            });
          }
        } else if (GlobalConstants.GRAPHICS_NAME === element.name) {
          if ((element.description && element.description !== '--') || isChromeUrl) {
            this.isGraphicsAvailable = true;
          }
          this.graphics.push({
            name: element.name,
            title: newSecGraphicsDetails.length > 0 ? newSecGraphicsDetails[0]?.title : element.title  ,
            description: element.description,
            image: newSecGraphicsDetails.length > 0 ? newSecGraphicsDetails[0]?.image : element.image            
          });
        } else if (GlobalConstants.MEMORY_NAME === element.name) {
          if ((element.description && element.description !== '--') || isChromeUrl) {
            this.isMemoryAvailable = true;
          }
          this.memory.push({
            name: element?.name,
            title: element?.title,
            description: element?.description?.replace('&shy;', '-')
          });
        } else if ((GlobalConstants.STORAGE_NAME === element.name) || (GlobalConstants.RETHINKSTORAGE === element.name)) {
          if (element.description && element.description !== '--' || isChromeUrl) {
            this.isStorageAvailable = true;
          }
          this.storage.push({
            name: element.name,
            title: element.title,
            description: element.description
          });
        } else if (GlobalConstants.DISPLAY_NAME === element.name) {
          if ((element.description && element.description !== '--') || isChromeUrl) {
            this.isDisplayAvailable = true;
          }
          this.display.push({
            name: element.name,
            title: element.title,
            description: element.description
          });
        } else if (GlobalConstants.PRICE_NAME === element.name) {
          if (element.description && element.description !== '--') {
            this.isPriceAvailable = true;
          }
          this.price.push({
            name: element.name,
            title: element.title,
            description: element.description
          });
        } else if (GlobalConstants.DEVICE_ID === element.name) {
          this.deviceId.push(element.description);
          this.deviceDescription.push({
            name: element.name,
            title: element.title,
            description: element.description,
            image: element.image
          });
          this.checkOemBadge(this.deviceDescription);
        } else if (GlobalConstants.OPERATING_SYSTEM === element.name) {
          if (element.description && element.description !== '--') {
            this.isOSAvailable = true;
          }
          this.operatingSystem.push({
            name: element.name,
            title: element.title,
            description: element.description
          });
        }
      });
    });
    // this.isPriceAvailable = false;
    // this.price.forEach(element => {
    //   let flag: boolean;
    //   if (element.description){
    //     flag = true;
    //   }else{
    //     flag = false;
    //   }
    //   if (this.isPriceAvailable || flag){
    //      this.isPriceAvailable = true;
    //   } else {
    //     this.isPriceAvailable = false;
    //   }
    // });
  }

  ngOnDestroy() {
    // IJR-1799 fix starts
    if (!this.checkDeviceDetailsFlag && !this.isAiIpos){
      this.gotoMobContentPage(); 
    } 
    this.checkDeviceDetailsFlag = false;
    // IJR-1799 fix ends
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //this.qrService.setComparePageFactTags(this.displayFactTag); // IJR-1799 fix
    clearTimeout(this.clearTimeOutValue);
  }

  checkDeviceDetails(index: number): void {
    this.checkDeviceDetailsFlag = true;
    const url = this.qrService.getQrUrlSource();
    this.params = {};
    this.params = {
      'rt': url[index]
    } as Params;
    this.qrService.setQrDataFromParams(this.params, GlobalConstants.DETAILS_SCAN);
    this.qrService.setComparePageFactTags(this.displayFactTag);
    this.router.navigate(['/mob-content']);
  }

  addPriceFacttag(): void {
    // Checking if any facttag is not detected
    const isChromeUrl = this.qrService.qrInput.ContentType[0] === 'C' ? true : false;
    if (!isChromeUrl) {
      this.factTagMenu.forEach((ele) => {
        if (ele.name === GlobalConstants.GRAPHICS_NAME) {
          let graphicsDescription = this.qrService.qrInput.SecondaryGraphicsCard ? this.qrService.qrInput.SecondaryGraphicsCard : this.qrService.qrInput.GraphicsDescription; // IIT2-2208
          ele.description = graphicsDescription;
        }
      });
    }

    if (!this.factTagMenu.some(ele => ele.name === GlobalConstants.PROCESSOR_NAME)) {
      const facttag = {
        name: GlobalConstants.PROCESSOR_NAME,
        title: this.libraryV2DataService.getStaticText('tr_processorTitle'),
        description: '--'  // IJR-2562: Added '--' condition as a part of bug fix for all below facttags
      };
      this.factTagMenu.push(facttag);
    }
    if (!this.factTagMenu.some(ele => ele.name === GlobalConstants.GRAPHICS_NAME)) {
      const facttag = {
        name: GlobalConstants.GRAPHICS_NAME,
        title: this.libraryV2DataService.getStaticText('tr_graphicTitle'),
        description: '--'
      };
      this.factTagMenu.push(facttag);
    }
    if (!this.factTagMenu.some(ele => ele.name === GlobalConstants.MEMORY_NAME)) {
      const facttag = {
        name: GlobalConstants.MEMORY_NAME,
        title: this.libraryV2DataService.getStaticText('tr_memoryTitle'),
        description: '--'
      };
      this.factTagMenu.push(facttag);
    }
    if (isChromeUrl) {
      // IJR-2382: For chrome, rethink storage will always come with or without value
      const rethinkStorage = this.factTagMenu.find(ele => ele.name === GlobalConstants.RETHINKSTORAGE).description;
      if (!rethinkStorage) {
        this.factTagMenu.find(ele => ele.name === GlobalConstants.RETHINKSTORAGE).description = '--';
      }
    } else {
      if (!this.factTagMenu.some(ele => ele.name === GlobalConstants.STORAGE_NAME)) {
        const facttag = {
          name: GlobalConstants.STORAGE_NAME,
          title: this.libraryV2DataService.getStaticText('tr_storageTitle'),
          description: '--'
        };
        this.factTagMenu.push(facttag);
      }
    }
    if (!this.factTagMenu.some(ele => ele.name === GlobalConstants.DISPLAY_NAME)) {
      const facttag = {
        name: GlobalConstants.DISPLAY_NAME,
        title: this.libraryV2DataService.getStaticText('tr_displayTitle'),
        description: '--'
      };
      this.factTagMenu.push(facttag);
    }
    if (this.qrService.qrInput.Price) {
      const facttag = {
        name: GlobalConstants.PRICE_NAME,
        title: this.libraryV2DataService.getStaticText('tr_priceTitle'),
        description: this.qrService.qrInput.Price
      };
      this.factTagMenu.push(facttag);
    } else {
      const facttag = {
        name: GlobalConstants.PRICE_NAME,
        title: this.libraryV2DataService.getStaticText('tr_priceTitle'),
        description: '--'
      };
      this.factTagMenu.push(facttag);
    }

    if (this.qrService.qrInput.DeviceId) {
      const oemBadge = Utils.getDeviceIconDetails(this.qrService.qrInput.SystemManufacturer);
      const facttag = {
        name: GlobalConstants.DEVICE_ID,
        title: this.qrService.qrInput.DeviceName,
        description: this.qrService.qrInput.DeviceId,
        image: oemBadge
      };
      this.factTagMenu.push(facttag);
    }

    if (isChromeUrl) {
      const facttag = {
        name: GlobalConstants.OPERATING_SYSTEM,
        title: this.libraryChromebookV2dataService.getStaticText('tr_OS'),
        description: this.libraryChromebookV2dataService.getStaticText('tr_Chrome_OS')
      };
      this.factTagMenu.push(facttag);
      this.chromebookCompare(); // IIT2-4550
    } else {
      const facttag = {
        name: GlobalConstants.OPERATING_SYSTEM,
        title: this.libraryV2DataService.getStaticText('tr_OS'),
        description: this.qrService.qrInput.OSDescription ? this.qrService.qrInput.OSDescription: '--'
      };
      this.factTagMenu.push(facttag);
    }
  }

  print(): void {
    this.scrollhidden = false;
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month  = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    let pdfName = this.libraryV2DataService.getStaticText('tr_device_comparison')
    .replace(GlobalConstants.DATE_FORMAT, date)
    .replace(GlobalConstants.MONTH_FORMAT, month)
    .replace(GlobalConstants.YEAR_FORMAT, year);
    const fileName = pdfName.replace(/&nbsp;/g, ' ') + GlobalConstants.PDF;
    let element = document.getElementById('exportsPDF');
    const timing = this.isRTL ? 800 : 600;
    this.clearTimeOutValue = setTimeout(() => {
      if(this.isRTL) {
        element.setAttribute('dir', 'rtl');
        this.addRemoveClass('#procDesc', true, 'compare-proc-desc');
        if(this.qrService?.qrInput?.Language === GlobalConstants.HE_LANGUAGE) {
          this.addRemoveClass('#memoryDesc', true, 'compare-memory-desc');
          this.addRemoveClass('#osDesc', true, 'compare-os-desc');
          this.addRemoveClass('#osTitle', true, 'compare-os-title');
          this.addRemoveClass('#displayDesc', true, 'compare-display-desc');
        }
      }

      let opt = {
        margin:       1,
        filename:     fileName,
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf().set(opt).from(element).save();
      // html2pdf(element, opt); // GLS Fix - 461771

      this.scan += ' Save';

      this.googleAnalyticsService.sendGtag('Compare behavior', { event_category: GlobalConstants.COMPARE_ACTION_TRACKER, event_label: this.scan, 'save_comparision': 1, 'compare_action_tracker': this.scan })
      this.saveSet();
      this.scan = '';
      if(this.isRTL) {
        setTimeout(() => {
          element.removeAttribute('dir');
          this.addRemoveClass('#procDesc', false, 'compare-proc-desc');
          if(this.qrService?.qrInput?.Language === GlobalConstants.HE_LANGUAGE) {
            this.addRemoveClass('#memoryDesc', false, 'compare-memory-desc');
            this.addRemoveClass('#osDesc', false, 'compare-os-desc');
            this.addRemoveClass('#osTitle', false, 'compare-os-title');
            this.addRemoveClass('#displayDesc', false, 'compare-display-desc');
          }

        }, 600);
      }
      
      
    }, timing);
    
  }


  googleAnalytics(qrInputData): void {
    const deviceType = qrInputData.DeviceType === GlobalConstants.DEVICE_TYPE_LAPTOP ?
     GlobalConstants.LAPTOP_NAME : (qrInputData.DeviceType === GlobalConstants.DEVICE_TYPE_TWOINONE ?
      GlobalConstants.TWO_IN_ONE:GlobalConstants.DESKTOP_NAME);
    let diskType;
    if (qrInputData.IsStorageOptaneAvailable === 'Y') {
      diskType = GlobalConstants.OPTANE_HYBRID
    } else if (qrInputData.IsSSDAvailable === 'Y' && qrInputData.IsHDDAvailable === 'Y') {
      diskType = GlobalConstants.SSDANDHDD;
    } else if (qrInputData.IsSSDAvailable === 'N' && qrInputData.IsHDDAvailable === 'Y') {
      diskType = GlobalConstants.HDD_TEXT;
    } else if (qrInputData.IsSSDAvailable === 'Y' && qrInputData.IsHDDAvailable === 'N') {
      diskType = GlobalConstants.SSD_TEXT;
    } else {
      diskType = '';
    }
    const procGen = this.googleAnalyticsService.processorGeneration();

    const oemBadge = Utils.getDeviceIconDetails(this.qrService?.qrInput?.SystemManufacturer);    

    const osType = this.googleAnalyticsService.osValue();

    const resolution = qrInputData.ResolutionWidth + '*' + qrInputData.ResolutionHeight;

     // GA-Processor multi-lang issue start
     const ProcessorDescription = this.googleAnalyticsService.processorFamily();

     // GA-Processor multi-lang issue end 
    this.eventEmitter('device_type', deviceType, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'device_type', deviceType);
    this.eventEmitter('device_model', qrInputData.DeviceName, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'device_name', qrInputData.DeviceName);
    this.eventEmitter('hard_drive_type', diskType, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'hard_drive_type', diskType);
    this.eventEmitter('manufacturer_device', oemBadge, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'manufacturer_device', oemBadge);
    this.eventEmitter('os_name', osType, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'os_name', osType);
    this.eventEmitter('processor_manufacturer_brand_name', qrInputData.CpuManufacture, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'processor_manufacturer_brand_name', qrInputData.CpuManufacture);
    this.eventEmitter('processor_generation_compare', procGen, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'processor_generation_compare', procGen);
    this.eventEmitter('processor_name', ProcessorDescription, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'processor_name', ProcessorDescription);
    this.eventEmitter('ipos_screen_resolution', resolution, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'ipos_screen_resolution', resolution);
    this.eventEmitter('wifi_generation', qrInputData.WifiGeneration || '', GlobalConstants.ADD_TO_COMPARE_SET, 1, 'wifi_generation', qrInputData.WifiGeneration || '');
    this.eventEmitter('deviceid', qrInputData.DeviceId, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'device_id', qrInputData.DeviceId);
    this.eventEmitter('product_price', qrInputData.Price, GlobalConstants.ADD_TO_COMPARE_SET, 1, 'product_price', qrInputData.Price);

    if (!this.isSave) {
      this.deviceTypeSave = deviceType;
      this.deviceModelSave = qrInputData.DeviceName;
      this.hardDriveSave = diskType;
      this.manufacturerSave = oemBadge;
      this.osSave = osType;
      this.processorManufacturerSave = qrInputData.CpuManufacture;
      this.processorGenerationSave = procGen;
      this.processorNameSave = ProcessorDescription;
      this.screenResolutionSave = resolution;
      this.wifiGenerationSave = qrInputData.WifiGeneration;
      // this.deviceId = qrInputData.DeviceId;
      this.productPriceSave = qrInputData.Price;
    } else {
      this.deviceTypeSave = this.appendGa(this.deviceTypeSave, deviceType);
      this.deviceModelSave = this.appendGa(this.deviceModelSave, qrInputData.DeviceName);
      this.hardDriveSave = this.appendGa(this.hardDriveSave, diskType);
      this.manufacturerSave = this.appendGa(this.manufacturerSave, oemBadge);
      this.osSave = this.appendGa(this.osSave, osType);
      this.processorManufacturerSave = this.appendGa(this.processorManufacturerSave, qrInputData.CpuManufacture);
      this.processorGenerationSave = this.appendGa(this.processorGenerationSave, procGen);
      this.processorNameSave = this.appendGa(this.processorNameSave, ProcessorDescription);
      this.screenResolutionSave = this.appendGa(this.screenResolutionSave, resolution);
      this.wifiGenerationSave = this.appendGa(this.wifiGenerationSave, qrInputData.WifiGeneration);
      this.deviceIdSave = this.appendGa(this.deviceIdSave, qrInputData.DeviceId);
      this.productPriceSave = this.appendGa(this.productPriceSave, qrInputData.Price);
    }
  }

  deleteGa(value: string, indexdel: any): string {
    let final = '';
    const temp = [];
    const split = value.split(' | ');
    for (const splitValue of split) {
      temp.push(splitValue);
    }
    temp.splice(indexdel, 1);
    for (let index = 0; index < temp.length; index++) {
      if (index !== (temp.length - 1)) {
        final += temp[index] + ' | ';
      } else {
         final += temp[index];
        }
    }
    return final;
  }

  appendGa(saveValue: string, value: string): string {
    saveValue += ' | ' + value;
    return saveValue;
  }

  removeGa(index: any): void {
    this.deviceTypeSave = this.deleteGa(this.deviceTypeSave, index);
    this.deviceModelSave = this.deleteGa(this.deviceModelSave, index);
    this.hardDriveSave = this.deleteGa(this.hardDriveSave, index);
    this.manufacturerSave = this.deleteGa(this.manufacturerSave, index);
    this.osSave = this.deleteGa(this.osSave, index);
    this.processorManufacturerSave = this.deleteGa(this.processorManufacturerSave, index);
    this.processorGenerationSave = this.deleteGa(this.processorGenerationSave, index);
    this.processorNameSave = this.deleteGa(this.processorNameSave, index);
    this.screenResolutionSave = this.deleteGa(this.screenResolutionSave, index);
    this.wifiGenerationSave = this.deleteGa(this.wifiGenerationSave, index);
    this.deviceIdSave = this.deleteGa(this.deviceIdSave, index);
    this.productPriceSave = this.deleteGa(this.productPriceSave, index);
  }


  saveSet(): void {
    this.eventEmitter('device_type_save', this.deviceTypeSave, GlobalConstants.SAVED_SET, 1, 'device_type_save', this.deviceTypeSave);
    this.eventEmitter('device_model_save', this.deviceModelSave, GlobalConstants.SAVED_SET, 1, 'device_model_save', this.deviceModelSave);
    this.eventEmitter('hard_drive_type_save', this.hardDriveSave, GlobalConstants.SAVED_SET, 1, 'hard_drive_type_save', this.hardDriveSave);
    this.eventEmitter('manufacturer_device_save', this.manufacturerSave, GlobalConstants.SAVED_SET, 1, 'manufacturer_device_save', this.manufacturerSave);
    this.eventEmitter('os_name_save', this.osSave, GlobalConstants.SAVED_SET, 1, 'os_name_save', this.osSave);
    this.eventEmitter('processor_manufacturer_brand_name_save', this.processorManufacturerSave, GlobalConstants.SAVED_SET, 1, 'processor_manufacturer_brand_name_save', this.processorManufacturerSave);
    this.eventEmitter('processor_generation_save', this.processorGenerationSave, GlobalConstants.SAVED_SET, 1, 'processor_generation_save', this.processorGenerationSave);
    this.eventEmitter('processor_name_save', this.processorNameSave, GlobalConstants.SAVED_SET, 1, 'processor_name_save', this.processorNameSave);
    this.eventEmitter('screen_resolution_save', this.screenResolutionSave, GlobalConstants.SAVED_SET, 1, 'screen_resolution_save', this.screenResolutionSave);
    this.eventEmitter('wifi_generation_save', this.wifiGenerationSave, GlobalConstants.SAVED_SET, 1, 'wifi_generation_save', this.wifiGenerationSave);
    this.eventEmitter('deviceid_save', this.deviceIdSave, GlobalConstants.SAVED_SET, 1, 'device_id_save', this.deviceIdSave);
    this.eventEmitter('product_price_save', this.productPriceSave, GlobalConstants.SAVED_SET, 1, 'product_price_save', this.productPriceSave);

    let rank = '1 | 2';
    if (this.displayFactTag.length === 3) {
      rank = '1 | 2 | 3';
    }
    this.eventEmitter('compare_rank_save', rank, GlobalConstants.SAVED_SET, 1,'compare_rank_save', rank);

  }

  public  decodePrice(val): string {
    return  decodeURIComponent(escape(window.atob(val)));
  }

  getQrInputData(dataUrl): void {
    if (dataUrl.currentTarget.value !== undefined) {
      this.qrUrl = dataUrl.currentTarget.value;
      this.getQrData(dataUrl.currentTarget.value);
      dataUrl.currentTarget.value = '';
    }
  }
 
  // IJR-2463
  closeScanner(isScannerClosed) {
    this.loadComponent = false;
  }

  public eventEmitter(eventCategory: string, eventLabel: string, eventAction: string, eventValue: number, dimensionId: string, dimensionValue: string) {
    
    this.googleAnalyticsService.sendGtag( eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      [dimensionId]: dimensionValue
    });
  }

  chromebookCompare() {
    // IIT2-4550
    this.factTagMenu.forEach(element => {
      if(element.name === GlobalConstants.RETHINKSTORAGE) {
        element.title = this.libraryChromebookV2dataService?.getStaticText(GlobalConstants.TR_STORAGE_TITLE);
      }
      if(element.name === GlobalConstants.GRAPHICS_NAME) {
        element.title = this.libraryChromebookV2dataService?.getStaticText(GlobalConstants.TR_GRAPHICS_TITLE);
      }
      if(element.name === GlobalConstants.MEMORY_NAME) {
        element.title = this.libraryChromebookV2dataService?.getStaticText(GlobalConstants.TR_MEMORY_TITLE);
      }
      if(element.name === GlobalConstants.DISPLAY_NAME) {
        element.title = this.libraryChromebookV2dataService?.getStaticText(GlobalConstants.TR_DISPLAY_TITLE);
      }
      if(element.name === GlobalConstants.PRICE_NAME) {
        element.title = this.libraryChromebookV2dataService?.getStaticText(GlobalConstants.TR_PRICE_TITLE);
      }
    })
    // IIT2-4550
  }
  // GLS-418245
  checkOemBadge(deviceDescription) {
    let oemBadgeList = [];
    deviceDescription.forEach(element => {
      if (element?.title) {
        oemBadgeList.push(element?.title)
      }
    })
    this.isOemBadge = oemBadgeList.length !== 0;
  }
  // GLS-418245

  addRemoveClass(elementId: string, isAddOperation: boolean, className: string) {
    const elements = document.querySelectorAll(elementId);
    if (elements?.length > 0) {
      elements.forEach((elem) => {
        if (elem && elem.classList) {
          isAddOperation ? elem.classList.add(className) : elem.classList.remove(className);
        }
      });
    }
  }

}
