export const manufacturerMapping = {
 "AC" : 'ACER',
 "AS" : 'ASUS',
 "DE" : 'DELL',
 "DR" : 'DREAMHACK',
 "FU" : 'FUJITSU',
 "HP" : 'HP',
 "HU" : 'HUAWEI',
 "IN" : 'SYSTEM_INTEL',
 "LE" : 'LENOVO',
 "MI" : 'MICROSOFT',
 "MO" : 'MOTOROLA',
 "MS" : 'MSI',
 "RA" : 'RAZER',
 "SA" : 'SAMSUNG',
 "SO" : 'SONY',
 "TO" : 'TOSHIBA',
 "VI" : 'VIZIO',
 "AL" : "ALIENWARE",
 "GA" : "GATEWAY",  
 "LG" : "LG",       
 "VA" : "VAIO"   
}

export const wifiModels = {
    1 : "KILLER_WIFI_6E_AX1675I",
    2 : "KILLER_WIFI_6E_AX1675S",
    3 : "KILLER_WIFI_6E_AX1675W",
    4 : "KILLER_WIFI_6E_AX1675X",
    5 : "KILLER_WIFI_6E_AX1690",
    6 : "INTEL_WIFI_6_AX101" 
}

export const wifiFamilyValues = {
    1 : "UNKNOWN",
    2 : "INTEL_WIFI_6",
    3 : "INTEL_WIFI_6E",
    4 : "KILLER_WIFI_6",
    5 : "KILLER_WIFI_6E",
    6 : "INTEL_WIFI_7",
    7 : "KILLER_WIFI_7"
}